<template>
  <div>
    <v-dialog
      v-model="show"
      width="500"
      :fullscreen="$store.getters['shared/isMobileDevice']"
    >
      <v-card>
        <v-carousel
          v-if="images.length > 0"
          hide-delimiters
          height="200"
          hide-delimiter-background
          :show-arrows="images.length > 1"
        >
          <template v-slot:prev="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon small>
              <v-icon>
                mdi-chevron-left
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:next="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon small>
              <v-icon>
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </template>
          <v-carousel-item
            v-for="(item, i) in images"
            :key="i"
            :src="item"
          ></v-carousel-item>
        </v-carousel>

        <v-card-title class="title primary--text">
          {{ title }}
        </v-card-title>

        <v-card-text>
          {{ description }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn class="mx-auto" color="primary" text @click="cancel">
            Lukk
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    description: {
      type: String
    },
    images: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    cancel() {
      this.show = false;
      this.$emit("cancel", true);
    }
  },
  computed: {
    show: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
