import baseApi from "@/api/api";

const taBlobLink = {
  bind(el, binding, vnode) {
    el.addEventListener("click", function(event) {
      event.preventDefault();
      var arr = {};
      arr.fileName = vnode.children[0].text;
      arr.guid = vnode.data.attrs.blobid;
      baseApi.downloadFaqAttachment(arr);
    });
  }
};

export default taBlobLink;
