<template>
  <div class="login-bg">
    <v-img
      class="login-trysilhusLogo d-none d-sm-flex"
      width="180"
      :src="require('@/assets/imgs/gfx/trysilhus_logo_hvit2.png')"
    ></v-img>
    <v-row justify="center">
      <v-col cols="11" sm="10">
        <v-card
          v-if="consent && !userDeleted"
          class="mx-auto px-4 px-sm-8 py-4 py-sm-8"
          elevation="6"
          max-width="500"
        >
          <h2 class="mt-1 mb-5 primary--text">
            Lagret informasjon om deg
          </h2>
          <v-text-field
            dense
            v-model="consent.firstName"
            label="Fornavn"
            required
          ></v-text-field>
          <v-text-field
            dense
            v-model="consent.lastName"
            label="Etternavn"
            required
          ></v-text-field>
          <v-text-field
            dense
            v-model="consent.email"
            label="E-post"
            required
          ></v-text-field>
          <v-text-field
            dense
            v-model="consent.mobile"
            label="Mobil"
            type="text"
            required
          ></v-text-field>

          <p class="mb-0 caption">
            Her kan du velge hvordan du vil at vi kan kontakte deg.
          </p>
          <div class="d-flex">
            <v-checkbox
              v-model="consent.acceptSms"
              class="mr-2 mt-0"
              label="SMS"
            ></v-checkbox>
            <v-checkbox
              v-model="consent.acceptEmail"
              class="mx-2 mt-0"
              label="E-post"
            ></v-checkbox>
            <v-checkbox
              v-model="consent.acceptNewsLetter"
              class="mx-2 mt-0"
              label="Nyhetsbrev"
            ></v-checkbox>
          </div>
          <h2 class="title">Samtykke</h2>
          <p class="overline primary--text mb-2" v-if="consent.consentAccepted">
            Samtykke gitt
            {{ consent.consentAccepted | moment("DD.MM.YYYY HH:mm") }}
          </p>
          <p class="caption">{{ consent.consentText }}</p>
          <div>
            <v-btn
              class="mr-2"
              depressed
              small
              color="success"
              dark
              @click="save"
              >Lagre endringer</v-btn
            >
            <v-btn
              depressed
              small
              color="error"
              outlined
              @click="showDialog = true"
              >Slett meg</v-btn
            >
          </div>
        </v-card>
        <v-card
          v-else
          class="mx-auto px-4 px-sm-8 py-4 py-sm-8"
          elevation="6"
          max-width="500"
        >
          <h2 class="mt-1 mb-5 primary--text">
            Lagret informasjon om deg
          </h2>
          <p v-if="userDeleted">Informasjonen om deg har vi slettet.</p>
          <p v-else>Vi kan ikke finne noe informasjon om deg</p>
          <p class="caption">
            Du kan ta kontakt med oss hvis du har spørsmål – telefon
            <a href="tel:32215510">32 21 55 10</a>, e-post
            <a href="mailto:kundesenter@trysilhus.no" title="Epost">
              kundesenter@trysilhus.no
            </a>
          </p>
          <div>
            <v-btn
              class="mr-2"
              depressed
              small
              color="primary"
              dark
              @click="goToTrysilhus"
              >Gå til Trysilhus.no</v-btn
            >
          </div>
        </v-card>
      </v-col>
    </v-row>
    <ConfirmDialog
      v-model="showDialog"
      title="Bekreft sletting"
      info="Er du sikker på at vi skal slette informasjonen vi har lagret om deg?"
      @accept="acceptDelete"
    />
  </div>
</template>

<script>
import api from "@/api/openApi";
import notificationsService from "@/notification.services";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
export default {
  name: "consent",
  data: () => ({
    consent: {},
    showDialog: false,
    userDeleted: false
  }),
  methods: {
    save() {
      api.saveConsent(this.consent).then(() => {
        if (this.consent.deleteMe) {
          this.userDeleted = true;
        }
        notificationsService.success("Endringer lagret");
      });
    },
    acceptDelete(value) {
      if (value) {
        this.consent.deleteMe = true;
        this.save();
      }
    },
    goToTrysilhus() {
      window.location.href = "http://trysilhus.no";
    }
  },
  async created() {
    this.consent = await api.getConsent(this.$route.params.consentGuid);
    if (
      this.consent &&
      this.consent.acceptEmail === undefined &&
      this.consent.acceptNewsLetter === undefined &&
      this.consent.acceptSms === undefined
    ) {
      this.userDeleted = true;
    }
  },
  mounted() {
    if (this.consent.isCustomer) {
      notificationsService.warn(
        "Når du har blitt kunde hos oss må du endre informasjon i Mitt Trysilhus."
      );
      this.$router.push({ name: "profile" });
    }
  },
  components: {
    ConfirmDialog
  }
};
</script>

<style lang="scss">
.login-bg {
  background: url("https://thg.blob.core.windows.net/public-images/login_bg.jpg")
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  .login-trysilhusLogo {
    position: absolute;
    top: 3%;
    left: 2%;
  }
  @media (max-width: 768px) {
    .login-trysilhusLogo {
      left: 50%;
      top: 91%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
