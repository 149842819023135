<template>
  <WebShopContainer
    @next="next"
    intro
    send-purchase-progress="SendOnPurchaseOptions"
  >
    <template slot="navigation">
      <OptionsRoomNavigation />
    </template>
    <IntroVideoComponent
      title="Tilvalg"
      background-image="https://thg.blob.core.windows.net/public-images/tilvalgbanner.jpg"
      videoUrl="https://thg.blob.core.windows.net/publicvideos/Film_tilvalg_FINAL.m4v"
      data-poster="https://thg.blob.core.windows.net/public-images/Camilla_thumbnail.jpg"
    >
      <p>
        Våre leiligheter har blitt utviklet over tid blant annet på bakgrunn av
        tilbakemeldinger fra våre kunder. Boligene kommer derfor med gode
        standardløsninger. Allikevel har du muligheten til å gjøre endringer
        basert på våre tilvalg.
      </p>
      <p>
        På de neste sidene tar vi deg gjennom de ulike kategoriene med tilvalg.
        Når du gjør et tilvalg, vil du se at leilighetsprisen i bunnen av siden
        endrer seg.
      </p>
      <p>
        Merk at du har mulighet til å gå gjennom tilvalgsprosessen på et senere
        tidspunkt hvis du ønsker.
      </p>
    </IntroVideoComponent>
    <IntroWebShopDialog />
  </WebShopContainer>
</template>

<script>
import { mapGetters } from "vuex";
import WebShopContainer from "@/components/store/webStore/shared/WebShopContainer.vue";
import IntroVideoComponent from "@/components/store/webStore/shared/IntroVideoComponent.vue";
import OptionsRoomNavigation from "@/components/store/webStore/options/children/OptionsRoomNavigation.vue";
import IntroWebShopDialog from "@/components/store/webStore/shared/dialogs/IntroWebShopDialog.vue";

IntroVideoComponent;
export default {
  methods: {
    next() {
      if (
        this.sectionInfo &&
        this.sectionInfo.isAtticAvailable &&
        !this.sectionInfo.isAtticIncluded
      ) {
        this.$router.push({
          name: "webShopOptionsAttic"
        });
      } else {
        this.$router.push({
          name: "webShopOptionsCategory",
          params: { categoryId: this.options[0].id }
        });
      }
    }
  },
  computed: {
    ...mapGetters({
      options: "purchase/options",
      sectionInfo: "purchase/sectionInfo"
    })
  },
  components: {
    WebShopContainer,
    IntroVideoComponent,
    OptionsRoomNavigation,
    IntroWebShopDialog
  }
};
</script>
