<template>
  <v-row class="justify-center mb-12">
    <v-col xl="9">
      <v-alert
        v-if="profile && !profile.user.isOwnerToday"
        class="mb-0"
        dense
        text
        type="warning"
      >
        Det er ikke mulig å legge inn en henvendelse når du ikke lenger eier
        seksjonen. Kontakt Trysilhus kundesenter på 32 21 55 10 hvis det er noe
        du lurer på.
      </v-alert>

      <v-row v-if="!$store.getters['shared/isMobileDevice']">
        <v-col cols="12">
          <v-list color="transparent">
            <v-list-item class="px-0 mb-2">
              <v-list-item-avatar>
                <v-icon class="primary white--text">{{
                  $route.name === "requests" ? "mdi-forum" : "mdi-account-group"
                }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  class="primary--text headline font-weight-black"
                >
                  {{
                    $route.name === "requests"
                      ? "Mine henvendelser"
                      : "Sameiets henvendelser"
                  }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Du har {{ openRequests }}
                  {{
                    openRequests === 1
                      ? "åpen henvendelse"
                      : "åpne henvendelser"
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-content
                v-if="getCategory.length > 0"
                class="flex-nowrap mr-10"
              >
                <v-select
                  class="mr-4"
                  v-model="categoryName"
                  :items="getCategory"
                  clearable
                  label="Filtrer etter status"
                  hide-no-data
                  @change="filter"
                ></v-select>
                <v-text-field
                  v-model="searchText"
                  label="Søk..."
                  clearable
                  hide-no-data
                  autocomplete="off"
                ></v-text-field>
              </v-list-item-content>
              <!-- changing listview  -->
              <v-list-item-action class="d-none d-sm-flex mr-1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="setChangeView(!changeView)"
                      v-on="on"
                      fab
                      dark
                      small
                      color="primary"
                    >
                      <v-icon dark>{{
                        changeView ? "mdi-view-module" : "mdi-view-list"
                      }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ changeView ? "Rutenett" : "Liste" }}</span>
                </v-tooltip>
              </v-list-item-action>

              <v-list-item-action class="d-none d-md-flex">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="help" v-on="on" fab dark small color="blue">
                      <v-icon dark>mdi-help</v-icon>
                    </v-btn>
                  </template>
                  <span>Hjelp</span>
                </v-tooltip>
              </v-list-item-action>
              <v-list-item-action
                v-if="profile && profile.user.isOwnerToday"
                class="d-none d-md-flex ml-1"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      @click="newRequest"
                      fab
                      dark
                      small
                      color="green"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Ny henvendelse</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
        </v-col>
      </v-row>

      <SpeedDial goTo="newrequest" save="true" @help="help" />
      <RequestCardList
        v-if="!changeView"
        :data="getData"
        @newRequest="newRequest"
      />
      <RequestList v-if="changeView" :data="getData" @newRequest="newRequest" />
    </v-col>
    <Dialog
      :show="showDialog"
      :title="title"
      :DialogMessage="DialogMessage"
      :DialogAccept="dialogAccept"
      @accept="accept"
    />
    <FilterDialog
      :show="showFilter"
      :getCategory="getCategory"
      @filter="filterMob"
    />
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Dialog from "@/components/dialog/DialogMessage";
import SpeedDial from "@/components/shared/SpeedDial";
import FilterDialog from "@/components/dialog/FilterDialog";
import RequestList from "@/components/requests/RequestList";
import RequestCardList from "@/components/requests/RequestCardList";
export default {
  data() {
    return {
      data: [],
      searchText: "",
      categoryName: "",
      showFilter: { show: false },
      showDialog: { show: false },
      dialogAccept: false,
      title: "",
      DialogMessage: ``,
      filterData: []
    };
  },
  watch: {
    searchText() {
      this.filter();
    }
  },
  methods: {
    ...mapActions({
      setChangeView: "request/changeViewRequests"
    }),
    filterMob(searchText, categoryName) {
      this.categoryName = categoryName;
      this.searchText = searchText;
      this.filter();
    },
    filter() {
      //Filter category and search
      if (this.categoryName && this.searchText) {
        this.filterData = this.getAllData.filter(x => {
          return (
            x.regStatus === this.categoryName &&
            (x.lineId
              .toLocaleLowerCase()
              .includes(this.searchText.toLocaleLowerCase()) ||
              x.name
                .toLocaleLowerCase()
                .includes(this.searchText.toLocaleLowerCase()) ||
              x.regStatus
                .toLocaleLowerCase()
                .includes(this.searchText.toLocaleLowerCase()))
          );
        });
        //Filter on only search
      } else if (this.searchText) {
        this.filterData = this.getAllData.filter(x => {
          return (
            x.lineId
              .toLocaleLowerCase()
              .includes(this.searchText.toLocaleLowerCase()) ||
            x.name
              .toLocaleLowerCase()
              .includes(this.searchText.toLocaleLowerCase()) ||
            x.regStatus
              .toLocaleLowerCase()
              .includes(this.searchText.toLocaleLowerCase())
          );
        });
        //Filter on only category
      } else if (this.categoryName) {
        this.filterData = this.getAllData.filter(x => {
          return x.regStatus === this.categoryName;
        });
        //no filter
      } else {
        this.filterData = [];
      }
    },

    statusRed(data) {
      return (
        data.regStatus === "Lukket" ||
        data.regStatus === "Avslått" ||
        data.regStatus === "Ferdigstilt"
      );
    },
    //Go to new request
    //Open dialog on jointOwnership
    newRequest() {
      if (this.$route.name === "requests") {
        this.$router.push({ name: "newrequest" });
      } else {
        this.title = "Henvendelser som gjelder sameiet";

        this.DialogMessage = `Alle henvendelser som meldes inn her, vil bli synlig for alle i sameiet.`;
        this.dialogAccept = true;
        this.showDialog.show = true;
      }
    },
    //Question dialog
    help() {
      this.title = "Hva er en henvendelse?";
      this.DialogMessage = `Når du melder inn en henvendelse huker du av for om det er en generell henvendelse eller en reklamasjonshenvendelse.
                      Merk at en reklamasjonshenvendelse må meldes inn så snart feilen eller mangelen oppdages. <br/><br/>
                      Har du flere henvendelser du ønsker å melde inn til oss, splitter du disse ved å trykke på pluss-tegnet.`;
      this.dialogAccept = false;
      this.showDialog.show = true;
    },
    //On JointOwnership when user accept message
    accept(value) {
      if (value) {
        this.$router.push({ name: "NewJointOwnership" });
      }
    },
    getNewCommentsCount(complaintsCustomer) {
      //add how many new commets on reqest
      complaintsCustomer.forEach(x => {
        x.newCommentsCount = 0;

        var unreadComments = this.getUnreadComments.data.find(
          y => y.id === x.id
        );

        if (unreadComments) {
          x.newCommentsCount = unreadComments.count;
        }
      });
    }
  },

  computed: {
    ...mapGetters({
      complaintsCustomer: "request/complaintsCustomer",
      complaintsJointOwnership: "request/complaintsJointOwnership",
      mobileNavBtn: "shared/mobileNavBtn",
      changeView: "request/changeViewRequests",
      profile: "shared/profile",
      getUnreadComments: "request/unreadComments"
    }),
    //To show data with filter and with out filter
    getData() {
      if ((this.searchText !== "" && this.searchText) || this.categoryName) {
        return this.filterData;
      } else if (this.getAllData) {
        return this.getAllData;
      }
      return [];
    },
    //To get data hvem filter is on
    getAllData() {
      if (
        this.complaintsCustomer.length > 0 &&
        this.$route.name === "requests" &&
        this.getUnreadComments
      ) {
        this.getNewCommentsCount(this.complaintsCustomer);
        return this.complaintsCustomer;
      } else if (
        this.complaintsJointOwnership.length > 0 &&
        this.$route.name === "JointOwnerships"
      ) {
        return this.complaintsJointOwnership;
      }
      return null;
    },
    //check different categorys in the data
    getCategory() {
      if (this.getAllData) {
        var filterUniq = [...new Set(this.getAllData.map(x => x.regStatus))];
        return filterUniq;
      }
      return [];
    },
    //Counter for open requests
    openRequests() {
      if (this.getAllData) {
        var count = 0;
        this.getAllData.map(x => {
          if (!this.statusRed(x)) {
            count = count + 1;
          }
        });
        return count;
      }
      return 0;
    }
  },
  created() {
    this.$store.watch(
      (state, getters) => getters["shared/mobileNavBtn"],
      newValue => {
        this.showFilter.show = newValue;
      }
    );
  },
  components: {
    Dialog,
    SpeedDial,
    FilterDialog,
    RequestList,
    RequestCardList
  }
};
</script>
