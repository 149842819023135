<template>
  <v-speed-dial
    class="d-block d-md-none mobileDial"
    v-model="mobileBtn"
    fixed
    bottom
    right
    direction="top"
    transition="slide-y-reverse-transition"
  >
    <template v-slot:activator>
      <v-btn v-model="mobileBtn" color="blue darken-2" dark fab>
        <v-icon v-if="mobileBtn">mdi-close</v-icon>
        <v-icon v-else>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-btn v-if="showSave" @click="save" fab dark small color="green">
      <v-icon>mdi-send</v-icon>
    </v-btn>
    <v-btn @click="help" fab dark small color="blue">
      <v-icon>mdi-help</v-icon>
    </v-btn>
    <v-btn
      v-if="newRequest"
      @click="addInquiries"
      fab
      dark
      small
      color="primary"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-btn
      v-if="!newRequest && profile && profile.user.isOwnerToday"
      :to="{ name: goTo }"
      fab
      dark
      small
      color="primary"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-speed-dial>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["goTo", "newRequest", "showSave"],
  data() {
    return {
      mobileBtn: false
    };
  },
  methods: {
    help() {
      this.$emit("help", true);
    },
    addInquiries() {
      this.$emit("addInquiries", true);
    },
    save() {
      this.$emit("save");
    }
  },
  computed: {
    ...mapGetters({
      profile: "shared/profile"
    })
  }
};
</script>
<style lang="scss">
.mobileDial {
  z-index: 8 !important;
  // bottom: 10% !important;
  bottom: calc(10% + env(safe-area-inset-top)) !important;
}
</style>
