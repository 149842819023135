<template>
  <div class="store-intro-bg">
    <v-card
      class="mx-auto"
      elevation="6"
      max-width="550"
      min-width="280"
      width="550"
      min-height="300"
    >
      <div class="login-content pt-9 px-12 pb-12">
        <v-img
          class="mx-auto"
          width="60"
          :src="'https://thg.blob.core.windows.net/public-images/mt_logo.svg'"
        ></v-img>
        <h3 class="headline text-center mt-1 mb-2 primary--text">
          Mitt Trysilhus
        </h3>
        <div>
          <!-- tablet ++-->
          <div class="d-none d-sm-block" v-if="notDeadline">
            <p>
              Det er viktig for oss at du trives i din nye bolig, derfor har vi
              utarbeidet en tilvalgsliste hvis du skulle ønske å gjøre noen
              endringer.
            </p>
            <p>
              På de neste sidene finner du en oversikt over tilgjengelig
              tilvalg, samt beskrivelser og pris. En oppsummering vil klargjøres
              for deg når du er klar for bestilling.
            </p>
            <p class="mb-6">
              Lykke til med planleggingen av ditt nye hjem!
            </p>
          </div>
          <!-- Mobile (shorter text to avoid spacing issues) -->
          <div class="d-block d-sm-none" v-if="notDeadline">
            <p class="body-2">
              Det er viktig for oss at du trives i din nye bolig, derfor har vi
              utarbeidet en tilvalgsliste hvis du ønsker å gjøre endringer.
            </p>
            <p class="body-2">
              De neste sidene finner du en oversikt over tilgjengelig tilvalg,
              samt beskrivelser, pris og oppsummering.
            </p>
            <p class="body-2">
              Lykke til med planleggingen av ditt nye hjem!
            </p>
          </div>

          <div v-if="notDeadline === false">
            <p>
              Fristen for å bestille eller gjøre endringer i tilvalg er
              dessverre utløpt. Har du spørsmål ta kontakt med Trysilhus
              kundesenter på <a href="tel:32215510">32 21 55 10</a>.
            </p>
          </div>
          <div v-if="notDeadline === null">
            <div v-for="n in 2" :key="n">
              <v-skeleton-loader
                ref="skeleton"
                type="list-item-three-line"
                class="mx-auto"
              ></v-skeleton-loader>
            </div>
          </div>

          <div class="text-center">
            <v-row>
              <template>
                <v-col
                  v-if="
                    notDeadline !== false &&
                      (optionsAvailable || optionsAvailable === null)
                  "
                  class="pb-0"
                  cols="12"
                  :sm="
                    notDeadline !== false &&
                    (colorAvailable || optionsAvailable === null)
                      ? 6
                      : 12
                  "
                  :md="
                    notDeadline !== false &&
                    (colorAvailable || optionsAvailable === null)
                      ? 6
                      : 12
                  "
                >
                  <v-btn
                    v-if="optionsAvailable"
                    :disabled="!optionsNotDeadline"
                    min-width="100%"
                    class="py-5 py-md-4"
                    large
                    depressed
                    color="primary"
                    :to="{ name: 'store' }"
                  >
                    Tilvalgsbutikk
                    <v-badge
                      v-if="changesConfirmed > 0 && optionsNotDeadline"
                      class="badgePosition"
                      :content="changesConfirmed"
                      color="warning"
                      overlap
                    >
                      <v-icon>mdi-cart</v-icon>
                    </v-badge>
                  </v-btn>

                  <v-skeleton-loader
                    v-else
                    ref="skeleton"
                    type="button"
                    style="width: 100%;"
                  ></v-skeleton-loader>
                </v-col>
                <v-col
                  v-if="
                    notDeadline !== false &&
                      (colorAvailable || colorAvailable === null)
                  "
                  class="pb-0"
                  cols="12"
                  :sm="
                    notDeadline !== false &&
                    (optionsAvailable || colorAvailable === null)
                      ? 6
                      : 12
                  "
                  :md="
                    notDeadline !== false &&
                    (optionsAvailable || colorAvailable === null)
                      ? 6
                      : 12
                  "
                >
                  <v-btn
                    v-if="colorAvailable"
                    :disabled="!colorNotDeadline"
                    min-width="100%"
                    class="py-5 py-md-4"
                    large
                    depressed
                    color="primary"
                    :to="{ name: 'colors' }"
                  >
                    Fargevelger
                    <v-badge
                      v-if="colorCart.changes > 0 && colorNotDeadline"
                      class="badgePosition"
                      :content="colorCart.changes"
                      color="warning"
                      overlap
                    >
                      <v-icon>mdi-cart</v-icon>
                    </v-badge>
                  </v-btn>
                  <v-skeleton-loader
                    v-else
                    ref="skeleton"
                    type="button"
                    style="width: 100%;"
                  ></v-skeleton-loader>
                </v-col>
              </template>
              <v-col cols="12">
                <v-btn
                  v-if="confirmed"
                  min-width="100%"
                  class="py-5 py-md-4"
                  large
                  depressed
                  color="info"
                  :to="{ name: 'summary' }"
                  >Kvittering
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import optionsService from "@/options.services";

export default {
  data() {
    return {
      optionsService
    };
  },

  computed: {
    ...mapGetters({
      storeCart: "options/cart",
      sectionConfirme: "options/sectionConfirme",
      changedCartOfConfirme: "options/changedCartOfConfirme",
      alldeadline: "options/alldeadline",
      categories: "options/categories",

      colorCart: "color/colorCart",
      optionsReady: "shared/optionsReady",
      colorAvailable: "shared/colorAvailable",
      optionsAvailable: "shared/optionsAvailable",
      isLoading: "shared/loading",
      colorAvailableInfo: "shared/colorAvailableInfo"
    }),
    // notConfirmed() {
    //   if (this.storeCart.length === undefined) {
    //     let countNotConfirme = 0;
    //     this.storeCart.groups.forEach(x => {
    //       x.categories.forEach(y => {
    //         y.options.forEach(options => {
    //           if (options.type !== 1 && options.selected === true) {
    //             countNotConfirme = countNotConfirme + 1;
    //           }
    //         });
    //       });
    //     });

    //     return countNotConfirme;
    //   }
    //   return 0;
    // },
    confirmed() {
      var sectionConfirmed = false;
      var colorConfirmed = false;
      if (this.sectionConfirme && this.optionsAvailable) {
        // return this.sectionConfirme.options.length > 0 ? true : false;
        //Check if section has anu confirmed
        sectionConfirmed =
          this.sectionConfirme.options.filter(x => x.type !== 1).length > 0
            ? true
            : false;
      }
      if (this.colorAvailable) {
        //Check if color has any confirmed
        colorConfirmed = this.colorCart.confirmed;
      }
      //Retrun true if any is true
      return sectionConfirmed || colorConfirmed;
    },
    // onlyConfirmedNoLimit() {
    //   if (
    //     this.sectionConfirme &&
    //     this.storeCart.length === undefined &&
    //     this.categories
    //   ) {
    //     var categorie = [];
    //     this.categories.map(x => {
    //       x.children.map(y => categorie.push(y.name));
    //     });
    //     // Confirmed and categori have deadline
    //     var confirmed = this.sectionConfirme.options.filter(x => {
    //       return x.type !== 1 && categorie.includes(x.categoryName);
    //     });
    //     // selected and categori have deadline
    //     this.storeCart.groups.forEach(x => {
    //       x.categories.forEach(y => {
    //         y.options.forEach(options => {
    //           if (
    //             options.type !== 1 &&
    //             options.selected === true &&
    //             categorie.includes(y.name)
    //           ) {
    //             confirmed.push(options);
    //           }
    //         });
    //       });
    //     });
    //     return confirmed.length === 0 ? true : false;
    //   }
    //   return true;
    // },
    optionsOpen() {
      if (this.sectionConfirme.options.length !== 0) {
        //If option is added before 15.05.2020 it vil not be posible to change options
        //It vil be posible to si the summary
        const show = this.sectionConfirme.options.some(x => {
          return moment(x.createdDate).isAfter(moment("2020-05-16").format());
        });
        return show;
      }

      return true;
    },
    changesConfirmed() {
      var count = 0;
      if (this.changedCartOfConfirme) {
        count =
          count +
          (this.sectionConfirme.options.length > 0
            ? optionsService.countChangeOfConfime(this.changedCartOfConfirme)
            : this.storeCart.cartCount);
      }
      // return 0;
      return count;
    },
    optionsNotDeadline() {
      // if non of them are loading and options is true in Album
      // else if one of them is loading. return null (loading)
      //optionsOpen check if some if options is added before 16.05.20. Then it is added in the old module
      if (
        this.optionsAvailable &&
        this.alldeadline !== null &&
        this.optionsOpen
      ) {
        // return this.alldeadline || this.onlyConfirmedNoLimit;
        return this.alldeadline;
      } else if (this.optionsAvailable === null || this.alldeadline === null) {
        return null;
      } else {
        return false;
      }
    },
    colorNotDeadline() {
      //Need to fix
      if (this.colorAvailable && this.colorAvailableInfo) {
        return this.colorAvailableInfo.ready;
      } else if (this.colorAvailable === null) {
        return null;
      } else {
        return false;
      }
    },
    notDeadline() {
      // check if data is stil loading. When some of them are null. Then they are loading
      if (this.optionsNotDeadline === null) {
        return null;
      } else if (this.colorNotDeadline === null) {
        return null;
      } else {
        return this.optionsNotDeadline || this.colorNotDeadline;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.badgePosition {
  position: absolute;
  right: -7px;
}
</style>

<style lang="scss">
.store-intro-bg {
  background: url("../../assets/imgs/store_intro.jpg") no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
</style>
