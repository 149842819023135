<template>
  <div>
    <h3 class="darkgrey--text mt-4">
      Estimert ferdigstillelse
    </h3>
    <p v-if="value">
      Selgers frist til å ha boligen klar til overtakelse fastsettes til
      {{ value | moment("DD.MM.YYYY") }}
    </p>
    <ul class="mb-4">
      <li class="mb-3">
        Selgeren kan kreve overtakelse inntil 4 måneder før den avtalte fristen
        til å ha boligen klar for overtakelse.
      </li>
      <li class="mb-3">
        Selger skal skriftlig varsle om dette minimum 2 måneder før det nye
        overtakelsestidspunktet.
      </li>
      <li>
        Det eksakte overtakelsestidspunktet skal gis med 14 kalenderdagers
        skriftlig varsel. Det beregnes dagmulkt fra det nye
        overtakelsestidspunktet.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    }
  }
};
</script>
