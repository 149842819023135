import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getData.events.length > 0 && !_vm.getData.sectionFinish)?_c(VRow,{staticClass:"showcase-row py-12 white",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c(VCol,{staticClass:"d-flex flex-column flex-md-row d-md-inline-flex py-xl-12",attrs:{"xl":"8"}},[_c('div',{staticClass:"w-100 d-none d-md-flex"},[_c(VWindow,{staticClass:"w-100",attrs:{"vertical":""},model:{value:(_vm.getData.countHasDone),callback:function ($$v) {_vm.$set(_vm.getData, "countHasDone", $$v)},expression:"getData.countHasDone"}},_vm._l((_vm.getData.events),function(previewItem){return _c(VWindowItem,{key:previewItem.id},[_c('div',{staticClass:"showcase-img"},[(previewItem.imageName)?_c(VImg,{attrs:{"src":("https://thg.blob.core.windows.net/" + (previewItem.imageName)),"lazy-src":("https://thg.blob.core.windows.net/" + (previewItem.imageName))}}):_vm._e()],1)])}),1),_c(VItemGroup,{staticClass:"align-self-center",attrs:{"mandatory":""},model:{value:(_vm.getData.countHasDone),callback:function ($$v) {_vm.$set(_vm.getData, "countHasDone", $$v)},expression:"getData.countHasDone"}},[_c('h3',{staticClass:"ml-12 mb-1"},[_vm._v("Tidslinje")]),_vm._l((_vm.getData.events),function(previewItem){return _c(VItem,{key:previewItem.id,staticClass:"showcase-btn ml-12",attrs:{"active-class":"showcase-btn-active"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('div',{staticClass:"frontpage-timeline-btns"},[_c(VBtn,{attrs:{"input-value":active,"text":""},on:{"click":toggle}},[(previewItem.hasDone && previewItem.color !== 'yellow')?_c(VIcon,{attrs:{"color":"success","left":""}},[_vm._v(" mdi-checkbox-marked-circle-outline ")]):_vm._e(),(previewItem.color === 'yellow')?_c(VIcon,{staticClass:"hammerAnimation",attrs:{"left":"","color":"yellow"}},[_vm._v(" mdi-hammer ")]):_vm._e(),(!previewItem.hasDone)?_c(VIcon,{attrs:{"left":"","color":previewItem.color}},[_vm._v(" mdi-checkbox-blank-circle-outline ")]):_vm._e(),_c('span',{class:{
                  'grey--text': !previewItem.hasDone,
                  'yellow--text': previewItem.color === 'yellow',
                  'primary--text': previewItem.hasDone,
                  'pl-2': previewItem.color === 'yellow'
                }},[_vm._v(_vm._s(previewItem.nameMT ? previewItem.nameMT : previewItem.resourceName))])],1)],1)]}}],null,true)})})],2)],1),_c(VCard,{staticClass:"d-block d-md-none",attrs:{"elevation":"0","tile":""}},[_c('div',{staticClass:"showcase-text mb-2"},[_c('h2',[_vm._v("Tidslinje")])]),_c(VCarousel,{attrs:{"show-arrows":false,"hide-delimiters":"","height":"auto"},model:{value:(_vm.getData.countHasDone),callback:function ($$v) {_vm.$set(_vm.getData, "countHasDone", $$v)},expression:"getData.countHasDone"}},_vm._l((_vm.getData.events),function(previewItem){return _c(VCarouselItem,{key:previewItem.id},[(previewItem.imageName)?_c(VImg,{attrs:{"src":("https://thg.blob.core.windows.net/" + (previewItem.imageName)),"lazy-src":("https://thg.blob.core.windows.net/" + (previewItem.imageName)),"height":"200px","aspect-ratio":"1.65"}}):_vm._e(),_c(VCardActions,{staticClass:"mt-4"},[_c(VListItem,[_c(VRow,{attrs:{"align":"center","justify":"end"}},[_c(VListItemContent,[_c(VListItemTitle,{class:{
                      'grey--text': !previewItem.hasDone,
                      'yellow--text': previewItem.color === 'yellow',
                      'primary--text': previewItem.hasDone,
                      'font-weight-bold': true
                    }},[(
                        previewItem.hasDone && previewItem.color !== 'yellow'
                      )?_c(VIcon,{attrs:{"color":"success","left":""}},[_vm._v(" mdi-checkbox-marked-circle-outline ")]):_vm._e(),(previewItem.color === 'yellow')?_c(VIcon,{staticClass:"hammerAnimation",attrs:{"left":"","color":"yellow"}},[_vm._v(" mdi-hammer ")]):_vm._e(),(!previewItem.hasDone)?_c(VIcon,{attrs:{"left":"","color":previewItem.color}},[_vm._v(" mdi-checkbox-blank-circle-outline ")]):_vm._e(),_vm._v(" "+_vm._s(previewItem.resourceName)+" ")],1)],1),(_vm.getData.countHasDone !== 0)?_c(VBtn,{staticClass:"mr-2",attrs:{"light":"","fab":"","small":""},on:{"click":function($event){_vm.getData.countHasDone--}}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1):_vm._e(),(_vm.getData.countHasDone !== _vm.getData.events.length - 1)?_c(VBtn,{attrs:{"light":"","fab":"","small":""},on:{"click":function($event){_vm.getData.countHasDone++}}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1):_vm._e()],1)],1)],1)],1)}),1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }