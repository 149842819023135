import notification from "@/notification.services";
import purchaseApi from "@/api/purchaseApi";
import documentsApi from "@/api/documentsApi";

export default {
  namespaced: true,

  state: {
    unreadDocuments: null
  },
  getters: {
    unreadDocuments: state => {
      return state.unreadDocuments;
    }
  },
  mutations: {
    unreadDocuments(state, data) {
      state.unreadDocuments = data;
    }
  },
  actions: {
    setUnreadDocuments({ commit }) {
      return new Promise((resolve, reject) => {
        documentsApi
          .documentsUnread()
          .then(response => {
            commit("unreadDocuments", response);
            resolve(response);
          })
          .catch(error => {
            notification.error(error.response.data);
            reject(false);
          });
      });
    }
  }
};
