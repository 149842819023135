<template>
  <div>
    <WebShopContainer :title="title" :leftTitle="deadline" @next="next">
      <template slot="navigation">
        <OptionsRoomNavigation />
      </template>
      <template v-if="this.data && this.data.description" slot="text">
        <div v-html="this.data.description"></div>
      </template>

      <div v-if="!data && !isAtticWithNoOptions" class="pt-6">
        <p>
          Kan ikke finne tilvalg!
        </p>

        <v-btn color="primary" depressed :to="{ name: 'webShopOptionsIntro' }">
          Gå tilbake
        </v-btn>
      </div>

      <v-row class="mt-6 mb-0">
        <v-col
          v-for="(n, index) in category"
          :key="index"
          cols="12"
          sm="6"
          md="4"
        >
          <OptonsCategoriCard
            :data="n"
            @click="goToCategori"
            :expired="expired"
            :notAvailable="categoryNotAvailable(n.isAtticCategory)"
          />
        </v-col>
      </v-row>
    </WebShopContainer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import WebShopContainer from "@/components/store/webStore/shared/WebShopContainer.vue";
import OptionsRoomNavigation from "@/components/store/webStore/options/children/OptionsRoomNavigation.vue";
import OptonsCategoriCard from "@/components/store/webStore/options/children/OptonsCategoriCard.vue";

export default {
  methods: {
    ...mapActions({
      updateProfile: "purchase/updateProfile",
      changeSelectedOptions: "purchase/changeSelectedOptions"
    }),
    goToCategori(data) {
      this.$router.push({
        name: "webShopOptionsItems",
        params: { id: data.id }
      });
    },
    next() {
      var atticIncluded =
        this.sectionInfo.isAtticAvailable && this.sectionInfo.isAtticIncluded;
      var atticAdded =
        this.sectionInfo.isAtticAvailable &&
        !this.sectionInfo.isAtticIncluded &&
        this.profile &&
        this.profile.includeAttic;

      var data = this.options.filter(x => {
        return x.isAtticCategory ? atticIncluded || atticAdded : true;
      });

      var index = data.findIndex(
        x => x.id === parseInt(this.$route.params.categoryId)
      );

      if (index !== -1 && index + 1 < data.length) {
        this.$router.push({
          name: "webShopOptionsCategory",
          params: { categoryId: data[index + 1].id }
        });
      } else {
        this.$router.push({
          name: "webShopColorIntro"
        });
      }
    },
    categoryNotAvailable(isAtticCategory) {
      if (this.data || this.sectionInfo) {
        if (this.sectionInfo && this.sectionInfo.isAtticIncluded) {
          return false;
        } else {
          return (
            (this.data.isAtticCategory || isAtticCategory) && !this.atticAdded
          );
        }
      }

      return false;
    }
  },

  computed: {
    ...mapGetters({
      options: "purchase/options",
      profile: "purchase/profile",
      selectedOptions: "purchase/selectedOptions",
      sectionInfo: "purchase/sectionInfo",
      selectedOptionsList: "purchase/selectedOptionsList"
    }),
    data() {
      if (this.options && this.$route.params.categoryId) {
        var data = this.options.find(x => {
          return x.id === parseInt(this.$route.params.categoryId);
        });

        return data;
      }

      return {};
    },
    expired() {
      if (this.data) {
        return this.data.deadlineExpired;
      }
      return false;
    },
    title() {
      if (this.data && this.data.name) {
        return this.data.name + (this.expired ? " (Frist utgått)" : "");
      } else if (this.isAtticWithNoOptions) {
        return "Loft";
      }
      return null;
    },
    deadline() {
      if (this.data && this.data.deadline) {
        return "Frist: " + moment(this.data.deadline).format("DD.MM.YYYY");
      }
      return "";
    },

    category() {
      if (this.data) {
        return this.data.children;
      }

      return [];
    },
    atticAdded() {
      if (this.profile) {
        return this.profile.includeAttic ? this.profile.includeAttic : false;
      }
      return false;
    },
    totalPriceIncludeAttic() {
      //Total price including attic (Not selected and selected)
      if (this.sectionInfo) {
        var tot = this.sectionInfo.estimatesTotalAmount;
        if (this.selectedOptionsList) {
          tot = tot + this.selectedOptionsList.totalPrice;
        }
        if (this.sectionInfo.atticPrice) {
          tot = tot + this.sectionInfo.atticPrice;
        }
        return tot;
      }
      return 0;
    },
    isAtticWithNoOptions() {
      if (
        this.sectionInfo &&
        this.sectionInfo.isAtticAvailable &&
        !this.sectionInfo.isAtticIncluded &&
        parseInt(this.$route.params.categoryId) === -1
      ) {
        return true;
      }
      return false;
    }
  },
  components: {
    WebShopContainer,
    OptionsRoomNavigation,
    OptonsCategoriCard
  }
};
</script>
