<template>
  <div>
    <v-card class="mb-6">
      <v-list class="py-0">
        <v-list-group
          active-class="grey lighten-3"
          prepend-icon="mdi-format-paint"
          :value="open"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ room.name }}</v-list-item-title>
            <v-chip
              style="min-width: 100px;"
              class="justify-center"
              x-small
              outlined
              :color="totalPrice > 0 ? 'success' : 'grey'"
            >
              Total:
              {{ optionsService.formatPrice(totalPrice) }}
            </v-chip>
          </template>

          <v-list class="pb-4">
            <v-list-item>
              <div class="d-flex" style="width: 100%;">
                <v-list-item-content>
                  <v-list-item-subtitle class="caption">
                    Erstatter standard
                  </v-list-item-subtitle>
                  <v-list-item-title
                    class="body-1 font-weight-bold primary--text"
                    >{{ room.name }} fargevalg</v-list-item-title
                  >
                </v-list-item-content>

                <v-list-item-action style="height: 35px;">
                  <v-list-item-action-text>
                    <v-btn
                      @click="resetColor(room)"
                      :disabled="disabled ? disabled : disabledReset(room)"
                      x-small
                      depressed
                      outlined
                      color="grey"
                    >
                      Tilbakestill
                    </v-btn>
                  </v-list-item-action-text>
                </v-list-item-action>
              </div>
            </v-list-item>
            <v-list-item>
              <!-- dialog -->
              <template>
                <v-btn
                  :class="{ 'disable-btn': disabled }"
                  @click="openDialog(room)"
                  class="justify-space-between"
                  :color="room.hexColor"
                  block
                  height="30"
                >
                  <v-chip x-small>
                    {{ room.ncs }}
                  </v-chip>
                  <v-chip x-small>
                    {{
                      optionsService.formatPrice(
                        room[typePrice] ? room[typePrice] : 0
                      )
                    }}
                  </v-chip>
                </v-btn>
              </template>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <!-- constrastWalls -->
          <template
            v-if="room && room.constrastWalls && room.constrastWalls.length > 0"
          >
            <v-list class="grey lighten-4">
              <v-list-item>
                <div class="d-flex" style="width: 100%;">
                  <v-list-item-content>
                    <v-list-item-subtitle class="caption">
                      Erstatter standard
                    </v-list-item-subtitle>
                    <v-list-item-title
                      class="body-1 font-weight-bold primary--text"
                      >{{ room.name }} kontrastvegger</v-list-item-title
                    >
                  </v-list-item-content>

                  <!-- <v-list-item-action>
                      <v-list-item-action-text>
                        <v-btn x-small depressed outlined color="grey">
                          Tilbakestill
                        </v-btn>
                      </v-list-item-action-text>
                    </v-list-item-action> -->
                </div>
              </v-list-item>
            </v-list>
            <v-list
              v-for="contrastWall in room.constrastWalls"
              :key="contrastWall.name"
              dense
              class="grey lighten-4 d-sm-flex"
            >
              <v-list-item style="max-width: 180px;">
                <v-checkbox
                  v-model="contrastWall.active"
                  hide-details
                  :label="contrastWall.name"
                  class="mt-0"
                  :disabled="disabled"
                  @click.stop="addDeleteCheckbox(contrastWall)"
                ></v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-btn
                  :class="{ 'disable-btn': disabled }"
                  @click="openDialog(contrastWall, room.name)"
                  class="justify-space-between"
                  :color="contrastWall.hexColor"
                  block
                  height="30"
                  :disabled="!contrastWall.active"
                >
                  <v-chip x-small>
                    {{ contrastWall.ncs }}
                  </v-chip>
                  <v-chip x-small>
                    {{
                      optionsService.formatPrice(
                        contrastWall[typePrice] ? contrastWall[typePrice] : 0
                      )
                    }}
                  </v-chip>
                </v-btn>
              </v-list-item>
            </v-list>
          </template>
        </v-list-group>
      </v-list>
    </v-card>
    <AcceptCancel
      v-model="showContrastWallAccept"
      title="Slette kontrastvegg"
      DialogMessage="Er du sikker på at du vil slette kontrastvegg?"
      @confirme="deleteContrastwall"
      @cancel="cancelDeleteContrastwall"
    />
  </div>
</template>

<script>
import optionsService from "@/options.services";
import AcceptCancel from "@/components/dialog/AcceptCancel";
export default {
  props: {
    data: {
      required: true
    },
    // disabledReset: {
    //   default: false
    // },
    open: {
      type: Boolean,
      default: false
    },
    typePrice: {
      default: "price"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showContrastWallAccept: false,
      optionsService
    };
  },
  methods: {
    openDialog(data, constrastWallRoomName) {
      this.room;
      if (constrastWallRoomName) {
        var parentRoomColor = this.room;
      }
      this.$emit("openDialog", {
        data,
        constrastWallRoomName,
        parentRoomColor
      });
    },
    resetColor(data) {
      this.$emit("resetColor", data);
    },
    reset() {
      //   this.deleteFromCart.deleteContrastWalls = true;
      this.$emit("reset", true);
    },
    addDeleteCheckbox(contrastWall) {
      if (!contrastWall.active && contrastWall[this.typePrice] > 0) {
        this.contrastWallDelete = contrastWall;
        this.showContrastWallAccept = true;
      } else if (contrastWall.active && !contrastWall[this.typePrice]) {
        contrastWall.active = true;
      } else if (!contrastWall.active && !contrastWall[this.typePrice]) {
        contrastWall.active = false;
      }
    },
    deleteContrastwall() {
      //Set state to 0 to sende confriremd with api
      //state -1 if not confirmed
      if (this.contrastWallDelete.isConfirmed) {
        this.contrastWallDelete.state = 0;
      } else {
        this.contrastWallDelete.state = -1;
      }
      this.$emit("deleteContrastwall", this.contrastWallDelete);
    },
    cancelDeleteContrastwall() {
      this.contrastWallDelete.active = true;
    },
    disabledReset(data) {
      if (data[this.typePrice] > 0) {
        return false;
      } else if (data.constrastWalls && data.constrastWalls.length > 0) {
        var haveChanged = data.constrastWalls.some(x => x[this.typePrice] > 0);
        return !haveChanged;
      }
      return true;
    }
  },
  computed: {
    room: {
      get: function() {
        return this.data;
      },
      set: function(value) {
        this.$emit("input", value);
      }
    },
    totalPrice() {
      var tot = 0;
      if (this.room) {
        if (this.room[this.typePrice]) {
          tot = tot + this.room[this.typePrice];
        }
        if (this.room.constrastWalls) {
          this.room.constrastWalls.forEach(x => {
            if (x[this.typePrice]) {
              tot = tot + x[this.typePrice];
            }
          });
        }
      }

      return tot;
    }
  },
  components: {
    AcceptCancel
  }
};
</script>

<style lang="scss" scoped>
.disable-btn {
  pointer-events: none;
}
</style>
