import contentDisposition from "content-disposition";

function b64toBlob(b64Data, contentType = "", sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

function transformDownload(data, headers) {
  var fileName;
  var contentType;
  var contentLength;

  var cd = headers["content-disposition"];

  if (cd) {
    var disposition = contentDisposition.parse(cd);
    fileName = disposition.parameters.filename;
  }

  if (!fileName) {
    fileName = "filnavn.mangler";
  }

  contentType = headers["content-type"];
  contentLength = headers["content-length"];

  var newData = {};
  newData.blob = data;
  newData.name = fileName;
  newData.contentType = contentType;
  newData.fileSize = contentLength;

  return newData;
}

export default { b64toBlob, transformDownload };
