<template>
  <v-row
    class="showcase-row py-12 white"
    no-gutters
    justify="center"
    align="center"
    v-if="getData.events.length > 0 && !getData.sectionFinish"
  >
    <v-col
      class="d-flex flex-column flex-md-row d-md-inline-flex py-xl-12"
      xl="8"
    >
      <!-- window display -->
      <div class="w-100 d-none d-md-flex">
        <v-window class="w-100" v-model="getData.countHasDone" vertical>
          <v-window-item
            v-for="previewItem in getData.events"
            :key="previewItem.id"
          >
            <div class="showcase-img">
              <v-img
                v-if="previewItem.imageName"
                :src="
                  `https://thg.blob.core.windows.net/${previewItem.imageName}`
                "
                :lazy-src="
                  `https://thg.blob.core.windows.net/${previewItem.imageName}`
                "
              />
            </div>
          </v-window-item>
        </v-window>
        <v-item-group
          class="align-self-center"
          v-model="getData.countHasDone"
          mandatory
        >
          <h3 class="ml-12 mb-1">Tidslinje</h3>
          <v-item
            v-for="previewItem in getData.events"
            :key="previewItem.id"
            v-slot:default="{ active, toggle }"
            class="showcase-btn ml-12"
            active-class="showcase-btn-active"
          >
            <div class="frontpage-timeline-btns">
              <v-btn :input-value="active" @click="toggle" text>
                <v-icon
                  v-if="previewItem.hasDone && previewItem.color !== 'yellow'"
                  color="success"
                  left
                >
                  mdi-checkbox-marked-circle-outline
                </v-icon>
                <v-icon
                  v-if="previewItem.color === 'yellow'"
                  class="hammerAnimation"
                  left
                  color="yellow"
                >
                  mdi-hammer
                </v-icon>
                <v-icon
                  left
                  v-if="!previewItem.hasDone"
                  :color="previewItem.color"
                >
                  mdi-checkbox-blank-circle-outline
                </v-icon>
                <span
                  :class="{
                    'grey--text': !previewItem.hasDone,
                    'yellow--text': previewItem.color === 'yellow',
                    'primary--text': previewItem.hasDone,
                    'pl-2': previewItem.color === 'yellow'
                  }"
                  >{{
                    previewItem.nameMT
                      ? previewItem.nameMT
                      : previewItem.resourceName
                  }}</span
                >
              </v-btn>
            </div>
          </v-item>
        </v-item-group>
      </div>
      <!-- carousel display for MOBILE -->
      <v-card elevation="0" tile class="d-block d-md-none">
        <div class="showcase-text mb-2">
          <h2>Tidslinje</h2>
        </div>
        <v-carousel
          v-model="getData.countHasDone"
          :show-arrows="false"
          hide-delimiters
          height="auto"
        >
          <v-carousel-item
            v-for="previewItem in getData.events"
            :key="previewItem.id"
          >
            <v-img
              v-if="previewItem.imageName"
              :src="
                `https://thg.blob.core.windows.net/${previewItem.imageName}`
              "
              :lazy-src="
                `https://thg.blob.core.windows.net/${previewItem.imageName}`
              "
              height="200px"
              aspect-ratio="1.65"
            />
            <v-card-actions class="mt-4">
              <v-list-item>
                <v-row align="center" justify="end">
                  <v-list-item-content>
                    <v-list-item-title
                      :class="{
                        'grey--text': !previewItem.hasDone,
                        'yellow--text': previewItem.color === 'yellow',
                        'primary--text': previewItem.hasDone,
                        'font-weight-bold': true
                      }"
                      ><v-icon
                        v-if="
                          previewItem.hasDone && previewItem.color !== 'yellow'
                        "
                        color="success"
                        left
                      >
                        mdi-checkbox-marked-circle-outline
                      </v-icon>

                      <v-icon
                        v-if="previewItem.color === 'yellow'"
                        class="hammerAnimation"
                        left
                        color="yellow"
                      >
                        mdi-hammer
                      </v-icon>
                      <v-icon
                        left
                        v-if="!previewItem.hasDone"
                        :color="previewItem.color"
                      >
                        mdi-checkbox-blank-circle-outline
                      </v-icon>
                      {{ previewItem.resourceName }}
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-btn
                    class="mr-2"
                    v-if="getData.countHasDone !== 0"
                    @click="getData.countHasDone--"
                    light
                    fab
                    small
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>

                  <v-btn
                    v-if="getData.countHasDone !== getData.events.length - 1"
                    @click="getData.countHasDone++"
                    light
                    fab
                    small
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </v-list-item>
            </v-card-actions>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import optionsService from "@/options.services";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      model: 0,
      timeline: null,
      optionsService
    };
  },
  computed: {
    ...mapGetters({
      getTimeline: "saveData/timeline"
    }),
    getData() {
      if (this.getTimeline.length === undefined) {
        //loop all in timeland and check if end date is before today
        let sectionFinish = true;
        this.getTimeline.events.map(x => {
          if (!x.hasDone) {
            sectionFinish = false;
          }
        });

        let data = this.getTimeline;
        data.sectionFinish = sectionFinish;
        return data;
      }
      return { events: [], sectionFinish: false };
    }
  }
};
</script>
