<template>
  <div>
    <v-row class="justify-center">
      <v-col class="pt-0" xl="9">
        <v-list color="transparent">
          <v-list-item class="pl-0 mb-2">
            <v-list-item-avatar>
              <v-icon class="primary white--text">mdi-cart</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                class="primary--text headline font-weight-black"
                >Kvittering
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>
        <v-row>
          <v-col cols="12" v-if="haveData(value)">
            <div v-if="value.optionsConfirmed.length > 0">
              <ListItems
                v-model="value.optionsConfirmed"
                title="Tilvalg"
                margin="mt-5"
              />
            </div>

            <div v-if="value.colorConfirmed.length > 0">
              <ListItems
                v-model="value.colorConfirmed"
                title="Farger"
                margin="mt-10"
                itemSubtitleKey="name"
                itemTitleKey="ncs"
              />
            </div>

            <TotalPrice
              v-model="totalPrice"
              @downloadReceipt="downloadReceipt"
            />
          </v-col>
          <v-col cols="12" v-if="!haveData(value)">
            <div>
              <h2 class="mb-4">
                Det er ikke bekreftet noen tilvalg eller endring av farger
              </h2>
              <v-btn :to="{ name: 'StoreInfo' }" color="primary">
                Endre tilvalg eller farger
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ListItems from "@/components/store/child/ListItems";
import TotalPrice from "@/components/store/child/TotalPrice";
import optionsService from "@/options.services";
export default {
  props: {
    value: {
      required: true
    },
    totalPrice: {
      default: 0
    }
  },
  data() {
    return {
      optionsService
    };
  },
  methods: {
    downloadReceipt() {
      this.$emit("downloadReceipt");
    },
    haveData(value) {
      if (
        value &&
        (value.optionsConfirmed.length > 0 || value.colorConfirmed.length > 0)
      ) {
        return true;
      }
      return false;
    }
  },
  components: {
    ListItems,
    TotalPrice
  }
};
</script>

<style lang="scss" scoped>
.img {
  height: 200px;
  width: 300px;
  border-radius: 15px !important;
}
</style>
