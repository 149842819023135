<template>
  <v-dialog
    v-model="show"
    max-width="600"
    :hide-overlay="hideOverlay"
    :fullscreen="$store.getters['shared/isMobileDevice'] && !noFullscreen"
    transition="scroll-x-transition"
    :persistent="persistent"
  >
    <v-card class="pt-4 pb-8">
      <v-card-title
        style="word-break: break-word;"
        class="primary--text display-1 font-weight-bold"
      >
        {{ title }}
      </v-card-title>
      <v-card-text class="body-1" v-html="DialogMessage"> </v-card-text>
      <v-card-actions class="flex-wrap justify-center justify-sm-start ml-sm-2">
        <v-btn
          class="m-w-100 mb-4 mb-sm-0"
          v-if="!hideCancelButton"
          depressed
          :color="acceptButtonColor"
          @click="cancel"
          >{{ cancelButtonText }}</v-btn
        >
        <v-btn
          class="m-w-100 ml-0 ml-sm-2"
          depressed
          outlined
          color="grey"
          @click="confirm"
        >
          {{ acceptButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    DialogMessage: {
      type: String,
      default: ""
    },
    DialogAccept: {
      default: true
    },
    hideOverlay: {
      type: Boolean,
      default: true
    },
    persistent: {
      type: Boolean,
      default: false
    },
    acceptButtonText: {
      type: String,
      default: "Ja"
    },
    acceptButtonColor: {
      type: String,
      default: "primary"
    },
    cancelButtonText: {
      type: String,
      default: "Avbryt"
    },
    hideCancelButton: {
      type: Boolean,
      default: false
    },
    noFullscreen: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    cancel() {
      this.show = false;
      this.$emit("cancel", true);
    },
    confirm() {
      this.$emit("confirm", true);
    }
  },
  computed: {
    show: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
