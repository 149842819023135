import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('WebShopContainer',{attrs:{"intro":"","send-purchase-progress":"SendOnPurchaseOptions"},on:{"next":_vm.next}},[_c('template',{slot:"navigation"},[_c('OptionsRoomNavigation')],1),_c(VRow,{staticClass:"mt-6 mb-4 align-center"},[_c(VCol,{attrs:{"cols":"12","md":"3"}},[(_vm.sectionInfo.atticDeadline)?_c(VAlert,{staticClass:"mb-0",attrs:{"text":"","dense":"","color":"teal","icon":"mdi-clock-fast","border":"left"}},[_vm._v(" Frist: "+_vm._s(_vm._f("moment")(_vm.sectionInfo.atticDeadline,"DD.MM.YYYY"))+" ")]):_vm._e()],1),_c(VCol,{staticClass:"text-center",attrs:{"cols":"12","md":"6"}},[_c('h1',{staticClass:"grey--text"},[_vm._v("Loft")])])],1),_c(VCarousel,{attrs:{"height":"auto","hide-delimiters":""},model:{value:(_vm.startCarousel),callback:function ($$v) {_vm.startCarousel=$$v},expression:"startCarousel"}},_vm._l((_vm.imageAttic),function(img,index){return _c(VCarouselItem,{key:index},[_c(VImg,{attrs:{"contain":"","src":img}},[_c('div',{staticClass:"d-flex ma-4"},[_c(VChip,{staticClass:"ml-auto",attrs:{"color":"grey","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.showImg(img)}}},[_vm._v(" Se bilde "),_c(VIcon,{staticClass:"ml-1",attrs:{"x-small":""}},[_vm._v("mdi-magnify")])],1)],1)])],1)}),1),(_vm.sectionInfo.isAtticAvailable)?_c(VCheckbox,{attrs:{"label":("Jeg ønsker å kjøpe loft (dette vil øke pris med " + (_vm.format.formatToNok(
            _vm.sectionInfo.atticPrice
          )) + " til en total av " + (_vm.format.formatToNok(_vm.totalPriceIncludeAttic)) + ")")},on:{"change":_vm.atticChange},model:{value:(_vm.includeAttic),callback:function ($$v) {_vm.includeAttic=$$v},expression:"includeAttic"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.sectionInfo.atticDescription)+" "),_c('IntroWebShopDialog')],2),_c('AcceptCancel',{attrs:{"hideOverlay":false,"persistent":"","title":"Tilbakestille valg av loft","DialogMessage":"Er du sikker på at du ikke vil kjøpe med loft?"},on:{"cancel":_vm.cancelAttic,"confirme":_vm.confirmeAttic},model:{value:(_vm.showAccept),callback:function ($$v) {_vm.showAccept=$$v},expression:"showAccept"}}),_c('DialogShowImage',{attrs:{"title":"Loft","url":_vm.imgUrl},model:{value:(_vm.showImage),callback:function ($$v) {_vm.showImage=$$v},expression:"showImage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }