<template>
  <v-app>
    <Loader />
    <div v-if="$route.name">
      <Navbar v-if="!login && !storeOpen" />
      <!-- no element under navbar is padding from top 64 px -->
      <!-- <v-content v-if="!login" class="">
        <v-container :class="{ 'pa-lg-0': noPading }" fluid>
          <router-view></router-view>
        </v-container>
      </v-content> -->

      <v-main :class="{ haveHeader: !storeOpen }">
        <v-container :class="{ 'pa-lg-0': noPading }" fluid>
          <router-view></router-view>
        </v-container>
      </v-main>

      <!-- Only on login screen to remove paddig from top -->
      <!-- <v-container v-if="login" :class="{ 'pa-lg-0': noPading }" fluid>
        <router-view></router-view>
      </v-container> -->

      <BottomNav v-if="!login && !storeOpen" class="d-flex d-md-none" />
      <Snackbar />
    </div>
    <CookieBar v-if="!hideCookieBar" />
    <WelcomeDialog :show="showDialog" />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Navbar from "./components/shared/Navbar";
import BottomNav from "./components/shared/BottomNav";

import Snackbar from "./components/shared/Snackbar";
import Loader from "./components/shared/loader/Loader";
import CookieBar from "@/components/shared/CookieBar";
import WelcomeDialog from "@/components/dialog/WelcomeDialog";

var tabTitle = "Mitt Trysilhus";

// window.onUsersnapCXLoad = async function(api) {
//   await api.init();
//   // document.title = tabTitle;
// };

export default {
  name: "App",
  components: {
    Navbar,
    BottomNav,
    Snackbar,
    Loader,
    CookieBar,
    WelcomeDialog,
  },
  data() {
    return {
      showDialog: { show: false },
    };
  },
  methods: {
    ...mapActions({
      isMobile: "shared/isMobileDevice",
    }),
    // createUsersnap() {
    //   let script = document.createElement("script");
    //   script.type = "text/javascript";
    //   script.async = "true";
    //   // script.src =
    //   //   "//api.usersnap.com/load/a4f5f311-5c08-4f8e-b407-12163efb28a4.js";
    //   script.src =
    //     "https://widget.usersnap.com/load/a4f5f311-5c08-4f8e-b407-12163efb28a4?onload=onUsersnapCXLoad";
    //   document.getElementsByTagName("head")[0].appendChild(script);
    // },
  },
  computed: {
    ...mapGetters({
      isMobileDevice: "shared/isMobileDevice",
      profile: "shared/profile",
    }),
    noPading() {
      return (
        this.$route.name === "home" ||
        this.$route.name === "profile" ||
        this.$route.meta.hideNavBar
      );
    },
    login() {
      return (
        this.$route.name === "login" ||
        this.$route.name === "signup" ||
        this.$route.name === "forgottenpassword" ||
        this.$route.name === "cookies" ||
        this.$route.name === "consent" ||
        this.$route.name === "noUser" ||
        this.$route.meta.hideNavBar
      );
    },
    storeOpen() {
      return (
        this.$route.name === "storeOptionsOpen" ||
        this.$route.name === "storeOpen" ||
        this.$route.name === "colorsOpen" ||
        this.$route.name === "colorCheckoutOpen"
      );
    },
    hideCookieBar() {
      if (this.$route && this.$route.meta && this.$route.meta.hideCookieBar) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.isMobile();
    //Hven getting profile info. Sjekk if user have accept email and newsLetter before
    this.$store.watch(
      (state, getters) => getters["shared/profile"],
      (profile) => {
        //First time user login to MT
        if (!profile.user.seenWelcomeMessage) {
          this.showDialog.show = true;
        }
      }
    );
  },
  mounted() {
    // let displayUsersnap = process.env.VUE_APP_DISPLAYUSERSNAP;
    // if (displayUsersnap == "true") {
    //   this.createUsersnap();
    //   debugger;
    if (
      this.$router.history.pending &&
      this.$router.history.pending.meta.webShopLoader
    ) {
      tabTitle = "Trysilhus";
    } else {
      tabTitle = "Mitt Trysilhus";
    }
    document.title = tabTitle;
    // }
  },
};
</script>

<style lang="scss">
//Hide scroll on non scroll screen
html {
  overflow-y: auto !important;
}

@media (min-width: 960px) {
  .haveHeader {
    padding-top: 64px !important;
  }
}

.v-main {
  margin-top: calc(0px + env(safe-area-inset-top)) !important;
  margin-bottom: calc(0px + env(safe-area-inset-bottom)) !important;
  margin-left: calc(0px + env(safe-area-inset-left)) !important;
  margin-right: calc(0px + env(safe-area-inset-right)) !important;
}
</style>
