<template>
  <v-overlay :z-index="1000" :value="isLoading" opacity="0.7">
    <v-progress-circular
      class="mt-loader"
      color="primary"
      indeterminate
      size="94"
    >
      <!-- MT LOADER -->
      <v-img
        v-if="!isWebShopLoader"
        width="64"
        src="https://thg.blob.core.windows.net/public-images/mt_logo.svg"
      ></v-img>

      <!-- BOLIGVELGER LOADER -->
      <span v-if="isWebShopLoader">
        <v-icon class="hammerAnimation">
          mdi-hammer
        </v-icon>
        <div class="hammer-circle"></div>
      </span>
    </v-progress-circular>
  </v-overlay>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      isLoading: "shared/isLoading"
    }),
    isWebShopLoader() {
      if (
        this.$router.history.pending &&
        this.$router.history.pending.meta.webShopLoader
      ) {
        return this.$router.history.pending.meta.webShopLoader;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scope>
.mt-loader {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  position: relative;
  .v-icon {
    z-index: 1;
  }
  .hammer-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--v-primary-base);
    border-radius: 50%;
    width: 64px;
    height: 64px;
  }
}
</style>
