<template>
  <div>
    <OptionsInfo />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import optionsService from "@/options.services";

import OptionsInfo from "@/components/store/OptionsInfo";
export default {
  data() {
    return {
      optionsService
    };
  },
  computed: {
    ...mapGetters({
      storeCart: "options/cart"
    })
  },
  components: {
    OptionsInfo
  }
};
</script>
