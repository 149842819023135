import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VAppBar,{staticStyle:{"z-index":"10"},attrs:{"color":"white","flat":"","fixed":"","app":""}},[_c('div',{staticClass:"nav"},[_c(VAppBarNavIcon,{staticClass:"d-flex d-md-none",on:{"click":function($event){_vm.drawer = true}}}),_c('a',{staticClass:"d-block ml-3 ml-sm-0",attrs:{"href":"https://www.trysilhus.no/"}},[_c('img',{attrs:{"width":"140","src":"https://thg.blob.core.windows.net/public-images/trysilhus_logo.png"}})]),_c(VSpacer),(!_vm.hideTabs)?_c(VTabs,{staticClass:"d-none d-md-flex justify-end",attrs:{"align-with-title":"","height":"56","right":""}},[_vm._l((_vm.steps),function(step,index){return [_c(VTab,{key:index,attrs:{"exact":step.exact,"to":{ name: step.to },"disabled":step.disabled}},[(step.badgeCount > 0)?_c(VBadge,{attrs:{"content":step.badgeCount,"color":"warning"}},[_c('span',[_vm._v(" "+_vm._s(index + 1 + ". ")+_vm._s(step.title)+" ")])]):_c('span',[_vm._v(_vm._s(index + 1 + ". ")+_vm._s(step.title))])],1)]})],2):_vm._e()],1)]),_c(VNavigationDrawer,{attrs:{"absolute":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,{attrs:{"nav":"","dense":""}},[_c(VListItemGroup,{attrs:{"active-class":"primary--text"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[_vm._l((_vm.steps),function(step,index){return [_c(VListItem,{key:index,attrs:{"exact":"","to":{ name: step.to }}},[_c(VListItemIcon,[(step.badgeCount > 0)?_c(VBadge,{attrs:{"content":step.badgeCount,"color":"warning","overlap":""}},[_c(VIcon,[_vm._v(_vm._s(step.icon))])],1):_c(VIcon,[_vm._v(_vm._s(step.icon))])],1),_c(VListItemTitle,[_vm._v(" "+_vm._s(index + 1 + ". ")+_vm._s(step.title)+" ")])],1)]})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }