<template>
  <v-card class="mt-8 theme-shadow">
    <v-list class="py-0">
      <v-list-group :value="true">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Forbehold</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-divider></v-divider>
        <ReservationList
          v-if="trysilhusReservations.length > 0"
          :data="trysilhusReservations"
          title="Trysilhus sine forbehold"
        />
        <ReservationList
          v-if="userReservations.length > 0"
          :data="userReservations"
          title="Dine/deres forbehold"
        />
      </v-list-group>
    </v-list>
  </v-card>
</template>

<script>
import ReservationList from "@/components/profile/ReservationList";
export default {
  props: {
    value: {
      required: true
    }
  },
  data() {
    return {
      data: [1, 2, 3, 4, 5]
    };
  },
  computed: {
    trysilhusReservations() {
      if (this.value) {
        return this.value.filter(x => x.type !== 1);
      }
      return [];
    },

    userReservations() {
      if (this.value) {
        return this.value.filter(x => x.type === 1);
      }
      return [];
    }
  },
  components: {
    ReservationList
  }
};
</script>
