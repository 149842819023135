import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.icon)?_c(VFileInput,{ref:"upload",attrs:{"show-size":"","filled":"","prepend-icon":"","prepend-inner-icon":"mdi-paperclip","counter":"","multiple":"","drop":true,"accept":".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,image/*,audio/*,video/*","label":"Last opp filer","rules":_vm.rules},on:{"click:clear":_vm.remove,"change":_vm.addFile},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}):_vm._e(),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.icon)?_c(VBtn,_vm._g({attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.$refs.upload.click()}}},on),[_c(VIcon,[_vm._v("mdi-paperclip")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Legg til filer")])]),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"upload",attrs:{"type":"file","multiple":""},on:{"change":_vm.addFile}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }