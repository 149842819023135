import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{class:{
    'theme-shadow': true
  },attrs:{"height":"100%","hover":""}},[_c(VSkeletonLoader,{attrs:{"type":"image"}}),_c(VList,[_c(VListItem,[_c(VListItemContent,{staticClass:"pt-0 pb-8"},[_c(VListItemTitle,{staticClass:"d-flex my-1"},[_c(VSkeletonLoader,{staticClass:"mr-1 chip-x-small",attrs:{"type":"chip"}}),_c(VSkeletonLoader,{staticClass:"chip-x-small",attrs:{"type":"chip"}}),_c(VSkeletonLoader,{staticClass:"ml-auto chip-x-small",attrs:{"type":"chip"}})],1),_c(VListItemTitle,{staticClass:"my-2 darkgrey--text"},[_c(VSkeletonLoader,{attrs:{"type":"heading"}})],1),_c(VSkeletonLoader,{staticClass:"mt-2",attrs:{"type":"sentences"}})],1)],1)],1),_c(VBtn,{staticStyle:{"position":"absolute","bottom":"0"},attrs:{"color":"grey","dark":"","block":"","depressed":"","tile":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }