import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import shared from "./modules/shared.js";
import saveData from "./modules/saveData.js";
import options from "./modules/options.js";
import request from "./modules/request.js";
import color from "./modules/color.js";
import purchase from "./modules/purchase.js";
import documents from "./modules/documents.js";

const store = new Vuex.Store({
  modules: {
    shared,
    saveData,
    options,
    request,
    color,
    purchase,
    documents
  }
});

export default store;
