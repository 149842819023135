<template>
  <WebShopContainer hide-tabs hide-footer>
    <v-card
      elevation="0"
      class="d-flex flex-column justify-center px-0 px-sm-12"
      style="height: 60vh;"
    >
      <v-btn
        class="d-block mx-auto mb-8"
        depressed
        fab
        dark
        x-large
        color="primary"
      >
        <v-icon x-large>
          mdi-check
        </v-icon>
      </v-btn>
      <h1 class="text-center display-2 font-weight-bold mb-6 mb-md-10">
        Takk for ditt kjøpetilbud!
      </h1>
      <p class="body-1 mb-6">
        Ansvarlig selger for leiligheten vil nå gå gjennom dokumentasjonen, og
        ta kontakt med deg hvis det er spørsmål. Du vil motta en SMS som
        bekrefter at kjøpetilbudet er mottatt. Du vil også få en e-post med kopi
        av dokumentene som du har signert.
      </p>
      <p class="body-1">
        Først når kjøpetilbudet er akseptert av selger, er bindende avtale
        inngått. Ansvarlig selger vil ta kontakt for å bekrefte avtalen. Du vil
        høre fra oss innen neste arbeidsdag etter at kjøpetilbudet er mottatt.
      </p>
      <div class="d-block mx-auto mt-12 px-8">
        <v-btn
          class="mr-2"
          outlined
          color="primary"
          depressed
          @click="downloadReceipt"
        >
          Last ned kvittering
        </v-btn>
        <v-btn
          class="ml-2"
          color="primary"
          depressed
          href="http://trysilhus.no/"
        >
          Gå til Trysilhus.no
        </v-btn>
      </div>
    </v-card>
  </WebShopContainer>
</template>

<script>
import purchaseApi from "@/api/purchaseApi";
import { mapGetters, mapActions } from "vuex";
import FileSaver from "file-saver";
import files from "@/helpers/files.js";
import WebShopContainer from "@/components/store/webStore/shared/WebShopContainer.vue";

export default {
  mounted() {
    this.deleteSectionInfo();
  },
  methods: {
    ...mapActions({
      deleteSectionInfo: "purchase/deleteSectionInfo"
    }),
    async downloadReceipt() {
      if (this.profile && this.profile.customer.id) {
        await purchaseApi
          .purchaseDownloadReceipt(
            parseInt(this.$route.params.sectionId),
            this.profile.customer.id
          )
          .then(response => {
            var data = files.transformDownload(response.data, response.headers);
            FileSaver.saveAs(data.blob, data.name);
          });
      }
    }
  },
  computed: {
    ...mapGetters({
      profile: "purchase/profile"
    })
  },
  components: {
    WebShopContainer
  }
};
</script>
