<template>
  <div>
    <OptionsContainer />
  </div>
</template>

<script>
import OptionsContainer from "@/components/store/options/options/container/OptionsContainer";
export default {
  components: {
    OptionsContainer
  }
};
</script>
