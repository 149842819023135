<template
  ><div>
    <WebShopContainer
      @next="next"
      intro
      send-purchase-progress="SendOnPurchaseOptions"
    >
      <template slot="navigation">
        <OptionsRoomNavigation />
      </template>
      <v-row class="mt-6 mb-4 align-center">
        <v-col cols="12" md="3">
          <v-alert
            v-if="sectionInfo.atticDeadline"
            class="mb-0"
            text
            dense
            color="teal"
            icon="mdi-clock-fast"
            border="left"
          >
            Frist: {{ sectionInfo.atticDeadline | moment("DD.MM.YYYY") }}
          </v-alert>
        </v-col>
        <v-col cols="12" md="6" class="text-center">
          <h1 class="grey--text">Loft</h1>
        </v-col>
      </v-row>

      <v-carousel height="auto" v-model="startCarousel" hide-delimiters>
        <v-carousel-item v-for="(img, index) in imageAttic" :key="index">
          <v-img contain :src="img">
            <div class="d-flex ma-4">
              <v-chip
                class="ml-auto"
                color="grey"
                dark
                @click.stop="showImg(img)"
              >
                Se bilde <v-icon class="ml-1" x-small>mdi-magnify</v-icon>
              </v-chip>
            </div>
          </v-img>
        </v-carousel-item>
      </v-carousel>

      <v-checkbox
        v-if="sectionInfo.isAtticAvailable"
        v-model="includeAttic"
        @change="atticChange"
        :label="
          `Jeg ønsker å kjøpe loft (dette vil øke pris med ${format.formatToNok(
            sectionInfo.atticPrice
          )} til en total av ${format.formatToNok(totalPriceIncludeAttic)})`
        "
      ></v-checkbox>

      {{ sectionInfo.atticDescription }}

      <IntroWebShopDialog />
    </WebShopContainer>
    <AcceptCancel
      v-model="showAccept"
      :hideOverlay="false"
      persistent
      title="Tilbakestille valg av loft"
      DialogMessage="Er du sikker på at du ikke vil kjøpe med loft?"
      @cancel="cancelAttic"
      @confirme="confirmeAttic"
    />
    <DialogShowImage v-model="showImage" title="Loft" :url="imgUrl" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import format from "@/helpers/format.js";
import WebShopContainer from "@/components/store/webStore/shared/WebShopContainer.vue";
import OptionsRoomNavigation from "@/components/store/webStore/options/children/OptionsRoomNavigation.vue";
import IntroWebShopDialog from "@/components/store/webStore/shared/dialogs/IntroWebShopDialog.vue";
import AcceptCancel from "@/components/dialog/AcceptCancel";
import DialogShowImage from "@/components/dialog/DialogShowImage";

export default {
  data() {
    return {
      showAccept: false,
      startCarousel: 0,
      showImage: false,
      imgUrl: null,
      format
    };
  },
  methods: {
    ...mapActions({
      updateProfile: "purchase/updateProfile",
      changeSelectedOptions: "purchase/changeSelectedOptions"
    }),
    next() {
      this.$router.push({
        name: "webShopOptionsCategory",
        params: { categoryId: this.options[0].id }
      });
    },
    atticChange(value) {
      if (!value) {
        this.showAccept = true;
      }
    },
    cancelAttic() {
      this.includeAttic = true;
    },
    confirmeAttic() {
      //Delete selected options on attic when remove attic
      var optionsSelected = this.selectedOptions;
      if (optionsSelected && optionsSelected[this.$route.params.categoryId]) {
        delete optionsSelected[this.$route.params.categoryId];
        //Save/update
        this.changeSelectedOptions(optionsSelected);
      }
    },
    showImg(url) {
      this.imgUrl = url;
      this.showImage = true;
    }
  },
  computed: {
    ...mapGetters({
      profile: "purchase/profile",
      options: "purchase/options",
      sectionInfo: "purchase/sectionInfo"
    }),
    includeAttic: {
      get() {
        if (this.profile) {
          return this.profile.includeAttic ? this.profile.includeAttic : false;
        }
        return false;
      },
      set(value) {
        var data = JSON.parse(JSON.stringify(this.profile));
        this.$set(data, "includeAttic", value);
        this.updateProfile(data);
      }
    },
    imageAttic() {
      if (this.sectionInfo) {
        return [
          this.sectionInfo.atticImageUrl,
          this.sectionInfo.atticInfoImageUrl
        ];
      }

      return [];
    },
    totalPriceIncludeAttic() {
      //Total price including attic (Not selected and selected)
      if (this.sectionInfo) {
        var tot = this.sectionInfo.estimatesTotalAmount;
        if (this.selectedOptionsList) {
          tot = tot + this.selectedOptionsList.totalPrice;
        }
        if (this.sectionInfo.atticPrice) {
          tot = tot + this.sectionInfo.atticPrice;
        }
        return tot;
      }
      return 0;
    }
  },
  components: {
    WebShopContainer,
    OptionsRoomNavigation,
    IntroWebShopDialog,
    AcceptCancel,
    DialogShowImage
  }
};
</script>
