<template>
  <v-card class="theme-shadow py-2">
    <v-list class="py-0" v-for="(item, index) in items" :key="index">
      <v-list-item>
        <v-list-item-action>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}:</v-list-item-title>
        </v-list-item-content>
        <v-list-item-content>
          <v-list-item-title>{{ item.content }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider inset v-if="index < items.length - 1"></v-divider>
    </v-list>
  </v-card>
</template>
<script>
import moment from "moment";
export default {
  props: {
    currentSection: {
      required: true
    }
  },
  computed: {
    items() {
      var buildingNum =
        this.currentSection.houseNum + " " + this.currentSection.floor;
      var apartmentNum =
        this.currentSection.floorApartmentNumber > 9
          ? this.currentSection.floorApartmentNumber
          : "0" + this.currentSection.floorApartmentNumber;
      var complaintDeadline = moment(
        this.currentSection.complaintDeadLine
      ).format("DD.MM.YYYY");
      var data = [
        {
          icon: "mdi-home-outline",
          title: "Navn",
          content: this.currentSection.name
        },
        {
          icon: "mdi-numeric-1-box-multiple-outline",
          title: "Leilighetsnummer",
          content: buildingNum + apartmentNum
        },
        {
          icon: "mdi-numeric-2-box-multiple-outline",
          title: "Byggnummer",
          content: this.currentSection.houseNum
        },
        {
          icon: "mdi-domain",
          title: "Modell",
          content: this.currentSection.modelName
        },
        {
          icon: "mdi-account-hard-hat",
          title: "Utbygger",
          content: this.currentSection.ownerName
        },
        {
          icon: "mdi-map-marker",
          title: "Adresse",
          content: this.currentSection.address
        },
        {
          icon: "mdi-label",
          title: "Seksjonsnummer",
          content: this.currentSection.sectionNum
        },
        {
          icon: "mdi-home-city-outline",
          title: "Type",
          content: this.currentSection.sectionTypeName
        },
        {
          icon: "mdi-calendar",
          title: "Reklamasjonsfrist",
          content: complaintDeadline
        }
      ];
      return data;
    }
  }
};
</script>
