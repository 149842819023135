import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mb-md-6",attrs:{"elevation":"0"}},[_c(VList,{attrs:{"color":"grey lighten-4","elevation":"2"}},[_c(VListItem,[_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%"}},[_c(VListItemContent,[_c(VListItemSubtitle,{staticClass:"caption"},[_vm._v(" Fargevalg lagt på alle rom ")]),_c(VListItemTitle,{staticClass:"font-weight-bold headline primary--text"},[_vm._v(" Hovedfarge bolig "),_c(VBtn,{attrs:{"x-small":"","icon":"","outlined":"","color":"grey"},on:{"click":function($event){_vm.showDialog.show = true}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-help")])],1)],1)],1),_c(VListItemAction,{staticStyle:{"height":"35px"}},[_c(VListItemActionText,[_c(VBtn,{attrs:{"disabled":_vm.disabledReset,"x-small":"","depressed":"","outlined":"","color":"grey"},on:{"click":function($event){return _vm.resetColor(_vm.data)}}},[_vm._v("Tilbakestill")])],1)],1)],1)]),_c(VListItem,[[_c(VBtn,{staticClass:"justify-space-between",class:{ 'disable-btn': _vm.disabled },attrs:{"color":_vm.data.hexColor,"block":"","height":"36"},on:{"click":function($event){return _vm.openColorDialog(_vm.data)}}},[_c(VChip,{attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.data.ncs)+" ")]),_c(VChip,{attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.optionsService.formatPrice( _vm.data[_vm.typePrice] ? _vm.data[_vm.typePrice] : 0 ))+" ")])],1)]],2),_c('AcceptCancel',{attrs:{"title":"Tilbakestille hovedfarge","DialogMessage":"Er du sikker på at du vil tilbakstille hovedfargen?"},on:{"confirme":_vm.reset},model:{value:(_vm.showAccept),callback:function ($$v) {_vm.showAccept=$$v},expression:"showAccept"}}),_c('DialogMessage',{attrs:{"show":_vm.showDialog,"title":_vm.title,"DialogMessage":_vm.DialogMessage,"DialogAccept":false,"hideOverlay":false}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }