<template>
  <div>
    <ListItems
      v-model="section"
      title="Bolig"
      item-title-key="text"
      item-subtitle-key="categoryName"
      price-key="amount"
      margin="pl-4 mb-2"
      icon
    >
      <template slot="footer">
        <v-list color="greybg">
          <v-list-item>
            <v-list-item-content class="justify-sm-end">
              Delsum bolig: {{ format.formatToNok(sectionTotal) }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
      </template>
    </ListItems>
    <HeaderDivider
      v-if="
        (optionsAndColor && optionsAndColor.length > 0) ||
          (selectedColorOptions && selectedColorOptions.length > 0)
      "
      title="Tilvalg og farger"
      margin="pl-4 mt-5 mb-2"
    />
    <ListItems
      v-if="optionsAndColor && optionsAndColor.length > 0"
      v-model="optionsAndColor"
      item-subtitle-key="categoryName"
      show-delete-icon
      hide-delete-icon-on="disabledDelete"
      hideHeader
      @delete="deleteOptionsOpenDialog"
    >
    </ListItems>
    <ListItems
      v-if="selectedColorOptions && selectedColorOptions.length > 0"
      v-model="selectedColorOptions"
      item-subtitle-key="name"
      item-title-key="ncs"
      priceKey="amount"
      show-delete-icon
      hide-delete-icon-on="disabledDelete"
      hideHeader
      @delete="deleteColorOptionsOpenDialog"
    >
    </ListItems>
    <template
      v-if="
        (optionsAndColor && optionsAndColor.length > 0) ||
          (selectedColorOptions && selectedColorOptions.length > 0)
      "
    >
      <v-list color="greybg">
        <v-list-item>
          <v-list-item-content class="justify-sm-end">
            Delsum tilvalg og fargevalg:
            {{ format.formatToNok(optionsAndColorPrice) }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
    </template>
    <v-list>
      <v-list-item>
        <v-list-item-content class="title justify-sm-end">
          Totalpris: {{ format.formatToNok(totalPrice) }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider class="mb-8"></v-divider>

    <AcceptCancel
      v-model="showAccept"
      :title="acceptCancelTitle"
      :DialogMessage="acceptCancelText"
      :hide-overlay="false"
      @confirme="deleteOptions(acceptCancelData)"
    />
    <AcceptCancel
      v-model="showAcceptColor"
      :title="acceptCancelTitle"
      :DialogMessage="acceptCancelText"
      :hide-overlay="false"
      @confirme="deleteColorOptions(acceptCancelData)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import format from "@/helpers/format.js";
import ListItems from "@/components/store/child/ListItems";
import HeaderDivider from "@/components/store/child/HeaderDivider";
import AcceptCancel from "@/components/dialog/AcceptCancel";
export default {
  data() {
    return {
      showAccept: false,
      showAcceptColor: false,
      acceptCancelTitle: "",
      acceptCancelText: "",
      acceptCancelData: null,
      format
    };
  },
  mounted() {
    //If color options is not saved and colorOptins is changed. Then get colorOptions info
    if (
      this.colorOptionsNotChanged.length === 0 &&
      this.selectedColorOptions.length > 0
    ) {
      this.setColorOptions(this.$route.params.sectionId);
    }
  },
  methods: {
    ...mapActions({
      changeSelectedOptions: "purchase/changeSelectedOptions",
      changeSelectedColorOptions: "purchase/changeSelectedColorOptions",
      setColorOptions: "purchase/setColorOptions"
    }),
    deleteOptionsOpenDialog(data) {
      this.acceptCancelTitle = `Bekrefte sletting av ${data.name}`;
      this.acceptCancelText = `Er du sikker på at du vil slette ${data.name}?`;
      this.acceptCancelData = data;
      this.showAccept = true;
    },
    deleteOptions(data) {
      var optionsSelected = this.selectedOptions;

      if (
        optionsSelected &&
        optionsSelected[data.categoryId] &&
        optionsSelected[data.categoryId][data.parentId]
      ) {
        //Find index to remove
        var removeIndex = optionsSelected[data.categoryId][
          data.parentId
        ].findIndex(x => x.id === data.id);
        if (removeIndex > -1) {
          //Remove from selected list
          optionsSelected[data.categoryId][data.parentId].splice(
            removeIndex,
            1
          );
          data.selected = false;

          //Save/update
          this.changeSelectedOptions(optionsSelected);
        }
      }
    },

    deleteColorOptionsOpenDialog(data) {
      this.acceptCancelTitle = `Bekrefte sletting av ${data.name}`;
      this.acceptCancelText = `Er du sikker på at du vil slette "${data.ncs}" på ${data.name}?`;
      this.acceptCancelData = data;
      this.showAcceptColor = true;
    },
    deleteColorOptions(data) {
      this.changeSelectedColorOptions({
        sectionId: this.$route.params.sectionId,
        data: data,
        deleteColor: true
      });
    }
  },
  computed: {
    ...mapGetters({
      sectionInfo: "purchase/sectionInfo",
      selectedOptionsList: "purchase/selectedOptionsList",
      profile: "purchase/profile",
      selectedOptions: "purchase/selectedOptions",
      selectedColorOptions: "purchase/selectedColorOptions",
      selectedColorOptionsTotal: "purchase/selectedColorOptionsTotal",
      colorOptionsNotChanged: "purchase/colorOptionsNotChanged"
    }),
    section() {
      if (this.sectionInfo && this.sectionInfo.estimates) {
        var data = this.sectionInfo.estimates;
        data.forEach(x => (x.icon = "mdi-home-city-outline"));
        var newArr = JSON.parse(JSON.stringify(data));

        if (this.profile && this.profile.includeAttic) {
          var attic = {
            amount: this.sectionInfo.atticPrice,
            categoryName: "Bolig",
            icon: "mdi-home-floor-l",
            name: "Loft",
            text: "Loft",
            type: 0
          };

          newArr.splice(1, 0, attic);
        }
        return newArr;
      }
      return [];
    },
    sectionTotal() {
      if (this.sectionInfo) {
        var atticPrice = 0;
        if (this.profile && this.profile.includeAttic) {
          var atticPrice = this.sectionInfo.atticPrice;
        }

        return this.sectionInfo.estimatesTotalAmount + atticPrice;
      }
      return 0;
    },
    optionsAndColor() {
      if (this.selectedOptionsList) {
        return this.selectedOptionsList.list;
      }
      return [];
    },
    optionsAndColorPrice() {
      return (
        (this.selectedOptionsList ? this.selectedOptionsList.totalPrice : 0) +
        this.selectedColorOptionsTotal
      );
    },
    totalPrice() {
      return this.sectionTotal + this.optionsAndColorPrice;
    }
  },
  components: {
    ListItems,
    HeaderDivider,
    AcceptCancel
  }
};
</script>
