import colorApi from "@/api/colorApi";
import ncsColor from "ncs-color";
import notificationsService from "@/notification.services";

function sortArrayObject(data, key) {
  return data.sort(function(a, b) {
    var textA = a[key];
    var textB = b[key];

    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
}

function colorOptionsGiveHexColor(colorOptions) {
  var mainColor = colorOptions.find(x => x.id === -1);
  return colorOptions.forEach(x => {
    x.hexColor = ncsColor.hex(x.ncs);
    x.total = x.price ? x.price : 0;
    x.constrastWalls.forEach(y => {
      x.total = x.total + (y.price ? y.price : 0);
      y.hexColor = ncsColor.hex(y.ncs);
      //Check if room color and main room color is not same as kontrastWall
      if (
        y.ncs !== x.ncs &&
        (x.ncs === mainColor.ncs ? y.ncs !== mainColor.ncs : true)
      ) {
        y.active = true;
      } else {
        y.active = false;
      }
    });
  });
}
function colorCartGiveHexColor(color) {
  return color.forEach(x => {
    x.hexColor = ncsColor.hex(x.ncs);
  });
}

function colorCartWithGroups(cart) {
  if (cart) {
    var data = [];
    var groupe = [...new Set(cart.map(item => item.roomId))];

    groupe.sort();
    groupe.forEach(id => {
      data.push({
        id: id,
        name: cart.find(s => s.roomId === id).name,
        data: cart.filter(y => y.roomId === id)
      });
    });
    return data;
  }
}

function colorCartGetInfo(cart) {
  var cartConfirmed = null;
  var cartChanges = null;
  var totalPrice = 0;
  var totalPriceConfirmed = 0;
  var changes = null;

  //Sort cart and add groups name
  var cartGroups = colorCartWithGroups(cart);
  //Find if some in cart is confirmed, added or deleted from confirmed.
  var isConfirmed = cart.some(
    x => x.state === 0 || x.state === 1 || x.state === -1
  );

  if (isConfirmed) {
    totalPrice = cart.reduce(
      (acc, item) => acc + (item.state !== -1 ? item.price : 0),
      0
    );
    totalPriceConfirmed = cart.reduce(
      (acc, item) =>
        acc + (item.state === 0 || item.state === -1 ? item.price : 0),
      0
    );
    //Find all confirmed(0)
    cartConfirmed = cart.filter(x => x.state === 0 || x.state === -1);
    sortArrayObject(cartConfirmed, "roomId");

    cartChanges = cart.filter(
      x => x.state === -1 || x.state === 1 || x.state === 2
    );

    changes = cart.reduce((acc, item) => {
      if (item.state === 2) {
        return acc + (item.state !== 0 ? 1 : 0);
      } else if (item.state === -1) {
        return cart.some(y => {
          if (item.wallLetter) {
            return (
              y.roomId === item.roomId &&
              y.wallLetter === item.wallLetter &&
              y.state !== item.state
            );
          } else {
            return y.roomId === item.roomId && y.state !== item.state;
          }
        })
          ? acc
          : acc + 1;
      } else {
        return acc;
      }
    }, 0);
  } else {
    totalPrice = cart.reduce((acc, item) => acc + item.price, 0);
    changes = cart.length;
  }
  return {
    cart: cartGroups,
    confirmed: isConfirmed,
    changes: changes,
    totalPrice: totalPrice,
    totalPriceConfirmed: totalPriceConfirmed,
    cartConfirmed: cartConfirmed,
    cartChanges: cartChanges,
    loading: false
  };
}

export default {
  namespaced: true,

  state: {
    showConfirme: false,
    colorOptions: null,
    colorCart: { loading: true, cart: [] },
    errorCount: 0
  },
  getters: {
    colorOptions: state => {
      return state.colorOptions;
    },
    colorCart: state => {
      return state.colorCart;
    },
    showConfirme: state => {
      return state.showConfirme;
    },
    errorCount: state => {
      return state.errorCount;
    }
  },
  mutations: {
    colorOptions(state, value) {
      state.colorOptions = value;
    },
    colorCart(state, value) {
      state.colorCart = value;
    },
    showConfirme(state, value) {
      return (state.showConfirme = value);
    },
    errorCount(state, value) {
      return (state.errorCount = value);
    }
  },
  actions: {
    async color({ commit }, value) {
      var colorOptions = await colorApi.GetColorOptions();
      colorOptionsGiveHexColor(colorOptions);

      var colorCart = await colorApi.getColorCart();
      colorCartGiveHexColor(colorCart);
      const cart = colorCartGetInfo(colorCart);

      commit("colorOptions", colorOptions);
      commit("colorCart", cart);
    },
    async updateColorCart({ commit }, data) {
      var respons = await colorApi.updateColorCart(data);
      colorOptionsGiveHexColor(respons.colorOptions);

      colorCartGiveHexColor(respons.cart);
      const cart = colorCartGetInfo(respons.cart);

      commit("colorOptions", respons.colorOptions);
      commit("colorCart", cart);
    },

    async deleteColorCart({ commit, state }, data) {
      let id = null;
      //If deleting from cart. Then id is id in cart
      if (data && data.id) {
        id = data.id;
      } else if (data && data.roomId) {
        id = data.roomId;
      }

      let wallLetter = null;
      if (data && data.wallLetter) {
        wallLetter = data.wallLetter;
      }

      let isconfirmed = null;
      if (data && data.state === 0) {
        isconfirmed = true;
      }

      let deleteContrastWalls = null;
      if (data && data.deleteContrastWalls) {
        deleteContrastWalls = true;
      }

      //If tilbakestill is clicked and user has confirmed somthing
      if (state.colorCart.confirmed && data.state === undefined) {
        data.isChangedFromConfirmed = true;
      }

      var respons = [];
      if (data.isChangedFromConfirmed) {
        respons = await colorApi.resetColorCart(
          id,
          wallLetter,
          deleteContrastWalls
        );
      } else {
        respons = await colorApi.deleteColorCart(
          id,
          wallLetter,
          isconfirmed,
          deleteContrastWalls
        );
      }

      if (respons) {
        colorOptionsGiveHexColor(respons.colorOptions);
        colorCartGiveHexColor(respons.cart);
        const cart = colorCartGetInfo(respons.cart);
        commit("colorOptions", respons.colorOptions);
        commit("colorCart", cart);
      }
    },

    async deleteAllColorCart({ commit }, value) {
      var respons = [];
      if (value === "confirmed") {
        respons = await colorApi.resetAllColorCart();
      } else {
        respons = await colorApi.deleteAllColorCart();
      }
      if (respons) {
        colorOptionsGiveHexColor(respons.colorOptions);
        colorCartGiveHexColor(respons.cart);
        const cart = colorCartGetInfo(respons.cart);
        commit("colorOptions", respons.colorOptions);
        commit("colorCart", cart);
      }
    },

    async confirmColorCart({ commit, state }, data) {
      await colorApi
        .confirmcodeColorCart(data)
        .then(respons => {
          colorOptionsGiveHexColor(respons.colorOptions);
          colorCartGiveHexColor(respons.cart);
          const cart = colorCartGetInfo(respons.cart);
          commit("colorOptions", respons.colorOptions);
          commit("colorCart", cart);
          notificationsService.success(respons.msg);
          commit("showConfirme", false);
        })
        .catch(() => {
          commit("errorCount", state.errorCount + 1);
        });
    },
    showConfirme({ commit }, value) {
      commit("showConfirme", value);
    },
    errorCountReset({ commit }) {
      commit("errorCount", 0);
    }
  }
};
