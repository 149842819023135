<template>
  <div>
    <v-dialog v-model="showDialog" max-width="600" hide-overlay>
      <v-card>
        <v-card-title>
          <span class="headline primary--text">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <span style="font-size: 16px;" v-html="DialogMessage"></span>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>

          <v-btn large color="success" @click="accept">Godta</v-btn>
          <v-btn large outlined @click="close">Avbryt</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["show", "title", "DialogMessage", "from"],
  methods: {
    accept() {
      this.show.show = false;
      this.$emit("confirme", true, this.from);
    },
    close() {
      this.show.show = false;
      this.$emit("confirme", false, this.from);
    }
  },
  computed: {
    showDialog: {
      get: function() {
        if (this.show.show) {
          return this.show.show;
        }
        return false;
      },
      set: function() {
        this.close();
      }
    }
  }
};
</script>
