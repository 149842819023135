<template
  ><div>
    <v-row class="justify-center">
      <v-col xl="9">
        <v-alert
          v-if="showDeadlineForComplaint"
          dense
          text
          type="warning"
          class="mb-0"
        >
          Fristen for å sende inn reklamasjonshenvendelse er gått ut. Det er
          fortsatt mulig å sende inn reklamasjonshenvendelse.
        </v-alert>

        <v-row>
          <v-col v-if="!$store.getters['shared/isMobileDevice']" cols="12">
            <v-list color="transparent">
              <v-list-item class="px-0 mb-2">
                <v-list-item-avatar>
                  <v-icon class="primary white--text">mdi-forum</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    class="primary--text headline font-weight-black"
                    >{{
                      data.length > 1 ? "Nye henvendelser" : "Ny henvendelse"
                    }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="d-none d-sm-flex mx-4">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="help"
                        fab
                        dark
                        small
                        color="blue"
                      >
                        <v-icon dark>mdi-help</v-icon>
                      </v-btn>
                    </template>
                    <span>Hjelp</span>
                  </v-tooltip>
                </v-list-item-action>

                <v-list-item-action class="d-none d-sm-flex">
                  <v-btn @click="addInquiries" dark color="primary">
                    <v-icon dark>mdi-plus</v-icon>
                    Nytt henvendelsespunkt
                  </v-btn>
                </v-list-item-action>
                <v-list-item-action class="d-none d-sm-flex">
                  <v-btn @click="saveRequest" dark color="success">
                    <v-icon dark>mdi-check</v-icon>
                    Send
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
            </v-list>
          </v-col>
          <!-- Mobile nav -->
          <SpeedDial
            @help="help"
            newRequest="true"
            @addInquiries="addInquiries"
            showSave="true"
            @save="saveRequest"
          />
          <v-col cols="12">
            <v-alert
              dismissible
              v-if="$route.name === 'newrequest'"
              border="left"
              color="#e0e4e2"
              class="mb-0"
            >
              En henvendelse er et generelt spørsmål om din bolig. En
              reklamasjonshenvendelse er en henvendelse der du mener at det
              foreligger en feil eller mangel ved boligen.
            </v-alert>
            <v-alert
              dismissible
              v-if="$route.name === 'NewJointOwnership'"
              border="left"
              color="#e0e4e2"
              class="mb-0"
            >
              En henvendelse er et generelt spørsmål som gjelder ditt sameie. En
              reklamasjonshenvendelse er en henvendelse der du mener at det
              foreligger en feil eller mangel ved fellesområdene eller generelt
              for prosjektet. Trykk på spørsmålstegnet for mer informasjon.
            </v-alert>
          </v-col>
          <v-col cols="12" v-for="item in data" :key="item.id">
            <v-card class="theme-shadow">
              <v-toolbar dense color="primary" flat dark>
                <v-toolbar-title class="text-uppercase subtitle-2">
                  <span v-if="item.mode === 'condominium'">sameie </span>
                  {{ item.isInquiry === "GH" ? "Generell " : "" }}
                  {{ item.isInquiry === "RH" ? "Reklamasjons" : "" }}henvendelse
                  #{{ item.index }}
                </v-toolbar-title>
                <v-spacer></v-spacer>

                <v-btn
                  v-if="data.length != 1 && data.length === item.index"
                  @click="deleteInquiries(item.index)"
                  text
                >
                  Slett
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-toolbar>
              <v-form ref="form" v-model="item.valid" lazy-validation>
                <div class="px-3 px-sm-10 py-6">
                  <v-radio-group
                    class="mt-0"
                    v-model="item.isInquiry"
                    row
                    :rules="[
                      () => item.isInquiry !== null || 'Dette feltet er påkrevd'
                    ]"
                  >
                    <v-radio label="Generell henvendelse" value="GH"></v-radio>
                    <v-radio
                      label="Reklamasjonshenvendelse"
                      value="RH"
                    ></v-radio>
                  </v-radio-group>

                  <v-text-field
                    dense
                    required
                    ref="title"
                    v-model="item.title"
                    :rules="[() => !!item.title || 'Dette feltet er påkrevd']"
                    clearable
                    filled
                    label="Tittel"
                  ></v-text-field>
                  <v-textarea
                    dense
                    rows="2"
                    auto-grow
                    clearable
                    filled
                    required
                    ref="text"
                    v-model="item.text"
                    :rules="[() => !!item.text || 'Dette feltet er påkrevd']"
                    label="Beskrivelse"
                  ></v-textarea>
                  <Upload
                    @fileResponse="fileResponse"
                    :index="item.index"
                    :multifiles="item.attachments"
                  />
                </div>
              </v-form>
            </v-card>
          </v-col>
          <v-col class="mt-4 mt-md-0" cols="12" md="6">
            <v-btn
              class="mr-0 mr-sm-3 m-w-100"
              @click="addInquiries"
              dark
              color="primary"
              ><v-icon dark>mdi-plus</v-icon>Nytt henvendelsespunkt</v-btn
            >
            <v-btn
              class="mt-2 mt-sm-0 m-w-100"
              @click="saveRequest"
              dark
              color="success"
            >
              <v-icon dark>mdi-check</v-icon>
              Send
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <DialogMessage
      :show="showDialogMessage"
      title="Hva er en henvendelser?"
      :DialogMessage="DialogMessage"
    />
    <AcceptCancel
      v-model="showAcceptDialog"
      :hideOverlay="false"
      title="Reklamasjonsfristen er ute"
      DialogMessage="Fristen for å sende inn reklamasjonerhenvendelser er ute. Er du sikker på at du fortsatt vil sende inn reklamasjonshenvendelsen?"
      @confirme="save"
    />
  </div>
</template>
<script>
import notificationsService from "@/notification.services";
import baseApi from "@/api/api";
import DialogMessage from "@/components/dialog/DialogMessage";
import Upload from "@/components/shared/Upload";
import SpeedDial from "@/components/shared/SpeedDial";

import AcceptCancel from "@/components/dialog/AcceptCancel";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      data: [
        {
          index: 1,
          attachments: [],
          mode: null,
          isInquiry: null,
          valid: true
        }
      ],
      showDialogMessage: { show: false },
      DialogMessage: `Når du melder inn en henvendelse huker du av for om det er en generell henvendelse eller en reklamasjonshenvendelse.
                      Merk at en reklamasjonshenvendelse må meldes inn så snart feilen eller mangelen oppdages. <br/><br/>
                      Har du flere henvendelser du ønsker å melde inn til oss, splitter du disse ved å trykke på pluss-tegnet.`,
      isRequired: ["title", "text"],
      route: null,
      mode: null,
      valid: true,
      requestIsComplaint: false,
      showAcceptDialog: false
    };
  },
  methods: {
    saveRequest() {
      if (this.showDeadlineForComplaint) {
        this.showAcceptDialog = true;
      } else {
        this.save();
      }
    },
    save() {
      var isValid;
      this.$refs.form.forEach(x => {
        isValid = x.validate();
      });
      if (isValid) {
        this.data.forEach(x => {
          delete x.valid;
        });
        this.data.forEach(x => {
          if (x.isInquiry === "GH") {
            x.isInquiry = true;
          } else if (x.isInquiry === "RH") {
            x.isInquiry = false;
          }
        });
        baseApi.editComplaint(this.data).then(Response => {
          if (Response.status === 200) {
            //Load complaints again to get the new one in list
            this.$store.dispatch("request/complaints");
            this.$router.push({
              name: this.route
            });

            notificationsService.success("Hendelse lagt til");
          }
        });
      } else {
        notificationsService.warn(
          "Henvendelsestype, tittel og beskrivelse må fylles ut og total filstørrelse kan max være 22MB"
        );
      }
    },
    addInquiries() {
      let length = this.data.length;
      const newInquiries = {
        index: length + 1,
        attachments: [],
        mode: this.mode,
        isInquiry: true,
        valid: true
      };
      this.data.push(newInquiries);
    },
    deleteInquiries(index) {
      this.data.splice(index - 1, 1);
      this.data.forEach((x, index) => {
        x.index = index + 1;
      });
    },
    fileResponse(item, index) {
      this.data[index - 1].attachments = [...item];
    },
    help() {
      this.showDialogMessage.show = !this.showDialogMessage.show;
      if (this.$route.name === "newrequest") {
        this.DialogMessage = `Når du melder inn en henvendelse huker du av for om det er en generell henvendelse eller en reklamasjonshenvendelse.
                      Merk at en reklamasjonshenvendelse må meldes inn så snart feilen eller mangelen oppdages. <br/><br/>
                      Har du flere henvendelser du ønsker å melde inn til oss, splitter du disse ved å trykke på pluss-tegnet.`;
      } else {
        this.DialogMessage = `Når du melder inn en henvendelse på vegne av sameiet, 
                              huker du av for om det er en generell henvendelse eller en reklamasjonshenvendelse. 
                              Merk at en reklamasjonshenvendelse må meldes inn så snart feilen eller mangelen oppdages.`;
      }
    }
  },
  watch: {
    data: {
      handler(data) {
        // See if some if request is complain
        this.requestIsComplaint = data.some(x => x.isInquiry === "RH");
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters({
      profile: "shared/profile"
    }),
    showDeadlineForComplaint() {
      if (
        this.$route.name === "newrequest" &&
        this.requestIsComplaint &&
        this.profile &&
        moment(moment()).isAfter(
          this.profile.user.currentSection.complaintDeadLine
        )
      ) {
        return true;
      }
      return false;
    }
  },

  created() {
    if (this.$route.name === "newrequest") {
      this.route = "requests";
      this.mode = "section";
      this.data[0].mode = "section";
    } else if (this.$route.name === "NewJointOwnership") {
      this.route = "JointOwnerships";
      this.mode = "condominium";
      this.data[0].mode = "condominium";
    }
  },
  components: {
    DialogMessage,
    Upload,
    SpeedDial,
    AcceptCancel
  }
};
</script>
