import baseApi from "./baseApi";

const api = {
  async colorOtionsInfoSeen() {
    const response = await baseApi.post(`customer/coloroptions`);
    return response.data;
  }
};

export default api;
