import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"fullscreen":_vm.$store.getters['shared/isMobileDevice'],"max-width":"1000"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline mb-3 primary--text"},[_vm._v(" "+_vm._s(_vm.title)+" "),_c(VSpacer),_c(VBtn,{attrs:{"color":"red","icon":""},on:{"click":_vm.cancel}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VImg,{staticClass:"white--text text-right",attrs:{"contain":"","max-height":"75vh","src":_vm.url}})],1),_c(VCardActions,{staticClass:"justify-center pb-sm-8"},[_c(VBtn,{attrs:{"depressed":"","large":"","color":"primary"},on:{"click":_vm.cancel}},[_vm._v(" Lukk ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }