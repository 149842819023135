import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"sidemenuWrapper",class:{ sidemenuToggle: _vm.openCart }},[_c('div',{staticClass:"sidemenu-btns"},[(!_vm.openCart)?_c(VBadge,{attrs:{"overlap":"","color":"yellow"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [(_vm.cartInfo)?_c('span',[_vm._v(_vm._s(_vm.cartInfo.changes))]):_c('span',[_vm._v("0")])]},proxy:true}],null,false,1597538226)},[_c(VBtn,{staticClass:"mb-sm-4",attrs:{"fab":"","color":'green'},on:{"click":_vm.toggleSidemenu}},[_c(VIcon,{staticClass:"white--text"},[_vm._v("mdi-cart")])],1)],1):_c(VBtn,{attrs:{"fab":"","color":"red"},on:{"click":_vm.toggleSidemenu}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1),_c(VBtn,{staticClass:"infoBtn",class:{ activeInfoBtn: _vm.showDialog.show === true },attrs:{"fab":"","color":"blue"},on:{"click":_vm.help}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-help")])],1)],1),_c(VCard,{staticClass:"cart-container",attrs:{"tile":"","flat":""}},[_c(VList,{staticClass:"py-0"},[_c(VListItem,[_c(VListItemContent,{staticStyle:{"max-width":"45%"}},[_c(VListItemTitle,{staticClass:"subtitle font-weight-bold text-uppercase"},[_vm._v("Handlekurv")])],1),_c(VListItemContent,[_c(VBtn,{attrs:{"disabled":_vm.cartInfo.changes <= 0,"color":"yellow","small":"","depressed":""},on:{"click":_vm.confirmeCart}},[_vm._v(" Bekreft bestilling ")])],1)],1),_c('div',{staticClass:"pl-4"},[_vm._v(" Totalpris fargevalg: "+_vm._s(_vm.optionsService.formatPrice(_vm.cartInfo.totalPrice))+" ")]),_c(VAlert,{staticClass:"mt-2 mb-0 body-2",attrs:{"color":"info","text":"","dismissible":""},on:{"click":function($event){_vm.showAlert = false}},model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}},[_vm._v(" Husk å bekrefte bestillingen for hver endring. Bekreftede farger kan endres helt frem til fristen. ")])],1),_c(VList,{staticClass:"cart-list-container pt-0",class:{
          'cart-list-container': _vm.showAlert,
          updatedHeight: !_vm.showAlert
        }},[(!_vm.cartInfo.confirmed)?_c('div',_vm._l((_vm.cartInfo.cart),function(itemCart){return _c('div',{key:itemCart.id},[_c('div',[_c(VCardSubtitle,{staticClass:"pb-0"},[_vm._v(_vm._s(itemCart.name))]),_vm._l((itemCart.data),function(options){return _c('div',{key:options.cartItemId},[_c(VListItem,{staticClass:"cart-list",attrs:{"two-line":""}},[_c(VListItemAvatar,{attrs:{"color":options.hexColor}}),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(options.name)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(options.ncs)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.optionsService.formatPrice(options.price))+",- inkludert mva ")])],1),_c(VBtn,{attrs:{"text":"","fab":"","dark":"","small":"","color":"red"},on:{"click":function($event){return _vm.deleteFromCart(options)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1)],1)],1)})],2)])}),0):_vm._e(),(_vm.cartInfo.confirmed)?_c('div',[_c(VCardSubtitle,[_vm._v("Bekreftet fargevalg")]),_vm._l((_vm.cartInfo.cartConfirmed),function(cartConfirmed){return _c('div',{key:cartConfirmed.id},[_c(VListItem,{staticClass:"cart-list",attrs:{"two-line":""}},[_c(VListItemAvatar,{attrs:{"color":cartConfirmed.hexColor}}),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(cartConfirmed.name)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(cartConfirmed.ncs)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.optionsService.formatPrice(cartConfirmed.price))+",- inkludert mva ")])],1),(cartConfirmed.state === 0)?_c(VBtn,{attrs:{"text":"","fab":"","dark":"","small":"","color":"red"},on:{"click":function($event){return _vm.deleteFromCart(cartConfirmed)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1):_vm._e()],1)],1)})],2):_vm._e(),(_vm.cartInfo.confirmed && _vm.cartInfo.cartChanges.length > 0)?_c('div',[_c(VCardSubtitle,[_vm._v("Endringer av fargevalg")]),_vm._l((_vm.cartInfo.cartChanges),function(cart){return _c('div',{key:cart.id},[_c(VListItem,{class:{
                'cart-list': true
              },attrs:{"two-line":""}},[_c(VListItemAvatar,{attrs:{"color":cart.hexColor}}),_c(VListItemContent,{class:{ remove: cart.state === -1 }},[_c(VListItemTitle,{class:{
                    colorDelete: cart.state === -1,
                    colorAdd: cart.state !== -1
                  }},[_vm._v(" "+_vm._s(cart.name)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(cart.ncs)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.optionsService.formatPrice(cart.price))+",- inkludert mva ")])],1),(cart.state !== -1)?_c(VBtn,{attrs:{"text":"","fab":"","dark":"","small":"","color":"red"},on:{"click":function($event){return _vm.deleteFromCart(cart, true)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1):_vm._e()],1)],1)})],2):_vm._e(),_c('div',{staticClass:"text-center mt-2 mt-md-6"},[_c(VBtn,{attrs:{"disabled":_vm.cartInfo.changes <= 0,"color":"yellow","depressed":""},on:{"click":_vm.confirmeCart}},[_vm._v("Bekreft bestilling")])],1)])],1)],1),_c('DialogMessage',{attrs:{"show":_vm.showDialog,"title":_vm.title,"DialogMessage":_vm.DialogMessage,"DialogAccept":false,"hideOverlay":false}}),_c('AcceptCancel',{attrs:{"title":"Bekrefte sletting av farge","DialogMessage":"Er du sikker på at du vil slette fargen fra handlekurven?"},on:{"confirme":_vm.reset},model:{value:(_vm.showAccept),callback:function ($$v) {_vm.showAccept=$$v},expression:"showAccept"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }