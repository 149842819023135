import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"1000"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{attrs:{"tile":""}},[_c(VCardTitle,{staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(_vm.getData.name)+" "),_c('div',{staticClass:"flex-grow-1"}),_c(VCardActions,[_c(VBtn,{attrs:{"color":"red","outlined":""},on:{"click":_vm.close}},[_vm._v("Lukk")])],1)],1),_c(VImg,{staticClass:"white--text text-right",attrs:{"contain":"","max-height":"75vh","src":_vm.optionsService.getOptionImage(_vm.getData, false, _vm.info)}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }