import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.getData.length === 0)?_c('TimelineNotReady'):_c(VRow,{staticClass:"justify-center"},[_c(VCol,{attrs:{"xl":"9"}},[_c(VList,{attrs:{"color":"transparent"}},[_c(VListItem,{staticClass:"pl-0 mb-2"},[_c(VListItemAvatar,[_c(VIcon,{staticClass:"primary white--text"},[_vm._v("mdi-chart-timeline-variant")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"primary--text headline font-weight-black"},[_vm._v("Tidslinje bygg")]),_c(VListItemSubtitle,[_vm._v("Tidslinje for din bolig")])],1),_c(VListItemAction,{staticClass:"d-none d-sm-flex"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"fab":"","dark":"","small":"","color":"blue"},on:{"click":function($event){_vm.showDialogMessage.show = true}}},on),[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-help")])],1)]}}])},[_c('span',[_vm._v("Hjelp")])])],1)],1),_c(VDivider)],1),_c(VTimeline,{attrs:{"dense":_vm.$store.getters['shared/isMobileDevice']}},_vm._l((_vm.getData),function(item,i){return _c(VTimelineItem,{key:i,staticClass:"align-center",attrs:{"color":item.color},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(VAvatar,{attrs:{"color":"#ffffff"}},[(item.color === 'yellow')?_c(VIcon,{staticClass:"hammerAnimation",attrs:{"left":"","color":"yellow"}},[_vm._v(" mdi-hammer ")]):_vm._e(),(item.color !== 'yellow')?_c(VIcon,{attrs:{"color":item.hasDone ? 'success' : item.color}},[_vm._v(" "+_vm._s(item.hasDone ? "mdi-checkbox-marked-circle-outline" : "mdi-checkbox-blank-circle-outline")+" ")]):_vm._e()],1)]},proxy:true}],null,true)},[_c('span',{attrs:{"slot":"opposite"},slot:"opposite"},[(item.imageName)?_c(VImg,{attrs:{"src":("https://thg.blob.core.windows.net/" + (item.imageName)),"lazy-src":("https://thg.blob.core.windows.net/" + (item.imageName))}}):_vm._e()],1),_c('div',{staticClass:"py-4"},[_c('h2',{class:{
                '`headline font-weight-light mb-4`': true,
                'grey--text': !item.hasDone,
                'yellow--text': item.color === 'yellow',
                'primary--text': item.hasDone
              }},[_vm._v(" "+_vm._s(item.nameMT ? item.nameMT : item.resourceName)+" ")]),_c('div',[_vm._v(" "+_vm._s(item.description)+" ")])])])}),1)],1),_c('DialogMessage',{attrs:{"show":_vm.showDialogMessage,"title":"Tidslinje","DialogMessage":_vm.DialogMessage}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }