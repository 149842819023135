import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.data),function(buyer,index){return _c('div',{key:index},[(index !== 0)?_c(VRow,{staticClass:"ma-0"},[_c('h4',{staticClass:"darkgrey--text mr-3"},[_vm._v("Medkjøper "+_vm._s(index))]),_c(VBtn,{attrs:{"x-small":"","outlined":"","depressed":"","color":"error"},on:{"click":function($event){return _vm.deleteCoBuyer(index)}}},[_vm._v(" Slett ")])],1):_vm._e(),_c(VRow,[_c(VCol,{staticClass:"pb-0",attrs:{"cols":"12","md":"6"}},[_c(VTextField,{attrs:{"type":"text","rules":[function (v) { return !!v || 'Fornavn må være utfylt'; }],"label":"Fornavn","prepend-inner-icon":"mdi-account","placeholder":("Hva er fornavnet " + (index === 0 ? 'ditt?' : 'til medkjøper?')),"required":"","filled":"","autocomplete":"given-name","disabled":index === 0},model:{value:(buyer.firstName),callback:function ($$v) {_vm.$set(buyer, "firstName", $$v)},expression:"buyer.firstName"}})],1),_c(VCol,{staticClass:"pb-0",attrs:{"cols":"12","md":"6"}},[_c(VTextField,{attrs:{"type":"text","rules":[function (v) { return !!v || 'Etternavn må være utfylt'; }],"label":"Etternavn","prepend-inner-icon":"mdi-account","placeholder":("Hva er etternavnet " + (index === 0 ? 'ditt?' : 'til medkjøper?')),"required":"","filled":"","autocomplete":"family-name","disabled":index === 0},model:{value:(buyer.lastName),callback:function ($$v) {_vm.$set(buyer, "lastName", $$v)},expression:"buyer.lastName"}})],1),_c(VCol,{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6"}},[_c(VTextField,{attrs:{"rules":[function (v) { return !!v || 'Adresse må være utfylt'; }],"label":"Adresse","prepend-inner-icon":"mdi-home","placeholder":"Eksempelveien 1","required":"","filled":""},model:{value:(buyer.address),callback:function ($$v) {_vm.$set(buyer, "address", $$v)},expression:"buyer.address"}})],1),_c(VCol,{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6"}},[_c(VTextField,{attrs:{"rules":[
            function (v) { return !!v || 'Postnummer må være utfylt'; },
            _vm.postCodeNotMoreThanFour
          ],"label":"Postnummer","prepend-inner-icon":"mdi-home","placeholder":"xxxx","counter":4,"required":"","filled":""},model:{value:(buyer.postCode),callback:function ($$v) {_vm.$set(buyer, "postCode", $$v)},expression:"buyer.postCode"}})],1),_c(VCol,{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6"}},[_c('MobileNumber',{attrs:{"filled":"","disabled":index === 0,"prepend-inner-icon":"mdi-phone"},model:{value:(buyer.mobile),callback:function ($$v) {_vm.$set(buyer, "mobile", $$v)},expression:"buyer.mobile"}})],1),_c(VCol,{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6"}},[_c(VTextField,{attrs:{"type":"email","rules":_vm.emailRules,"label":"E-post","prepend-inner-icon":"mdi-email","placeholder":"navn@eksempel.no","required":"","filled":"","disabled":index === 0},model:{value:(buyer.email),callback:function ($$v) {_vm.$set(buyer, "email", $$v)},expression:"buyer.email"}})],1),_c(VCol,{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6"}},[_c('PersonNumber',{attrs:{"filled":"","prepend-inner-icon":"mdi-card-account-details"},model:{value:(buyer.pNumber),callback:function ($$v) {_vm.$set(buyer, "pNumber", $$v)},expression:"buyer.pNumber"}})],1)],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }