<template>
  <div>
    <v-text-field
      ref="inputMob"
      class="rounded-0"
      v-model="number"
      type="tel"
      pattern="[0-9]*"
      :rules="[
        v => !!v || 'Mobilnummer må fylles ut!',
        telRequired,
        telLength,
        telNotMoreThanEight
      ]"
      :label="label"
      :prepend-inner-icon="prependInnerIcon"
      :counter="8"
      :counter-value="counterValue"
      placeholder="xx xx xx xx"
      :outlined="outlined"
      :filled="filled"
      :dense="dense"
      :disabled="disabled"
      @change="telFinishedChange = true"
      @blur="fucusInput = false"
      @focus="fucusInput = true"
      :autocomplete="autocomplete"
      required
    ></v-text-field>
  </div>
</template>

<script>
import format from "@/helpers/format";
export default {
  props: {
    value: {
      default: null
    },
    label: {
      type: String,
      default: "Mobil"
    },
    outlined: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    prependInnerIcon: {
      type: String,
      default: null
    },
    autocomplete: {
      type: String,
      default: "tel"
    }
  },
  data() {
    return {
      telFinishedChange: false,
      fucusInput: false
    };
  },
  methods: {
    convertToNumber(value) {
      this.number = value;
    }
  },
  computed: {
    number: {
      get: function() {
        if (!this.fucusInput) {
          var num = format.phoneNumber(this.value);
          return num;
        }

        return this.value;
      },
      set: function(value) {
        var value = value.replace(/\s/g, "")
          ? parseInt(value.replace(/\s/g, ""))
          : null;

        this.$emit(
          "input",
          !isNaN(value) ? value : this.$refs.inputMob.reset()
        );
      }
    },
    counterValue() {
      return v => {
        return !!v && v.toString().replace(/\s/g, "").length
          ? v.toString().replace(/\s/g, "").length
          : 0;
      };
    },
    telRequired() {
      return () =>
        Number.isInteger(this.value) || "Mobilnummer må være et nummer!";
    },
    telLength() {
      if (this.telFinishedChange) {
        return value =>
          (value && value.toString().replace(/\s/g, "").length >= 8) ||
          "Mobilnummer må være 8 siffer!";
      }
      return true;
    },
    telNotMoreThanEight() {
      return value => {
        return (
          (!!value && value.toString().replace(/\s/g, "").length <= 8) ||
          "Mobilnummer må være 8 siffer!"
        );
      };
    }
  }
};
</script>
