<template>
  <div>
    <OptionsCategoriContainer />
  </div>
</template>

<script>
import OptionsCategoriContainer from "@/components/store/webStore/options/container/OptionsCategoriContainer.vue";
export default {
  components: {
    OptionsCategoriContainer
  }
};
</script>
