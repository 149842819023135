<template>
  <div>
    <v-row class="justify-center">
      <v-col xl="9">
        <!-- Alert when req is sendt after req deadline -->
        <v-alert
          v-if="afterDedlineForComplaint"
          dense
          text
          type="warning"
          class="mb-0"
        >
          Denne reklamasjonenen er sendt inn etter reklmasjonsfristen
        </v-alert>
        <v-row>
          <v-col cols="12">
            <v-list color="transparent">
              <v-list-item class="px-0 mb-2">
                <v-list-item-avatar>
                  <v-icon class="primary white--text">mdi-forum</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    class="primary--text headline font-weight-black"
                    >{{ data.name }}</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action class="d-none d-sm-flex">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="showDialogMessage.show = true"
                        fab
                        dark
                        small
                        color="blue"
                      >
                        <v-icon dark>mdi-help</v-icon>
                      </v-btn>
                    </template>
                    <span>Hjelp</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
            </v-list>
          </v-col>

          <!-- Mobile nav -->
          <HelpBadges @help="showDialogMessage.show = true" />
          <v-col class="pt-0 mb-4 mb-md-12" cols="12" md="6">
            <h2 class="mb-4 font-weight-light">Innhold</h2>

            <v-text-field
              outlined
              readonly
              label="Saksnummer"
              :value="data.lineId"
            ></v-text-field>
            <v-text-field
              outlined
              readonly
              label="Henvendelse"
              :value="data.name"
            ></v-text-field>
            <v-textarea
              outlined
              readonly
              label="Henvendelse beskrivelse"
              :value="data.text"
            ></v-textarea>
            <v-text-field
              outlined
              readonly
              label="Status"
              :value="data.regStatus"
            ></v-text-field>
            <v-text-field
              outlined
              readonly
              label="Ansvarlig"
              :value="data.contactPersonName"
            ></v-text-field>
            <v-text-field
              outlined
              readonly
              label="Firma"
              :value="data.contactName"
            ></v-text-field>
            <h2 class="font-weight-light" v-if="data.attachmentsCount !== 0">
              Vedlegg
            </h2>
            <v-chip
              class="mt-1 mr-1"
              pill
              small
              v-for="attachments in data.attachments"
              :key="attachments.id"
              @click="downloadFile(attachments)"
            >
              <v-avatar left color="lightgrey darken-1">
                <v-icon>mdi-download</v-icon>
              </v-avatar>
              {{ attachments.fileName }}
            </v-chip>
          </v-col>
          <v-col class="pt-0" cols="12" md="6">
            <h2 class="mb-4 font-weight-light">Kommentarer</h2>
            <v-card class="theme-shadow mb-3">
              <v-list class="pb-4">
                <v-list-item class="pl-1">
                  <v-list-item-content class="py-0 new-comment-content">
                    <v-form ref="form" lazy-validation>
                      <Upload
                        class="commentFiles-btn"
                        @fileResponse="fileResponse"
                        :multifiles="newComment.attachments"
                        :icon="true"
                      />
                      <v-textarea
                        class="ml-9"
                        v-model="newComment.text"
                        label="Kommentar"
                        rows="1"
                        auto-grow
                        :rules="rules"
                        append-outer-icon="mdi-send"
                        @click:append-outer="sendComment(newComment)"
                        clearable
                      >
                      </v-textarea>
                    </v-form>
                    <div class="ml-8">
                      <div v-if="newComment.attachments.length < 3">
                        <v-chip
                          pill
                          class="mr-2"
                          x-small
                          v-for="attachment in newComment.attachments"
                          :key="attachment.id"
                        >
                          {{ attachment.name }}
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon
                                @click="deleteFile(attachment)"
                                v-on="on"
                                right
                                color="red"
                                small
                                >mdi-close</v-icon
                              >
                            </template>
                            <span>Slett filen</span>
                          </v-tooltip>
                        </v-chip>
                      </div>
                      <div v-else>
                        <v-chip pill class="mr-2" x-small>
                          {{ newComment.attachments.length }} filer
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon
                                @click="deleteFiles"
                                v-on="on"
                                right
                                color="red"
                                small
                                >mdi-close</v-icon
                              >
                            </template>
                            <span>Slett filene</span>
                          </v-tooltip>
                        </v-chip>
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>

            <div v-for="comment in comments" :key="comment.id">
              <v-card class="theme-shadow mb-3">
                <v-sheet
                  :color="!comment.isCustomerComment ? 'primary' : 'blue'"
                  height="2"
                ></v-sheet>
                <v-list
                  :class="{
                    notRead:
                      !comment.readByCustomer &&
                      $route.name === 'request' &&
                      !comment.isCustomerComment,
                    notReadRemove:
                      comment.readByCustomer && $route.name === 'request'
                  }"
                >
                  <v-list-item>
                    <div class="d-flex flex-column align-self-start pt-4 pr-4">
                      <v-avatar class="mx-auto mb-1">
                        <v-img
                          v-if="
                            comment.isCustomerComment &&
                              comment.commentedById === getProfile.id
                          "
                          :src="
                            optionsService.getProfileImage(
                              comment.commentedById,
                              getStamp
                            )
                          "
                          alt="Kunde bilde"
                        />

                        <!-- If user should be anonyme or employee is undefined -->
                        <v-img
                          v-if="
                            (comment.isCustomerComment &&
                              comment.commentedById !== getProfile.id) ||
                              (!comment.isCustomerComment &&
                                !comment.commentedById)
                          "
                          :src="require('@/assets/imgs/gfx/profile.svg')"
                          alt="Kunde bilde"
                        />
                        <v-img
                          class="mx-auto mb-1"
                          v-if="
                            !comment.isCustomerComment && comment.commentedById
                          "
                          :src="
                            optionsService.getEmployeeProfileImage(
                              comment.commentedById
                            )
                          "
                          alt="Trysilhus ansatt"
                        />
                      </v-avatar>
                      <v-chip
                        v-if="comment.isCustomerComment"
                        color="blue"
                        dark
                        x-small
                        >Kunde</v-chip
                      >
                      <v-chip
                        v-if="!comment.isCustomerComment"
                        color="primary"
                        x-small
                        >Ansatt</v-chip
                      >
                    </div>
                    <v-list-item-content>
                      <v-list-item-title
                        class="d-block d-sm-flex justify-space-between"
                      >
                        <div v-if="comment.commentedByName">
                          <div
                            v-if="
                              (comment.isCustomerComment &&
                                comment.commentedById === getProfile.id) ||
                                !comment.isCustomerComment
                            "
                          >
                            {{
                              comment.commentedByName
                                ? comment.commentedByName
                                : "Ansatt"
                            }}
                          </div>
                          <div v-else>Kunde</div>
                        </div>
                        <div v-else>
                          <div v-if="!comment.isCustomerComment">
                            Trysilhus ansatt
                          </div>
                          <div v-else>
                            Kunde
                          </div>
                        </div>

                        <div class="d-flex caption align-center">
                          <v-chip class="mr-1" x-small>{{
                            comment.stringStatus
                          }}</v-chip>
                          {{ comment.createdDate | moment("DD.MM.YYYY HH:mm") }}
                        </div>
                      </v-list-item-title>
                      <v-card-text
                        style="white-space: break-spaces;"
                        class="pa-0"
                      >
                        <div class="my-2" v-html="comment.text"></div>
                        <v-chip
                          pill
                          v-for="attachments in comment.attachments"
                          :key="attachments.id"
                          @click="downloadFile(attachments)"
                          class="mt-1 mr-1"
                          x-small
                        >
                          <v-avatar left color="lightgrey darken-1">
                            <v-icon small>mdi-download</v-icon>
                          </v-avatar>
                          {{ attachments.fileName }}
                        </v-chip>
                      </v-card-text>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <Dialog :show="showDialog" />
    <DialogMessage
      :show="showDialogMessage"
      title="Henvendelse"
      :DialogMessage="DialogMessage"
    />
  </div>
</template>
<script>
import baseApi from "@/api/api";
import { mapGetters, mapActions } from "vuex";
import Dialog from "@/components/dialog/DialogNotification";
import DialogMessage from "@/components/dialog/DialogMessage";
import notificationsService from "@/notification.services";
import HelpBadges from "@/components/shared/HelpBadges";
import optionsService from "@/options.services";
import Upload from "@/components/shared/Upload";
import moment from "moment";
import requestApi from "@/api/requestApi";
export default {
  data() {
    return {
      data: { isInquiry: true },
      newData: null,
      showDialog: { show: false },
      showDialogMessage: { show: false },
      DialogMessage: "Her kan du følge fremdrift og status på din henvendelse.",
      newComment: {
        text: null,
        attachments: [],
        regLineId: null,
        commentStatus: 6
      },
      fileAdded: false,
      optionsService
    };
  },
  methods: {
    ...mapActions({
      setUnreadComments: "request/unreadComments"
    }),

    sendComment(data) {
      if (this.$refs.form.validate()) {
        if (!this.getProfile.acceptEmail && !this.getProfile.acceptSms) {
          this.showDialog.show = true;
        }
        baseApi.addComment(data).then(Response => {
          if (Response.status === 200) {
            notificationsService.success("Kommentar lagt til");
            Response.data.isCustomerComment = true;
            this.$store.dispatch("request/complaints");
            // remove comment text and attachments
            this.newComment.text = null;
            this.newComment.attachments = [];
            //Reset check if comment text is not null
            this.$refs.form.reset();
            //Check if this is the first comment
            if (this.data.comments.length === 0) {
              this.data.comments.push(Response.data);
              this.data.commentsCount = 1;
            } else {
              this.data.comments.unshift(Response.data);
            }
          }
        });
      }
    },
    downloadFile(att) {
      baseApi.downloadFile(att);
    },
    fileResponse(item) {
      this.fileAdded = true;
      this.newComment.attachments = [...item];
      this.$refs.form.validate();
    },
    deleteFile(data) {
      if (data.id) {
        const index = this.newComment.attachments.findIndex(x => {
          return x.id === data.id;
        });
        this.newComment.attachments.splice(index, 1);
      }
      this.$refs.form.validate();
    },
    deleteFiles() {
      this.newComment.attachments = [];
      this.$refs.form.validate();
    },
    resetAdded() {
      this.fileAdded = false;
    },
    async setCommentReaded() {
      //Copy data so it detects changes in comments
      this.newData = JSON.parse(JSON.stringify(this.data));

      //Go tru all comments and send inn not readed before.
      await this.newData.comments.forEach(async x => {
        if (!x.readByCustomer) {
          await requestApi.commentIsViewed(x.id).then(Response => {
            //Set tot unread comments from respons
            this.setUnreadComments(Response);
            x.readByCustomer = true;
            //after some time set nye value. So background color cahnge late
            setTimeout(() => {
              this.data = [];
              this.data = this.newData;
            }, 2000);
            // this.data = [];
            // this.data = data;
          });
        }
      });
    }
  },
  computed: {
    ...mapGetters({
      profile: "shared/profile",
      changeProfileImage: "shared/changeProfileImage"
    }),
    comments() {
      if (this.data && this.data.commentsCount > 0) {
        return this.data.comments;
      }
      return {};
    },
    getProfile() {
      if (this.profile) {
        return this.profile.user;
      }
      return {};
    },
    getStamp() {
      if (this.changeProfileImage) {
        return this.changeProfileImage;
      }
      return 0;
    },
    afterDedlineForComplaint() {
      // is not sameie
      //Is complain
      // and is created after complaint deadline
      if (
        this.$route.name === "request" &&
        this.data &&
        !this.data.isInquiry &&
        this.profile &&
        moment(this.data.createdDate).isAfter(
          this.profile.user.currentSection.complaintDeadLine
        )
      ) {
        return true;
      }
      return false;
    },
    rules() {
      const rules = [];

      const rule = v =>
        this.newComment.attachments.length === 0 ||
        this.newComment.attachments.reduce((total, y) => {
          return total + y.size;
        }, 0) < 22000001 ||
        "Total filstørrelse kan max være 22MB";

      rules.push(rule);

      const newComment = () =>
        (this.fileAdded && this.newComment.text === null) ||
        !!this.newComment.text ||
        "Dette feltet er påkrevd";
      rules.push(newComment);
      this.resetAdded();
      return rules;
    }
  },
  async created() {
    this.data = await baseApi.getComplaint(this.$route.params.id);

    this.data.comments = this.data.comments.reverse();
    this.newComment.regLineId = this.$route.params.id;
    this.newComment.commentStatus = this.data.lineStatus;

    this.setCommentReaded(this.data.comments);
  },
  components: {
    Dialog,
    DialogMessage,
    Upload,
    HelpBadges
  }
};
</script>
<style lang="scss">
.new-comment-content {
  position: relative;
  .commentFiles-btn {
    position: absolute;
    left: 0;
    top: 15px;
  }
}
.notRead {
  background-color: #a023614d !important;
}
.notReadRemove {
  -moz-transition: all 1s ease-in;
  -o-transition: all 1s ease-in;
  -webkit-transition: all 1s ease-in;
  transition: all 1s ease-in;
  background-color: #ffffff !important;
}
</style>
