<template>
  <div v-if="$vuetify.breakpoint.smAndDown" class="pb-7">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title hide-details class="text-center py-1" single-line>{{
          this.projectName
        }}</v-list-item-title>
        <v-list-item-title class="text-center pb-2">
          {{ getFormattedTotalPrice }}
          NOK
        </v-list-item-title>
        <Slider
          v-model="getCapital"
          :minValue="getMinCapital"
          :maxValue="totalPrice"
          :stepValue="1"
          :includeSlider="true"
          label="Egenkapital"
          suffix="NOK"
          class="slBtn"
        />
        <v-divider></v-divider>

        <Slider
          v-model="data.rent"
          label="Effektiv rente"
          :suffix="'%'"
          :type="'number'"
          :minValue="0.1"
          :maxValue="10.0"
          :stepValue="0.01"
          :includeSlider="true"
          :delay="true"
          class="slBtn"
        />
        <div class="text-right pr-3 pt-3 pb-1">
          <span small hide-details style="font-size: 13px;font-weight: bold;"
            >Lånebehov:&nbsp;{{ getloanAmount }}</span
          >
        </div>
        <v-divider></v-divider>

        <Slider
          v-model="data.timePeriod"
          label="Nedbetalingstid"
          :type="'number'"
          :suffix="'År'"
          :minValue="5"
          :maxValue="30"
          :stepValue="1"
          :includeSlider="true"
          class="slBtn"
        >
        </Slider>
        <v-row no-gutters class="justify-space-between ">
          <v-col cols="6" class="text-left pl-5 pt-5">
            <span small hide-details style="font-size: 13px;font-weight: bold;"
              >Lånekost:&nbsp;{{ getMonthlyAnnutiyCost }}</span
            >
          </v-col>
          <!-- <v-col cols="1">
            <v-divider vertical hide-details></v-divider>
          </v-col> -->
          <v-col cols="5" class="text-right pr-3 pt-5">
            <span small hide-details style="font-size: 13px;font-weight: bold;">
              Fellesutgifter:&nbsp;{{ getCommonCost }}
            </span>
          </v-col>
          <v-col cols="1">
            <v-btn
              class="d-flex align-end  pr-6 mb-0"
              color="transparent"
              depressed
              small
              fab
              dark
              hide-details
              :ripple="false"
              @click="handelInfo((text = 'commonCost'))"
            >
              <v-icon hide-details color="blue" class="pr-1"
                >mdi-help-circle-outline</v-icon
              >
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row no-gutters align="center" class="py-4 pl-5">
          <v-col cols="10">
            <v-text-field
              v-model="getmaintanceCost"
              type="number"
              dense
              outlined
              disabled
              label="Vedlikeholdskostnader pr. mnd."
              suffix="NOK"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              class="pl-2"
              color="transparent"
              depressed
              fab
              dark
              :ripple="false"
              @click="handelInfo((text = 'maintanceCost'))"
            >
              <v-icon color="blue">mdi-help-circle-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider hide-details class="mx-1"></v-divider>
        <v-row no-gutters align="center" class="py-4 pl-5">
          <v-col cols="10">
            <v-select
              v-model="powerData.newArea"
              :items="powerData.areaWithBoost"
              dense
              small
              outlined
              hide-details
              required
              :disabled="!powerData.optionForBoost"
              label="Antall kvadratmeter"
              @change="updateNewArea"
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-btn
              class=""
              color="transparent"
              depressed
              fab
              dark
              hide-details
              :ripple="false"
              @click="handelInfo((text = 'powerCost'))"
            >
              <v-icon hide-details color="blue" class="ml-2"
                >mdi-help-circle-outline</v-icon
              >
            </v-btn>
          </v-col>
        </v-row>
        <div class="text-right pr-4 pb-1">
          <span hide-details style="font-weight:bold;font-size: 13px;"
            >{{ getnewMonthlyKwt }}&nbsp;kWt per måned</span
          >
        </div>
        <v-divider hide-details class="mx-1"></v-divider>
      </v-list-item-content>
    </v-list-item>
    <InfoDialog
      v-model="showInfoDialog"
      :title="getinfoTitle"
      :infoText="getinfoText"
    />
  </div>

  <div v-else>
    <v-list-item>
      <v-list-item-content>
        <!-- <v-list-item-subtitle
          hide-details
          class="text-center py-1"
          single-line
          >{{ this.projectName }}</v-list-item-subtitle
        > -->
        <v-list-item-title hide-details class="text-center py-1" single-line>{{
          this.projectName
        }}</v-list-item-title>
        <v-list-item-title class="text-center">
          {{ getFormattedTotalPrice }}
          NOK
        </v-list-item-title>
        <!-- <v-list-item-title>
                  {{ getFormattedTotalPrice + 5000000000000000000000000000 }}
                  NOK</v-list-item-title
                > -->

        <div class="text-left pt-14 pl-5">
          <v-label small hide-details>Egenkapital</v-label>
        </div>
        <Slider
          v-model="getCapital"
          :minValue="getMinCapital"
          :maxValue="totalPrice"
          :stepValue="1"
          label="NOK"
          class="slBtn"
        />
        <v-divider></v-divider>

        <div class="text-left pt-3 pl-5">
          <v-label small hide-details>Effektiv rente</v-label>
        </div>
        <Slider
          v-model="data.rent"
          label="%"
          :minValue="0.1"
          :maxValue="15.0"
          :stepValue="0.01"
          class="slBtn"
          :delay="true"
        >
        </Slider>
        <div class="text-right pr-3 pb-1">
          <span small hide-details style="font-size: 14px;font-weight: bold;"
            >Lånebehov:&nbsp;{{ getloanAmount }}</span
          >
        </div>
        <v-divider></v-divider>

        <div class="text-left pt-3 pl-5">
          <v-label small hide-details>Nedbetalingstid</v-label>
        </div>
        <Slider
          v-model="data.timePeriod"
          label="År"
          :minValue="5"
          :maxValue="30"
          :stepValue="1"
          class="slBtn"
        >
          <template v-slot:bottomField>
            <v-row no-gutters class="justify-space-between">
              <v-col cols="6" class="text-left pt-1">
                <span
                  small
                  hide-details
                  style="font-size: 14px;font-weight: bold;"
                  >Lånekost:&nbsp;{{ getMonthlyAnnutiyCost }}</span
                >
              </v-col>
              <!-- <v-divider vertical></v-divider> -->
              <v-col cols="6" class="text-right pt-1">
                <span
                  small
                  hide-details
                  style="font-size: 14px;font-weight: bold;"
                >
                  Fellesutgifter:&nbsp;{{ getCommonCost
                  }}<v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="blue" hide-details class="pl-1"
                        >mdi-help-circle-outline</v-icon
                      >
                    </template>
                    {{ infoTextComm }}
                  </v-tooltip>
                </span>
              </v-col>
            </v-row>
          </template>
        </Slider>
        <v-divider></v-divider>

        <v-row no-gutters align="center" class="pt-6 pl-4 pb-5">
          <v-col cols="10">
            <v-text-field
              v-model="getmaintanceCost"
              type="number"
              dense
              outlined
              disabled
              label="Vedlikeholdskostnader pr. mnd."
              hide-details
              class="pr-2"
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <div class="">
              <v-label hide-details>NOK</v-label>
            </div>
          </v-col>
          <v-col cols="1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="blue">mdi-help-circle-outline</v-icon>
              </template>
              Tall hentet fra et av Trysilhus sine prosjekt i Nannestad kommune.
              Avsetning vedlikehold pr bolig iht. driftsbudsjett for sameiet
            </v-tooltip>
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import Slider from "@/components/store/webStore/shared/loanCalculation/children/SliderWithNumberBase.vue";
import Calculator from "@/helpers/loanCalculator.js";
import InfoDialog from "@/components/dialog/InfoDialog.vue";

export default {
  emits: ["newArea", "update:newhouseData", "update:newMonthlyCost"],
  props: {
    totalPrice: { type: Number, required: true },
    projectName: { type: String },
    newhouseData: { type: Object, required: true },
    newMonthlyCost: { type: Number, default: 0 },
    powerData: { type: Object, required: false, required: false },
  },
  data() {
    return {
      showInfoDialog: false,
      newMaintainanceInfo:
        "<p>Noe info om Trysilhus bolig vedlikehold!</p><a href=”#”>Mer informasjon</a>",
      infoTextComm:
        "Erfaringstall fra Trysilhus prosjekter. Beløpet kan variere i prosjekter og etter boligens størrelse",
      text: "",
      title: "",
      infoText: "",
    };
  },
  computed: {
    data: {
      get() {
        return this.newhouseData;
      },
      set(data) {
        this.$emit("update:newhouseData", data);
      },
    },
    getMinCapital() {
      return this.totalPrice * 0.15; //15% of total
    },
    getCapital: {
      get() {
        return this.data.capital;
      },
      set(value) {
        let val = isNaN(value) ? Number(value.replace(/\D/g, "")) : value;
        this.data.capital = val;
      },
    },
    getFormattedTotalPrice() {
      return this.getFormattedValue(this.totalPrice);
    },
    getMonthlyAnnutiyCost() {
      let monthlycost = this.calcMonthlyAnnutiyCost();
      this.$emit("update:newMonthlyCost", monthlycost);
      return this.getFormattedValue(this.data.monthlyCost);
    },
    getmaintanceCost() {
      return this.getFormattedValue(this.data.maintanceCost);
    },
    getCommonCost() {
      return this.getFormattedValue(this.data.commonCost);
    },
    getloanAmount() {
      return this.getFormattedValue(this.data.loanAmount);
    },
    getnewMonthlyKwt() {
      return Math.round(this.powerData.newMonthlyKwt);
    },
    getinfoTitle() {
      return this.title;
    },
    getinfoText() {
      return this.infoText;
    },
  },
  methods: {
    getFormattedValue(data) {
      if (isNaN(data)) return data;
      return new Intl.NumberFormat("no-No", {
        minimumFractionDigits: 0,
      }).format(Math.round(data));
    },
    calcMonthlyAnnutiyCost() {
      if (!this.data.rent && !this.data.timePeriod) return null;
      let payment = Calculator.annutiyPayment(
        this.data.rent,
        this.data.timePeriod,
        this.data.loanAmount
      );

      this.data.monthlyCost = payment;
      return payment;
    },
    handelInfo() {
      switch (this.text) {
        case "maintanceCost":
          this.title = "Vedlikehold Trysilhus";
          this.infoText =
            "Tall hentet fra et av Trysilhus sine prosjekt i Nannestad kommune. Avsetning vedlikehold pr bolig iht. driftsbudsjett for sameiet.";
          break;
        case "powerCost":
          this.title = "Strømforbruk per måned";
          this.infoText = !this.powerData.optionForBoost
            ? "Basert på beregning av netto energibehov i normalklima for Trysilhus leilighetsbygg i Årnes. Trysilhus benytter Simien 6 til våre energiberegninger."
            : "Boligen kan innredes med loftsetasje, sett inn korrekt areal for boligen du vil beregne. Basert på beregning av netto energibehov i normalklima for Trysilhus leilighetsbygg i Årnes. Trysilhus benytter Simien 6 til våre energiberegninger.";
          break;
        case "commonCost":
          this.title = "Fellesutgifter per måned";
          this.infoText =
            "Erfaringstall fra Trysilhus prosjekter. Beløpet kan variere i prosjekter og etter boligens størrelse";
          break;
        default:
          this.title = "";
          this.infoText = "";
          break;
      }
      this.showInfoDialog = true;
    },
    updateNewArea() {
      this.$emit("newArea", this.powerData.newArea);
    },
  },
  watch: {
    getCapital() {
      this.data.loanAmount = Calculator.normalHousingLoanAmount(
        this.data.capital,
        this.totalPrice
      );
    },
  },
  components: { Slider, InfoDialog },
};
</script>

<style lang="scss">
.hstatus {
  .v-input--selection-controls {
    margin-top: 4px;
  }
}

.slBtn {
  .v-slider__thumb {
    &:before {
      opacity: 0;
    }

    width: 30px !important;
    height: 30px !important;
    left: -14px !important;
    transform: translateY(-33%) !important;
    //$slider-thick-border-radius: 4px !important;
    //$slider-border-radius: 8px !important;
  }

  .v-slider--horizontal .v-slider__track-container {
    height: 4px;
    top: 68%;
  }
}
</style>
