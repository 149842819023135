<template>
  <div>
    <h3 class="darkgrey--text mt-4">
      Forbehold
    </h3>
    <div v-for="(item, index) in reservations" :key="index">
      <div
        class="reservation-checkbox px-4 py-2"
        :class="{ hideBottomBorder: index + 1 !== value.length }"
      >
        <div>
          {{ item.text }} innen {{ item.deadline | moment("DD.MM.YYYY") }}
          <br />
          <span>
            <small
              v-if="(isValidating || item.isChanged) && !item.confirm"
              class="errorText"
            >
              Forbehold må være godkjent. Huk av for å godkjenne forbehold.
            </small>
          </span>
        </div>

        <v-checkbox
          v-model="item.confirm"
          class="my-0 py-0"
          hide-details
          :rules="[v => !!v]"
          :required="true"
          @change="change(item, index)"
        >
        </v-checkbox>
      </div>
    </div>
    <div class="mt-4">
      <p>
        Selger tar følgende forbehold for gjennomføring av kontrakten:
      </p>

      <ul class="mb-4">
        <li class="mb-3">
          Selger skal skriftlig meddele Kjøper når grunnlaget for forbeholdene
          er bortfalt samt dato for bortfallet.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    },
    isValidating: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    change(newValue, index) {
      if (newValue && !newValue.confirm) {
        newValue.isChanged = true;
      }
      this.$set(this.reservations, index, newValue);
    }
  },
  computed: {
    reservations: {
      get() {
        return this.value;
      },
      set(data) {
        this.$emit("input", data);
      }
    }
  }
};
</script>

<style lang="scss">
.reservation-checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--v-darkgrey-base);
}
.hideBottomBorder {
  border-bottom: none;
}
.errorText {
  color: var(--v-error-base);
}
</style>
