<template>
  <WebShopContainer
    title="Kjøpetilbud"
    @next="next"
    send-purchase-progress="SendOnPurchaseOffer"
    footerButtonTitle="Send"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <p class="mb-12">
        Her finner du all nødvendig informasjon om boligen. Les disse
        dokumentene nøye. Når du fyller ut og trykker send er dette et
        forpliktende tilbud. Når selger aksepterer tilbudet er det inngått en
        bindende avtale om kjøp av bolig fra Trysilhus.
      </p>

      <CollapsibleContainer
        v-model="purchaseOfferOpen"
        :title="titleLaw"
        header-icon="mdi-square-edit-outline"
      >
        <h3 class="darkgrey--text my-4">KJØPETILBUD TIL FASTPRIS</h3>

        <BuyerForm v-model="data" :validate="validateForm" />

        <div class="pt-4 pb-8" align="center" justify="center">
          <v-btn @click="addBuyer" outlined depressed tile>
            Legg til medkjøper
          </v-btn>
        </div>

        <v-divider class="mt-4 mb-10"></v-divider>

        <Investor v-model="data" />

        <v-divider class="my-10"></v-divider>
        <h4 class="darkgrey--text">
          Jeg/vi gir med dette et forpliktende tilbud om kjøp av boligen under.
        </h4>
        <p>Tilbudet er gyldig frem til kl. 16.00 neste arbeidsdag.</p>

        <SummaryList
          v-model="estimates"
          :totalPrice="totalPrice"
          item-title-key="text"
          price-key="amount"
          margin="pl-4 mt-5 mb-2"
          show-delete-icon
        />

        <v-divider class="my-10"></v-divider>

        <ConfirmLink v-model="links" :isValidating="isValidating" />

        <v-divider class="my-6"></v-divider>

        <Reservations
          v-if="reservations.length > 0"
          v-model="reservations"
          :isValidating="isValidating"
        />

        <v-divider v-if="reservations.length > 0" class="my-6"></v-divider>

        <TakeoverDate
          v-if="sectionInfo && sectionInfo.useAquisitionDate"
          v-model="data"
        />

        <CompletionEstimate
          v-else
          v-model="sectionInfo.deliveryDate"
        ></CompletionEstimate>

        <v-divider class="my-6"></v-divider>

        <Financing v-model="data" :isValidating="isValidating" />
      </CollapsibleContainer>

      <CollapsibleContainer
        v-model="porjectsDetailsOpen"
        title="Prosjektdetaljer"
        header-icon="mdi-text-box-search-outline"
      >
        <h3 class="darkgrey--text my-2">
          Informasjon om kjøpetilbudet:
        </h3>
        <ProjectDetails
          :titleLaw="titleLaw"
          :purchaseAndSalesAct="sectionInfo.purchaseAndSalesAct"
        ></ProjectDetails>
        <hr class="my-3" />
        <div class="conditions-checkbox mb-12">
          <span>
            <h4>
              Jeg bekrefter at jeg har lest gjennom informasjonen ovenfor.
            </h4>
            <small
              v-if="projectDetailsIsNotValid(porjectsDetails)"
              class="error--text"
            >
              Du må bekrefte at du har lest informasjonen ovenfor!
            </small>
          </span>

          <v-spacer></v-spacer>
          <v-checkbox
            v-model="porjectsDetails"
            class="condition-checkbox"
            hide-details
            color="primary"
            :required="true"
            :rules="[
              v => !!v || 'Du må bekrefte at du har lest informasjonen ovenfor!'
            ]"
          ></v-checkbox>
        </div>
      </CollapsibleContainer>
    </v-form>
    <Confirmcode
      v-model="showDialogConfirme"
      title="Bekreft kjøpetilbud"
      info="Skriv inn bekreftelseskoden vi sendte til deg på sms."
      :error="errorCount"
      @sendNewConfirmcode="sendNewConfirmcode"
      @confirmcode="confirmcode"
      @reset="resetConfirm"
    />
  </WebShopContainer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import purchaseApi from "@/api/purchaseApi";
import notificationsService from "@/notification.services";

import WebShopContainer from "@/components/store/webStore/shared/WebShopContainer.vue";
import CollapsibleContainer from "@/components/store/webStore/purchaseOffer/children/CollapsibleContainer.vue";
import BuyerForm from "@/components/store/webStore/purchaseOffer/children/BuyerForm";
import SummaryList from "@/components/store/webStore/purchaseOffer/children/SummaryList";
import ConfirmLink from "@/components/store/webStore/purchaseOffer/children/ConfirmLink";
import Reservations from "@/components/store/webStore/purchaseOffer/children/Reservations";
import CompletionEstimate from "@/components/store/webStore/purchaseOffer/children/CompletionEstimate";
import TakeoverDate from "@/components/store/webStore/purchaseOffer/children/TakeoverDate";
import Financing from "@/components/store/webStore/purchaseOffer/children/Financing";
import Investor from "@/components/store/webStore/purchaseOffer/children/Investor";
import ProjectDetails from "@/components/store/webStore/purchaseOffer/children/ProjectDetails";
import Confirmcode from "@/components/store/ConfirmcodeDialog";

export default {
  data() {
    return {
      purchaseOfferOpen: true,
      porjectsDetailsOpen: true,
      valid: true,
      validateForm: false,
      isValidating: false,
      // data: {
      //   loanBankName: null,
      //   loanBankContactName: null,
      //   loanBankContactPhone: null,
      //   loanBankContactEmail: null,
      //   loanAmount: null,
      //   loanEquityAmount: null,
      //   loanBankTotal: null,
      //   aquisitionDate: null
      // },
      showDialogConfirme: false,
      errorCount: 0,
      porjectsDetails: false
    };
  },
  mounted() {
    if (this.selectedOptionsList) {
      this.data.selectedOptions = this.selectedOptionsList.list.map(x => {
        return {
          id: x.id
        };
      });
    }
  },
  watch: {
    data: {
      handler(data) {
        this.updateProfile(data);
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      updateProfile: "purchase/updateProfile",
      setHasPurchasedSection: "purchase/setHasPurchasedSection"
    }),

    async next() {
      this.isValidating = true;
      var isValid = this.$refs.form.validate();

      if (isValid) {
        this.data.useAquisitionDate = this.sectionInfo.useAquisitionDate;
        await purchaseApi
          .purchaseSendConfirmcode(this.profile.customer.id)
          .then(response => {
            this.showDialogConfirme = true;
          });
      } else {
        this.purchaseOfferOpen = true;
        this.porjectsDetailsOpen = true;
        notificationsService.warn("Alle felter må fylles ut!");
        //Find first element with error
        var firstError = this.$refs.form.inputs.find(x => {
          return !x.valid;
        });
        //Page scroll to first element with error
        this.$vuetify.goTo("#" + firstError.$refs.input.id, { offset: 100 });
      }
    },
    async confirmcode(code) {
      this.setHasPurchasedSection("loading");
      this.data.documents = this.sectionInfo.documents;
      await purchaseApi
        .purchaseConfirm(code, this.data)
        .then(() => {
          this.setHasPurchasedSection(true);
          notificationsService.success("Kjøpetilbud sendt!");
          this.$router.push({ name: "webShopReceipt" });
        })
        .catch(() => {
          this.setHasPurchasedSection(false);
        });
      // const data = { key: "code", value: code };
      // this.confirmColorCart(data);
    },
    async sendNewConfirmcode() {
      await purchaseApi
        .purchaseSendConfirmcode(this.profile.customer.id)
        .then(() => {
          notificationsService.success("Ny bekreftelseskode sendt");
        });
    },
    resetConfirm() {
      this.errorCount = 0;
    },
    addBuyer() {
      this.data.buyers.push({
        firstName: null,
        lastName: null,
        pNumber: null,
        address: null,
        mobile: null,
        email: null
      });
    },
    projectDetailsIsNotValid(porjectsDetails) {
      if (this.isValidating && !porjectsDetails) {
        return true;
      }
      return false;
    }
  },
  computed: {
    ...mapGetters({
      profile: "purchase/profile",
      sectionInfo: "purchase/sectionInfo",
      totalPrice: "purchase/totalPrice",

      selectedOptionsList: "purchase/selectedOptionsList",
      selectedColorOptionsTotal: "purchase/selectedColorOptionsTotal"
    }),
    data: {
      get() {
        if (this.profile) {
          return this.profile;
        }
        return {};
      },
      set(value) {
        this.updateProfile(value);
      }
    },
    estimates() {
      if (this.sectionInfo) {
        var data = [];
        var attic = [];
        var selectedOptions = [];
        var selectedColorOptions = [];
        var estimates = this.sectionInfo.estimates;
        estimates.forEach(x => (x.icon = "mdi-home-city-outline"));
        var newEstimates = JSON.parse(JSON.stringify(estimates));
        if (this.profile && this.profile.includeAttic) {
          attic = {
            amount: this.sectionInfo.atticPrice,
            categoryName: "Bolig",
            icon: "mdi-home-floor-l",
            name: "Loft",
            text: "Loft",
            type: 0
          };

          newEstimates.splice(1, 0, attic);
        }

        if (this.selectedOptionsList && this.selectedOptionsList.totalPrice) {
          selectedOptions = [
            {
              text: "Tilvalg",
              amount: this.selectedOptionsList.totalPrice,
              icon: "mdi-package-variant-closed"
            }
          ];
        }
        //add color price if selected
        if (this.selectedColorOptionsTotal) {
          selectedColorOptions = [
            {
              text: "Farger",
              amount: this.selectedColorOptionsTotal,
              icon: "mdi-format-paint"
            }
          ];
        }

        data = [...newEstimates, ...selectedOptions, ...selectedColorOptions];

        return data;
      }
      return [];
    },
    links: {
      get() {
        if (this.sectionInfo && this.sectionInfo.documents) {
          var data = this.sectionInfo.documents;
          data.forEach(x => {
            x.confirm = x.confirm ? x.confirm : false;
            x.linkRead = x.linkRead ? x.linkRead : false;
          });
          return data;
        }
        return [];
      }
    },
    reservations: {
      get() {
        if (this.sectionInfo && this.sectionInfo.reservations) {
          var data = this.sectionInfo.reservations;
          data.forEach(x => {
            x.confirm = x.confirm ? x.confirm : false;
          });
          return data;
        }
        return [];
      }
    },
    titleLaw() {
      if (this.sectionInfo) {
        return (
          "Kjøpetilbud " +
          (this.sectionInfo.purchaseAndSalesAct === 1
            ? "avhendingsloven"
            : "bustadoppføringsloven")
        );
      }
      return "";
    }
  },
  components: {
    WebShopContainer,
    CollapsibleContainer,
    BuyerForm,
    SummaryList,
    ConfirmLink,
    Reservations,
    CompletionEstimate,
    TakeoverDate,
    Financing,
    Investor,
    ProjectDetails,
    Confirmcode
  }
};
</script>
