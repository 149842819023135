<template>
  <div>
    <OptionsStoreContainer />
  </div>
</template>
<script>
import OptionsStoreContainer from "@/components/store/options/store/container/OptionsStoreContainer";
export default {
  components: {
    OptionsStoreContainer
  }
};
</script>
