<template>
  <div>
    <v-row class="justify-center mb-6">
      <v-col xl="9">
        <v-row>
          <v-col v-if="!$store.getters['shared/isMobileDevice']" cols="12">
            <v-list color="transparent">
              <v-list-item class="px-0 mb-2">
                <v-list-item-avatar :color="color">
                  <v-icon class="white--text">{{ this.icon }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    :style="{ color: color }"
                    class="headline font-weight-black"
                    >{{ title }}</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-content class="mr-10">
                  <v-text-field
                    v-model="search"
                    label="Søk..."
                    clearable
                    hide-no-data
                    autocomplete="off"
                  ></v-text-field>
                </v-list-item-content>
                <v-list-item-action>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="showDialog.show = true"
                        v-on="on"
                        fab
                        dark
                        small
                        color="blue"
                      >
                        <v-icon dark>mdi-help</v-icon>
                      </v-btn>
                    </template>
                    <span>Hjelp</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
            </v-list>
          </v-col>
        </v-row>

        <v-text-field
          v-if="$store.getters['shared/isMobileDevice']"
          v-model="search"
          label="Søk..."
          clearable
          hide-no-data
          autocomplete="off"
          dense
        ></v-text-field>

        <div v-if="getFaq.length > 0">
          <div v-for="faq in getData" :key="faq.id">
            <v-card
              flat
              class="mx-auto"
              tile
              v-bind:class="{
                transparent: !faq.show,
                white: faq.show
              }"
            >
              <a @click="open(faq)">
                <v-card-title
                  style="word-break: break-word;"
                  class="px-md-12 darkgrey--text subtitle-1 font-weight-bold"
                >
                  {{ faq.question }}
                  <v-spacer></v-spacer>
                  <v-btn icon>
                    <v-icon>
                      {{ faq.show ? "mdi-chevron-up" : "mdi-chevron-down" }}
                    </v-icon>
                  </v-btn>
                </v-card-title>
              </a>
              <v-expand-transition>
                <div class="px-md-12" v-show="faq.show">
                  <v-divider class="py-2"></v-divider>
                  <v-card-text class="px-0 pt-3">
                    <v-runtime-template
                      :template="faq.answer"
                    ></v-runtime-template>

                    <div v-if="faq.attachments.length > 0">
                      <v-divider class="py-2"></v-divider>
                      <h3>Vedlegg:</h3>
                      <v-chip
                        pill
                        v-for="attachments in faq.attachments"
                        :key="attachments.id"
                        small
                        class="ma-1"
                        color="lightgrey"
                        @click="download(attachments)"
                      >
                        <v-avatar left color="lightgrey darken-1">
                          <v-icon small>mdi-download</v-icon>
                        </v-avatar>
                        {{ attachments.fileName }}
                      </v-chip>
                    </div>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>
            <v-divider></v-divider>
          </div>
        </div>
        <v-alert class="mt-6" v-else type="info">
          Ingen ofte stilte spørsmål er tilgjengelig
        </v-alert>
      </v-col>
    </v-row>
    <HelpBadges @help="showDialog.show = true" />
    <Dialog
      :show="showDialog"
      :title="titleDialog"
      :DialogMessage="DialogMessage"
      :DialogAccept="dialogAccept"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VRuntimeTemplate from "v-runtime-template";
import baseApi from "@/api/api";
import HelpBadges from "@/components/shared/HelpBadges";
import Dialog from "@/components/dialog/DialogMessage";
export default {
  data() {
    return {
      search: null,
      filterData: [],
      color: "primary",
      icon: "mdi-help",
      title: "",
      showDialog: { show: false },
      dialogAccept: false,
      titleDialog: "Ofte stilte spørsmål",
      DialogMessage: `Her finner du svar på spørsmål vi ofte får om våre boliger. 
      Bruk søkefunksjonen hvis du ikke finner informasjonen du leter etter. 
      Finner du ikke svar på spørsmålet ditt her, ta kontakt med vårt kundesenter på kundesenter@trysilhus.no eller 32 21 55 10.`
    };
  },
  watch: {
    search(newValue) {
      if (!newValue || newValue === "") {
        this.filterData = [];
      } else {
        this.filterData = this.getFaq.filter(x => {
          return x.question
            .toLocaleLowerCase()
            .includes(newValue.toLocaleLowerCase());
        });
      }
    }
  },
  methods: {
    ...mapActions({
      setFaq: "shared/faq"
    }),
    setTitle(isAll) {
      if (isAll) {
        this.title = "Alle kategorier";
      } else {
        if (this.faq) {
          var category = this.faq.find(x => {
            return x.categoryId === parseInt(this.$route.params.id);
          });
          this.title = category.category;
        }
      }
    },
    async open(item) {
      if (!item.answer) {
        var data = await baseApi.getFaq(item.id);

        if (data && data.answer) {
          data.answer = data.answer.replace(/ta-blob-link/gi, "v-ta-blob-link");
          var faqanswer = "<div>" + data.answer + "</div>";
          item.answer = faqanswer;
        }
        item.attachments = data.attachments;
      }
      item.show = !item.show;
    },
    download(att) {
      baseApi.downloadFaqAttachment(att);
    }
  },
  computed: {
    ...mapGetters({ faq: "shared/faq", profile: "shared/profile" }),
    getData() {
      if (this.search !== "" && this.search) {
        return this.filterData;
      } else if (this.getFaq) {
        return this.getFaq;
      }
      return [];
    },
    getFaq() {
      if (this.faq && this.profile) {
        var faq = this.faq.filter(x => {
          return (
            x.projTypeId ===
              this.profile.user.currentSection.project.projTypeId ||
            x.projTypeId === undefined
          );
        });
        if (parseInt(this.$route.params.id) !== -1) {
          let data = faq.filter(x => {
            return x.categoryId === parseInt(this.$route.params.id);
          });
          data.forEach(x => {
            x.answer = null;
          });
          this.setTitle();
          return data;
        } else {
          faq.forEach(x => {
            x.answer = "";
          });
          this.setTitle(true);
          return faq;
        }
      }
      return [];
    }
  },

  created() {
    if (!this.faq) {
      this.setFaq();
    }

    switch (parseInt(this.$route.params.id)) {
      case -1:
        this.color = "#A82C6B";
        this.icon = "mdi-menu";
        break;
      case 1:
        this.color = "#FDB913";
        this.icon = "mdi-radiator";
        break;
      case 2:
        this.color = "#F58025";
        this.icon = "mdi-home-city-outline";
        break;
      case 3:
        this.color = "#679146";
        this.icon = "mdi-account-group";
        break;
      case 4:
        this.color = "#F15A5A";
        this.icon = "mdi-clipboard-alert";
        break;
      default:
        this.color = "#A82C6B";
        this.icon = "mdi-menu";
    }
  },
  components: {
    VRuntimeTemplate,
    HelpBadges,
    Dialog
  }
};
</script>
