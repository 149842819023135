import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600","fullscreen":_vm.$vuetify.breakpoint.smAndDown},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-center flex-column cardTitle headline primary--text pt-13 pb-10",attrs:{"single-line":""}},[_c(VIcon,{staticClass:"pb-4",attrs:{"x-large":"","color":"primary"}},[_vm._v("mdi-information-outline")]),_vm._v(" "+_vm._s(_vm.title)+" ")],1),_c('div',{staticClass:"text-center px-4",domProps:{"innerHTML":_vm._s(_vm.infoText)}}),_c(VCardActions,{staticClass:"justify-center cardActions"},[_c(VBtn,{attrs:{"depressed":"","dark":"","ripple":false,"color":"primary"},on:{"click":function($event){_vm.show = false}}},[_vm._v("Lukk")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }