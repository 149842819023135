<template>
  <div>
    <h3 :class="margin">{{ title }}</h3>
    <v-divider></v-divider>
  </div>
</template>

<script>
import optionsService from "@/options.services";
export default {
  props: {
    title: {
      required: true
    },
    margin: {
      default: "mt-0"
    }
  },
  data() {
    return {
      optionsService
    };
  },
  methods: {
    downloadReceipt() {
      this.$emit("downloadReceipt");
    }
  }
};
</script>
