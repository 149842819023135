import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"700","hide-overlay":"","fullscreen":_vm.$store.getters['shared/isMobileDevice'],"persistent":_vm.mouseDown},on:{"click:outside":_vm.setMouse},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{staticClass:"safeAreaDialog",on:{"mousedown":_vm.setMouseDown,"mouseup":_vm.setMouseDown}},[_c(VCardTitle,[_c('span',{staticClass:"headline primary--text"},[_vm._v("Endre bruker informasjon")])]),_c(VAlert,{staticClass:"mr-7 ml-7",attrs:{"type":"info"}},[_vm._v(" NB: Her endrer du kun din egen brukerinformasjon. Har du solgt din Trysilhus-bolig, må du be ny eier ta kontakt med vårt kundesenter og opprette ny bruker på boligen. Dette er pga. personvernhensyn. ")]),_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{staticClass:"pt-0 pb-0",attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"rules":[
                    function () { return !!_vm.dialogTable.firstName || 'Fornavn er påkrevd'; } ],"label":"Fornavn*","required":""},model:{value:(_vm.dialogTable.firstName),callback:function ($$v) {_vm.$set(_vm.dialogTable, "firstName", $$v)},expression:"dialogTable.firstName"}})],1),_c(VCol,{staticClass:"pt-0 pb-0",attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"rules":[
                    function () { return !!_vm.dialogTable.lastName || 'Etternavn er påkrevd'; } ],"label":"Etternavn*","required":""},model:{value:(_vm.dialogTable.lastName),callback:function ($$v) {_vm.$set(_vm.dialogTable, "lastName", $$v)},expression:"dialogTable.lastName"}})],1),_c(VCol,{staticClass:"pt-0 pb-0",attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"type":"email","rules":_vm.emailRules,"label":"E-post*","required":""},model:{value:(_vm.dialogTable.email),callback:function ($$v) {_vm.$set(_vm.dialogTable, "email", $$v)},expression:"dialogTable.email"}})],1),_c(VCol,{staticClass:"pt-0 pb-0",attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"rules":[
                    function () { return !!_vm.dialogTable.address || 'Adresse er påkrevd'; } ],"label":"Adresse*","type":"text","required":""},model:{value:(_vm.dialogTable.address),callback:function ($$v) {_vm.$set(_vm.dialogTable, "address", $$v)},expression:"dialogTable.address"}})],1),_c(VCol,{staticClass:"pt-0 pb-0",attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"rules":_vm.postCodeRules,"label":"Postnr*","type":"text","pattern":"[0-9]*","inputmode":"numeric","required":""},model:{value:(_vm.dialogTable.postCode.postCodeText),callback:function ($$v) {_vm.$set(_vm.dialogTable.postCode, "postCodeText", $$v)},expression:"dialogTable.postCode.postCodeText"}})],1),_c(VCol,{staticClass:"pt-0 pb-0",attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"label":"Poststed","disabled":"","type":"text"},model:{value:(_vm.dialogTable.postCode.postName),callback:function ($$v) {_vm.$set(_vm.dialogTable.postCode, "postName", $$v)},expression:"dialogTable.postCode.postName"}})],1),_c(VCol,{staticClass:"pt-0 pb-0",attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"rules":[
                    function () { return _vm.dialogTable.phone === undefined ||
                      _vm.dialogTable.phone.length === 0 ||
                      _vm.dialogTable.phone.length === 8 ||
                      'Telefon nummer må ha 8 tegn'; } ],"label":"Telefon","counter":"8","type":"text","pattern":"[0-9]*","inputmode":"numeric","required":""},model:{value:(_vm.dialogTable.phone),callback:function ($$v) {_vm.$set(_vm.dialogTable, "phone", $$v)},expression:"dialogTable.phone"}})],1),_c(VCol,{staticClass:"pt-0 pb-0",attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"rules":[
                    function () { return !!_vm.dialogTable.mobile || 'Mobilenummer er påkrevd'; },
                    (_vm.dialogTable.mobile &&
                      (_vm.dialogTable.mobile.length === 0 ||
                        _vm.dialogTable.mobile.length === 8)) ||
                      'Mobil nummer må ha 8 tegn' ],"label":"Mobil*","counter":"8","type":"text","pattern":"[0-9]*","inputmode":"numeric","required":""},model:{value:(_vm.dialogTable.mobile),callback:function ($$v) {_vm.$set(_vm.dialogTable, "mobile", $$v)},expression:"dialogTable.mobile"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.save(_vm.dialogTable)}}},[_vm._v("Lagre")]),_c(VBtn,{attrs:{"color":"grey","text":""},on:{"click":_vm.close}},[_vm._v("Lukk")])],1)],1)],1)],1),_c('Confirmcode',{attrs:{"show":_vm.showDialogCode,"title":_vm.titleConfirme,"info":_vm.infoCode,"saveNotification":_vm.saveNotification,"saveTo":"confirmeProfileInfo"},on:{"closeProfile":_vm.closeProfile}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }