// import baseApi from "@/api/baseApi";
import baseApi from "@/api/api";
import requestApi from "@/api/requestApi";

export default {
  namespaced: true,

  state: {
    complaintsCustomer: [],
    complaintsJointOwnership: [],
    sectionOverlayTable: [],
    jointOverlayTable: [],
    changeViewRequests: false,
    unreadComments: null
  },
  getters: {
    complaintsCustomer: state => {
      return state.complaintsCustomer;
    },
    complaintsJointOwnership: state => {
      return state.complaintsJointOwnership;
    },
    sectionOverlayTable: state => {
      return state.sectionOverlayTable;
    },
    jointOverlayTable: state => {
      return state.jointOverlayTable;
    },
    changeViewRequests: state => {
      return state.changeViewRequests;
    },
    unreadComments: state => {
      return state.unreadComments;
    }
  },
  mutations: {
    complaints(state, data) {
      state.complaintsCustomer = data.section;
      state.complaintsJointOwnership = data.joint;
      state.sectionOverlayTable = data.sectionOverlayTable;
      state.jointOverlayTable = data.jointOverlayTable;
    },
    changeViewRequests(state, value) {
      state.changeViewRequests = value;
    },

    unreadComments(state, value) {
      state.unreadComments = value;
    }
  },
  actions: {
    async complaints({ commit }) {
      const response = await baseApi.getComplaints();

      let categoryName = [
        "Ikke behandlet",
        "Åpen",
        "Startet",
        "Gjenåpnet",
        "Ettårsbefaring",
        "Ferdigstilt",
        "Lukket",
        "Avslått"
      ];
      //Get all unique category name in list and add categoryName
      const distinct = [
        ...categoryName,
        ...new Set(response.map(x => x.regStatus))
      ];
      //get all unique categoryname
      //if category is not in the sorting list
      categoryName = [...new Set(distinct)];

      var array = [];
      categoryName.map(x => {
        var filter = response.filter(y => {
          return y.regStatus === x;
        });
        array = [...array, ...filter];
      });

      var section = [];
      var joint = [];
      var sectionOverlayTable = [];
      var jointOverlayTable = [];

      array.forEach(x => {
        x.overlay = false;
        if (x.customerRating) {
          x.hasRating = true;
        }
        if (x.complaintType === "Seksjon") {
          section.push(x);
          sectionOverlayTable.push(false);
        } else if (x.complaintType === "Sameie") {
          joint.push(x);
          jointOverlayTable.push(false);
        }
      });

      var dataSave = {
        section: section,
        joint: joint,
        sectionOverlayTable: sectionOverlayTable,
        jointOverlayTable: jointOverlayTable
      };

      commit("complaints", dataSave);
    },
    changeViewRequests({ commit }, value) {
      localStorage.setItem("viewRequests", value);
      commit("changeViewRequests", value);
    },
    async unreadComments({ commit }, value) {
      var data = value;

      if (!data) {
        data = await requestApi.getUnreadComments();
      }

      const totalNewComments = data.reduce(function(accumulator, request) {
        return accumulator + request.count;
      }, 0);
      commit("unreadComments", { data, totalNewComments });
    }
  }
};
