import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VCard,_vm._g({class:{
        categoriExpired: _vm.expired,
        notAvailable: _vm.notAvailable,
        clickyCard: true,
        'theme-shadow': true,
        imgActive: _vm.data.selected,
      },attrs:{"hover":""},on:{"click":function($event){return _vm.$emit('click', _vm.data)}}},on),[_c(VImg,{attrs:{"min-height":"200","max-height":"200","lazy-src":_vm.optionsService.getOptionImage(
            _vm.data,
            true,
            false,
            _vm.data.optionsSelectdId
              ? 'optionsSelectdId'
              : _vm.data.addonSelectdId
              ? 'addonSelectdId'
              : 'standardOptionId'
          ),"src":_vm.optionsService.getOptionImage(
            _vm.data,
            true,
            false,
            _vm.data.optionsSelectdId
              ? 'optionsSelectdId'
              : _vm.data.addonSelectdId
              ? 'addonSelectdId'
              : 'standardOptionId'
          )}},[_c('div',{staticClass:"optionOverview pa-2 pl-3"},[(_vm.data.standardOptionId || _vm.data.optionsSelectdId)?_c(VChip,{attrs:{"x-small":"","dark":"","color":_vm.data.optionsSelectdId ? 'yellow' : 'primary'}},[_vm._v(" "+_vm._s(_vm.data.optionsSelectdId ? "Tilvalg" : "Standard")+" ")]):_vm._e(),(_vm.data.addonSelectdId)?_c(VChip,{staticClass:"ml-2",attrs:{"x-small":"","dark":"","color":"info"}},[_vm._v(" Tillegg ")]):_vm._e()],1)]),_c(VList,{staticClass:"text-center underBar"},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_c('h4',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.data.name))])])],1)],1)],1)],1)]}}])},[_c('span',[_vm._v("Klikk for å se tilvalg")])])}
var staticRenderFns = []

export { render, staticRenderFns }