import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,{staticClass:"pt-4 pb-8"},[_c(VCardTitle,{staticClass:"primary--text display-1 font-weight-bold",staticStyle:{"word-break":"break-word"}},[_vm._v(" Registrert som interessent ")]),(_vm.name)?_c(VCardText,{staticStyle:{"font-size":"1rem"}},[_c('strong',[_vm._v(_vm._s(_vm.name))]),_vm._v(" er registrert som interessent på denne seksjonene. Vil du fortsette eller registrere ny interessent? ")]):_vm._e(),_c(VCardActions,{staticClass:"ml-2"},[_c(VBtn,{staticClass:"px-4 mr-1",attrs:{"depressed":"","color":"primary"},on:{"click":_vm.accept}},[_vm._v(" Fortsett ")]),_c(VBtn,{attrs:{"depressed":"","outlined":"","color":"darkgrey"},on:{"click":_vm.cancel}},[_c('span',{staticClass:"d-none d-sm-block"},[_vm._v("Registrer ny interessent")]),_c('span',{staticClass:"d-block d-sm-none"},[_vm._v("Ny interessent")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }