<template>
  <div>
    <cookie-law theme="dark-lime" buttonText="Godta">
      <div slot="message">
        På denne siden brukes informasjonskapsler for å få statistikk på bruken
        av siden vår. Ved å fortsette å bruke siden bekrefter du at du godtar
        dette
        <a class="green--text" href="https://www.trysilhus.no/personvern/"
          >Les mer</a
        >
      </div>
    </cookie-law>

    <v-dialog
      v-model="cookiesOff"
      max-width="600"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline primary--text"
            >Informasjonskapsler<br
              v-if="$store.getters['shared/isMobileDevice']"
            />
            (Cookies)</span
          >
        </v-card-title>
        <v-card-text>
          <span style="font-size: 16px;"
            >Mitt Trysilhus er avhengig av at informasjonskapsler er skrudd på.
            For å kunne logge inn på Mitt Trysilhus må du skru på
            informasjonskapsler.</span
          >
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn large color="primary" @click="close">Lukk</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CookieLaw from "vue-cookie-law";
import { mapActions, mapGetters } from "vuex";
export default {
  mounted() {
    try {
      localStorage;
    } catch (e) {
      this.setCookiesOff(true);
    }
  },
  computed: {
    ...mapGetters({ cookiesOff: "shared/cookiesOff" })
  },
  methods: {
    ...mapActions({
      setCookiesOff: "shared/cookiesOff"
    }),
    close() {
      this.setCookiesOff(false);
    }
  },
  components: { CookieLaw }
};
</script>
