import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"justify-center"},[_c(VCol,{attrs:{"xl":"9"}},[_c(VRow,[(!_vm.$store.getters['shared/isMobileDevice'])?_c(VCol,{attrs:{"cols":"12"}},[_c(VList,{attrs:{"color":"transparent"}},[_c(VListItem,{staticClass:"px-0 mb-2"},[_c(VListItemAvatar,[_c(VIcon,{staticClass:"primary white--text"},[_vm._v("mdi-help")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"primary--text headline font-weight-black"},[_vm._v(" Ofte stilte spørsmål ")])],1),_c(VListItemAction,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"fab":"","dark":"","small":"","color":"blue"},on:{"click":function($event){_vm.showDialog.show = true}}},on),[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-help")])],1)]}}],null,false,1571901371)},[_c('span',[_vm._v("Hjelp")])])],1)],1),_c(VDivider)],1)],1):_vm._e()],1),(_vm.getFaq.length > 0)?_c(VRow,_vm._l((_vm.getFaq),function(n){return _c(VCol,{key:n.id,attrs:{"cols":"12","sm":"4"}},[_c('a',{on:{"click":function($event){return _vm.goTo(n.id)}}},[_c(VList,{attrs:{"color":"transparent"}},[_c(VListItem,{staticClass:"px-0 mb-2"},[_c(VListItemAvatar,{attrs:{"color":n.color}},[_c(VIcon,{staticClass:"white--text"},[_vm._v(_vm._s(n.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"--text headline"},[_vm._v(_vm._s(n.name)+" ")])],1)],1),_c(VDivider)],1)],1)])}),1):_vm._e(),(!_vm.loading && _vm.getFaq.length === 0)?_c(VAlert,{staticClass:"mt-6",attrs:{"type":"info"}},[_vm._v(" Ingen ofte stilte spørsmål er tilgjengelig ")]):_vm._e()],1),_c('HelpBadges',{on:{"help":function($event){_vm.showDialog.show = true}}}),_c('Dialog',{attrs:{"show":_vm.showDialog,"title":_vm.title,"DialogMessage":_vm.DialogMessage,"DialogAccept":_vm.dialogAccept}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }