<template>
  <div>
    <v-app-bar class="roomNav grey lighten-3" style="z-index:10;" fixed flat>
      <div class="nav">
        <v-tabs
          center-active
          show-arrows
          class="ma-0 removeUnderLine"
          background-color="transparent"
          grow
          height="64"
        >
          <template v-for="(room, index) in rooms">
            <v-tab
              :key="index"
              :exact="room.exact"
              :to="room.to"
              active-class="underlineText"
            >
              {{ room.name }}
            </v-tab>
          </template>
        </v-tabs>
      </div>
    </v-app-bar>
    <div class="pb-12 mb-2"></div>
  </div>
</template>

<script>
export default {
  props: {
    rooms: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss">
.roomNav {
  z-index: 1;
  top: 60px !important;
}
.removeUnderLine {
  .v-tabs-slider-wrapper {
    display: none;
  }
}
.underlineText {
  text-decoration: underline;
}
.v-slide-group__prev {
  display: none !important;
}

@media (min-width: 550px) and (max-width: 960px) {
  .roomNav {
    top: 56px !important;
  }
}

@media (max-width: 549px) {
  .roomNav {
    margin-top: -4px !important;
  }
}
</style>
