import store from "@/store/index";

const notificationsService = {
  success(message) {
    store.dispatch("shared/showSnackbar", {
      message: message,
      color: "success"
    });
  },

  error(message) {
    store.dispatch("shared/showSnackbar", {
      message: message,
      color: "error"
    });
  },
  warn(message) {
    store.dispatch("shared/showSnackbar", {
      message: message,
      color: "warning"
    });
  },
  hide() {
    store.dispatch("shared/hideSnackbar", {
      message: "",
      color: ""
    });
  }
};

export default notificationsService;
