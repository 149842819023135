<template>
  <div>
    <div
      class="checkoutFooter d-flex flex-column flex-sm-row align-center mt-12 py-4"
    >
      <div class="grow">
        <v-btn
          depressed
          v-if="showConfirmBtnDisable || showConfirmBtn"
          color="success"
          :disabled="showConfirmBtnDisable ? showConfirmBtn : null"
          @click="confirm"
        >
          {{ confirmBtnName }}
        </v-btn>
        <v-btn
          v-if="showChangeBtn"
          color="info"
          class="my-2 my-sm-0 mx-sm-3"
          depressed
          @click="change"
        >
          {{ changeBtnName }}
          <v-icon class="ml-1">
            mdi-swap-horizontal
          </v-icon>
        </v-btn>
        <v-btn v-if="showSummaryBtn" color="primary" depressed @click="summary">
          {{ summaryBtnName }}
          <v-icon class="ml-1" small>mdi-file-search-outline</v-icon>
        </v-btn>
      </div>
      <h4 class="mt-4 mt-sm-0">
        <b>Totalpris:</b>
        <span v-if="totalPrice">
          {{ optionsService.formatPrice(totalPrice) }} ,-
        </span>
      </h4>
    </div>
  </div>
</template>

<script>
import optionsService from "@/options.services";
export default {
  props: {
    totalPrice: {},
    showConfirmBtn: {
      type: Boolean,
      default: false
    },
    confirmBtnName: {
      type: String,
      default: "Bekreft"
    },
    showConfirmBtnDisable: {
      type: Boolean,
      default: false
    },
    showChangeBtn: {
      type: Boolean,
      default: false
    },
    changeBtnName: {
      type: String,
      default: "Endre"
    },
    showSummaryBtn: {
      type: Boolean,
      default: false
    },
    summaryBtnName: {
      type: String,
      default: "Kvittering"
    }
  },
  data() {
    return {
      optionsService
    };
  },
  methods: {
    confirm() {
      this.$emit("confirm", true);
    },
    change() {
      this.$emit("change", true);
    },
    summary() {
      this.$emit("summary", true);
    }
  }
};
</script>
