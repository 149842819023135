<template>
  <div>
    <v-card
      hover
      class="clickyCard theme-shadow"
      :class="{
        expired: categorie.disabled || categorie.disabled === undefined
      }"
      @click="change(item)"
    >
      <v-img
        :src="
          item.isActive
            ? optionsService.getImage(item)
            : optionsService.getOptionImage(item.isActiveAddon)
        "
        height="100px"
      >
        <v-row class="fill-height">
          <v-card-title class="align-start">
            <v-chip
              v-if="item.isActive && item.isActive.type === 2"
              small
              color="primary"
              class="ml-2"
              >Tilvalg</v-chip
            >
            <v-chip
              v-if="item.isActive && item.isActive.type === 1"
              small
              dark
              color="yellow"
              class="ml-2"
              >Standard</v-chip
            >
            <v-chip
              v-if="item.isActiveAddon"
              small
              dark
              color="primary"
              class="ml-2"
              >Tillegg</v-chip
            >
            <v-chip color="green" small class="ml-2 white--text">
              {{ format.formatToNok(item.price) }}
            </v-chip>
          </v-card-title>
          <v-spacer></v-spacer>
        </v-row>
      </v-img>
      <v-btn
        rounded
        class="rateInquiryBadge white--text"
        small
        height="24"
        elevation="0"
        color="yellow"
      >
        Endre
      </v-btn>
      <span v-if="item.isActiveAddon && item.isActive" class="addonImg">
        <v-img
          class="imgAddon"
          :src="optionsService.getOptionImage(item.isActiveAddon)"
          height="50px"
        ></v-img
      ></span>
      <v-list class="text-center" two-line>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <h3>{{ item.name }}</h3>
            </v-list-item-title>
            <v-list-item-subtitle v-if="item.isActive">
              {{ item.isActive.name }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.isActiveAddon && !item.isActive">
              {{ item.isActiveAddon.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import optionsService from "@/options.services";
import format from "@/helpers/format";
export default {
  props: {
    item: {
      required: true
    },
    categorie: {
      default: {}
    }
  },
  data() {
    return {
      optionsService,
      format
    };
  },
  methods: {
    change(data) {
      this.$emit("change", data);
    }
  }
};
</script>
