import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ListItems',{attrs:{"title":"Bolig","item-title-key":"text","item-subtitle-key":"categoryName","price-key":"amount","margin":"pl-4 mb-2","icon":""},model:{value:(_vm.section),callback:function ($$v) {_vm.section=$$v},expression:"section"}},[_c('template',{slot:"footer"},[_c(VList,{attrs:{"color":"greybg"}},[_c(VListItem,[_c(VListItemContent,{staticClass:"justify-sm-end"},[_vm._v(" Delsum bolig: "+_vm._s(_vm.format.formatToNok(_vm.sectionTotal))+" ")])],1)],1),_c(VDivider)],1)],2),(
      (_vm.optionsAndColor && _vm.optionsAndColor.length > 0) ||
        (_vm.selectedColorOptions && _vm.selectedColorOptions.length > 0)
    )?_c('HeaderDivider',{attrs:{"title":"Tilvalg og farger","margin":"pl-4 mt-5 mb-2"}}):_vm._e(),(_vm.optionsAndColor && _vm.optionsAndColor.length > 0)?_c('ListItems',{attrs:{"item-subtitle-key":"categoryName","show-delete-icon":"","hide-delete-icon-on":"disabledDelete","hideHeader":""},on:{"delete":_vm.deleteOptionsOpenDialog},model:{value:(_vm.optionsAndColor),callback:function ($$v) {_vm.optionsAndColor=$$v},expression:"optionsAndColor"}}):_vm._e(),(_vm.selectedColorOptions && _vm.selectedColorOptions.length > 0)?_c('ListItems',{attrs:{"item-subtitle-key":"name","item-title-key":"ncs","priceKey":"amount","show-delete-icon":"","hide-delete-icon-on":"disabledDelete","hideHeader":""},on:{"delete":_vm.deleteColorOptionsOpenDialog},model:{value:(_vm.selectedColorOptions),callback:function ($$v) {_vm.selectedColorOptions=$$v},expression:"selectedColorOptions"}}):_vm._e(),(
      (_vm.optionsAndColor && _vm.optionsAndColor.length > 0) ||
        (_vm.selectedColorOptions && _vm.selectedColorOptions.length > 0)
    )?[_c(VList,{attrs:{"color":"greybg"}},[_c(VListItem,[_c(VListItemContent,{staticClass:"justify-sm-end"},[_vm._v(" Delsum tilvalg og fargevalg: "+_vm._s(_vm.format.formatToNok(_vm.optionsAndColorPrice))+" ")])],1)],1),_c(VDivider)]:_vm._e(),_c(VList,[_c(VListItem,[_c(VListItemContent,{staticClass:"title justify-sm-end"},[_vm._v(" Totalpris: "+_vm._s(_vm.format.formatToNok(_vm.totalPrice))+" ")])],1)],1),_c(VDivider,{staticClass:"mb-8"}),_c('AcceptCancel',{attrs:{"title":_vm.acceptCancelTitle,"DialogMessage":_vm.acceptCancelText,"hide-overlay":false},on:{"confirme":function($event){return _vm.deleteOptions(_vm.acceptCancelData)}},model:{value:(_vm.showAccept),callback:function ($$v) {_vm.showAccept=$$v},expression:"showAccept"}}),_c('AcceptCancel',{attrs:{"title":_vm.acceptCancelTitle,"DialogMessage":_vm.acceptCancelText,"hide-overlay":false},on:{"confirme":function($event){return _vm.deleteColorOptions(_vm.acceptCancelData)}},model:{value:(_vm.showAcceptColor),callback:function ($$v) {_vm.showAcceptColor=$$v},expression:"showAcceptColor"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }