import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mt-8 theme-shadow"},[_c(VList,{staticClass:"py-0"},[_c(VListGroup,{attrs:{"value":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Forbehold")])],1)]},proxy:true}])},[_c(VDivider),(_vm.trysilhusReservations.length > 0)?_c('ReservationList',{attrs:{"data":_vm.trysilhusReservations,"title":"Trysilhus sine forbehold"}}):_vm._e(),(_vm.userReservations.length > 0)?_c('ReservationList',{attrs:{"data":_vm.userReservations,"title":"Dine/deres forbehold"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }