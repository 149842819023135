<template>
  <div v-if="value && value.length > 0">
    <v-card
      class="px-sm-4"
      elevation="0"
      v-for="(option, index) in value"
      :key="index"
    >
      <v-list-item>
        <v-list-item-icon class="d-none d-sm-flex">
          <v-icon>{{ option.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ option[itemTitleKey] }}</v-list-item-title>
          <v-list-item-subtitle class="d-flex d-sm-none">
            {{ format.formatToNok(option[priceKey]) }},-
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-content class="d-none d-sm-flex text-right">
          <v-list-item-title
            >{{ format.formatToNok(option[priceKey]) }},-</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <v-divider class="d-none d-sm-flex" inset></v-divider>
      <v-divider class="d-flex d-sm-none"></v-divider>
    </v-card>

    <v-card class="px-sm-4" elevation="0">
      <v-list-item>
        <v-list-item-icon class="d-none d-sm-flex"></v-list-item-icon>
        <v-list-item-content class="font-weight-bold"
          >Totalpris:</v-list-item-content
        >
        <v-list-item-content class="text-right">
          <v-list-item-title class="font-weight-bold"
            >{{ format.formatToNok(totalPrice) }},-</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </v-card>
    <p class="subtitle-2 font-weight-regular mt-4">
      Det tas forbehold om endringer av omkostningsbeløpene som følge av
      politiske vedtak og/eller lovendringer og at Statens kartverk
      v/tinglysingen godkjenner dokumentavgiftsgrunnlaget. Økninger i satser og
      grunnlag må bæres av kjøper.
    </p>
    <slot name="footer"></slot>
  </div>
</template>

<script>
import format from "@/helpers/format";
import optionsService from "@/options.services";
export default {
  props: {
    value: {
      required: true
    },
    totalPrice: {
      default: 0
    },
    itemTitleKey: {
      default: "name"
    },
    priceKey: {
      default: "price"
    },
    margin: {
      default: null
    }
  },
  data() {
    return {
      optionsService,
      format
    };
  }
};
</script>
