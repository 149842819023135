import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('WebShopContainer',{attrs:{"title":_vm.title,"leftTitle":_vm.deadline},on:{"next":_vm.next}},[_c('template',{slot:"navigation"},[_c('ColorRoomNavigation')],1),(_vm.getColorRoom)?_c(VRow,{staticClass:"pb-12 pb-md-0"},[_c(VCol,{staticClass:"order-1 order-md-2 text-center",attrs:{"cols":"12","md":"6","lg":"6","xl":"6"}},[_c('p',{staticClass:"mb-3 caption"},[_vm._v(" Merk at tegningen ikke er interaktiv, trykk \"fortsett\" for å velge farge i neste rom. ")]),_c(VImg,{attrs:{"max-height":"750","contain":"","lazy-src":_vm.optionsService.getSectionPlanImage(
              _vm.$route.params.sectionId,
              null,
              _vm.getColorRoom.isOnAttic
            ),"src":_vm.optionsService.getSectionPlanImage(
              _vm.$route.params.sectionId,
              null,
              _vm.getColorRoom.isOnAttic
            )}})],1),_c(VCol,{staticClass:"order-3",attrs:{"cols":"12","md":"6","lg":"6","xl":"6"}},[_c('p',{staticClass:"mb-3 caption text-center text-sm-right"},[_vm._v(" Pris per nye farge du velger i boligen er kr. 2.000."),_c('br'),_vm._v(" Pris per kontrastvegg er kr. 2.500 uansett farge. ")]),(!_vm.getColorRoom.isOnAttic)?_c('div',[(parseInt(_vm.$route.params.roomId) === -1)?_c('MainColorRoom',{attrs:{"disabledReset":_vm.disabledChangeColor ? _vm.disabledChangeColor : !_vm.getColorRoom.amount,"typePrice":"amount","disabled":_vm.disabledChangeColor},on:{"changeColor":_vm.openDialog,"reset":_vm.resetColor},model:{value:(_vm.getColorRoom),callback:function ($$v) {_vm.getColorRoom=$$v},expression:"getColorRoom"}}):_c('ColorRoom',{attrs:{"data":_vm.getColorRoom,"open":true,"typePrice":"amount","disabled":_vm.disabledChangeColor},on:{"openDialog":_vm.openDialog,"resetColor":_vm.resetColorOpenDialog,"deleteContrastwall":_vm.deleteColorOption}})],1):_vm._e(),(_vm.getColorRoom.isOnAttic)?_c('div',_vm._l((_vm.getColorRoomOnAttic),function(room,index){return _c('span',{key:index},[_c('ColorRoom',{attrs:{"data":room,"open":true,"typePrice":"amount","disabled":_vm.disabledChangeColor},on:{"openDialog":_vm.openDialog,"resetColor":_vm.resetColorOpenDialog,"deleteContrastwall":_vm.deleteColorOption}})],1)}),0):_vm._e()])],1):_vm._e(),_c('ColorPicker',{attrs:{"data":_vm.roomData,"constrastWallRoomName":_vm.constrastWallRoomName},on:{"addToCart":_vm.addToCart},model:{value:(_vm.showColorDialog),callback:function ($$v) {_vm.showColorDialog=$$v},expression:"showColorDialog"}}),_c('AcceptCancel',{attrs:{"title":"Bekrefte tilbakestilling av farge","DialogMessage":"Er du sikker på at du vil tilbakestille farge?"},on:{"confirme":function($event){return _vm.resetColor(_vm.resetData)}},model:{value:(_vm.showAccept),callback:function ($$v) {_vm.showAccept=$$v},expression:"showAccept"}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }