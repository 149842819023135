<template>
  <!-- <div> -->
  <v-card
    height="100%"
    @mouseover="overlayMouseover(getData)"
    @mouseleave="overlayMouseleave(getData)"
    hover
    :class="{
      optionExpired: expired && notOnSelected(getData),
      notAvailable: notAvailable,
      clickyCard: true,
      'theme-shadow': true,
      imgActive: getData.selected
    }"
  >
    <!-- <v-img :src="optionsService.getOptionImage(getData)"> </v-img> -->

    <v-carousel
      v-model="getData.imageShowing"
      hide-delimiters
      height="200"
      hide-delimiter-background
      :show-arrows="getData.thumbnailImage.length > 1"
    >
      <template v-slot:prev="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon small>
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:next="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon small>
          <v-icon>
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </template>
      <v-carousel-item
        v-for="(item, i) in getData.thumbnailImage"
        :key="i"
        :src="item"
        @click="showImg(getData, getData.imageShowing)"
      ></v-carousel-item>
    </v-carousel>

    <v-list class="pt-0">
      <v-list-item class="pt-2" @click.native="addDeleteFromCart(getData)">
        <v-list-item-content class="pt-0 pb-8">
          <v-list-item-title class="d-flex my-1">
            <v-chip
              class="mr-1"
              v-if="!addon"
              x-small
              dark
              :color="
                getData.type === 1
                  ? 'primary'
                  : getData.type === 2
                  ? 'yellow'
                  : 'info'
              "
            >
              {{ optionType(getData.type) }}
            </v-chip>
            <v-chip v-if="addon" x-small dark color="primary">Tillegg</v-chip>

            <v-chip x-small :color="getData.selected ? 'success' : ''">
              {{ format.formatToNok(getData.price) }}
            </v-chip>

            <v-chip
              class="ml-auto"
              x-small
              color="grey"
              dark
              @click.stop="showImg(getData, getData.imageShowing)"
            >
              Se bilde <v-icon class="ml-1" x-small>mdi-magnify</v-icon>
            </v-chip>
          </v-list-item-title>
          <v-list-item-title class="my-2 darkgrey--text">
            <h3>{{ getData.name }}</h3>
          </v-list-item-title>
          <p v-truncate="truncateValue" style="font-size: 0.9rem;">
            {{ getData.description }}
          </p>
          <v-btn
            v-if="descriptionsCount(getData.description) > truncateValue"
            @click.stop="showDialogInfo = true"
            class="mt-2 mb-2"
            style="flex: 0 30%;"
            x-small
            depressed
          >
            Les mer
          </v-btn>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-btn
      style="position: absolute; bottom: 0;"
      :color="getData.selected ? 'success' : 'grey'"
      dark
      block
      depressed
      tile
      @click="addDeleteFromCart(getData)"
    >
      {{ textOnBtn(getData) }}
    </v-btn>

    <DialogOptionsInfo
      v-model="showDialogInfo"
      :title="getData.name"
      :description="getData.description"
      :images="getData.image"
    />
  </v-card>

  <!-- </div> -->
</template>

<script>
import format from "@/helpers/format";
import optionsService from "@/options.services";
import DialogOptionsInfo from "@/components/store/options/options/children/DialogOptionsInfo";

export default {
  props: {
    data: {
      required: true
    },
    readOnly: {
      default: false
    },
    addon: {
      type: Boolean,
      default: false
    },
    expired: {
      type: Boolean,
      default: false
    },
    expiredNotOnSelected: {
      type: Boolean,
      default: false
    },
    notAvailable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      optionsService,
      overlay: false,
      showDialogInfo: false,
      truncateValue: 250,
      format
    };
  },
  methods: {
    addDeleteFromCart(data) {
      if (!this.expired) {
        if (data.selected) {
          this.$emit("removeFromCart", data);
        } else {
          this.$emit("addToCart", data);
        }
      }
    },
    showImg(data, index) {
      if (data && data.image) {
        this.$emit("showImg", { url: data.image[index], data: data });
      }
    },
    overlayMouseover(data) {
      //   this.$emit("overlayMouseover", data);
    },
    overlayMouseleave(data) {
      //   this.$emit("overlayMouseleave", data);
    },
    optionType(type) {
      if (type === 1) {
        return "Standard";
      } else if (type === 2) {
        return "Tilvalg";
      } else if (type === 3) {
        return "Tillegg";
      }
    },
    textOnBtn(data) {
      if (data.selected && (data.type !== 3 || this.expired)) {
        return "Valgt";
      } else if (data.selected && data.type === 3) {
        return "Fjern";
      } else {
        return "Klikk for å velge";
      }
    },
    notOnSelected(data) {
      if (this.expiredNotOnSelected && data.selected) {
        return false;
      }
      return true;
    },
    descriptionsCount(description) {
      let count = 0;
      if (description) {
        count = description.length;
      }

      return count;
    }
  },
  computed: {
    getData() {
      var data = this.data;
      data.thumbnailImage = [optionsService.getOptionImage(data, true)];
      data.image = [optionsService.getOptionImage(data)];

      if (data.hasInfoImage) {
        var img = optionsService.getOptionImage(data, false, true);
        data.thumbnailImage.push(img);
        data.image.push(img);
      }

      return data;
    }
  },
  components: {
    DialogOptionsInfo
  }
};
</script>

<style lang="scss" scoped>
// .text-cap {
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-line-clamp: 5; /* number of lines to show */
//   -webkit-box-orient: vertical;
//   height: 76px;
// }
.notAvailable {
  .v-list-item {
    .v-avatar {
      background-color: darkgrey !important;
    }
    .v-list-item__content {
      .v-list-item__title {
        color: darkgrey !important;
      }
    }
  }
}
.clickyCard.notAvailable {
  position: relative;
  pointer-events: none;
  &:after {
    content: "Ikke tilgjengelig";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: white;
    font-weight: bold;
  }
}

.optionExpired {
  .v-list-item {
    .v-avatar {
      background-color: darkgrey !important;
    }
    .v-list-item__content {
      .v-list-item__title {
        color: darkgrey !important;
      }
    }
  }
}
.clickyCard.optionExpired {
  position: relative;
  pointer-events: none;
  &:after {
    content: "Frist utgått";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: white;
    font-weight: bold;
  }
}
</style>
