<template>
  <div>
    <ReceiptContainer />
  </div>
</template>

<script>
import ReceiptContainer from "@/components/store/webStore/receipt/container/ReceiptContainer.vue";
export default {
  components: {
    ReceiptContainer
  }
};
</script>
