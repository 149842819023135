import baseApi from "./baseApi";

const api = {
  //   async getSectionOptions() {
  //     return await baseApi.get(`/options/sectionoptions`);
  //   },
  //   async getOptionsData() {
  //     return await baseApi.get(`/options/data`);
  //   },
  async getCategoryOptionsData(id) {
    const response = await baseApi.get(`/options/category/${id}/options`, {
      cache: {
        maxAge: 0 // 12 timer instead of 15 min
      },
      adapter: null
    });
    return response.data;
  },
  //   async getOptionsReady() {
  //     const response = await baseApi.get(`/options/ready`);
  //     return response.data;
  //   },

  async removeOptionFromCart(r) {
    const response = await baseApi.post(`/options/cart/item/remove`, r);
    return response.data;
  },
  async saveOptionCart(r) {
    const response = await baseApi.post(`/options/cart/save`, r);
    return response.data;
  },
  async getSendConfirmcode() {
    const response = await baseApi.get(`/options/cart/confirmcode`, {
      cache: {
        maxAge: 0 // 0 timer instead of 15 min
      }
    });

    // return await baseApi.get(`/options/cart/confirmcode`);

    return response.status;
  },
  async confirmcode(r) {
    const response = await baseApi.post(`/options/cart/confirm`, r);

    return response.data;
  },

  async getSectionoptions() {
    const response = await baseApi.get(`/options/sectionoptions`, {
      cache: {
        maxAge: 0 // 0 timer instead of 15 min
      },
      adapter: null
    });
    return response.data;
  },

  async downloadOptionsReceipt() {
    return await baseApi.get(`/options/receipt?mtVersion=2`).then(response => {
      return { file: response.data.file, fileName: response.data.fileName };
    });
  }

  // downloadOptionsReceipt() {
  //   return
  //     axios({
  //       url: "/options/receipt",
  //       method: "GET",
  //       responseType: "blob"
  //     }).then(
  //       fileResponse => {
  //         var filename = "";
  //         var disposition = fileResponse.headers["content-disposition"];
  //         if (disposition && disposition.indexOf("attachment") !== -1) {
  //           var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  //           var matches = filenameRegex.exec(disposition);
  //           if (matches != null && matches[1]) {
  //             filename = matches[1].replace(/['"]/g, "");
  //           }
  //         }

  //         resolve({
  //           blob: new Blob([fileResponse.data]),
  //           fileName: filename
  //         });
  //       }
  //     );
  //   });
  // },
};

export default api;
