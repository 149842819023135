import baseApi from "./baseApi";
import notificationsService from "@/notification.services";

const api = {
  async colorAvailable() {
    return await baseApi
      .get(`/colors/available`)
      .then(response => {
        return response.data;
      })
      .catch(() => {
        notificationsService.error("Kunne ikke hente plantegning");
        return undefined;
      });
  },

  async getColorReady() {
    return await baseApi
      .get(`/colors/ready`)
      .then(response => {
        return response.data;
      })
      .catch(() => {
        // notificationsService.error("Kunne ikke hente plantegning");
        return undefined;
      });
  },

  async GetColorOptions() {
    return await baseApi
      .get(`/colors`)
      .then(response => {
        return response.data;
      })
      .catch(() => {
        notificationsService.error("Kunne ikke hente plantegning");
        return undefined;
      });
  },
  async getColorCart() {
    return await baseApi
      .get(`/colors/cart`)
      .then(response => {
        return response.data;
      })
      .catch(() => {
        notificationsService.error("Kunne ikke hente handlekurven");
        return undefined;
      });
  },
  async updateColorCart(data) {
    return await baseApi
      .put(`/colors/cart`, data)
      .then(response => {
        return response.data;
      })
      .catch(() => {
        notificationsService.error("Kunne ikke legge til i handlekurven");
        return undefined;
      });
  },
  async deleteColorCart(id, wallLetter, isconfirmed, deleteContrastWalls) {
    var querry = "";
    var wall = "";
    var confirmed = "";
    var contrastWalls = "";

    if (wallLetter) {
      wall = `?wallLetter=${wallLetter}`;
    }
    if (isconfirmed) {
      confirmed = (wallLetter ? "&" : "?") + `isconfirmed=${isconfirmed}`;
    }
    if (deleteContrastWalls) {
      contrastWalls =
        (wallLetter || isconfirmed ? "&" : "?") +
        `deleteContrastWalls=${deleteContrastWalls}`;
    }

    querry = wall + confirmed + contrastWalls;

    return await baseApi
      .delete(`/colors/cart` + (id ? `/` + id : "") + querry)
      .then(response => {
        return response.data;
      })
      .catch(() => {
        notificationsService.error("Kunne ikke tilbakestille");
        return undefined;
      });

    // .delete(
    //   `/colors/cart` +
    //     (id ? `/` + id : "") +
    //     (wallLetter ? `?wallLetter=${wallLetter}` : ``)
    // )
  },
  async deleteAllColorCart() {
    return await baseApi
      .delete(`/colors/cart`)
      .then(response => {
        return response.data;
      })
      .catch(() => {
        notificationsService.error("Kunne ikke tilbakestille");
        return undefined;
      });
  },

  async resetAllColorCart() {
    return await baseApi
      .post(`/colors/reset`)
      .then(response => {
        return response.data;
      })
      .catch(() => {
        notificationsService.error("Kunne ikke tilbakestille");
        return undefined;
      });
  },

  async resetColorCart(id, wallLetter, deleteContrastWalls) {
    var querry = "";
    var wall = "";
    var contrastWalls = "";

    if (wallLetter) {
      wall = `?wallLetter=${wallLetter}`;
    }
    if (deleteContrastWalls) {
      contrastWalls =
        (wallLetter ? "&" : "?") +
        `includeContrastWalls=${deleteContrastWalls}`;
    }

    querry = wall + contrastWalls;

    return await baseApi
      .post(`/colors/reset/${id}` + querry)
      .then(response => {
        return response.data;
      })
      .catch(() => {
        notificationsService.error("Kunne ikke tilbakestille");
        return undefined;
      });
  },

  async confirmcode(r) {
    const response = await baseApi.post(`/options/cart/confirm`, r);
    return response.data;
  },

  async confirmcodeColorCart(code) {
    // return await baseApi
    //   .post(`/colors/cart/confirm`, code)
    //   .then(response => {
    //     return response.data;
    //   })
    //   .catch(() => {
    //     notificationsService.error("Kunne ikke bekrefte handlekurven");
    //     return undefined;
    //   });

    const response = await baseApi.post(`/colors/cart/confirm`, code);
    return response.data;
  },
  async getColorCartConfirmcode() {
    return await baseApi
      .get(`/colors/cart/confirmcode`, {
        cache: {
          maxAge: 0 // 0 timer instead of 15 min
        }
      })
      .then(response => {
        return response.data;
      })
      .catch(() => {
        notificationsService.error("Kunne ikke sende bekreftelseskode");
        return undefined;
      });
  },
  async getColorReceipt() {
    return await baseApi
      .get(`/colors/receipt`)
      .then(response => {
        return response.data;
      })
      .catch(() => {
        notificationsService.error("Kunne ikke hente kvittering");
        return undefined;
      });
  }
};

export default api;
