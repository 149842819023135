<template>
  <div>
    <Checkout
      v-model="cartColor"
      :confirmed-cart="storeCart.cartConfirmed"
      :changed-from-confirmed="cartChanges"
      :haveConfirmed="storeCart.confirmed"
      :haveChangedConfirmed="haveChangedConfirmed"
      showDeleteIcon
      showDeleteIconChanged
      showDeleteIconConfirmed
      itemTitleKey="ncs"
      :showbuttons="storeCart.changes > 0 || storeCart.confirmed"
      :showEmptyCheckout="storeCart.changes === 0 && !storeCart.confirmed"
      showEmptyGoTo="colors"
      showEmptyBtnName="Gå til fargevelger"
      :totalPrice="storeCart.totalPrice"
      :showConfirmBtn="storeCart.changes === 0"
      showConfirmBtnDisable
      :showChangeBtn="this.$route.name !== 'colorCheckoutOpen'"
      :showSummaryBtn="
        this.$route.name !== 'colorCheckoutOpen' && storeCart.confirmed
      "
      :confirmBtnName="
        storeCart.confirmed ? 'Bekreft endringer' : 'Bekreft fargevalg'
      "
      changeBtnName="Endre fargevalg"
      @confirm="confirmcart"
      @change="goTo('colors')"
      @summary="goTo('summary')"
      @delete="deleteFromCart"
    >
    </Checkout>

    <Confirmcode
      v-model="showDialogConfirme"
      title="BEKREFT FARGEVALG"
      info="Skriv inn bekreftelseskoden vi sendte til deg på sms."
      :error="errorCount"
      @sendNewConfirmcode="sendNewConfirmcode"
      @confirmcode="confirmcode"
      @reset="resetConfirm"
    />
    <AcceptCancel
      v-model="showAccept"
      title="Bekrefte sletting av farge"
      DialogMessage="Er du sikker på at du vil slette fargen fra handlekurven?"
      @confirme="deleteItemFromCart"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import optionsService from "@/options.services";

import colorApi from "@/api/colorApi";
import commonApi from "@/api/commonApi";
import Confirmcode from "@/components/store/ConfirmcodeDialog";
import AcceptCancel from "@/components/dialog/AcceptCancel";
import notificationsService from "@/notification.services";
import Checkout from "@/components/store/shared/checkout/contianer/Checkout";

export default {
  data() {
    return {
      showConfirmcode: { show: false },
      showAccept: false,
      deleteFromCartData: null,
      optionsService
    };
  },
  async created() {
    if (this.$route.name === "colorCheckoutOpen") {
      await commonApi
        .isAlbumUser()
        .then(response => {
          if (!response) {
            this.$router.push({ name: "home" });
          }
        })
        .catch(error => {
          error.response;
          this.$router.push({ name: "home" });
        });
    }
  },
  methods: {
    ...mapActions({
      deleteColorCart: "color/deleteColorCart",
      confirmColorCart: "color/confirmColorCart",
      resetColorCart: "color/resetColorCart",
      setShowConfirme: "color/showConfirme",
      errorCountReset: "color/errorCountReset"
    }),
    async confirmcart() {
      //If route is colorCheckoutOpen then it is from album and user dont need to type in code to confirm
      if (this.$route.name === "colorCheckoutOpen") {
        this.confirmcode(0);
      } else {
        await colorApi.getColorCartConfirmcode().then(() => {
          this.setShowConfirme(true);
          this.countError = this.countError + 1;
        });
      }
    },
    async confirmcode(code) {
      const data = { key: "code", value: code };
      this.confirmColorCart(data);
    },
    async sendNewConfirmcode() {
      await colorApi.getColorCartConfirmcode().then(() => {
        notificationsService.success("Ny bekreftelseskode sendt");
      });
    },
    deleteFromCart(item, reset) {
      if (reset) {
        var isChangedFromConfirmed = this.storeCart.cartConfirmed.some(
          x =>
            x.roomId === item.roomId &&
            (item.wallLetter ? x.wallLetter === item.wallLetter : !x.wallLetter)
        );
      }

      this.deleteFromCartData = item;
      this.deleteFromCartData.isChangedFromConfirmed = isChangedFromConfirmed;
      this.showAccept = true;
    },
    deleteItemFromCart() {
      this.deleteColorCart(this.deleteFromCartData);
    },
    resetConfirm() {
      this.errorCountReset();
    },
    goTo(name) {
      this.$router.push({ name: name });
    }
  },
  computed: {
    ...mapGetters({
      storeCart: "color/colorCart",
      colorAvailable: "shared/colorAvailable",
      showConfirme: "color/showConfirme",
      errorCount: "color/errorCount"
    }),
    showDialogConfirme: {
      get() {
        return this.showConfirme;
      },
      set(value) {
        this.setShowConfirme(value);
      }
    },
    cartColor() {
      if (!this.storeCart.loading && this.storeCart.cart) {
        var data = [];
        this.storeCart.cart.forEach(x => {
          x.data.forEach(y => {
            if (y.state === 2) {
              data.push(y);
            }
          });
        });

        return data;
      }
      return null;
    },
    cartChanges() {
      if (this.storeCart.cartChanges) {
        this.storeCart.cartChanges.forEach(x => {
          x.state !== -1
            ? (x.selected = true)
            : ((x.selected = false), (x.hideDelete = true));
        });

        return this.storeCart.cartChanges;
      }

      return null;
    },
    haveChangedConfirmed() {
      if (
        this.storeCart &&
        this.storeCart.cartChanges &&
        this.storeCart.cartChanges.length > 0
      ) {
        return true;
      }
      return false;
    }
  },
  components: {
    Confirmcode,
    AcceptCancel,
    Checkout
  }
};
</script>
<style lang="scss">
.checkoutFooter {
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}
@media (max-width: 600px) {
  .checkoutFooter {
    button,
    a {
      width: 100%;
    }
  }
}
</style>
