import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline primary--text"},[_vm._v(_vm._s(_vm.title))])]),_c(VCardText,[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.info))])]),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{attrs:{"depressed":"","color":"success"},on:{"click":_vm.accept}},[_vm._v("Ja")]),_c(VBtn,{attrs:{"depressed":"","color":"error"},on:{"click":function($event){_vm.show = false}}},[_vm._v("Nei")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }