<template>
  <div v-if="$vuetify.breakpoint.smAndDown">
    <InfoCardDropDown
      title="Strømforbruk"
      icon="mdi-home-lightning-bolt-outline"
      :removeMarginPrependIcon="true"
      :removeMarginToIcon="true"
      :outlined="true"
      :value="show"
      @click="show = !show"
    >
      <template v-slot:headerInfo>
        <div class="mr-6">
          <v-chip v-if="getIsFilled" x-small hide-details color="success">
            Fylt ut
          </v-chip>
          <v-chip v-if="!getIsFilled" x-small hide-details color="warning">
            Ikke Fylt
          </v-chip>
        </div>
      </template>
      <PowerItems
        :class="$vuetify.breakpoint.smAndDown ? '' : 'pl-5 pr-2  pt-2 '"
        :powerData.sync="getpowerData"
        @houseKwt="houseKwt"
        @powerCost="powerCost"
        @newArea="newArea"
        :monthlyKwt="monthlyKwt"
        :sectionInfo="sectionInfo"
        :houseTotalCost.sync="houseTotalCost"
        :newhouseTotalCost.sync="newhouseTotalCost"
        :totalhouse.sync="gettotalhouse"
        :totalnewhouse.sync="gettotalnewhouse"
      />
    </InfoCardDropDown>
  </div>
  <div v-else>
    <PowerItems
      :powerData.sync="getpowerData"
      @houseKwt="houseKwt"
      @powerCost="powerCost"
      @newArea="newArea"
      :houseMonthlyKwt.sync="gethouseMonthlyKwt"
      :sectionInfo="sectionInfo"
      :houseTotalCost.sync="houseTotalCost"
      :newhouseTotalCost.sync="newhouseTotalCost"
      :totalhouse.sync="gettotalhouse"
      :totalnewhouse.sync="gettotalnewhouse"
    />
  </div>
</template>

<script>
import PowerItems from "@/components/store/webStore/shared/loanCalculation/children/PowerUsageItems.vue";
import InfoCardDropDown from "@/components/shared/InfoCardBaseDropDown.vue";

export default {
  emits: ["houseKwt", "powerCost", "newArea"],
  data() {
    return {
      power: {},
      powcost: 0,
      show: true,
    };
  },
  props: {
    powerData: { type: Object, required: true },
    houseMonthlyKwt: { type: Number, default: 0 },
    sectionInfo: { type: Object, required: true },
    houseTotalCost: { type: Number },
    newhouseTotalCost: { type: Number },
    totalhouse: { type: Number },
    totalnewhouse: { type: Number },
    monthlyKwt: { type: Number, required: false },
  },
  created() {
    this.powcost = this.powerData.powerDefault;
  },
  mounted() {
    this.show = false;
  },
  computed: {
    getpowerData: {
      get() {
        return this.powerData;
      },
      set(data) {
        this.$emit("update:powerData", data);
      },
    },
    getIsFilled() {
      return this.powcost && this.powcost > 0;
    },
    gethouseMonthlyKwt: {
      get() {
        return this.houseMonthlyKwt;
      },
      set(value) {
        this.$emit("update:houseMonthlyKwt", value);
      },
    },
    gettotalhouse: {
      get() {
        return this.totalhouse;
      },
      set(value) {
        this.$emit("update:totalhouse", value);
      },
    },
    gettotalnewhouse: {
      get() {
        return this.totalnewhouse;
      },
      set(value) {
        this.$emit("update:totalnewhouse", value);
      },
    },
  },
  methods: {
    houseKwt(value, check) {
      this.$emit("houseKwt", value, check);
    },
    powerCost(value) {
      this.powcost = value;
      this.$emit("powerCost", value);
    },
    newArea(value) {
      this.$emit("newArea", value);
    },
  },
  components: {
    PowerItems,
    InfoCardDropDown,
  },
};
</script>
