<template>
  <v-row v-if="!loading || getProfile" class="justify-center my-12">
    <v-img
      class="profile-banner"
      :src="image.url"
      :lazy-src="image.url"
      height="350"
    />

    <!-- User profile -->
    <v-col v-if="getProfile" class="mt-md-6 mr-lg-6" sm="8" lg="4" xl="3">
      <v-card class="theme-shadow">
        <v-img
          :lazy-src="optionsService.getProfileImage(getProfile.id, getStamp)"
          :src="optionsService.getProfileImage(getProfile.id, getStamp)"
          max-height="180"
          spect-ratio="2.22222"
          height="180"
        >
          <div class="d-flex align-center float-right mr-2 mt-2">
            <v-btn
              v-if="delProfileImage"
              class="mr-1"
              x-small
              dark
              color="red"
              @click="delImage"
              >Slett</v-btn
            >
            <v-btn
              v-if="!delProfileImage"
              class="mr-1"
              x-small
              dark
              color="primary"
              @click="changeImage"
              >Legg til profilbilde</v-btn
            >

            <v-tooltip top v-if="delProfileImage">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" text icon dark @click="confirme(true, 'add')">
                  <v-icon>mdi-image-edit</v-icon>
                </v-btn>
              </template>
              <span>Endre profilbilde</span>
            </v-tooltip>
            <input
              accept="image/*"
              v-show="false"
              ref="upload"
              type="file"
              @change="imageUplode"
            />
          </div>
        </v-img>

        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h3 class="primary--text headline font-weight-bold">
                  {{ getProfile.firstName }} {{ getProfile.lastName }}
                </h3>
              </v-list-item-title>
            </v-list-item-content>

            <!-- User settings modal -->
            <v-dialog v-model="dialog" max-width="600px"></v-dialog>
            <template>
              <v-list-item-icon>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="open" color="blue" fab small>
                      <v-icon color="white">mdi-account-edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Endre profil</span>
                </v-tooltip>
              </v-list-item-icon>
            </template>
          </v-list-item>
        </v-list>

        <v-list class="py-0" v-for="(item, index) in profileItems" :key="index">
          <v-list-item>
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>

            <v-list-item-content style="max-width: 100px;">
              <v-list-item-title>{{ item.title }}:</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>{{ item.content }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list dense>
          <v-subheader class="ml-3">VARSLINGER</v-subheader>
          <v-list-item-group multiple>
            <v-list-item>
              <v-list-item-action class="mr-3">
                <v-checkbox
                  v-model="getProfile.acceptNewsLetter"
                  color="success"
                  @change="save2(getProfile, 'acceptNewsLetter')"
                  hide-details
                ></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-list-item-title v-on="on">
                      Automatisk nyhetsbrev
                    </v-list-item-title>
                  </template>
                  <span
                    >Tips og oppdateringer om boligen sendes i automatiske
                    nyhetsbrev</span
                  >
                </v-tooltip>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-col>

    <v-col class="mt-6" sm="8" lg="6" xl="5">
      <!-- Residential info -->
      <ResidentialInfo :currentSection="currentSection" />

      <!-- Contact person -->
      <PersonalInfo
        v-if="!HasMoveIn(currentSection.complaintDeadLine)"
        title="Kontaktperson"
        :name="currentSection.salesRepName"
        :email="currentSection.salesRepEmail"
        :phone="currentSection.salesRepPhone"
        :photo="currentSection.salesRepPhoto"
      />
      <MyAgreement :currentSection="currentSection" :colorInfo="colorCart" />
      <!-- KK -->
      <PersonalInfo
        v-if="HasMoveIn(currentSection.complaintDeadLine)"
        title="Kundeansvarlig"
        :name="currentSection.qaRepName"
        phoneName="Kundesenter"
        email="kundesenter@trysilhus.no"
        :phone="32215510"
        :photo="currentSection.qaRepPhoto"
      />

      <ReservationsProfile
        v-if="
          currentSection &&
            currentSection.reservations &&
            currentSection.reservations.length > 0
        "
        v-model="currentSection.reservations"
      />

      <AccountModal :dialogTable="dialogTable" :show="showDialog" />
      <AcceptCancelDialog
        title="Skru av varslinger"
        :DialogMessage="DialogMessage"
        :show="showAcceptCancel"
        @notifications="notifications"
      />
      <AcceptDialog
        :title="ImageTitle"
        :DialogMessage="ImageMessage"
        :from="acceptFrom"
        :show="showAccept"
        @confirme="confirme"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import notificationsService from "@/notification.services";
import baseApi from "@/api/api";
import ResidentialInfo from "@/components/profile/Residential_info";
import MyAgreement from "@/components/profile/MyAgreement";
import PersonalInfo from "@/components/profile/PersonalInfo";
import ReservationsProfile from "@/components/profile/ReservationsProfile";
// import AccountModal from "@/components/profile/Account_modal";
import AccountModal from "@/components/profile/EditAccountDialog";
import AcceptCancelDialog from "@/components/dialog/AcceptCancelDialog";
import AcceptDialog from "@/components/dialog/AcceptDialog";

import optionsService from "@/options.services";

export default {
  data: () => ({
    overlay: false,
    dialog: false,
    dialogTable: { postCode: {} },
    showDialog: { show: false },
    showAcceptCancel: { show: false },
    showAccept: { show: false },
    acceptFrom: null,
    ImageTitle: "",
    ImageMessage: "",
    errorMessages: "",
    isRequired: ["firstName", "lastName", "email", "address"],
    isRequiredPostCode: ["postCodeText", "postName"],
    drawer: null,
    items: [
      { title: "Home", icon: "dashboard" },
      { title: "About", icon: "question_answer" }
    ],
    DialogMessage: "Er du sikker på at du vil skru av varslinger?",
    notificationsData: null,
    notificationsType: null,
    file: null,
    optionsService,
    image: { url: "https://dummyimage.com/1000x200/fff/fff.png" },
    delProfileImage: false
  }),
  methods: {
    ...mapActions({
      setChangeProfileImage: "shared/changeProfileImage"
    }),
    save(data) {
      baseApi.editUser(data).then(Response => {
        if (Response.status === 200) {
          notificationsService.success("Endring lagret");
        }
      });
    },
    save2(data, type) {
      if (!data[type]) {
        if (type === "acceptEmail") {
          this.DialogMessage =
            "Hvis du skrur av e-post-varslinger, vil du ikke motta varsler om svar og endringer på dine henvendelser. Er du fortsatt sikker på at du vil skru av e-post-varslinger?";
        } else if (type === "acceptNewsLetter") {
          this.DialogMessage =
            "Hvis du ikke lenger vil motta nyhetsbrev, kan du gå glipp av nyttige tips og oppdateringer om din bolig.";
        }

        this.showAcceptCancel.show = true;
        this.notificationsData = data;
        this.notificationsType = type;
      } else {
        this.save(data);
      }
    },
    notifications(value) {
      if (value) {
        this.save(this.notificationsData);
      } else {
        this.getProfile[this.notificationsType] = true;
      }
    },
    open() {
      this.dialogTable = Object.assign({}, this.getProfile);
      this.showDialog.show = true;
    },
    changeImage() {
      this.ImageTitle = "Legg til profilbilde";
      this.ImageMessage =
        "Hvis du ønsker, kan du laste opp et profilbilde av deg selv. Bildet blir lagret i Mitt Trysilhus og blir tilgjengelig for våre medarbeidere som er involvert i boligprosjektet. ";
      this.acceptFrom = "add";
      this.showAccept.show = !this.showAccept.show;
    },
    delImage() {
      this.ImageTitle = "Slette profilbilde";
      this.ImageMessage = "Er du sikker på at du vil slette profilbilde?";
      this.acceptFrom = "del";
      this.showAccept.show = !this.showAccept.show;
    },
    async confirme(value, from) {
      if (value && from === "add") {
        this.$refs.upload.click();
      } else if (value && from === "del") {
        await baseApi
          .deleteProfileImage(this.profile.user.id)
          .then(response => {
            if (response.status === 200) {
              this.setChangeProfileImage();
              this.ImageIsNotDefault(this.profile.user.id);
            }
          });
      }
    },
    async imageUplode(file) {
      if (file) {
        if (file.currentTarget && file.currentTarget.files.length > 0) {
          file = file.currentTarget.files[0];
        }
        let formData = new FormData();
        formData.append("file", file);
        await baseApi.uplodeProfileImage(formData).then(x => {
          if (x.status === 200) {
            this.setChangeProfileImage();
            this.ImageIsNotDefault(this.profile.user.id);
            notificationsService.success("Nytt profilbilde lagret");
          }
        });
      }
    },
    HasMoveIn(complaintDeadLine) {
      let hasMovedIn = false;
      if (complaintDeadLine) {
        const moveInDate = moment(complaintDeadLine)
          .subtract(5, "years")
          .format();
        hasMovedIn = moment(moment().format()).isAfter(moveInDate);
      }

      return hasMovedIn;
    },
    async getImage(projectId) {
      this.image = await baseApi.getProjectImage(projectId);
      this.image.url = `data:image/png;base64,${this.image.image}`;
    },
    async ImageIsNotDefault(userId) {
      this.delProfileImage = await baseApi.profileImageIsNotDefault(userId);
    }
  },
  computed: {
    ...mapGetters({
      profile: "shared/profile",
      changeProfileImage: "shared/changeProfileImage",
      loading: "shared/loading",
      colorCart: "color/colorCart"
    }),
    getProfile() {
      if (this.profile) {
        this.getImage(this.profile.user.currentSection.project.id);
        this.ImageIsNotDefault(this.profile.user.id);
        return this.profile.user;
      }
      return null;
    },
    getPostCode() {
      if (this.profile) {
        return this.profile.user.postCode;
      }
      return {};
    },
    currentSection() {
      if (this.profile) {
        return this.profile.user.currentSection;
      }
      return {};
    },
    getStamp() {
      if (this.changeProfileImage) {
        return this.changeProfileImage;
      }
      return 0;
    },

    profileItems() {
      var data = [
        {
          icon: "mdi-home-outline",
          title: "Adresse",
          content: this.getProfile.address
        },
        {
          icon: "mdi-email-outline",
          title: "Epost",
          content: this.getProfile.email
        },
        {
          icon: "mdi-cellphone",
          title: "Mobil",
          content: this.getProfile.mobile
        }
      ];
      return data;
    }
  },
  components: {
    ResidentialInfo,
    PersonalInfo,
    AccountModal,
    AcceptCancelDialog,
    MyAgreement,
    AcceptDialog,
    ReservationsProfile
  }
};
</script>

<style lang="scss">
.profile-banner {
  position: absolute;
  top: 0;
  width: 100%;
}
.card-icon {
  position: relative;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border-radius: 50%;
  }
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 4rem;
    opacity: 0.5;
  }
}
</style>
