import baseApi from "./baseApi";

const api = {
  async getProjectsSignup() {
    const response = await baseApi.get(`/signup`);
    return response.data;
  },
  async signup(data) {
    const response = await baseApi.post(`/signup`, data);
    return response.data;
  },
  async resetpassword(r) {
    const response = await baseApi.post(`/resetpassword`, { email: r });
    return response;
  }
};

export default api;
