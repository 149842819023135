<template>
  <div>
    <v-row class="showcase-row" no-gutters>
      <v-col cols="12">
        <div class="showcase-intro">
          <div class="showcase-text">
            <h1>Mitt Trysilhus</h1>
            <h2 class="d-none d-sm-block">Kundeportal for din bolig</h2>
            <h3 class="primary--text mb-3 d-sm-none">Din bolig kundeportal</h3>
            <v-icon class="my-4" color="yellow">fas fa-star</v-icon>
            <p>
              I Mitt Trysilhus finner du alt du trenger å vite om din bolig. Du
              kan blant annet finne dokumenter knyttet til boligen, finne svar
              på spørsmål du måtte ha og legge inn dine henvendelser.
            </p>
          </div>
          <v-img
            v-if="image"
            :src="image.url"
            :lazy-src="image.url"
            aspect-ratio="2.71"
          ></v-img>
          <v-skeleton-loader
            v-else
            class="imgaeLoader"
            type="image"
          ></v-skeleton-loader>
        </div>
      </v-col>
    </v-row>
    <VideoShowcase />
    <v-row class="showcase-row customersupport py-6 py-sm-12 greybg" no-gutters>
      <v-col class="d-flex align-center mx-auto py-12" sm="8" lg="6" xl="4">
        <div class="showcase-text">
          <v-icon class="mb-3" color="yellow" large>mdi-headset</v-icon>
          <h1 class="mb-2 display-2">Kundesenter</h1>
          <h3 class="primary--text mb-12">
            Vårt kundesenter består av erfarne takstmenn som står klare til å
            hjelpe deg dersom du har spørsmål om din bolig.
          </h3>
          <!-- <v-icon class="my-6" color="yellow">fas fa-star</v-icon> -->

          <div class="d-sm-flex justify-center">
            <div class="mx-6 mb-10 mb-sm-0">
              <v-img
                class="mx-auto mb-4"
                width="50"
                :src="require('@/assets/imgs/gfx/phone.svg')"
              />
              <h3>Ring oss</h3>
              <p class="mb-0">Mandag-fredag kl. 10-14</p>
              <a href="tel:32215510">32 21 55 10</a>
            </div>
            <div class="mx-6">
              <v-img
                class="mx-auto mb-4"
                width="50"
                :src="require('@/assets/imgs/gfx/email.svg')"
              />
              <h3>Send e-post</h3>
              <a href="mailto:kundesenter@trysilhus.no" title="Epost">
                kundesenter@trysilhus.no
              </a>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import api from "@/api/api";
import VideoShowcase from "@/components/home/Video_showcase";
import { mapGetters } from "vuex";
import notificationsService from "@/notification.services";
export default {
  components: {
    VideoShowcase
  },
  // async created() {
  //   const data = await baseApi.getFaqs();
  // },
  data() {
    return {
      profile: null,
      project: null,
      image: null
    };
  },
  methods: {
    async getImage(projectId) {
      await api
        .getProjectImage(projectId)
        .then(response => {
          this.image = response;
          this.image.url = `data:image/png;base64,${this.image.image}`;
        })
        .catch(error => {
          error.response;
          notificationsService.hide();
        });
    }
  },
  created() {
    this.profile = this.$store.getters["shared/profile"];
    this.$store.watch(
      (state, getters) => getters["shared/profile"],
      newValue => {
        this.profile = newValue.user;
        this.getImage(newValue.user.currentSection.project.id);
      }
    );
    if (this.profile) {
      this.getImage(this.profile.user.currentSection.project.id);
    }
  },
  computed: {
    ...mapGetters({
      getOptionsReady: "shared/optionsReady"
    }),
    getProjectId() {
      if (this.profile && !this.profile.user) {
        return this.profile.currentSection.project.id;
      } else if (this.profile) {
        return this.profile.user.currentSection.project.id;
      }
      return null;
    }
  }
};
</script>

<style lang="scss">
.imgaeLoader {
  .v-skeleton-loader__image {
    height: 65vh;
  }
}
</style>
