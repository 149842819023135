import { VCard } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"outlined":_vm.outlined}},[_c(VList,{staticClass:"py-0"},[_c(VListGroup,{class:{
        removeMarginIcon: _vm.removeMarginToIcon,
        removeMarginPrependIcon: _vm.removeMarginPrependIcon,
      },attrs:{"active-class":"headerColor","prepend-icon":_vm.icon,"value":_vm.value},on:{"click":_vm.click},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemTitle,[_vm._v(_vm._s(_vm.title))]),_vm._t("headerInfo")]},proxy:true}],null,true)},[_c(VList,{staticClass:"pa-0 cardHeight",class:_vm.includeClass ? _vm.cardClass : '',attrs:{"max-height":_vm.maxHeigth}},[_vm._t("default")],2)],1)],1),_vm._t("underCard")],2)}
var staticRenderFns = []

export { render, staticRenderFns }