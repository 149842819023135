import Vue from "vue";
import Router from "vue-router";
import func from "@/routerFunctions";
import Home from "@/views/Home.vue";
import Profile from "@/views/Profile.vue";
import Documents from "@/views/Documents.vue";
import Timeline from "@/views/Timeline.vue";
import Requests from "@/views/request/Requests.vue";
import Request from "@/views/request/Request.vue";
import NewRequest from "@/views/request/NewRequest.vue";
// import JointOwnerships from "./views/request/JointOwnerships.vue";
import Faq from "@/views/faq/Faq.vue";
import Faqs from "@/views/faq/Faqs.vue";
// import FaqInfo from "@/views/faq/FaqInfo.vue";
import Store from "@/views/store/Store.vue";
import ColorStore from "@/views/store/ColorStore.vue";
import ColorCheckout from "@/views/store/ColorCheckout.vue";

import IntroStore from "@/views/store/IntroStore.vue";
import StoreInfo from "@/views/store/StoreInfo.vue";

import StoreOptions from "@/views/store/StoreOptions.vue";
import Checkout from "@/views/store/Checkout.vue";
import Summary from "@/views/store/Summary.vue";
import MySections from "@/views/MySections.vue";

import Login from "@/views/startpage/Login.vue";
import Consent from "@/views/Consent.vue";

import Cookies from "@/views/Cookies.vue";

// import Signup from "@/views/startpage/Signup.vue";
// import Forgottenpassword from "@/views/startpage/Forgottenpassword.vue";

import NoUser from "@/views/startpage/NoUser.vue";
import NotFound from "@/views/NotFound.vue";
import NotFoundStore from "@/views/store/NotFoundStore.vue";
import EmptyRouterView from "@/views/EmptyRouterView.vue";
import { authGuard } from "./auth/authGuard";

//WebShop
//WebShopFunctions
import webShopFunc from "@/helpers/router/webShopRouterFunctions";

//intro
import WebShopIntro from "@/views/webShop/intro/WebShopIntro.vue";
//store
import WebShopOptionsIntro from "@/views/webShop/store/WebShopOptionsIntro.vue";
import WebShopOptionsAttic from "@/views/webShop/store/WebShopOptionsAttic.vue";
import WebShopOptionsCategory from "@/views/webShop/store/WebShopOptionsCategory.vue";
import WebShopOptions from "@/views/webShop/store/StoreOptions.vue";
//color
import WebShopColorIntro from "@/views/webShop/color/WebShopColorIntro.vue";
import WebShopRoomColor from "@/views/webShop/color/WebShopRoomColor.vue";

//PurchaseOffer
import WebShopPurchaseOffer from "@/views/webShop/purchaseOffer/WebShopPurchaseOffer.vue";
//Summary
import WebShopSummary from "@/views/webShop/summary/WebShopSummary.vue";
//Receipt
import WebShopReceipt from "@/views/webShop/receipt/WebShopReceipt.vue";
//NoSectionFound
import WebShopNoSectionFound from "@/views/webShop/NoSectionFound.vue";

// import dOptions from "@/views/3dOptions/Options.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
      // meta: {
      //   notRequiresAuth: true
      // }
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        notRequiresAuth: true,
        mobName: "Login"
      }
    },
    {
      path: "/cookies",
      name: "cookies",
      component: Cookies,
      meta: {
        notRequiresAuth: true,
        mobName: "Innformasjonskapsler"
      }
    },

    {
      path: "/registrerkonto",
      alias: "/signup",
      name: "signup",
      component: Login,
      meta: {
        notRequiresAuth: true,
        mobName: "Registrerkonto"
      }
    },
    {
      path: "/glemtpassord",
      alias: "/forgottenpassword",
      name: "forgottenpassword",
      component: Login,
      meta: {
        notRequiresAuth: true,
        mobName: "Glemt passord"
      }
    },
    {
      path: "/profil",
      name: "profile",
      component: Profile,
      meta: { mobName: "Min profil" }
    },
    {
      path: "/dokumenter",
      name: "documents",
      component: Documents,
      beforeEnter: func.setDocuments,
      meta: { mobName: "Dokumenter" }
    },
    {
      path: "/tidslinje",
      name: "timeline",
      component: Timeline,
      meta: { mobName: "Tidslinje bygg" }
    },
    {
      path: "/mineseksjoner",
      name: "mysections",
      component: MySections,
      meta: { mobName: "Mine seksjoner" }
    },
    {
      path: "/henvendelser",
      alias: "/complaints",
      component: EmptyRouterView,
      children: [
        {
          path: "",
          name: "requests",
          component: Requests,
          beforeEnter: func.setComplaints,
          meta: { mobName: "Henvendelser" }
        },
        {
          path: "ny-henvendelse",
          name: "newrequest",
          component: NewRequest,
          meta: { mobName: "Ny henvendelse" }
        },
        {
          path: ":id",
          name: "request",
          component: Request,
          meta: { mobName: "Henvendelse" }
        }
      ]
    },
    // {
    //   path: "/henvendelser",
    //   name: "requests",
    //   component: Requests
    // },
    // {
    //   path: "/henvendelse",
    //   name: "request",
    //   component: Request
    // },
    // {
    //   path: "/ny-henvendelse",
    //   name: "newrequest",
    //   component: NewRequest
    // },
    {
      path: "/sameie",
      component: EmptyRouterView,
      children: [
        {
          path: "",
          name: "JointOwnerships",
          // component: JointOwnerships
          component: Requests,
          beforeEnter: func.setComplaints,
          meta: { mobName: "Henvendelser" }
        },
        {
          path: "ny-reklamasjon",
          name: "NewJointOwnership",
          component: NewRequest,
          meta: { mobName: "Ny sameie henvendelse" }
        },
        {
          path: ":id",
          name: "JointOwnership",
          component: Request,
          meta: { mobName: "Sameiets henvendelse" }
        }
      ]
    },
    // {
    //   path: "/sameie",
    //   name: "JointOwnerships",
    //   component: JointOwnership
    // },
    {
      path: "/faqs",
      component: EmptyRouterView,
      children: [
        {
          path: "",
          name: "faq",
          component: Faq,
          meta: { mobName: "Ofte stilte spørsmål" }
        },

        {
          path: "kategori/:id",
          name: "faqs",
          component: Faqs,
          meta: { mobName: "Ofte stilte spørsmål" }
        }
        // {
        //   path: ":id",
        //   name: "faqInfo",
        //   component: FaqInfo
        // }
      ]
    },
    // {
    //   path: "/faq",
    //   name: "faq",
    //   component: Faq
    // },

    // {
    //   path: "/farger",
    //   name: "colors",
    //   component: ColorStore,
    //   meta: { mobName: "Fargevalg" }
    // },

    {
      path: "/tilvalg",
      component: EmptyRouterView,
      children: [
        {
          path: "",
          name: "IntroStore",
          component: IntroStore,
          beforeEnter: func.optionsIsOpen,
          meta: { mobName: "Tilvalg" }
        },
        {
          path: "info",
          name: "StoreInfo",
          component: StoreInfo,
          beforeEnter: func.optionsOrColorIsOpen,
          meta: { mobName: "Tilvalg" }
        },
        {
          path: "butikk",
          name: "store",
          component: Store,
          beforeEnter: func.optionsIsOpen,
          meta: { mobName: "Tilvalg" }
        },
        // {
        //   path: "butikk/:id",
        //   name: "storeOptions",
        //   component: Store,
        //   beforeEnter: func.setOptionsData,
        //   meta: { mobName: "Tilvalg" }
        // },
        {
          path: "butikk/:id",
          name: "storeOptions",
          component: StoreOptions,
          beforeEnter: func.optionsIsOpen,
          meta: { mobName: "Tilvalg" }
        },
        {
          path: "handlekurv",
          name: "checkout",
          component: Checkout,
          beforeEnter: func.optionsIsOpen,
          meta: { mobName: "Handelekurv" }
        },
        {
          path: "handlekurv/:id",
          name: "checkoutChange",
          component: Checkout,
          beforeEnter: func.optionsIsOpen,
          meta: { mobName: "Tilvalg" }
        },
        {
          path: "kvittering",
          name: "summary",
          component: Summary,
          beforeEnter: func.optionsOrColorReady,
          meta: { mobName: "Kvittering" }
        },
        {
          path: "farger",
          name: "colors",
          component: ColorStore,
          beforeEnter: func.setColor,
          meta: { mobName: "Fargevalg" }
        },
        {
          path: "farger/handlekurv",
          name: "colorCheckout",
          component: ColorCheckout,
          beforeEnter: func.setColor,
          meta: { mobName: "Handlekurv" }
        },
        {
          path: "notavailable",
          name: "notavailable",
          component: NotFoundStore
        }
      ]
    },
    {
      path: "/tilvalgsvisning",
      component: EmptyRouterView,
      children: [
        // {
        //   path: "",
        //   name: "IntroStoreOpen",
        //   component: IntroStore,
        //   beforeEnter: func.setOptionsData,
        //   meta: { notRequiresAuth: true, mobName: "Tilvalg" }
        // },
        {
          path: "vis",
          name: "storeOpen",
          component: Store,
          beforeEnter: func.setOptionsData,
          meta: { notRequiresAuth: true, mobName: "Tilvalg" }
        },
        {
          path: "vis/:id",
          name: "storeOptionsOpen",
          component: StoreOptions,
          beforeEnter: func.setOptionsData,
          meta: { notRequiresAuth: true, mobName: "Tilvalg" }
        },
        //Open for album users
        {
          path: "farger",
          name: "colorsOpen",
          component: ColorStore,
          beforeEnter: func.setColorOpen,
          meta: { notRequiresAuth: true, mobName: "Fargevalg" }
        },
        {
          path: "farger/handlekurv",
          name: "colorCheckoutOpen",
          component: ColorCheckout,
          beforeEnter: func.setColorOpen,
          meta: { notRequiresAuth: true, mobName: "Handlekurv" }
        }
      ]
    },
    {
      path: "/design/:sectionId",
      component: EmptyRouterView,
      children: [
        {
          path: "intro",
          alias: "",
          name: "webShopIntro",
          component: WebShopIntro,
          beforeEnter: webShopFunc.intro,
          meta: {
            notRequiresAuth: true,
            hideNavBar: true,
            mobName: "Tilvalg",
            hideCookieBar: true,
            webShopLoader: true
          }
        },
        {
          path: "oppsumering",
          name: "webShopSummary",
          component: WebShopSummary,
          beforeEnter: webShopFunc.summary,
          meta: {
            notRequiresAuth: true,
            hideNavBar: true,
            mobName: "Oppsumering",
            hideCookieBar: true,
            webShopLoader: true
          }
        },
        {
          path: "kjopstilbud",
          name: "webShopPurchaseOffer",
          component: WebShopPurchaseOffer,
          beforeEnter: webShopFunc.purchaseOffer,
          meta: {
            notRequiresAuth: true,
            hideNavBar: true,
            mobName: "Kjøpetilbud",
            hideCookieBar: true,
            webShopLoader: true
          }
        },
        {
          path: "kvittering",
          name: "webShopReceipt",
          component: WebShopReceipt,
          beforeEnter: webShopFunc.purchaseReceipt,
          meta: {
            notRequiresAuth: true,
            hideNavBar: true,
            mobName: "Kvittering",
            hideCookieBar: true,
            webShopLoader: true
          }
        },
        {
          path: "NoSectionFound",
          name: "webShopNoSectionFound",
          component: WebShopNoSectionFound,
          beforeEnter: null,
          meta: {
            notRequiresAuth: true,
            hideNavBar: true,
            mobName: "Ingen seksjon",
            hideCookieBar: true,
            webShopLoader: true
          }
        }
      ]
    },

    {
      path: "/design/:sectionId/tilvalg",
      component: EmptyRouterView,
      children: [
        {
          path: "",
          name: "webShopOptionsIntro",
          component: WebShopOptionsIntro,
          beforeEnter: webShopFunc.options,
          meta: {
            notRequiresAuth: true,
            hideNavBar: true,
            mobName: "Tilvalg",
            hideCookieBar: true,
            webShopLoader: true
          }
        },
        {
          path: "loft",
          name: "webShopOptionsAttic",
          component: WebShopOptionsAttic,
          beforeEnter: webShopFunc.options,
          meta: {
            notRequiresAuth: true,
            hideNavBar: true,
            mobName: "Loft",
            hideCookieBar: true,
            webShopLoader: true
          }
        },

        {
          path: ":categoryId",
          name: "webShopOptionsCategory",
          component: WebShopOptionsCategory,
          beforeEnter: webShopFunc.optionsCategory,
          meta: {
            notRequiresAuth: true,
            hideNavBar: true,
            mobName: "Tilvalg",
            hideCookieBar: true,
            webShopLoader: true
          }
        },
        {
          path: ":categoryId/:id",
          name: "webShopOptionsItems",
          component: WebShopOptions,
          beforeEnter: webShopFunc.optionsItems,
          meta: {
            notRequiresAuth: true,
            hideNavBar: true,
            mobName: "Tilvalg",
            hideCookieBar: true,
            webShopLoader: true
          }
        }
      ]
    },
    {
      path: "/design/:sectionId/farger",
      component: EmptyRouterView,
      children: [
        {
          path: "",
          name: "webShopColorIntro",
          component: WebShopColorIntro,
          beforeEnter: webShopFunc.color,
          meta: {
            notRequiresAuth: true,
            hideNavBar: true,
            mobName: "Farger",
            hideCookieBar: true,
            webShopLoader: true
          }
        },
        {
          path: ":roomId",
          name: "webShopRoomColor",
          component: WebShopRoomColor,
          beforeEnter: webShopFunc.colorRoom,
          meta: {
            notRequiresAuth: true,
            hideNavBar: true,
            mobName: "Farger",
            hideCookieBar: true,
            webShopLoader: true
          }
        }
      ]
    },
    {
      path: "/consent/:consentGuid",
      name: "consent",
      component: Consent,
      meta: {
        notRequiresAuth: true,
        mobName: "consent"
      }
    },
    // {
    //   path: "/3dOptions",
    //   name: "3dOptions",
    //   component: dOptions,
    //   meta: {
    //     mobName: "3d Options"
    //   }
    // },

    {
      path: "/callback",
      name: "callback"
    },
    {
      path: "/ingen-bruker",
      name: "noUser",
      component: NoUser,
      meta: {
        notRequiresAuth: true,
        mobName: "Ingen bruker"
      }
    },
    {
      path: "/404",
      name: "404",
      component: NotFound,
      meta: { mobName: "404" }
    },
    {
      path: "*",
      redirect: "/404"
    }
  ],

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach(authGuard);

export default router;
