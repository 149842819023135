<template>
  <div>
    <v-list :class="{ expired: expired }" color="transparent">
      <v-list-item class="px-0 mb-2">
        <v-list-item-avatar color="primary">
          <span class="headline font-weight-bold white--text">
            {{ number }}
          </span>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="primary--text headline font-weight-black">
            {{ title }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ subTitle }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>
  </div>
</template>

<script>
export default {
  props: {
    expired: {
      default: false
    },
    number: {
      default: 1
    },
    title: {
      default: ""
    },
    subTitle: {
      default: null
    }
  }
};
</script>
