<template>
  <div>
    <v-dialog
      v-model="showDialog"
      max-width="700"
      hide-overlay
      :fullscreen="$store.getters['shared/isMobileDevice']"
      :persistent="mouseDown"
      @click:outside="setMouse"
    >
      <v-card
        class="safeAreaDialog"
        @mousedown="setMouseDown"
        @mouseup="setMouseDown"
      >
        <v-card-title>
          <span class="headline primary--text">Endre bruker informasjon</span>
        </v-card-title>

        <v-alert type="info" class="mr-7 ml-7">
          NB: Her endrer du kun din egen brukerinformasjon. Har du solgt din
          Trysilhus-bolig, må du be ny eier ta kontakt med vårt kundesenter og
          opprette ny bruker på boligen. Dette er pga. personvernhensyn.
        </v-alert>

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" class="pt-0 pb-0">
                  <v-text-field
                    v-model="dialogTable.firstName"
                    :rules="[
                      () => !!dialogTable.firstName || 'Fornavn er påkrevd',
                    ]"
                    label="Fornavn*"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="pt-0 pb-0">
                  <v-text-field
                    v-model="dialogTable.lastName"
                    :rules="[
                      () => !!dialogTable.lastName || 'Etternavn er påkrevd',
                    ]"
                    label="Etternavn*"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="pt-0 pb-0">
                  <v-text-field
                    v-model="dialogTable.email"
                    type="email"
                    :rules="emailRules"
                    label="E-post*"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="pt-0 pb-0">
                  <v-text-field
                    v-model="dialogTable.address"
                    :rules="[
                      () => !!dialogTable.address || 'Adresse er påkrevd',
                    ]"
                    label="Adresse*"
                    type="text"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="pt-0 pb-0">
                  <v-text-field
                    v-model="dialogTable.postCode.postCodeText"
                    :rules="postCodeRules"
                    label="Postnr*"
                    type="text"
                    pattern="[0-9]*"
                    inputmode="numeric"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="pt-0 pb-0">
                  <v-text-field
                    v-model="dialogTable.postCode.postName"
                    label="Poststed"
                    disabled
                    type="text"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="pt-0 pb-0">
                  <v-text-field
                    v-model="dialogTable.phone"
                    :rules="[
                      () =>
                        dialogTable.phone === undefined ||
                        dialogTable.phone.length === 0 ||
                        dialogTable.phone.length === 8 ||
                        'Telefon nummer må ha 8 tegn',
                    ]"
                    label="Telefon"
                    counter="8"
                    type="text"
                    pattern="[0-9]*"
                    inputmode="numeric"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="pt-0 pb-0">
                  <v-text-field
                    v-model="dialogTable.mobile"
                    :rules="[
                      () => !!dialogTable.mobile || 'Mobilenummer er påkrevd',
                      (dialogTable.mobile &&
                        (dialogTable.mobile.length === 0 ||
                          dialogTable.mobile.length === 8)) ||
                        'Mobil nummer må ha 8 tegn',
                    ]"
                    label="Mobil*"
                    counter="8"
                    type="text"
                    pattern="[0-9]*"
                    inputmode="numeric"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark @click="save(dialogTable)">Lagre</v-btn>
            <v-btn color="grey" text @click="close">Lukk</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <Confirmcode
      :show="showDialogCode"
      :title="titleConfirme"
      :info="infoCode"
      :saveNotification="saveNotification"
      saveTo="confirmeProfileInfo"
      @closeProfile="closeProfile"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import notificationsService from "@/notification.services";
import baseApi from "@/api/api";
import Confirmcode from "@/components/store/NewConfirmcodeDialog";
export default {
  props: ["dialogTable", "show"],
  data: () => ({
    valid: true,
    mouseDown: false,
    showDialogCode: { show: false },
    titleConfirme: "",
    infoCode: "",
    saveNotification: "",
    emailRules: [
      (v) => !!v || "E-post er påkrevd",
      (v) => /.+@.+\..+/.test(v) || "E-post må være gyldig",
    ],
    postCodeRules: [
      (r) => !!r || "Postnr er påkrevd",
      (r) =>
        r
          ? r.length === 4 || "Postnr må inneholde 4 tall"
          : !!r || "Postnr er påkrevd",
    ],
  }),
  watch: {
    "dialogTable.postCode.postCodeText"(value) {
      if (value.length === 4) {
        this.getPostCode(value);
      } else {
        this.dialogTable.postCode.postName = "";
      }
    },
  },
  methods: {
    save(data) {
      let isValid = this.$refs.form.validate();

      if (isValid) {
        baseApi.editUser(data).then((Response) => {
          if (Response.status === 200) {
            //If user ned to confirme e-post/mobile chages.
            if (
              Response.data.confirmCodeSentBySms &&
              Response.data.confirmCodeSentByEmail
            ) {
              this.titleConfirme = "BEKREFT E-POST/MOBILE";
              this.infoCode =
                "Skriv inn bekreftelseskoden vi sendte til deg på e-post/sms.";
              this.saveNotification = "E-post og mobil lagret";
              notificationsService.success("Endring lagret utenom e-post/sms");
              this.showDialogCode.show = true;
            } else if (Response.data.confirmCodeSentBySms) {
              this.titleConfirme = "BEKREFT E-POST";
              this.infoCode =
                "Skriv inn bekreftelseskoden vi sendte til deg på sms.";
              this.saveNotification = "E-post lagret";
              notificationsService.success("Endring lagret utenom e-post");
              this.showDialogCode.show = true;
            } else if (Response.data.confirmCodeSentByEmail) {
              this.titleConfirme = "BEKREFT MOBILE";
              this.saveNotification = "Mobil lagret";
              this.infoCode =
                "Skriv inn bekreftelseskoden vi sendte til deg på e-post.";
              notificationsService.success("Endring lagret utenom sms");
              this.showDialogCode.show = true;
            } else {
              notificationsService.success("Endring lagret");
              this.$store.dispatch("shared/profile");
              this.close();
            }
          }
        });
      } else {
        notificationsService.error("Påkrevde felt må fylles ut!");
      }
    },
    async getPostCode(code) {
      this.dialogTable.postCode.postName = await baseApi.getPostCode(code);
      this.dialogTable.postCode.display =
        code + " " + this.dialogTable.postCode.postName;
    },
    close() {
      this.show.show = false;
    },
    closeProfile(value) {
      if (!value) {
        this.dialogTable.mobile = this.getProfile.mobile;
        this.dialogTable.email = this.getProfile.email;
      }
      this.$store.dispatch("shared/profile", this.dialogTable);
      this.close();
    },
    setMouseDown() {
      this.mouseDown = !this.mouseDown;
    },
    setMouse() {
      this.mouseDown = false;
    },
  },
  computed: {
    ...mapGetters({ profile: "shared/profile" }),
    getProfile() {
      if (this.profile) {
        return this.profile.user;
      }
      return {};
    },
    showDialog: {
      get: function() {
        if (this.show.show) {
          return this.show.show;
        }
        return false;
      },
      set: function() {
        this.close();
      },
    },
  },
  components: {
    Confirmcode,
  },
};
</script>

<style lang="scss" scoped>
.safeAreaDialog {
  padding-top: calc(0px + env(safe-area-inset-top)) !important;
  padding-bottom: calc(0px + env(safe-area-inset-bottom)) !important;
  padding-left: calc(0px + env(safe-area-inset-left)) !important;
  padding-right: calc(0px + env(safe-area-inset-right)) !important;
}
</style>
