<template>
  <v-card :outlined="outlined">
    <v-list class="py-0">
      <v-list-group
        :class="{
          removeMarginIcon: removeMarginToIcon,
          removeMarginPrependIcon: removeMarginPrependIcon,
        }"
        active-class="headerColor"
        :prepend-icon="icon"
        :value="value"
        @click="click"
      >
        <template v-slot:activator>
          <v-list-item-title>{{ title }}</v-list-item-title>
          <slot name="headerInfo"></slot>
        </template>
        <v-list
          :max-height="maxHeigth"
          class="pa-0 cardHeight"
          :class="includeClass ? cardClass : ''"
        >
          <slot></slot>
        </v-list>
      </v-list-group>
    </v-list>
    <slot name="underCard"></slot>
  </v-card>
</template>

<script>
// slots:
// headerInfo: to add more in header
// default: to add info when card is expanded
// UnderCard: to get under card. This vil not hide. (Kan use to add btn under card)
export default {
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    maxHeigth: {
      default: null,
    },
    cardClass: {
      default: "px-3",
    },
    removeMarginToIcon: {
      type: Boolean,
      default: false,
    },
    removeMarginPrependIcon: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
      required: false,
    },
    includeClass: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss">
.cardHeight {
  overflow-y: auto;
}
.removeMarginIcon {
  .v-list-group__header__append-icon {
    min-width: 0 !important;
    margin-left: 0 !important;
  }
}

.removeMarginPrependIcon {
  .v-list-group__header__prepend-icon {
    min-width: 0 !important;
    margin-right: 14px !important;
  }
}
</style>
