<template>
  <div>
    <v-dialog v-model="show" max-width="450">
      <v-card class="text-center py-6">
        <v-card-title
          class="d-block mx-auto"
          :class="{
            'ml-1': isMobileDevice,
            'ml-2': !isMobileDevice
          }"
        >
          <span class="headline primary--text">{{ title }}</span>
        </v-card-title>
        <v-card-text class="pb-0">
          <div
            :class="{
              'ml-1': isMobileDevice,
              'ml-2': !isMobileDevice
            }"
          >
            <span>
              {{ info }}
            </span>
          </div>

          <div class="d-flex">
            <v-text-field
              v-model="code"
              ref="code"
              @input="newNumber(0)"
              :class="{
                'ma-1': isMobileDevice,
                'ma-2': !isMobileDevice,
                'centered-input': true,
                inputNotDisplayUnder: true
              }"
              dense
              filled
              autocomplete="off"
              type="text"
              pattern="[0-9]*"
              inputmode="numeric"
            ></v-text-field>
          </div>
          <div
            :class="{
              'ml-1': isMobileDevice,
              'ml-2': !isMobileDevice
            }"
          >
            <a @click="sendNewCode">Send ny bekreftelseskode</a>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mx-auto"
            outlined
            @click="close"
            v-shortkey.once="['esc']"
            @shortkey="close"
            >Avbryt
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import optionsApi from "@/api/optionsApi";
import api from "@/api/api";
import notificationsService from "@/notification.services";
import { mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    error: {},
    title: {
      type: String,
      required: true
    },
    info: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      code: null
    };
  },
  methods: {
    close() {
      this.show = false;
      this.reset();
      this.$emit("reset", true);
    },
    newNumber(value) {
      if (this.code.length === 6) {
        this.confirmecode();
      }
    },
    async confirmecode() {
      this.$emit("confirmcode", this.code);
      this.reset();
    },
    async sendNewCode() {
      this.$emit("sendNewConfirmcode");
    },
    reset() {
      this.code = null;
      this.$refs.code.focus();
    },
    focusInpute() {
      setTimeout(() => {
        this.$refs["code"].focus();
      }, 100);
    }
  },
  watch: {
    error(value) {
      if (value > 4) {
        notificationsService.error("Feil code for mange ganger. Prøv igjen");
        this.close();
      }
    }
  },
  computed: {
    ...mapGetters({
      isMobileDevice: "shared/isMobileDevice"
    }),
    show: {
      get: function() {
        if (this.value) {
          this.focusInpute();
        }
        return this.value;
      },
      set: function(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style lang="scss">
.inputNotDisplayUnder.v-text-field.v-text-field--enclosed
  .v-text-field__details {
  display: none;
}
</style>
