<template>
  <div v-if="$vuetify.breakpoint.smAndDown" class="pt-2 pb-10">
    <FormattedTextBox
      v-model="houseData.monthlyCost"
      label="Månedlig låne-/leiekostnad"
      suffix="NOK"
      :stepValue="1"
      :showInfo="true"
      :infoText="infoText"
      @showInfo="handelInfo((text = 'monthlyCost'))"
    />

    <FormattedTextBox
      v-model="houseData.otherCost"
      label="Andre boligkostnader pr. mnd."
      suffix="NOK"
      :stepValue="1"
      :showInfo="true"
      :infoText="infoText"
      @showInfo="handelInfo((text = 'otherCost'))"
    />
    <FormattedTextBox
      v-model="houseData.maintanceCost"
      label="Vedlikeholdskostnader pr. mnd."
      suffix="NOK"
      :stepValue="1"
      :showInfo="true"
      :infoText="infoText"
      @showInfo="handelInfo((text = 'maintanceCost'))"
    />
    <v-divider hide-details class="mx-1"></v-divider>
    <v-checkbox
      dense
      hide-details
      v-model="getCheck"
      @change="resetAreaOrPrice"
      label="Jeg vet ikke mitt strømforbruk"
      class="pl-5 pt-4 pb-3"
    ></v-checkbox>
    <FormattedTextBox
      v-model="getAreaOrPrice"
      :label="checkbox ? 'Antall kvadratmeter' : 'Strømforbruk kWt pr mnd'"
      suffix="NOK"
      :stepValue="1"
      :showInfo="true"
      :infoText="infoText"
      :hideDetails="true"
      @showInfo="handelInfo((text = 'powerCost'))"
    />
    <div class="text-right pr-7">
      <span style="font-weight:bold;font-size: 13px;"
        >{{ gethouseMonthlyKwt }}&nbsp;kWt per måned
      </span>
    </div>
    <v-divider hide-details class="mx-1"></v-divider>
    <InfoDialog
      v-model="showInfoDialog"
      :title="getinfoTitle"
      :infoText="infoText"
    />
  </div>

  <div v-else>
    <!-- <div id="info" v-html="infoText" class="text-center" color="primary"></div> -->
    <v-list-item style="top: 0px">
      <v-list-item-content>
        <div class="d-flex justify-start px-2">
          <div id="info" class="text-center px-4 pb-2 pt-3">
            <span v-html="maininfo" style="line-height: 1.6;"></span>
          </div>
        </div>

        <!-- <div class="pt-7">
          <FormattedTextBox
            v-model="houseData.monthlyCost"
            label="Månedlig lånekostnad"
            suffix="NOK"
            :stepValue="1"
            :showInfo="true"
            infoText="Dagens månedlig kostnad for din bosituasjon!"
          />
        </div> -->
        <div class="d-flex justify-start px-2 py-6">
          <v-btn
            class="px-2 mt-6"
            color="primary"
            depressed
            small
            fab
            :ripple="false"
            @click="decrementMonthly"
          >
            <v-icon> mdi-minus </v-icon>
          </v-btn>

          <v-text-field
            v-model="getmonthlyCost"
            dense
            outlined
            :rules="[rules.required, rules.maxlen, rules.isnumer]"
            :maxlength="maxlen"
            hide-details
            label="Månedlig låne-/leiekostnad"
            suffix="NOK"
            class="px-2 flex-grow-1 flex-shrink-0 pt-6 pb-2"
            @keyup.up="onPressUpKey($event, 1)"
            @keyup.down="onPressDownKey($event, 1)"
            @keydown="handelKeydown"
          ></v-text-field>
          <v-btn
            class="px-2 mt-6"
            color="primary"
            depressed
            small
            fab
            :ripple="false"
            :retain-focus-on-click="true"
            @click="incrementMonthly"
          >
            <v-icon> mdi-plus </v-icon>
          </v-btn>
          <div class="mt-6 pt-2 pl-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" hide-details color="blue"
                  >mdi-help-circle-outline</v-icon
                >
              </template>
              Fyll inn månedlige lånekostnader du har på din nåværende bolig.
            </v-tooltip>
          </div>
        </div>
        <div class="pt-2"></div>

        <v-divider></v-divider>

        <!-- <div class="pt-3 pb-1">
          <FormattedTextBox
            v-model="houseData.otherCost"
            label="Andre mnd. boligkostnader"
            suffix="NOK"
            :stepValue="1"
            :showInfo="true"
            infoText="Andre mådelig boligkostnader for dagens bosituasjonen! feks"
          />
        </div> -->
        <div class="d-flex justify-start px-2 py-7">
          <v-btn
            class="px-2 mt-5"
            color="primary"
            depressed
            small
            fab
            :ripple="false"
            @click="decrementother"
          >
            <v-icon> mdi-minus </v-icon>
          </v-btn>

          <v-text-field
            v-model="getotherCost"
            dense
            outlined
            :rules="[rules.required, rules.maxlen, rules.isnumer]"
            :maxlength="maxlen"
            hide-details
            label="Andre boligkostnader pr. mnd."
            suffix="NOK"
            class="px-2 flex-grow-1 flex-shrink-0 pt-5 pb-2"
            @keyup.up="onPressUpKey($event, 2)"
            @keyup.down="onPressDownKey($event, 2)"
            @keydown="handelKeydown"
          ></v-text-field>
          <v-btn
            class="px-2 mt-5"
            color="primary"
            depressed
            small
            fab
            :ripple="false"
            :retain-focus-on-click="true"
            @click="incrementother"
          >
            <v-icon> mdi-plus </v-icon>
          </v-btn>
          <div class="mt-5 pt-2 pl-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" hide-details color="blue"
                  >mdi-help-circle-outline</v-icon
                >
              </template>
              Fyll inn andre faste månedlige kostnader, f.eks fellesutgifter,
              kommunale avgifter osv.
            </v-tooltip>
          </div>
        </div>
        <div class="pt-2"></div>

        <v-divider></v-divider>

        <div class="d-flex justify-start px-2 ">
          <!-- <FormattedTextBox
            v-model="houseData.maintanceCost"
            label="Vedlikehold mnd kostnader"
            suffix="NOK"
            :stepValue="1"
            :showInfo="true"
            :infoText="infoText"
          /> -->
          <v-btn
            class="px-2 mt-6"
            color="primary"
            depressed
            small
            fab
            :ripple="false"
            @click="decrementmaint"
          >
            <v-icon> mdi-minus </v-icon>
          </v-btn>

          <v-text-field
            v-model="getmaintanceCost"
            dense
            outlined
            :rules="[rules.required, rules.maxlen, rules.isnumer]"
            :maxlength="maxlen"
            hide-details
            label="Vedlikeholdskostnader pr. mnd."
            suffix="NOK"
            class="px-2 flex-grow-1 flex-shrink-0 pt-6 pb-2"
            @keyup.up="onPressUpKey($event, 3)"
            @keyup.down="onPressDownKey($event, 3)"
            @keydown="handelKeydown"
          ></v-text-field>
          <v-btn
            class="px-2 mt-6"
            color="primary"
            depressed
            small
            fab
            :ripple="false"
            :retain-focus-on-click="true"
            @click="incrementmaint"
          >
            <v-icon> mdi-plus </v-icon>
          </v-btn>
          <div class="mt-6 pt-2 pl-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" hide-details color="blue"
                  >mdi-help-circle-outline</v-icon
                >
              </template>
              Tall hentet fra SSB og levekårsundersøkelse fra 2017.
              Gjennomsnittlig husholdning i enebolig på 120 kvm.
            </v-tooltip>
          </div>
        </div>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import InfoDialog from "@/components/dialog/InfoDialog.vue";
import FormattedTextBox from "@/components/store/webStore/shared/loanCalculation/children/FormattedTextBox.vue";
export default {
  emits: ["houseKwt"],
  props: {
    houseData: { type: Object, required: true },
    houseMonthlyCost: { type: Number, default: 10000 },
    houseOtherCost: { type: Number, default: null },
    houseMaintanceCost: { type: Number, default: null },
    powerData: { type: Object, required: false, required: false },
  },
  data() {
    return {
      monthlyCost: 0,
      showInfoDialog: false,
      // infoText:
      //   "<p>I vår boligkalkulator kan du sammenlikne hva det vil koste å bo i en ny Trysilhusbolig mot en brukt bolig.</p><a href=”#”>Mer informasjon</a>",
      infoText: "",
      maininfo:
        "I vår boligkalkulator kan du sammenlikne hva det vil koste å bo i en ny Trysilhusbolig mot en brukt bolig. Trysilhus benytter talldata hentet fra SSB og rapport fra Samfunnsøkonomisk analyse som beregningsgrunnlag for vedlikehold og energiforbruk i brukte boliger. Kalkulatoren er kun ment som veiledende for å sammenlikne ny bolig mot brukt bolig.",
      areaOrPrice: 0,
      powerCost: 0,
      text: "",
      title: "",
      checkbox: false,
      rules: {
        required: (value) => !!value || "Må ha en verdi",
        maxlen: (value) =>
          value.length <= 9 || "Verdi bør ikke overstige 9 sifre",
        isnumer: (value) => {
          const pattern = /[^0-9\,\s]+/g;
          return !pattern.test(value) || "Må ha en gyldig verdi";
        },
      },
      maxlen: 10,
    };
  },
  created() {
    this.powerCost = this.powerData.avgCurrentkwtPrice;
    this.areaOrPrice = Number(this.powerData.avgAreaOrPrice);
  },
  computed: {
    data: {
      get() {
        return this.houseData;
      },
      set(value) {
        this.$emit("update:houseData", value);
      },
    },
    getinfoTitle() {
      return this.title;
    },
    getinfoText() {
      return this.infoText;
    },
    getAreaOrPrice: {
      get() {
        return this.areaOrPrice;
      },
      set(value) {
        this.areaOrPrice = value;
        this.$emit("houseKwt", value, this.checkbox);
      },
    },
    getCheck: {
      get() {
        return this.checkbox;
      },
      set(value) {
        this.checkbox = value;
        this.$emit("houseKwt", this.getAreaOrPrice, this.checkbox);
      },
    },
    gethouseMonthlyKwt() {
      let kwt = 0;
      if (this.checkbox) {
        kwt =
          Number(this.areaOrPrice) * Number(this.powerData.avgCurrentkwtPrice); //*12
      } else {
        kwt = this.areaOrPrice;
      }

      //this.$emit("update:houseMonthlyKwt", kwt);
      return this.getFormattedValue(kwt);
    },
    getmaintanceCost: {
      get() {
        return new Intl.NumberFormat("no-No", {
          minimumFractionDigits: 0,
        }).format(Math.round(this.data.maintanceCost));
      },
      set(value) {
        let val = isNaN(value) ? Number(value.replace(/\D/g, "")) : value;
        this.data.maintanceCost = Number(val);
      },
    },
    getotherCost: {
      get() {
        return new Intl.NumberFormat("no-No", {
          minimumFractionDigits: 0,
        }).format(Math.round(this.data.otherCost));
      },
      set(value) {
        let val = isNaN(value) ? Number(value.replace(/\D/g, "")) : value;
        this.data.otherCost = Number(val);
      },
    },
    getmonthlyCost: {
      get() {
        return new Intl.NumberFormat("no-No", {
          minimumFractionDigits: 0,
        }).format(Math.round(this.data.monthlyCost));
      },
      set(value) {
        let val = isNaN(value) ? Number(value.replace(/\D/g, "")) : value;
        this.data.monthlyCost = Number(val);
      },
    },
  },
  methods: {
    getFormattedValue(data) {
      if (isNaN(data)) return data;
      return new Intl.NumberFormat("no-No", {
        minimumFractionDigits: 0,
      }).format(Math.round(data));
    },
    resetAreaOrPrice() {
      this.areaOrPrice = this.checkbox ? Number(this.powerData.newArea) : 1500;
      let pricekwt = this.checkbox
        ? this.areaOrPrice * Number(this.powerData.avgCurrentkwtPrice)
        : this.areaOrPrice;
      this.getAreaOrPrice = this.checkbox ? this.areaOrPrice : pricekwt;
      this.$emit("houseKwt", this.getAreaOrPrice, this.checkbox);
    },
    handelInfo() {
      switch (this.text) {
        case "monthlyCost":
          this.title = "Månedlig kostnad";
          this.infoText =
            "Fyll inn månedlige lånekostnader du har på din nåværende bolig. ";
          break;
        case "otherCost":
          this.title = "Andre månedlig kostnad";
          this.infoText =
            "Fyll inn andre faste månedlige kostnader, f.eks fellesutgifter, kommunale avgifter osv.";
          break;
        case "maintanceCost":
          this.title = "Vedlikehold månedlig kostnad";
          this.infoText =
            "Tall hentet fra SSB og levekårsundersøkelse fra 2017. Gjennomsnittlig husholdning i enebolig på 120 kvm.";
          break;
        case "powerCost":
          this.title = "Strømforbruk per måned";
          this.infoText =
            "Tall hentet fra SSB i 2012. Gjennomsnittlig årlig energiforbruk for eneboliger i størrelse 100-149 kvm.";
          break;
        default:
          this.title = "";
          this.infoText = "";
          break;
      }
      this.showInfoDialog = true;
    },
    decrementMonthly() {
      --this.data.monthlyCost;
    },
    incrementMonthly() {
      ++this.data.monthlyCost;
    },
    decrementother() {
      --this.data.otherCost;
    },
    incrementother() {
      ++this.data.otherCost;
    },
    decrementmaint() {
      --this.data.maintanceCost;
    },
    incrementmaint() {
      ++this.data.maintanceCost;
    },
    onPressUpKey(e, val) {
      if (e.key != "ArrowUp" || this.isMobile) return;
      switch (val) {
        case 1:
          this.incrementMonthly();
          break;
        case 2:
          this.incrementother();
          break;
        case 3:
          this.incrementmaint();
          break;
      }
    },
    onPressDownKey(e, val) {
      if (e.key != "ArrowDown" || this.isMobile) return;
      switch (val) {
        case 1:
          this.decrementMonthly();
          break;
        case 2:
          this.decrementother();
          break;
        case 3:
          this.decrementmaint();
          break;
      }
    },
    handelKeydown(e) {
      if (e.which == 38 || e.which == 40) {
        e.preventDefault();
      }
    },
  },
  components: {
    //YearPicker
    InfoDialog,
    FormattedTextBox,
  },
};
</script>

<style lang="scss" scoped>
// .hstatus {
//   .v-input--selection-controls {
//     margin-top: 4px;
//   }
// }
//
div#f2 {
  border: dotted 1px;
  border-radius: 8px;
}

div#info {
  background-color: rgb(242, 242, 242);
  border: dotted 1px;
  border-radius: 8px;
  //max-height: 50px;
}

.slBtn {
  min-width: 100%;
  .v-slider__thumb {
    &:before {
      opacity: 0;
    }
    width: 30px !important;
    height: 30px !important;
    left: -18px !important;
    transform: translateY(-33%) !important;
    //$slider-thick-border-radius: 4px !important;
    //$slider-border-radius: 8px !important;
  }

  .v-slider--horizontal .v-slider__track-container {
    height: 4px;
    top: 68%;
  }
}
</style>
