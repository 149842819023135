import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600","hide-overlay":_vm.hideOverlay,"fullscreen":_vm.$store.getters['shared/isMobileDevice'] && !_vm.noFullscreen,"transition":"scroll-x-transition","persistent":_vm.persistent},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,{staticClass:"pt-4 pb-8"},[_c(VCardTitle,{staticClass:"primary--text display-1 font-weight-bold",staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardText,{staticClass:"body-1",domProps:{"innerHTML":_vm._s(_vm.DialogMessage)}}),_c(VCardActions,{staticClass:"flex-wrap justify-center justify-sm-start ml-sm-2"},[(!_vm.hideCancelButton)?_c(VBtn,{staticClass:"m-w-100 mb-4 mb-sm-0",attrs:{"depressed":"","color":_vm.acceptButtonColor},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.cancelButtonText))]):_vm._e(),_c(VBtn,{staticClass:"m-w-100 ml-0 ml-sm-2",attrs:{"depressed":"","outlined":"","color":"grey"},on:{"click":_vm.confirm}},[_vm._v(" "+_vm._s(_vm.acceptButtonText)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }