<template>
  <div class="mb-4">
    <h4 class="darkgrey--text">
      Tilbudet gis i henhold til følgende dokumentasjon som er fremlagt:
    </h4>
    <div
      class="conditions-checkbox my-1"
      v-for="(link, index) in links"
      :key="index"
    >
      <span
        :class="{ 'error--text': isNotValid }"
        class="condition-index d-none d-sm-block"
        >{{ index + 1 }}</span
      >
      <span>
        <h4>
          <a @click="openLink(links, index)">{{ link.name }}</a>
        </h4>
      </span>
    </div>
    <hr class="my-3" />
    <div class="conditions-checkbox my-1">
      <span>
        <h4>
          Jeg bekrefter at jeg har lest gjennom vilkår og godkjenner innholdet.
        </h4>
      </span>
      <v-spacer></v-spacer>
      <v-checkbox
        class="condition-checkbox"
        hide-details
        v-model="linksConfirm"
        color="primary"
        :rules="[v => !!v]"
        :required="true"
        @click="isChanged = true"
      ></v-checkbox>
    </div>
    <small v-if="isNotValid" class="pl-2 error--text">
      vilkår må godkjennes!
    </small>
  </div>
</template>

<script>
import purchaseApi from "@/api/purchaseApi";
import files from "@/helpers/files.js";
import FileSaver from "file-saver";
export default {
  props: {
    value: {
      required: true
    },
    isValidating: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      linksConfirm: false,
      isChanged: false
    };
  },
  methods: {
    async openLink(data, index) {
      await purchaseApi.purchaseDownloadDocument(data[index]).then(response => {
        var blob = files.b64toBlob(
          response.documentBytes,
          response.contentType
        );
        FileSaver.saveAs(blob, response.fileName);
        data[index].linkRead = true;
        this.$set(data, index, data[index]);
      });
    }
  },
  computed: {
    links: {
      get() {
        return this.value;
      },
      set(data) {
        this.$emit("input", data);
      }
    },
    isNotValid() {
      if ((this.isValidating || this.isChanged) && !this.linksConfirm) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style lang="scss">
.conditions-checkbox {
  display: flex;
  align-items: center;
  padding: 0.1rem 0.5rem;
  .condition-index {
    font-size: 1.5rem;
    color: var(--v-darkgrey-base);
    font-weight: bold;
    margin-right: 1.5rem;
  }
  h4 {
    color: var(--v-darkgrey-base);
    a {
      color: var(--v-primary-base);
      text-decoration: underline;
    }
  }
  .condition-checkbox {
    margin: 0;
    padding-top: 0;
    .v-input--selection-controls__input {
      margin-right: 0;

      i {
        font-size: 1.5rem;
      }
    }
  }
  &:hover {
    background-color: var(--v-lightgrey-base);
  }
}
.linkConfirmed {
  background-color: var(--v-primary-base);
  .condition-index {
    color: white;
  }
  h4 {
    color: white;
    a {
      color: white;
    }
  }
  &:hover {
    background-color: var(--v-primary-base);
  }
}
</style>
