<template>
  <div>
    <v-list color="grey lighten-4" class="py-0">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ title }}:</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list class="py-0" v-for="(reservation, index) in data" :key="index">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            >Frist forbehold:
            {{ reservation.deadline | moment("DD.MM.YYYY") }}</v-list-item-title
          >
          <v-list-item-subtitle
            v-html="reservation.text"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      required: true
    },
    title: {
      type: String
    }
  }
};
</script>
