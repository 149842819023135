<template>
  <div>
    <Cart
      v-if="!readOnly"
      :changedCart="changedCart"
      :cartInfo="storeCart"
      :getCartGroups="storeCart"
      @change="change"
    />
    <v-row class="justify-center" v-if="getInfo.length > 0">
      <v-col sm="10" xl="9">
        <h2>Tilvalg</h2>
        <v-divider class="primary mb-4"></v-divider>

        <!-- <v-row>
          <v-col sm="6" xl="4" v-for="n in getInfo" :key="n.id">
            <OptionsCard
              :data="n"
              :readOnly="readOnly"
              @addDeleteFromCart="addDeleteFromCart"
              @showImg="showImg"
              @expired="expired"
              @overlayMouseover="overlayMouseover"
              @overlayMouseleave="overlayMouseleave"
            />
          </v-col>
        </v-row> -->

        <v-row>
          <v-col sm="6" xl="4" v-for="(n, index) in getInfo" :key="index">
            <OptionsCardNew
              :data="n"
              :readOnly="readOnly"
              :expired="expired(n)"
              @showImg="showImg"
              @addToCart="addDeleteFromCart"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="justify-center ma-0" v-if="getAddon.length > 0">
      <v-col sm="10" xl="9">
        <h2>Tillegg</h2>
        <v-divider class="primary mb-4"></v-divider>
        <v-row v-if="getAddon.length > 0">
          <v-col sm="6" xl="4" v-for="n in getAddon" :key="n.id">
            <!-- <OptionsCard
              :data="n"
              :readOnly="readOnly"
              addon
              @removeFromCart="removeFromCart"
              @addToCart="addToCart"
              @showImg="showImg"
              @expired="expired"
              @overlayMouseover="overlayMouseover"
              @overlayMouseleave="overlayMouseleave"
            /> -->

            <OptionsCardNew
              :data="n"
              :readOnly="readOnly"
              :expired="expired(n)"
              @showImg="showImg"
              @addToCart="addToCart"
              @removeFromCart="removeFromCart"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- <DialogImage :show="showImage" :data="imgData" :info="infoimage" /> -->
    <DialogShowImage v-model="showImage" :title="imgTitle" :url="imgUrl" />
    <AcceptCancel
      v-model="showChangeFromConfirmed"
      title="Endre tilvalg til standard"
      DialogMessage="Er du sikker på at du vil endre tilvalg til standard?"
      :responsData="responsData"
      @confirme="changeCart"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import DialogImage from "@/components/dialog/DialogImage";
import DialogShowImage from "@/components/dialog/DialogShowImage";
import optionsService from "@/options.services";
import Cart from "@/components/store/Cart";
import AcceptCancel from "@/components/dialog/AcceptCancel";
import moment from "moment";

// import OptionsCard from "@/components/store/options/options/children/OptionsCard";
import OptionsCardNew from "@/components/store/webStore/options/children/OptionsCard";
export default {
  data() {
    return {
      data: null,
      id: null,
      title: null,
      dataAddon: null,
      optionsService,
      showImage: false,
      imgTitle: "",
      imgUrl: null,
      imgData: null,
      optionalId: null,
      addonCart: [],
      changedCart: [],
      cart: null,
      infoimage: null,
      showChangeFromConfirmed: false,
      responsData: null
    };
  },
  methods: {
    ...mapActions({
      saveToCart: "options/saveToCart",
      deleteCart: "options/deleteCart"
      // setStoreOptions: "options/storeOptions"
    }),

    addDeleteFromCart(data) {
      //When change to is standard and some options is confirmed. Then show confirm dialog
      if (
        (data.type === 1 || data.selected) &&
        optionsService.confirmedOptions(this.sectionConfirme.options)
      ) {
        this.showChangeFromConfirmed = true;
        this.responsData = data;
      } else {
        this.changeCart(data);
      }
    },
    changeCart(data) {
      if (!this.optionalId) {
        this.optionalId = this.storeOptions.optionalId;
      }
      //Remove selected from cart and replase with confirmed if has confirmed else select standard.
      if (this.optionalId === data.id) {
        let confirmed = null;
        if (!data.confirmedDate && data.type !== 3) {
          confirmed = this.getInfo.find(x => {
            return x.confirmedDate;
          });
        }
        if (!confirmed) {
          var standard = this.getInfo.find(x => {
            return x.type === 1;
          });
          this.optionalId = standard.id;
        } else {
          this.optionalId = confirmed.id;
        }
      } else {
        this.optionalId = data.id;
      }
      this.save();
    },
    save() {
      let addon = [];
      if (this.addonCart) {
        addon = this.addonCart.map(x => {
          return { id: x.id };
        });
      }

      if (!this.optionalId) {
        this.optionalId = this.storeOptions.optionalId;
      }

      const options = this.optionalId
        ? [...[{ id: this.optionalId }], ...addon]
        : [...addon];
      const saveData = { id: parseInt(this.$route.params.id), options };
      this.addonCart = [];
      this.saveToCart(saveData);
      //Get data again when somthing is added to cart
      // this.setStoreOptions(this.$route.params.id);
    },
    // showImg({ img, info }) {
    //   debugger;
    //   this.infoimage = info;
    //   this.imgData = img;
    //   this.showImage.show = true;
    // },
    showImg({ url, data }) {
      this.imgTitle = data.name;
      this.imgUrl = url;
      this.showImage = true;
    },
    //Adding addon to cart
    addToCart(data) {
      this.addonCart.push(data);
      this.save();
    },
    //Removing addon form cart
    removeFromCart(data) {
      data.categoryId = this.$route.params.id;
      this.deleteCart(data);
      //Get data again when somthing is removed to cart
      // this.setStoreOptions(this.$route.params.id);
    },
    //Overlay on when hower over card
    overlayMouseleave(data) {
      this.$set(data, "overlay", false);
    },
    //Overlay off when hower over card
    overlayMouseover(data) {
      this.$set(data, "overlay", true);
    },
    openCartDialog() {
      this.$emit("openCartDialog", !this.drawer);
    },
    change(data) {
      this.data = null;
      this.addonCart = [];
      this.optionalId = null;
      this.$emit("change", data);
    },
    expired(data) {
      if (data.deadline !== undefined) {
        const disabled = moment(
          moment()
            .subtract(1, "days")
            .format()
        ).isAfter(data.deadline);
        if (!disabled) {
          return false;
        }
      }
      return true;
    }
  },
  computed: {
    ...mapGetters({
      sectionConfirme: "options/sectionConfirme",
      storeCart: "options/cart",
      storeOptions: "options/storeOptions",
      readOnly: "options/readOnly",
      optionsAvailable: "shared/optionsAvailable"
    }),
    getInfo() {
      if (this.storeOptions.length !== 0) {
        return this.storeOptions.dataOptions;
      }
      return {};
    },
    getAddon() {
      if (this.storeOptions.length !== 0) {
        return this.storeOptions.dataAddon;
      }
      return [];
    }
  },
  components: {
    DialogShowImage,
    Cart,
    AcceptCancel,
    // OptionsCard,
    OptionsCardNew
  }
};
</script>
