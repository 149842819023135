<template>
  <v-row v-if="colorAvailable" class="justify-center">
    <v-col class="pt-0" xl="9">
      <Cart :cartInfo="colorCart" @reset="deleteFromCart" />
      <div id="cart-bg" :class="{ cartbgToggle: openCart }"></div>

      <v-row justify="center">
        <v-col class="order-0" cols="12" md="11" lg="10" xl="12">
          <v-list color="transparent">
            <v-list-item class="px-0 mb-2">
              <v-list-item-avatar color="primary">
                <v-icon dark>mdi-format-paint</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  class="primary--text headline font-weight-black"
                  >Fargevalg
                </v-list-item-title>
                <v-list-item-subtitle
                  >Siste frist:
                  {{
                    colorAvailableInfo.deadline | moment("DD.MM.YYYY")
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action class="d-none d-md-flex flex-row">
                <v-btn
                  v-if="colorCart.confirmed"
                  :disabled="disabledResetToStandard"
                  class="mr-3"
                  depressed
                  >Tilbakestill til bekreftet</v-btn
                >

                <v-btn
                  @click="resetToStandard"
                  :disabled="disabledDeleteAll"
                  depressed
                  >Tilbakestill til Standard</v-btn
                >
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
        </v-col>
        <v-col cols="12" md="11" lg="10" xl="12" class="order-2 order-md-1">
          <MainColorRoom
            v-model="getMainColor"
            :disabledReset="getMainColor.isConfirmed || !getMainColor.price"
            @reset="deleteFromCart"
            @changeColor="changeColor"
          />

          <ColorPicker
            v-model="showColorDialog"
            :data="getMainColor"
            @addToCart="addToCart"
          />
        </v-col>
        <v-col cols="12" md="5" lg="5" xl="6" class="order-1 order-md-2">
          <v-img
            v-if="profile || albumUserAuth"
            max-height="750"
            contain
            :lazy-src="
              optionsService.getSectionPlanImage(
                profile ? profile.user.currentSection.id : null,
                albumUserAuth
              )
            "
            :src="
              optionsService.getSectionPlanImage(
                profile ? profile.user.currentSection.id : null,
                albumUserAuth
              )
            "
          ></v-img>
          <v-skeleton-loader
            v-else
            ref="skeleton"
            height="500"
            class="mx-auto imageLoader"
            type="image"
          />
          <div class="d-block d-md-none mt-3">
            <v-btn
              block
              v-if="colorCart.confirmed"
              @click="resetToConfirmed"
              :disabled="disabledResetToStandard"
              class="mr-3"
              depressed
              >Tilbakestill til bekreftet</v-btn
            >

            <v-btn
              class="mt-3"
              block
              @click="resetToStandard"
              :disabled="disabledDeleteAll"
              depressed
              >Tilbakestill til Standard</v-btn
            >
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="5" xl="6" class="order-3">
          <p class="mb-4 caption text-center text-sm-right">
            Pris per nye farge du velger i boligen er kr. 2.000. Pris per
            kontrastvegg er kr. 2.500 uansett farge.
          </p>
          <ColorRoomsList
            v-model="getColorRooms"
            @addToCart="addToCart"
            @reset="deleteFromCart"
          />
        </v-col>
      </v-row>

      <!-- color option on loft -->
      <v-row v-if="getColorRoomsAttic.length > 0" justify="center">
        <v-col cols="12" lg="10" xl="12">
          <v-list color="transparent">
            <v-list-item class="px-0 mb-2">
              <v-list-item-avatar color="primary">
                <v-icon dark>mdi-format-paint</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  class="primary--text headline font-weight-black"
                  >Fargevalg loft
                </v-list-item-title>
                <v-list-item-subtitle
                  >Siste frist:
                  {{
                    colorAvailableInfo.deadline | moment("DD.MM.YYYY")
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
        </v-col>

        <v-col cols="12">
          <v-alert class="mb-0" color="info" text>
            Plantegningen på loft kan variere. Når du skal bestille farge, må du
            følge plantegningen for din leilighet som du finner i
            kontraktspermen.
          </v-alert>
        </v-col>
        <v-col cols="12" sm="5" lg="5" xl="6">
          <v-img
            v-if="profile || albumUserAuth"
            max-height="750"
            contain
            :lazy-src="
              optionsService.getSectionPlanImage(
                profile ? profile.user.currentSection.id : null,
                albumUserAuth,
                true
              )
            "
            :src="
              optionsService.getSectionPlanImage(
                profile ? profile.user.currentSection.id : null,
                albumUserAuth,
                true
              )
            "
          ></v-img>
          <v-skeleton-loader
            v-else
            ref="skeleton"
            height="500"
            class="mx-auto imageLoader"
            type="image"
          />
        </v-col>
        <v-col cols="12" sm="7" lg="5" xl="6">
          <ColorRoomsList
            v-model="getColorRoomsAttic"
            @addToCart="addToCart"
            @reset="deleteFromCart"
          />
        </v-col>
      </v-row>
      <AcceptCancel
        v-model="showAccept"
        :title="dialogTitle"
        :DialogMessage="dialogMessage"
        @confirme="deleteAllFromCart"
      />
    </v-col>
  </v-row>
</template>
<script>
import ChangeOptions from "@/components/store/ChangeOptions";
import Cart from "@/components/store/color/ColorCart";
import MainColorRoom from "@/components/store/color/MainColorRoom";
import ColorRoomsList from "@/components/store/color/ColorRoomsList";
import AcceptCancel from "@/components/dialog/AcceptCancel";
import optionsService from "@/options.services";
import { mapGetters, mapActions } from "vuex";
import api from "@/api/colorApi";
import commonApi from "@/api/commonApi";
import moment from "moment";
import ColorPicker from "@/components/store/color/ColorPicker";

export default {
  data() {
    return {
      showAccept: false,
      optionsService,
      openCart: false,
      dialogTitle: "",
      dialogMessage: "",
      resetTo: null,
      albumUserAuth: null,
      showColorDialog: false
    };
  },
  async created() {
    if (this.$route.name === "colorsOpen") {
      await commonApi
        .isAlbumUser()
        .then(response => {
          if (!response) {
            this.$router.push({ name: "home" });
          }
        })
        .catch(error => {
          error.response;
          this.$router.push({ name: "home" });
        });

      this.albumUserAuth = localStorage.getItem("authorization");
    }
  },
  methods: {
    ...mapActions({
      setColorCart: "color/colorCart",
      updateColorCart: "color/updateColorCart",
      deleteColorCart: "color/deleteColorCart",
      deleteAllColorCart: "color/deleteAllColorCart"
    }),
    async addToCart(item) {
      this.updateColorCart(this.colorOptions);
    },
    deleteFromCart(data) {
      this.deleteColorCart(data);
    },

    deleteAllFromCart() {
      this.deleteAllColorCart(this.resetTo);
    },
    resetToStandard() {
      this.resetTo = "standard";
      this.dialogTitle = "Tilbakestille alle valgte farger";
      this.dialogMessage =
        "Er du sikker på at du vil tilbakstille fargene til standard?";
      this.showAccept = true;
    },
    resetToConfirmed() {
      this.resetTo = "confirmed";
      this.dialogTitle = "Tilbakestille alle valgte farger";
      this.dialogMessage =
        "Er du sikker på at du vil tilbakstille alle fargene til bekreftede farger?";
      this.showAccept = true;
    },
    changeColor() {
      this.showColorDialog = true;
    }
  },
  computed: {
    ...mapGetters({
      colorAvailable: "shared/colorAvailable",
      colorOptions: "color/colorOptions",
      readOnly: "options/readOnly",
      profile: "shared/profile",
      colorCart: "color/colorCart",
      colorAvailableInfo: "shared/colorAvailableInfo"
    }),
    getMainColor() {
      if (this.colorOptions) {
        var main = this.colorOptions.find(x => x.id === -1);
        return main;
      }
      return { loading: true };
    },
    getColorRooms() {
      if (this.colorOptions) {
        var data = this.colorOptions.filter(x => x.id >= 0 && !x.isOnAttic);
        return data;
      }
      return { loading: true };
    },
    getColorRoomsAttic() {
      if (this.colorOptions) {
        var data = this.colorOptions.filter(x => x.id >= 0 && x.isOnAttic);
        return data;
      }
      return [];
    },
    disabledDeleteAll() {
      //if some color is confirmed
      if (this.colorCart.confirmed) {
        if (this.colorCart.changes === this.colorCart.cartConfirmed.length) {
          return true;
        }
        return false;
        //If not confirmed. Check if some changes in cart
      } else if (this.colorCart.cart.length > 0) {
        return false;
      }
      return true;
    },

    disabledResetToStandard() {
      //if some color is confirmed
      if (this.colorCart.confirmed) {
        //Can reset if it is some changes
        if (this.colorCart.changes > 0) {
          return false;
        }
        return true;
        //If not confirmed. Check if some changes in cart
      }
      return true;
    }

    // cartInfo() {
    //   if (this.cart.length !== 0) {
    //     this.getSelected();
    //     return this.cart;
    //   }
    //   return 0;
    // },
    // getCartGroups() {
    //   if (this.cart.length !== 0) {
    //     return this.cart;
    //   }
    //   return {};
    // }
  },
  components: {
    Cart,
    MainColorRoom,
    ColorRoomsList,
    AcceptCancel,
    ColorPicker
  }
};
</script>
<style lang="scss">
// skeleton loader
.imageLoader .v-skeleton-loader__image {
  height: 750px !important;
}
</style>
