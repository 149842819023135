import store from "@/store/index";
// import notification from "@/notification.services";
import router from "@/router";

function setTimeline(to, from, next) {
  if (store.getters["saveData/timeline"].length === 0) {
    if (store.getters["shared/profile"]) {
      store
        .dispatch(
          "saveData/timeline",
          store.getters["shared/profile"].user.currentSection
        )
        .then(next());
    } else {
      store.watch(
        (state, getters) => getters["shared/profile"],
        profile => {
          store
            .dispatch("saveData/timeline", profile.user.currentSection)
            .then(next());
        }
      );
    }
  } else {
    next();
  }
}
function setDocuments(to, from, next) {
  if (store.getters["saveData/documents"].length === 0) {
    store.dispatch("saveData/documents").then(next());
  } else {
    next();
  }
}

function optionsOrColorIsOpen(to, from, next) {
  if (store.getters["shared/optionsReady"] === null) {
    store.dispatch("shared/optionsReady").then(() => {
      if (store.getters["shared/optionsReady"]) {
        setOptionsData(to, from, next);
      } else {
        router.push({ name: "notavailable" });
      }
    });
  } else if (store.getters["shared/optionsReady"]) {
    setOptionsData(to, from, next);
  } else {
    router.push({ name: "notavailable" });
  }
}

function optionsIsOpen(to, from, next) {
  if (store.getters["shared/optionsAvailable"] === null) {
    store.dispatch("shared/optionsReady").then(() => {
      if (store.getters["shared/optionsAvailable"]) {
        setOptionsData(to, from, next);
      } else {
        router.push({ name: "StoreInfo" });
      }
    });
  } else if (store.getters["shared/optionsAvailable"]) {
    setOptionsData(to, from, next);
  } else {
    router.push({ name: "StoreInfo" });
  }
}
function setOptionsData(to, from, next) {
  //When user se on options and go back. Then it clears options.
  //Add this to read only to

  if (from.name === "storeOptions" || from.name === "storeOptionsOpen") {
    store.dispatch("options/removeStoreOptions");
  }
  var readOnly = false;
  if (to.name === "storeOpen" || to.name === "storeOptionsOpen") {
    readOnly = true;
  }
  //Set readOnly on storeOpen and StoreOptionsOpen
  store.dispatch("options/readOnly", readOnly);
  //If not store options then get options data
  if (to.name !== "storeOptions" && to.name !== "storeOptionsOpen") {
    // if (
    //   store.getters["options/cart"].length === 0 &&
    //   store.getters["options/categories"].length === 0 &&
    //   store.getters["options/sectionConfirme"]
    // ) {
    //   store.dispatch("options/OptionsData").then(next());
    // } else {
    next();
    // }
  } else {
    //Check if i have options data
    //Get storeOptions any

    if (
      store.getters["options/cart"].length === 0 &&
      store.getters["options/categories"].length === 0 &&
      store.getters["options/sectionConfirme"]
    ) {
      store.dispatch("options/storeOptions", to.params.id).then(next());
    } else {
      store.dispatch("options/storeOptions", to.params.id).then(next());
    }
  }
  // } else {
  //   notification.error("Ingen adgang");
  //   router.push({ name: "home" });
  // }
}
function setComplaints(to, from, next) {
  setViewRequests();
  if (store.getters["request/complaintsCustomer"].length === 0) {
    store.dispatch("request/complaints").then(next());
  } else {
    next();
  }
}
function setViewRequests() {
  const view = JSON.parse(localStorage.getItem("viewRequests"));
  if (view) {
    store.dispatch("request/changeViewRequests", view);
  }
}
// function setSectionConfirme(to, from, next) {
//   if (store.getters["options/sectionConfirme"]) {
//     store.dispatch("options/sectionConfirme").then(next());
//   } else {
//     next();
//   }
// }

function setColor(to, from, next) {
  // var test = store.getters["shared/colorAvailable"];
  if (store.getters["shared/colorAvailable"] === null) {
    store.dispatch("shared/optionsReady").then(() => {
      if (
        store.getters["shared/colorAvailable"] &&
        store.getters["shared/colorReady"]
      ) {
        // if (store.getters["color/colorCart"].loading) {
        // store.dispatch("color/color").then(next());
        // } else {
        next();
        // }
      } else {
        router.push({ name: "StoreInfo" });
      }
    });
  } else if (
    store.getters["shared/colorAvailable"] &&
    store.getters["shared/colorReady"]
  ) {
    // if (store.getters["color/colorCart"].loading) {
    // store.dispatch("color/color").then(next());
    // } else {
    next();
    // }
  } else {
    router.push({ name: "StoreInfo" });
  }
}

function optionsOrColorReady(to, from, next) {
  if (store.getters["shared/optionsReady"] === null) {
    store.dispatch("shared/optionsReady").then(() => {
      if (store.getters["shared/optionsReady"]) {
        setColorAndOptionsSummary(to, from, next);
      } else {
        router.push({ name: "notavailable" });
      }
    });
  } else if (store.getters["shared/optionsReady"]) {
    setColorAndOptionsSummary(to, from, next);
  } else {
    router.push({ name: "notavailable" });
  }
}

function setColorAndOptionsSummary(to, from, next) {
  if (store.getters["color/colorCart"].loading) {
    store.dispatch("color/color").then(next());
  } else {
    next();
  }
}

function setColorOpen(to, from, next) {
  if (store.getters["shared/colorAvailable"] === null) {
    store.dispatch("shared/colorReady").then(() => {
      next();
    });
  } else if (store.getters["shared/colorAvailable"]) {
    next();
  } else {
    router.push({ name: "StoreInfo" });
  }
}

export default {
  setTimeline,
  setDocuments,
  optionsOrColorIsOpen,
  optionsIsOpen,
  setOptionsData,
  setComplaints,
  // setSectionConfirme,
  setColor,
  setColorOpen,
  optionsOrColorReady,
  setColorAndOptionsSummary
};
