<template>
  <div>
    <div
      class="mt-8 d-sm-flex align-center justify-space-between text-center text-sm-right"
    >
      <p class="d-sm-none mb-4">
        Totalpris:
        {{ optionsService.formatPrice(value) }} ,-
      </p>
      <v-btn class="m-w-100" depressed @click="downloadReceipt" color="primary"
        >Last ned kvittering
      </v-btn>
      <p class="d-none d-sm-block mb-0">
        Totalpris:
        {{ optionsService.formatPrice(value) }} ,-
      </p>
    </div>
  </div>
</template>

<script>
import optionsService from "@/options.services";
export default {
  props: {
    value: {
      default: 0
    }
  },
  data() {
    return {
      optionsService
    };
  },
  methods: {
    downloadReceipt() {
      this.$emit("downloadReceipt");
    }
  }
};
</script>
