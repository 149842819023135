import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRating } from 'vuetify/lib/components/VRating';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"flex-column flex-sm-row"},[_vm._l((_vm.data),function(inquiryItem,index){return _c(VCol,{key:inquiryItem.id,staticClass:"mt-4",attrs:{"sm":"6","lg":"4"}},[_c(VCard,{staticClass:"theme-shadow request-card",attrs:{"min-height":"160","hover":""},on:{"click":function($event){return _vm.inquiry(index, inquiryItem.id)}}},[(inquiryItem.regStatus === 'Lukket')?_c(VOverlay,{staticClass:"request-card-overlay",attrs:{"absolute":"","opacity":"0.93","value":_vm.getOverlay[index]}},[_c(VBtn,{staticClass:"clickyCard-overlay-closeBtn",attrs:{"fab":"","x-small":"","color":"white"},on:{"click":function($event){return _vm.showOverlay(index)}}},[_c(VIcon,{staticClass:"red--text"},[_vm._v("mdi-window-close")])],1),_c('h3',[_vm._v("Vurder min sak")]),_c('p',[_vm._v(" Hjelp oss forbedre vår kundeservice ved å gi oss din tilbakemelding ")]),_c(VRating,{attrs:{"background-color":"grey","color":"yellow accent-4","hover":"","size":"20"},model:{value:(inquiryItem.customerRating),callback:function ($$v) {_vm.$set(inquiryItem, "customerRating", $$v)},expression:"inquiryItem.customerRating"}})],1):_vm._e(),_c(VRow,[_c(VCol,{staticClass:"card-icon text-center",class:{
            'closed-case':
              inquiryItem.regStatus === 'Lukket' && _vm.$route.name === 'requests'
          },attrs:{"cols":"4"}},[_c(VBadge,{staticClass:"comments",attrs:{"overlap":"","color":"green","value":inquiryItem.newCommentsCount > 0
                ? inquiryItem.newCommentsCount
                : false},scopedSlots:_vm._u([(inquiryItem.newCommentsCount > 0)?{key:"badge",fn:function(){return [_vm._v(" "+_vm._s(inquiryItem.newCommentsCount)+" ")]},proxy:true}:null],null,true)},[_c(VBtn,{attrs:{"color":"white","elevation":"2","fab":"","x-small":""}},[_c(VIcon,{class:{
                  'yellow--text': inquiryItem.commentsCount > 0,
                  'lightgrey--text': inquiryItem.commentsCount === 0
                }},[_vm._v("mdi-message-reply-text")])],1)],1),_c(VIcon,{staticClass:"pb-6 icon request-type-icon"},[_vm._v(" "+_vm._s(inquiryItem.isInquiry ? "mdi-headset" : " mdi-clipboard-alert-outline")+" ")]),_c('small',{staticClass:"status"},[_vm._v(" "+_vm._s(inquiryItem.isInquiry ? "Generell henvendelse" : "Reklamasjons-henvendelse")+" ")]),(
              inquiryItem.regStatus === 'Lukket' &&
                !inquiryItem.customerRating &&
                _vm.$route.name === 'requests'
            )?_c(VBtn,{staticClass:"rateInquiryBadge white--text rating-btn text-uppercase mt-2",attrs:{"rounded":"","x-small":"","elevation":"0","color":"orange"},on:{"click":function($event){return _vm.showOverlay(index)}}},[_vm._v(" Vurder "),_c('i',{staticClass:"fas fa-check ml-1"})]):_vm._e(),(
              inquiryItem.regStatus === 'Lukket' &&
                inquiryItem.customerRating &&
                _vm.$route.name === 'requests'
            )?_c(VRating,{staticClass:"request-card-rating",attrs:{"dense":"","background-color":"grey","color":"yellow accent-4","hover":"","readonly":"","size":"13"},model:{value:(inquiryItem.customerRating),callback:function ($$v) {_vm.$set(inquiryItem, "customerRating", $$v)},expression:"inquiryItem.customerRating"}}):_vm._e()],1),_c(VCol,{attrs:{"cols":"8"}},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_c('h3',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(inquiryItem.name)+" ")])])],1)],1),_c(VDivider),_c('ul',[_c('li',{staticClass:"mt-1",class:{
                  'green--text': _vm.statusGreen(inquiryItem)
                }},[_c(VIcon,{class:{
                    'green--text': _vm.statusGreen(inquiryItem)
                  }},[_vm._v("mdi-folder-sync-outline")]),_vm._v(" "+_vm._s(inquiryItem.regStatus)+" ")],1),_c('li',[_c(VIcon,[_vm._v("mdi-folder-pound-outline")]),_vm._v(" Saksnr: "+_vm._s(inquiryItem.lineId)+" ")],1),_c('li',[_c(VIcon,[_vm._v("mdi-folder-search-outline")]),_vm._v(" Kilde: "+_vm._s(inquiryItem.source)+" ")],1)])],1)],1)],1)],1)],1)}),(_vm.profile && _vm.profile.user.isOwnerToday)?_c(VCol,{staticClass:"pt-4",attrs:{"sm":"6","lg":"4"}},[_c(VCard,{staticClass:"theme-shadow clickyCard",attrs:{"ripple":"","hover":""},on:{"click":_vm.newRequest}},[_c(VImg,{attrs:{"src":require('@/assets/imgs/gfx/add_inquiry.svg'),"height":"175"}})],1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }