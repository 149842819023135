<template>
  <div>
    <ColorCheckoutContainer />
  </div>
</template>
<script>
import ColorCheckoutContainer from "@/components/store/color/ColorCheckoutContainer";
export default {
  components: {
    ColorCheckoutContainer
  }
};
</script>
