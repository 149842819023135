<template>
  <div>
    <div class="sidemenuWrapper" :class="{ sidemenuToggle: openCart }">
      <div class="sidemenu-btns">
        <v-badge v-if="!openCart" overlap color="yellow">
          <template v-slot:badge>
            {{
              sectionConfirme.options.length > 0
                ? countChangeOfConfime
                : cartInfo.cartCount
            }}
          </template>
          <v-btn
            class="mb-0 mb-sm-4"
            fab
            :color="'green'"
            @click="toggleSidemenu"
          >
            <v-icon class="white--text">mdi-cart</v-icon>
          </v-btn>
        </v-badge>

        <v-btn @click="toggleSidemenu" fab color="red" v-else>
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>

        <v-btn
          v-if="!$store.getters['shared/isMobileDevice']"
          @click="help"
          fab
          color="blue"
          class="infoBtn"
          :class="{ activeInfoBtn: showDialog.show === true }"
        >
          <v-icon color="white">mdi-help</v-icon>
        </v-btn>
      </div>

      <v-card tile flat class="cart-container">
        <v-list class="py-0">
          <v-list-item>
            <v-list-item-content style="max-width: 45%;">
              <v-list-item-title
                class="subtitle font-weight-bold text-uppercase"
                >Handlekurv</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-content>
              <v-btn
                :disabled="CartIsEmpty()"
                @click="confirmeCart"
                color="yellow"
                small
                depressed
                >Bekreft bestilling</v-btn
              >
            </v-list-item-content>
          </v-list-item>
          <div class="pl-4">
            Totalpris tilvalg:
            {{ format.formatToNok(cartInfo.cartTotPrice) }}
          </div>
        </v-list>

        <v-list
          v-if="
            optionsService.summaryIsEmty(getCartGroups) ||
              sectionConfirme.options.length > 0
          "
          class="cart-list-container pt-0"
        >
          <div v-if="!optionsService.confirmedOptions(sectionConfirme.options)">
            <div v-for="itemCart in getCartGroups.groups" :key="itemCart.id">
              <div v-if="itemCart.show">
                <v-card-subtitle class="pb-0">
                  {{ itemCart.name }}
                  <span>
                    -
                    <span
                      :class="{
                        deadlineHasBeen:
                          itemCart.deadline &&
                          deadlineHasBeen(itemCart.deadline)
                      }"
                    >
                      {{ itemCart.deadline | moment("DD.MM.YYYY") }}
                    </span>
                    <v-icon
                      v-if="
                        itemCart.deadline && deadlineHasBeen(itemCart.deadline)
                      "
                      small
                      color="warning"
                    >
                      mdi-calendar-alert
                    </v-icon>
                  </span>
                </v-card-subtitle>
                <div
                  v-for="categories in itemCart.categories"
                  :key="categories.id"
                >
                  <div v-for="options in categories.options" :key="options.id">
                    <v-list-item
                      v-if="options.cartItemId && options.type !== 1"
                      class="cart-list"
                      two-line
                    >
                      <v-list-item-avatar
                        class="pointer"
                        @click="change(categories)"
                      >
                        <v-img
                          :src="optionsService.getOptionImage(options)"
                        ></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content
                        class="pointer"
                        @click="change(categories)"
                      >
                        <v-list-item-title>
                          {{ options.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ format.formatToNok(options.price) }},-inkludert mva
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-btn
                        text
                        fab
                        dark
                        small
                        color="red"
                        @click="deleteFromCart(options, categories.id)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-list-item>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="optionsService.confirmedOptions(sectionConfirme.options)">
            <v-card-subtitle>Bekreftet tilvalg</v-card-subtitle>

            <div v-for="itemCart in sectionConfirme.options" :key="itemCart.id">
              <v-list-item
                v-if="itemCart.type !== 1"
                class="cart-list"
                two-line
              >
                <v-list-item-avatar class="pointer">
                  <v-img :src="optionsService.getOptionImage(itemCart)"></v-img>
                </v-list-item-avatar>
                <v-list-item-content class="pointer">
                  <v-list-item-title>{{ itemCart.name }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ format.formatToNok(itemCart.price) }},- inkludert mva
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>

          <div
            v-if="
              optionsService.confirmedOptions(sectionConfirme.options) &&
                changedCartOfConfirme.length > 0
            "
          >
            <v-card-subtitle>Endringer av tilvalg</v-card-subtitle>

            <div v-for="cart in changedCartOfConfirme" :key="cart.id">
              <v-list-item
                :class="{
                  'cart-list': true
                }"
                two-line
              >
                <v-icon :color="cart.selected ? 'success' : 'error'">
                  {{ cart.selected ? "mdi-plus" : "mdi-minus" }}
                </v-icon>
                <v-list-item-avatar class="pointer">
                  <v-img :src="optionsService.getOptionImage(cart)"></v-img>
                </v-list-item-avatar>
                <v-list-item-content class="pointer">
                  <v-list-item-title
                    :class="{
                      colorDelete: !cart.selected,
                      colorAdd: cart.selected
                    }"
                  >
                    {{ cart.name }}

                    <v-icon
                      v-if="cart.deadline && deadlineHasBeen(cart.deadline)"
                      small
                      color="warning"
                    >
                      mdi-calendar-alert
                    </v-icon>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ format.formatToNok(cart.price) }},- inkludert mva
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
        </v-list>
        <div class="text-center mt-2 mt-md-6">
          <v-btn
            :disabled="CartIsEmpty()"
            @click="confirmeCart"
            color="yellow"
            depressed
            >Bekreft bestilling</v-btn
          >
        </div>
      </v-card>
    </div>
    <DialogMessage
      :show="showDialog"
      :title="title"
      :DialogMessage="DialogMessage"
      :DialogAccept="dialogAccept"
      :hideOverlay="false"
    />
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import optionsService from "@/options.services";
import format from "@/helpers/format";
import DialogMessage from "@/components/dialog/DialogMessage";

export default {
  props: ["cartInfo", "getCartGroups", "changedCart"],
  data() {
    return {
      openCart: false,
      showDialog: { show: false },
      dialogAccept: false,
      title: "Tilvalg",
      DialogMessage: `Når du kjøper en bolig av Trysilhus har du mulighet til å gjøre enkelte tilvalg og endringer på for eksempel gulv,
       kjøkken, farger, elektro osv. Her finner du informasjon om disse tilvalgene. Merk at tilvalgene har en frist som følger fremdriften til din bolig.`,
      optionsService,
      format
    };
  },
  methods: {
    ...mapActions({
      deleteCart: "options/deleteCart"
    }),
    confirmeCart() {
      var htmlContainer = document.getElementById("htmlContainer");
      htmlContainer.classList.remove("overflow-hidden");
      this.$router.push({ name: "checkout" });
    },
    toggleSidemenu() {
      this.openCart = !this.openCart;
      var htmlContainer = document.getElementById("htmlContainer");
      if (this.openCart) {
        htmlContainer.classList.add("overflow-hidden");
      } else {
        htmlContainer.classList.remove("overflow-hidden");
      }
    },
    async deleteFromCart(item, categoryId) {
      if (parseInt(this.$route.params.id) === categoryId) {
        //Set categoryId to get updated data if categoryId is route id
        item.categoryId = categoryId;
      }

      this.deleteCart(item);
    },
    change(data) {
      if (parseInt(this.$route.params.id) !== data.id) {
        this.$emit("change", data);
      }
      // this.$router.push({ name: "storeChoice", params: { id: id } });

      //   this.id = data.id;
      //   this.title = data.name;
      //   this.showStoreChoice.show = true;
    },
    help() {
      this.showDialog.show = true;
    },
    CartIsEmpty() {
      if (!this.alldeadline) {
        return true;
      } else if (this.sectionConfirme.options.length > 0) {
        return this.countChangeOfConfime > 0 ? false : true;
      } else {
        return this.cartInfo.cartCount > 0 ? false : true;
      }
    },
    deadlineHasBeen(deadline) {
      return moment(deadline)
        .add(1, "day")
        .isBefore(moment().format());
    }
  },
  computed: {
    ...mapGetters({
      sectionConfirme: "options/sectionConfirme",
      changedCartOfConfirme: "options/changedCartOfConfirme",
      alldeadline: "options/alldeadline"
    }),
    //It counts changes of the confirmed options
    countChangeOfConfime() {
      if (this.changedCartOfConfirme.length > 0) {
        //Filter oute type 3. Type 3 kan be multi
        const filterNotType3 = this.changedCartOfConfirme.filter(x => {
          return x.type !== 3;
        });
        //Find unique in list of type 1 and type 2
        const distinctAges = [
          ...new Set(filterNotType3.map(x => x.categoryName))
        ];
        //Finde alle changes on type 3
        const filterType3 = this.changedCartOfConfirme.filter(x => {
          return x.type === 3;
        });
        //The count of unique type 1 and 2 and add all cahnges of type 3
        return distinctAges.length + filterType3.length;
      }
      return 0;
    }
  },
  components: {
    DialogMessage
  }
};
</script>

<style lang="scss">
.colorDelete {
  color: red;
}
.colorAdd {
  color: #4caf50;
}
.deadlineHasBeen {
  color: var(--v-warning-base);
}
</style>
