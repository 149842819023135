<template>
  <div v-if="$vuetify.breakpoint.smAndDown">
    <v-card-subtitle hide-details class="text-center pb-1" single-line>{{
      title
    }}</v-card-subtitle>
    <v-slider
      v-model="slider"
      :min="minValue"
      :max="maxValue"
      :step="stepValue"
      hide-details
      dense
      class="px-4 py-4"
    ></v-slider>
    <div class="d-flex justify-center pl-4 pr-5">
      <v-btn
        class="px-2 mt-4"
        color="primary"
        depressed
        small
        fab
        :ripple="false"
        @click="decrementSlider"
      >
        <v-icon> mdi-minus </v-icon>
      </v-btn>
      <div class="flex-grow-0 flex-shrink-1 pt-4 px-4">
        <v-text-field
          v-model="getFormattedSlider"
          dense
          outlined
          :rules="[rules.isnumer]"
          :label="label"
          :suffix="suffix"
          @keyup.up="onPressUpKey"
          @keyup.down="onPressDownKey"
          @keydown="handelKeydown"
        ></v-text-field>
      </div>
      <v-btn
        class="px-2 mt-4"
        color="primary"
        depressed
        small
        fab
        :ripple="false"
        :retain-focus-on-click="true"
        @click="incrementSlider"
      >
        <v-icon> mdi-plus </v-icon>
      </v-btn>
    </div>
    <slot name="bottomField" hide-details></slot>
  </div>

  <div v-else class="pr-3 pl-3">
    <!-- <v-card-title hide-details class="text-left pb-1" single-line>{{
      title
    }}</v-card-title> -->
    <div class="d-flex justify-space-between py-3">
      <v-slider
        class="flex-grow-1 flex-shrink-0 "
        v-model="slider"
        :min="minValue"
        :max="maxValue"
        :step="stepValue"
        hide-detailsbottomField
        dense
        :thumb-color="thumbCol"
      >
        <template v-slot:prepend>
          <v-btn
            class="mr-1"
            color="primary"
            depressed
            small
            fab
            hide-details
            :ripple="false"
            @click="decrementSlider"
          >
            <v-icon> mdi-minus </v-icon>
          </v-btn>
        </template>
        <template v-slot:append>
          <v-btn
            class="ml-1"
            color="primary"
            depressed
            small
            fab
            hide-details
            :ripple="false"
            :retain-focus-on-click="true"
            @click="incrementSlider"
          >
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </template>
      </v-slider>
      <v-text-field
        v-model="getFormattedSlider"
        dense
        outlined
        hide-details
        :label="label"
        :rules="[rules.isnumer]"
        @keyup.up="onPressUpKey"
        @keyup.down="onPressDownKey"
        @keydown="handelKeydown"
        class="flex-grow-0 flex-shrink-0 pl-2 pt-1"
        style="width: 120px"
      ></v-text-field>
    </div>

    <slot name="bottomField" hide-details></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rules: {
        isnumer: (value) => {
          const pattern = /[^0-9\,\s]+/g;
          return !pattern.test(value) || "Må ha en gyldig verdig";
        },
      },
      timer: null,
    };
  },
  props: {
    value: {
      required: true,
    },
    type: { type: String, default: null, required: false },
    suffix: { type: String, default: null, required: false },
    title: { type: String, default: null },
    label: { type: String, default: null },
    minValue: { type: Number },
    maxValue: { type: Number },
    stepValue: { type: Number, required: true },
    thumbCol: { type: String, default: "primary", required: false },
    delay: { type: Boolean, required: false, default: false },
  },
  computed: {
    slider: {
      get() {
        return this.value;
      },
      set(data) {
        this.$emit("input", data);
      },
    },
    getFormattedSlider: {
      get() {
        if (this.stepValue && this.stepValue < 1) {
          return new Intl.NumberFormat("no-No", {
            minimumFractionDigits: 2,
          }).format(this.slider);
        } else {
          return new Intl.NumberFormat("no-No", {
            minimumFractionDigits: 0,
          }).format(Math.round(this.slider));
        }
      },
      set(data) {
        if (this.delay) {
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.slider = isNaN(data) ? Number(data.replace(/\,/g, ".")) : data;
          }, 2000);
        } else {
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.slider = isNaN(data)
              ? Number(data.replace(/\D/g, "").replace(/\,/g, "."))
              : data;
          }, 2000);
        }
      },
    },
  },
  methods: {
    decrementSlider() {
      return (this.slider = this.slider - this.stepValue);
    },
    incrementSlider() {
      return (this.slider = this.slider + this.stepValue);
    },
    onPressUpKey(e) {
      if (e.key != "ArrowUp") return;
      this.incrementSlider();
    },
    onPressDownKey(e) {
      if (e.key != "ArrowDown") return;
      this.decrementSlider();
    },
    handelKeydown(e) {
      if (e.which == 38 || e.which == 40) {
        e.preventDefault();
      }
    },
  },
  watch: {
    // value(data) {
    //   setTimeout(() => {
    //     this.slider = data;
    //   }, 3000);
    // },
    // slider(data) {
    //   setTimeout(() => {
    //     this.value = data;
    //   }, 3000);
    // },
  },
};
</script>
