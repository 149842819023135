import baseApi from "./baseApi";
import FileSaver from "file-saver";
import notification from "@/notification.services";
const api = {
  async getUser() {
    const response = await baseApi.get("/customer", {
      cache: {
        maxAge: 0 // 0 min instead of 15 min
      }
    });
    return response.data;
  },
  async editUser(r) {
    return await baseApi.post(`/customer/save`, r);
  },
  async confirmCodeUser(confirmCode) {
    return await baseApi.post(`/customer/confirm/${confirmCode}`);
  },
  async getPostCode(postCode) {
    const response = await baseApi.get(
      `/customer/postname?postCode=${postCode}`
    );
    return response.data;
  },
  async firstLogin() {
    return await baseApi.post(`/customer/welcome`);
  },
  async getFaqs() {
    const response = await baseApi.get("/faqs");
    return response.data;
  },
  async getFaq(r) {
    const response = await baseApi.get(`/faq/${r}`);
    return response.data;
  },
  async getDocuments() {
    const response = await baseApi.get("/documents");
    return response.data;
  },
  async getDocument(r, getModelDocs) {
    const response = await baseApi.get(
      `/documents/${r}?getModelDocuments=${getModelDocs}`
    );
    return response.data;
  },
  async getComplaints() {
    const response = await baseApi.get("/complaints", {
      cache: {
        maxAge: 0 // 0 min instead of 15 min
      }
    });
    return response.data;
  },
  async getComplaint(r) {
    const response = await baseApi.get(`/complaint/${r}`, {
      cache: {
        maxAge: 0 // 0 min instead of 15 min
      }
    });
    return response.data;
  },
  async editComplaint(r) {
    return await baseApi.post(`/complaint/save/multiple`, r);
  },
  async addComment(r) {
    return await baseApi.post(`/complaint/comment/save`, r);
  },
  async getSectionOptions() {
    return await baseApi.get(`/options/sectionoptions`);
  },
  async changeSection(section, customerId) {
    return await baseApi.post(
      `/customer/${customerId}/section/current/`,
      section
    );
  },
  async getOptionsData() {
    return await baseApi.get(`/options/data`);
  },

  //Sjekk om den kan slettes!!!
  async getCategoryOptionsData(id) {
    const response = await baseApi.get(`/options/category/${id}/options`, {
      cache: {
        maxAge: 0 // 12 timer instead of 15 min
      }
    });
    return response.data;
  },
  async getOptionsReady() {
    const response = await baseApi.get(`/options/ready`).catch(() => {
      notification.hide();
      return { data: false };
    });

    return response.data;
  },

  async downloadDocument(doc) {
    return await baseApi
      .post(`/document/download?mtVersion=2`, doc)
      .then(response => {
        var blob = this.b64toBlob(
          response.data.file,
          response.data.contentType
        );
        FileSaver.saveAs(blob, response.data.fileName);
      });
  },

  async getTimeline(sectionId) {
    // 536337
    const response = await baseApi.get(`/timeline/${sectionId}`);
    // http://localhost:4498/timeline/536337
    // const response = await baseApi.get(`/timelines/497556/A`);
    return response.data;
  },

  async getProjectImage(projectId) {
    const response = await baseApi.get(`/common/project/${projectId}/image`, {
      cache: {
        maxAge: 12 * 60 * 60 * 1000 // 12 timer instead of 15 min
      },
      hideLoader: true
    });
    return response.data;
  },
  async uplodeProfileImage(file) {
    const response = await baseApi.post(`/customer/profileimage`, file);
    return response;
  },
  async saveComplaintRate(data) {
    const response = await baseApi.post(`/complaint/rate`, data);
    return response;
  },
  async profileImageIsNotDefault(customerId) {
    const response = await baseApi.get(
      `/customer/profile/${customerId}/hasImage`,
      {
        cache: {
          maxAge: 0 // 12 timer instead of 15 min
        }
      }
    );
    return response.data;
  },
  async deleteProfileImage(customerId) {
    const response = await baseApi.delete(
      `/customer/profile/${customerId}/image`
    );
    return response;
  },

  downloadFaqAttachment(att) {
    baseApi({
      url: `/faq/blob/download/${att.guid}?mtVersion=2`,
      method: "GET"
    }).then(response => {
      var blob = this.b64toBlob(response.data.file, response.data.contentType);
      FileSaver.saveAs(blob, response.data.fileName);
    });
  },
  downloadFile(att) {
    if (att.guid) {
      baseApi({
        url: `/complaint/blob/download/${att.guid}?mtVersion=2`,
        method: "GET"
      }).then(response => {
        var blob = this.b64toBlob(
          response.data.file,
          response.data.contentType
        );
        FileSaver.saveAs(blob, response.data.fileName);
      });
    } else {
      baseApi({
        url: "/complaint/attachment/download",
        method: "POST",
        data: att,
        responseType: "blob"
      }).then(fileResponse => {
        FileSaver.saveAs(new Blob([fileResponse.data]), att.fileName);
      });
    }
  },
  b64toBlob(b64Data, contentType = "", sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
};

export default api;
