import { getInstance } from "./index";
import store from "@/store/index";
import router from "../router";
import func from "@/routerFunctions";

export const authGuard = (to, from, next) => {
  if (to.name !== "callback") {
    const authService = getInstance();
    const fn = async () => {
      // Check if the route need authentication
      if (to.meta.notRequiresAuth) {
        //If the user is logged inn and go to login route. User will be redirected to home page
        if (
          authService.isAuthenticated &&
          (to.name === "login" ||
            to.name === "signup" ||
            to.name === "forgottenpassword")
        ) {
          router.push({ name: "home" });
        }
        next();
      } else {
        // If the user is authenticated, continue with the route
        if (authService.isAuthenticated) {
          const token = await authService.getTokenSilently();
          localStorage.setItem("token", token);

          // const user = await authService.getIdTokenClaims();
          if (store.getters["shared/profile"] === null) {
            store.dispatch("shared/profile");
          }
          func.setTimeline(to, from, next);
          return next();
        } else {
          store.dispatch("shared/routerTo", to.fullPath);
          router.push({ name: "login" }).catch(() => {});
        }
      }
    };
    // If loading has already finished, check our auth state using `fn()`
    if (!authService.loading) {
      return fn();
    }
    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch("loading", loading => {
      if (loading === false) {
        return fn();
      }
    });
  } else {
    next();
  }
};
