import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"600","hide-overlay":""},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline primary--text"},[_vm._v(_vm._s(_vm.title))])]),_c(VCardText,[_c('span',{staticStyle:{"font-size":"16px"},domProps:{"innerHTML":_vm._s(_vm.DialogMessage)}})]),_c(VCardActions,[_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{attrs:{"large":"","color":"success"},on:{"click":_vm.close}},[_vm._v("Avbryt")]),_c(VBtn,{attrs:{"large":"","outlined":""},on:{"click":_vm.turnOn}},[_vm._v("Ja")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }