<template>
  <div>
    <WebShopContainer @next="goBack">
      <template slot="navigation">
        <OptionsRoomNavigation />
      </template>
      <v-row class="mt-6 d-flex justify-space-between align-center">
        <v-col style="min-width: 145px;" cols="auto" order="0">
          <v-btn depressed @click="goBack">
            <v-icon left>mdi-arrow-left</v-icon>
            Tilbake
          </v-btn>
        </v-col>
        <v-col cols="12" sm="auto" order="3" order-sm="1" class="pt-0 pt-sm-3">
          <h1 class="grey--text">{{ title }}</h1>
        </v-col>
        <v-col style="min-width: 145px;" cols="auto" order="2" order-sm="3">
          <v-btn
            text
            v-if="addOn.length > 0"
            @click="$vuetify.goTo('#extras', scrolloptions)"
            depressed
          >
            Tillegg
            <v-icon right>mdi-arrow-down</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="options.length > 0" class="mt-0 mb-8">
        <v-col cols="12">
          <h3>Tilvalg</h3>
          <p class="body-2">
            Standard løsning som følger med leiligheten er forhåndsvalgt under.
            Ønsker du et tilvalg, klikker du på boksen til det tilvalget du
            ønsker.
          </p>
        </v-col>
        <v-col sm="6" xl="4" v-for="(n, index) in options" :key="index">
          <OptionsCard
            :data="n"
            :expired="expired"
            expiredNotOnSelected
            :notAvailable="categoryNotAvailable"
            @showImg="showImg"
            @addToCart="addToCart"
          />
        </v-col>
      </v-row>

      <v-row v-if="options.length === 0 && loading" class="mt-0 mb-8">
        <!-- <v-row class="mt-0 mb-8"> -->
        <h3 class="col-12">Tilvalg</h3>
        <v-col sm="6" xl="4" v-for="(n, index) in 3" :key="index">
          <OptionsCardSkeleton />
        </v-col>
      </v-row>

      <v-row id="extras" v-if="addOn.length > 0" class="mt-0 mb-8">
        <v-col cols="12">
          <h3>Tillegg</h3>
          <p class="body-2">
            Et tillegg er et eller flere valg du kan gjøre i tillegg til
            standardløsningen eller tilvalget du har valgt over.
          </p>
        </v-col>
        <v-col sm="6" xl="4" v-for="(n, index) in addOn" :key="index">
          <OptionsCard
            :data="n"
            :expired="expired"
            expiredNotOnSelected
            :notAvailable="categoryNotAvailable"
            @showImg="showImg"
            @addToCart="addAddonToCart"
            @removeFromCart="removeAddonFromCart"
          />
        </v-col>
      </v-row>
    </WebShopContainer>
    <DialogShowImage v-model="showImage" :title="imgTitle" :url="imgUrl" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import sort from "@/helpers/sort.js";
import purchaseApi from "@/api/purchaseApi";
import WebShopContainer from "@/components/store/webStore/shared/WebShopContainer.vue";
import OptionsRoomNavigation from "@/components/store/webStore/options/children/OptionsRoomNavigation.vue";
import OptionsCard from "@/components/store/webStore/options/children/OptionsCard";
import OptionsCardSkeleton from "@/components/store/webStore/options/children/OptionsCardSkeleton";
import DialogShowImage from "@/components/dialog/DialogShowImage";
import * as easings from "vuetify/es5/services/goto/easing-patterns";

export default {
  data() {
    return {
      showImage: false,
      imgUrl: null,
      imgTitle: null,
      loading: true,
      data: [],

      duration: 400,
      offset: 0,
      easing: "easeInOutCubic",
      easings: Object.keys(easings)
    };
  },
  async mounted() {
    await purchaseApi
      .getOptionsCategory(
        this.$route.params.sectionId,
        this.$route.params.id,
        this.isAttic
      )
      .then(response => {
        this.loading = false;
        this.data = response;
      });

    // this.setSelectedOptions().then(options => {
    //   if (options) {
    this.setSelected();
    //   }
    // });
  },
  methods: {
    ...mapActions({
      setSelectedOptions: "purchase/setSelectedOptions",
      changeSelectedOptions: "purchase/changeSelectedOptions"
    }),
    goBack() {
      this.$router.push({
        name: "webShopOptionsCategory",
        params: { categoryId: this.$route.params.categoryId }
      });
    },
    showImg({ url, data }) {
      this.imgTitle = data.name;
      this.imgUrl = url;
      this.showImage = true;
    },
    haveNotSavedSelectedOptions(optionsSelected) {
      //First time option is selected on this category
      if (!optionsSelected) {
        optionsSelected = {};
      }
      if (!optionsSelected[this.$route.params.categoryId]) {
        optionsSelected[this.$route.params.categoryId] = {};
      }
      if (
        !optionsSelected[this.$route.params.categoryId][this.$route.params.id]
      ) {
        optionsSelected[this.$route.params.categoryId][
          this.$route.params.id
        ] = [];
      }
      return optionsSelected;
    },

    addToCart(data) {
      var optionsSelected = this.selectedOptions;
      optionsSelected = this.haveNotSavedSelectedOptions(optionsSelected);
      //Remove allredy selected
      if (data.type !== 3 && optionsSelected) {
        var isSelected = this.data.find(x => x.type !== 3 && x.selected);
        isSelected.selected = false;
        if (
          isSelected.type === 2 &&
          optionsSelected[this.$route.params.categoryId]
        ) {
          const index = optionsSelected[this.$route.params.categoryId][
            this.$route.params.id
          ].findIndex(x => x.id === isSelected.id);
          if (index > -1) {
            optionsSelected[this.$route.params.categoryId][
              this.$route.params.id
            ].splice(index, 1);
          }
        }
      }

      data.selected = true;
      //Save parentId and categoryId to delete item in summary
      data.parentId = this.$route.params.id;
      data.categoryId = this.$route.params.categoryId;

      if (
        data.type === 2 &&
        optionsSelected[this.$route.params.categoryId][this.$route.params.id]
      ) {
        //Save selected option
        optionsSelected[this.$route.params.categoryId][
          this.$route.params.id
        ].push(data);
      }

      this.changeSelectedOptions(optionsSelected);
    },

    addAddonToCart(data) {
      var optionsSelected = this.selectedOptions;
      optionsSelected = this.haveNotSavedSelectedOptions(optionsSelected);
      if (
        data.type === 3 &&
        optionsSelected[this.$route.params.categoryId][this.$route.params.id]
      ) {
        data.selected = true;
        //Save parentId and categoryId to delete item in summary
        data.parentId = this.$route.params.id;
        data.categoryId = this.$route.params.categoryId;
        //Save selected option
        optionsSelected[this.$route.params.categoryId][
          this.$route.params.id
        ].push(data);
      }
      this.changeSelectedOptions(optionsSelected);
    },
    removeAddonFromCart(data) {
      var optionsSelected = this.selectedOptions;
      if (
        optionsSelected &&
        optionsSelected[this.$route.params.categoryId] &&
        optionsSelected[this.$route.params.categoryId][this.$route.params.id]
      ) {
        //Find index to remove
        var removeIndex = optionsSelected[this.$route.params.categoryId][
          this.$route.params.id
        ].findIndex(x => x.id === data.id);
        if (removeIndex > -1) {
          //Remove from selected list
          optionsSelected[this.$route.params.categoryId][
            this.$route.params.id
          ].splice(removeIndex, 1);
          data.selected = false;

          //Save/update
          this.changeSelectedOptions(optionsSelected);
        }
      }
    },
    setSelected() {
      if (
        this.selectedOptions &&
        this.selectedOptions[this.$route.params.categoryId] &&
        this.selectedOptions[this.$route.params.categoryId][
          this.$route.params.id
        ]
      ) {
        var optionsIsSelected = false;
        var selected = this.selectedOptions[this.$route.params.categoryId][
          this.$route.params.id
        ];
        if (selected) {
          this.data.forEach(x => {
            var isSelected = selected.find(y => y.id === x.id);
            if (isSelected) {
              x.selected = true;
              //When price is changed in Album
              if (x.price !== isSelected.price) {
                x.price = isSelected.price;
              }
              if (x.type === 2) {
                optionsIsSelected = true;
              }
            }
          });
          if (optionsIsSelected) {
            var standard = this.data.find(x => x.type === 1);
            standard.selected = false;
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      selectedOptions: "purchase/selectedOptions",
      getOptions: "purchase/options",
      profile: "purchase/profile"
    }),
    getCategoryData() {
      if (this.getOptions) {
        var data = this.getOptions.find(x => {
          return x.id === parseInt(this.$route.params.categoryId);
        });
        return data;
      }
      return {};
    },
    title() {
      if (
        this.getCategoryData &&
        this.getCategoryData.children &&
        this.$route.params
      ) {
        return this.getCategoryData.children.find(
          x => x.id === parseInt(this.$route.params.id)
        ).name;
      }
      return "";
    },
    parentCategory() {
      if (this.getCategoryData) {
        return this.getCategoryData.name;
      }
      return null;
    },
    expired() {
      if (this.getCategoryData) {
        return this.getCategoryData.deadlineExpired;
      }
      return false;
    },
    options() {
      var options = this.data.filter(x => x.type !== 3);

      //Sort by price, low price first
      options = sort.sortArrayObject(options, "price");

      //Sort by name
      options = sort.sortArrayObject(options, "name");

      //Sort so standard gets first
      options = sort.sortArrayObject(options, "type");

      return options;
    },
    addOn() {
      var addon = this.data.filter(x => x.type === 3);

      //Sort by price, low price first
      addon = sort.sortArrayObject(addon, "price");
      //Sort by name
      addon = sort.sortArrayObject(addon, "name");

      return addon;
    },
    isAttic() {
      if (this.profile) {
        return this.profile.includeAttic;
      }
      return false;
    },
    categoryNotAvailable() {
      if (this.getCategoryData || this.sectionInfo) {
        if (this.sectionInfo && this.sectionInfo.isAtticIncluded) {
          return false;
        } else {
          return this.getCategoryData.isAtticCategory && !this.isAttic;
        }
      }

      return false;
    },
    scrolloptions() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing
      };
    }
  },
  components: {
    WebShopContainer,
    OptionsRoomNavigation,
    OptionsCard,
    OptionsCardSkeleton,
    DialogShowImage
  }
};
</script>
