<template>
  <div>
    <v-file-input
      v-if="!icon"
      ref="upload"
      v-model="file"
      show-size
      filled
      prepend-icon
      prepend-inner-icon="mdi-paperclip"
      counter
      @click:clear="remove"
      multiple
      :drop="true"
      accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,image/*,audio/*,video/*"
      @change="addFile"
      label="Last opp filer"
      :rules="rules"
    ></v-file-input>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" v-if="icon" text icon @click="$refs.upload.click()">
          <v-icon>mdi-paperclip</v-icon>
        </v-btn>
      </template>
      <span>Legg til filer</span>
    </v-tooltip>

    <input v-show="false" ref="upload" type="file" @change="addFile" multiple />
  </div>
</template>

<script>
import axios from "axios";
import notificationsService from "@/notification.services";
const baseURL = process.env.VUE_APP_APIURL;
function readFile(file) {
  // Create a blob field
  var blob;
  let URL = window.URL || window.webkitURL;
  if (URL && URL.createObjectURL) {
    blob = URL.createObjectURL(file);
  }

  axios.defaults.baseURL = "";
  return new Promise((resolve, reject) => {
    axios.get(blob, { responseType: "blob" }).then(
      response => {
        var reader = new FileReader();
        reader.readAsDataURL(response.data);
        reader.onloadend = function() {
          var dataOnly = reader.result.substring(
            reader.result.indexOf(",") + 1,
            reader.result.length
          );
          axios.defaults.baseURL = baseURL;
          resolve(dataOnly);
        };
      },
      error => {
        reject(error);
      }
    );
  });
}

export default {
  props: ["index", "multifiles", "icon"],
  data() {
    return {
      file: [],
      saveFiles: [],
      removeValue: false,
      rules: [
        files =>
          !files ||
          files.reduce((total, y) => {
            return total + y.size;
          }, 0) < 22000001 ||
          "Total filstørrelse kan max være 22MB"
      ]
    };
  },
  methods: {
    remove() {
      this.$emit("fileResponse", [], this.index);
      this.removeValue = true;
    },
    addFile(data) {
      if (!data.length && data.target) {
        data = Array.from(data.target.files);
      }
      if (data.length) {
        data.forEach(x => {
          if (!x.id) {
            readFile(x)
              .then(file => {
                //Adding parameters to file for saving.
                var files = x;
                let dataArray = [];
                files.id = Math.random()
                  .toString(36)
                  .substring(7);
                files.base64 = file;
                files.status = 2;
                files.contentType = x.type;
                files.fileName = x.name;
                dataArray.push(files);
                this.file = [...this.multifiles, ...dataArray];
                this.$emit("fileResponse", this.file, this.index);
              })
              .catch(() => {
                this.file = [];
                notificationsService.error("Fil ble ikke lagt til");
              });
          } else {
            this.saveFiles = data;
          }
        });
      } else {
        if (!this.removeValue) {
          this.file = [...this.saveFiles];
        } else {
          this.removeValue = false;
          this.saveFiles = [];
        }
      }
    }
  }
};
</script>
