<template>
  <WebShopIntroContainer />
</template>

<script>
import WebShopIntroContainer from "@/components/store/webStore/intro/container/WebShopIntroContainer.vue";
export default {
  components: {
    WebShopIntroContainer
  }
};
</script>
