import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"450"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,{staticClass:"text-center py-6"},[_c(VCardTitle,{staticClass:"d-block mx-auto",class:{
          'ml-1': _vm.isMobileDevice,
          'ml-2': !_vm.isMobileDevice
        }},[_c('span',{staticClass:"headline primary--text"},[_vm._v(_vm._s(_vm.title))])]),_c(VCardText,{staticClass:"pb-0"},[_c('div',{class:{
            'ml-1': _vm.isMobileDevice,
            'ml-2': !_vm.isMobileDevice
          }},[_c('span',[_vm._v(" "+_vm._s(_vm.info)+" ")])]),_c('div',{staticClass:"d-flex"},[_c(VTextField,{ref:"code",class:{
              'ma-1': _vm.isMobileDevice,
              'ma-2': !_vm.isMobileDevice,
              'centered-input': true,
              inputNotDisplayUnder: true
            },attrs:{"dense":"","filled":"","autocomplete":"off","type":"text","pattern":"[0-9]*","inputmode":"numeric"},on:{"input":function($event){return _vm.newNumber(0)}},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1),_c('div',{class:{
            'ml-1': _vm.isMobileDevice,
            'ml-2': !_vm.isMobileDevice
          }},[_c('a',{on:{"click":_vm.sendNewCode}},[_vm._v("Send ny bekreftelseskode")])])]),_c(VCardActions,[_c(VBtn,{directives:[{name:"shortkey",rawName:"v-shortkey.once",value:(['esc']),expression:"['esc']",modifiers:{"once":true}}],staticClass:"mx-auto",attrs:{"outlined":""},on:{"click":_vm.close,"shortkey":_vm.close}},[_vm._v("Avbryt ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }