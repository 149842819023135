<template>
  <div v-if="$vuetify.breakpoint.smAndDown">
    <InfoCardDropDown
      title="Dagens bosituasjon"
      icon="mdi-home-edit"
      :removeMarginPrependIcon="true"
      :removeMarginToIcon="true"
      :outlined="true"
    >
      <template v-slot:headerInfo>
        <div class="mr-6">
          <v-chip v-if="getIsFilled" x-small hide-details color="success">
            Fylt ut
          </v-chip>
          <v-chip v-if="!getIsFilled" x-small hide-details color="warning">
            Ikke Fylt
          </v-chip>
        </div>
      </template>
      <HousingItems
        :houseData.sync="getHouseData"
        :houseMonthlyCost.sync="gethouseMonthlyCost"
        :houseOtherCost.sync="gethouseOtherCost"
        :houseMaintanceCost.sync="gethouseMaintanceCost"
        @houseKwt="houseKwt"
        :powerData="powerData"
      />
    </InfoCardDropDown>
  </div>
  <div v-else>
    <h3 class="text-center pt-4 pb-2">
      Dagens bosituasjon
    </h3>
    <HousingItems
      :houseData.sync="getHouseData"
      :houseMonthlyCost.sync="gethouseMonthlyCost"
      :houseOtherCost.sync="gethouseOtherCost"
      :houseMaintanceCost.sync="gethouseMaintanceCost"
    />
  </div>
</template>

<script>
import InfoCardDropDown from "@/components/shared/InfoCardBaseDropDown.vue";
import HousingItems from "@/components/store/webStore/shared/loanCalculation/children/HousingListItems.vue";

export default {
  emits: ["houseKwt"],
  props: {
    houseData: { type: Object, required: true },
    houseMonthlyCost: { type: Number, default: null },
    houseOtherCost: { type: Number, default: null },
    houseMaintanceCost: { type: Number, default: null },
    //avgCurrentkwtPrice: { type: Number, default: null, required: false },
    //avgareaOrPrice: { type: Number, default: null, required: false },
    //houseMonthlyKwt: { type: Number, default: null, required: false },
    powerData: { type: Object, required: false, required: false },
  },
  data() {
    return {
      // currentHouseData: {
      //   type: [
      //     { id: 1, name: "Lelighet" },
      //     { id: 2, name: "EneBolig" },
      //     { id: 2, name: "Rekkehus" },
      //   ],
      //   year: ["2030", "2020", "2010", "2000"],
      //   monthlyCost: 10000,
      //   otherCost: 3750,
      //   maintanceCost: 22644 / 12,
      // },
    };
  },
  computed: {
    getHouseData: {
      get() {
        return this.houseData;
      },
      set(data) {
        this.$emit("update:houseData", data);
      },
    },
    gethouseMonthlyCost: {
      get() {
        return this.houseData.monthlyCost;
      },
      set(value) {
        this.$emit("update:houseMonthlyCost", value);
      },
    },
    gethouseOtherCost: {
      get() {
        return this.houseData.otherCost;
      },
      set(value) {
        this.$emit("update:houseOtherCost", value);
      },
    },
    gethouseMaintanceCost: {
      get() {
        return this.houseData.maintanceCost;
      },
      set(value) {
        this.$emit("update:houseMaintanceCost", value);
      },
    },
    getIsFilled() {
      return (
        this.houseData &&
        this.houseData.monthlyCost > 0 &&
        this.houseData.otherCost > 0 &&
        this.houseData.maintanceCost > 0
      );
    },
    // gethouseMonthlyKwt: {
    //   get() {
    //     return this.houseMonthlyKwt;
    //   },
    //   set(value) {
    //     this.$emit("update:houseMonthlyKwt", value);
    //   },
    // },
  },
  methods: {
    houseKwt(value, check) {
      this.$emit("houseKwt", value, check);
    },
  },
  components: {
    InfoCardDropDown,
    HousingItems,
  },
};
</script>

<style lang="scss" scoped>
.houseTitle {
  margin-top: 3px;
  padding-top: 3px;
  padding-bottom: 0px;
}
</style>
