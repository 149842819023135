<template>
  <v-dialog
    v-model="show"
    max-width="600"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title
        single-line
        class="text-center flex-column cardTitle  headline primary--text pt-13 pb-10"
      >
        <v-icon x-large color="primary" class="pb-4"
          >mdi-information-outline</v-icon
        >
        {{ title }}
      </v-card-title>
      <div class="text-center px-4" v-html="infoText"></div>
      <v-card-actions class="justify-center cardActions">
        <v-btn
          depressed
          dark
          :ripple="false"
          color="primary"
          @click="show = false"
          >Lukk</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["value", "title", "infoText"],
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss">
.cardTitle {
  padding-top: calc(30px + env(safe-area-inset-top)) !important;
}

.cardActions {
  bottom: 45px;
  position: absolute;
  transform: translate(155px, -60%);
  z-index: 2px;
  padding-bottom: calc(35px +env(safe-area-inset-bottom)) !important;
}
</style>
