<template>
  <div>
    <SummaryContainer />
  </div>
</template>

<script>
import SummaryContainer from "@/components/store/webStore/summary/container/SummaryContainer.vue";
export default {
  components: {
    SummaryContainer
  }
};
</script>
