<template>
  <div>
    <WebShopContainer
      title="Mine valg"
      @next="next"
      send-purchase-progress="SendOnPurchaseSummary"
    >
      <p class="text-center mb-6">
        Under ser du en oppsummering av valgene du har gjort og hvilke gebyrer
        og avgifter som tilkommer boligen.
      </p>
      <SummaryListItems />
    </WebShopContainer>
  </div>
</template>

<script>
import WebShopContainer from "@/components/store/webStore/shared/WebShopContainer.vue";
import SummaryListItems from "@/components/store/webStore/summary/children/SummaryListItems.vue";

export default {
  methods: {
    next() {
      this.$router.push({ name: "webShopPurchaseOffer" });
    }
  },
  components: {
    WebShopContainer,
    SummaryListItems
  }
};
</script>
