<template>
  <div>
    <div class="sidemenuWrapper" :class="{ sidemenuToggle: openCart }">
      <div class="sidemenu-btns">
        <v-badge v-if="!openCart" overlap color="yellow">
          <template v-slot:badge>
            <span v-if="cartInfo">{{ cartInfo.changes }}</span>
            <span v-else>0</span>
          </template>
          <v-btn class="mb-sm-4" fab :color="'green'" @click="toggleSidemenu">
            <v-icon class="white--text">mdi-cart</v-icon>
          </v-btn>
        </v-badge>

        <v-btn @click="toggleSidemenu" fab color="red" v-else>
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>

        <v-btn
          @click="help"
          fab
          color="blue"
          class="infoBtn"
          :class="{ activeInfoBtn: showDialog.show === true }"
        >
          <v-icon color="white">mdi-help</v-icon>
        </v-btn>
      </div>

      <v-card tile flat class="cart-container">
        <v-list class="py-0">
          <v-list-item>
            <v-list-item-content style="max-width: 45%;">
              <v-list-item-title
                class="subtitle font-weight-bold text-uppercase"
                >Handlekurv</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-content>
              <v-btn
                :disabled="cartInfo.changes <= 0"
                @click="confirmeCart"
                color="yellow"
                small
                depressed
              >
                Bekreft bestilling
              </v-btn>
            </v-list-item-content>
          </v-list-item>
          <div class="pl-4">
            Totalpris fargevalg:
            {{ optionsService.formatPrice(cartInfo.totalPrice) }}
          </div>

          <v-alert
            v-model="showAlert"
            class="mt-2 mb-0 body-2"
            color="info"
            text
            dismissible
            @click="showAlert = false"
          >
            Husk å bekrefte bestillingen for hver endring. Bekreftede farger kan
            endres helt frem til fristen.
          </v-alert>
        </v-list>
        <v-list
          class="cart-list-container pt-0"
          :class="{
            'cart-list-container': showAlert,
            updatedHeight: !showAlert
          }"
        >
          <div v-if="!cartInfo.confirmed">
            <div v-for="itemCart in cartInfo.cart" :key="itemCart.id">
              <div>
                <v-card-subtitle class="pb-0">{{
                  itemCart.name
                }}</v-card-subtitle>
                <!-- <div
                  v-for="categories in itemCart.categories"
                  :key="categories.id"
                > -->
                <div v-for="options in itemCart.data" :key="options.cartItemId">
                  <v-list-item class="cart-list" two-line>
                    <v-list-item-avatar :color="options.hexColor">
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ options.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ options.ncs }}
                      </v-list-item-subtitle>

                      <v-list-item-subtitle>
                        {{ optionsService.formatPrice(options.price) }},-
                        inkludert mva
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-btn
                      @click="deleteFromCart(options)"
                      text
                      fab
                      dark
                      small
                      color="red"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item>
                </div>
              </div>
              <!-- </div> -->
            </div>
          </div>

          <div v-if="cartInfo.confirmed">
            <v-card-subtitle>Bekreftet fargevalg</v-card-subtitle>

            <div
              v-for="cartConfirmed in cartInfo.cartConfirmed"
              :key="cartConfirmed.id"
            >
              <v-list-item class="cart-list" two-line>
                <!-- <v-list-item-avatar>
                  <v-img
                    :src="optionsService.getOptionImage(cartConfirmed)"
                  ></v-img>
                </v-list-item-avatar> -->
                <v-list-item-avatar :color="cartConfirmed.hexColor">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ cartConfirmed.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ cartConfirmed.ncs }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ optionsService.formatPrice(cartConfirmed.price) }},-
                    inkludert mva
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-btn
                  v-if="cartConfirmed.state === 0"
                  @click="deleteFromCart(cartConfirmed)"
                  text
                  fab
                  dark
                  small
                  color="red"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item>
            </div>
          </div>

          <div v-if="cartInfo.confirmed && cartInfo.cartChanges.length > 0">
            <v-card-subtitle>Endringer av fargevalg</v-card-subtitle>

            <div v-for="cart in cartInfo.cartChanges" :key="cart.id">
              <v-list-item
                :class="{
                  'cart-list': true
                }"
                two-line
              >
                <!-- <v-icon :color="cart.state !== -1 ? 'success' : 'error'">
                  {{ cart.state !== -1 ? "mdi-plus" : "mdi-minus" }}
                </v-icon> -->
                <!-- <v-list-item-avatar>
                  <v-img :src="optionsService.getOptionImage(cart)"></v-img>
                </v-list-item-avatar> -->
                <v-list-item-avatar :color="cart.hexColor">
                </v-list-item-avatar>
                <!-- <v-icon :color="cart.state !== -1 ? 'success' : 'error'">
                  {{ cart.state !== -1 ? "mdi-plus" : "mdi-minus" }}
                </v-icon> -->
                <v-list-item-content :class="{ remove: cart.state === -1 }">
                  <v-list-item-title
                    :class="{
                      colorDelete: cart.state === -1,
                      colorAdd: cart.state !== -1
                    }"
                  >
                    {{ cart.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ cart.ncs }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ optionsService.formatPrice(cart.price) }},- inkludert mva
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-btn
                  v-if="cart.state !== -1"
                  @click="deleteFromCart(cart, true)"
                  text
                  fab
                  dark
                  small
                  color="red"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item>
            </div>
          </div>
          <div class="text-center mt-2 mt-md-6">
            <v-btn
              :disabled="cartInfo.changes <= 0"
              @click="confirmeCart"
              color="yellow"
              depressed
              >Bekreft bestilling</v-btn
            >
          </div>
        </v-list>
      </v-card>
    </div>
    <DialogMessage
      :show="showDialog"
      :title="title"
      :DialogMessage="DialogMessage"
      :DialogAccept="false"
      :hideOverlay="false"
    />
    <AcceptCancel
      v-model="showAccept"
      title="Bekrefte sletting av farge"
      DialogMessage="Er du sikker på at du vil slette fargen fra handlekurven?"
      @confirme="reset"
    />
  </div>
</template>

<script>
import customerApi from "@/api/customerApi";

import optionsService from "@/options.services";
import { mapGetters, mapActions } from "vuex";
import AcceptCancel from "@/components/dialog/AcceptCancel";
import DialogMessage from "@/components/dialog/DialogMessage";
export default {
  props: {
    cartInfo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      openCart: false,
      showDialog: { show: false },
      title: "Fargevalg",
      DialogMessage: `I denne fargevelgeren kan du endre både hovedfargen i boligen samt velge farger i de ulike rommene. Du betaler en fastsatt pris per farge og en pris per kontrastvegg du velger. På plantegningen er de ulike rommene samt kontrastvegger markert. Når du har valgt farger, går du til handlekurven for å bekrefte. Bestillingen er ikke registrert før du har bekreftet fargevalgene dine. Husk at du kan gjøre endringer helt frem til fristen for fargevalg går ut.`,
      showAccept: false,
      deleteFromCartData: null,
      showAlert: true,
      optionsService
    };
  },
  created() {
    if (this.$route.name === "colors") {
      if (this.profile) {
        if (!this.profile.user.seenColorOptionsMessage) {
          this.showDialog.show = true;
          this.colorOptionsMessageSeen();
        }
      } else {
        this.$store.watch(
          (state, getters) => getters["shared/profile"],
          profile => {
            if (!profile.user.seenColorOptionsMessage) {
              this.showDialog.show = true;
              this.colorOptionsMessageSeen();
            }
          }
        );
      }
    }
  },
  methods: {
    ...mapActions({
      deleteCart: "options/deleteCart",
      setProfile: "shared/profile"
    }),
    confirmeCart() {
      var htmlContainer = document.getElementById("htmlContainer");
      htmlContainer.classList.remove("overflow-hidden");
      if (this.$route.name === "colorsOpen") {
        this.$router.push({ name: "colorCheckoutOpen" });
      } else {
        this.$router.push({ name: "colorCheckout" });
      }
    },
    toggleSidemenu() {
      this.openCart = !this.openCart;
      var htmlContainer = document.getElementById("htmlContainer");
      if (this.openCart) {
        htmlContainer.classList.add("overflow-hidden");
      } else {
        htmlContainer.classList.remove("overflow-hidden");
      }
    },
    deleteFromCart(item, reset) {
      if (reset) {
        var isChangedFromConfirmed = this.cartInfo.cartConfirmed.some(
          x =>
            x.roomId === item.roomId &&
            (item.wallLetter ? x.wallLetter === item.wallLetter : !x.wallLetter)
        );
      }
      this.deleteFromCartData = item;
      this.deleteFromCartData.isChangedFromConfirmed = isChangedFromConfirmed;
      this.showAccept = true;
    },
    help() {
      this.showDialog.show = true;
    },
    reset() {
      this.$emit("reset", this.deleteFromCartData);
    },
    async colorOptionsMessageSeen() {
      await customerApi.colorOtionsInfoSeen().then(() => {
        if (this.profile) {
          this.profile.user.seenColorOptionsMessage = true;
          this.setProfile(this.profile.user);
        }
      });
    }
  },
  computed: {
    ...mapGetters({
      sectionConfirme: "options/sectionConfirme",
      changedCartOfConfirme: "options/changedCartOfConfirme",
      alldeadline: "options/alldeadline",
      profile: "shared/profile"
    })
  },
  components: {
    DialogMessage,
    AcceptCancel
  }
};
</script>

<style lang="scss">
.infoBtn {
  &:after {
    content: "";
    -moz-transition: all 0.4s ease-in;
    -o-transition: all 0.4s ease-in;
    -webkit-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
    position: absolute;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    border: 2px solid var(--v-info-base);
    box-shadow: none !important;
  }
}
.activeInfoBtn {
  &:after {
    content: "";
    width: 70px;
    height: 70px;
  }
}
.colorDelete {
  color: red;
}
.colorAdd {
  color: #4caf50;
}
.remove {
  text-decoration: line-through;
}
</style>
