import baseApi from "./baseApi";

const api = {
  async documentsUnread() {
    const response = await baseApi.get(`/documents/unread`, {
      adapter: null
    });
    return response.data;
  },

  async getDocuments() {
    const response = await baseApi.get("/documents");
    return response.data;
  },
  async getDocument(r, getModelDocs) {
    const response = await baseApi.get(
      `/documents/${r}?getModelDocuments=${getModelDocs}`
    );
    return response.data;
  }
};

export default api;
