import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"headline primary--text pl-4"},[_vm._v(" "+_vm._s(_vm.title)+" "),_c(VSpacer),_c('div',{staticClass:"text-right"},[_c(VBtn,{attrs:{"color":"red","icon":""},on:{"click":_vm.cancel}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1),_c(VCardText,{staticClass:"pa-0"},[_vm._t("content",null,{"hideDetails":""})],2),_c(VFlex,{attrs:{"align-self-end":"","sticky":"","bottom":""}},[_c(VBtn,{attrs:{"depressed":"","text":"","color":"darkgrey"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.btnText)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }