<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-card
        v-on="on"
        hover
        :class="{
          categoriExpired: expired,
          notAvailable: notAvailable,
          clickyCard: true,
          'theme-shadow': true,
          imgActive: data.selected,
        }"
        @click="$emit('click', data)"
      >
        <v-img
          min-height="200"
          max-height="200"
          :lazy-src="
            optionsService.getOptionImage(
              data,
              true,
              false,
              data.optionsSelectdId
                ? 'optionsSelectdId'
                : data.addonSelectdId
                ? 'addonSelectdId'
                : 'standardOptionId'
            )
          "
          :src="
            optionsService.getOptionImage(
              data,
              true,
              false,
              data.optionsSelectdId
                ? 'optionsSelectdId'
                : data.addonSelectdId
                ? 'addonSelectdId'
                : 'standardOptionId'
            )
          "
        >
          <div class="optionOverview pa-2 pl-3">
            <v-chip
              v-if="data.standardOptionId || data.optionsSelectdId"
              x-small
              dark
              :color="data.optionsSelectdId ? 'yellow' : 'primary'"
            >
              {{ data.optionsSelectdId ? "Tilvalg" : "Standard" }}
            </v-chip>
            <v-chip
              v-if="data.addonSelectdId"
              class="ml-2"
              x-small
              dark
              color="info"
            >
              Tillegg
            </v-chip>
          </div>
        </v-img>

        <v-list class="text-center underBar">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h4 class="grey--text">{{ data.name }}</h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </template>
    <span>Klikk for å se tilvalg</span>
  </v-tooltip>
</template>
<script>
import optionsService from "@/options.services";
export default {
  props: {
    data: {
      required: true,
    },
    readOnly: {
      default: false,
    },
    addon: {
      type: Boolean,
      default: false,
    },
    expired: {
      type: Boolean,
      default: false,
    },
    notAvailable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //   data: { id: 557300, selected: false, overlay: false, name: "test" },
      optionsService,
    };
  },
  methods: {
    addDeleteFromCart(data) {},
    showImg(data) {},
    // expired(data) {},
    overlayMouseover(data) {},
    overlayMouseleave(data) {},
  },
};
</script>

<style lang="scss" scoped>
.underBar {
  .v-list-item {
    min-height: 0px;
    .v-list-item__content {
      padding: 0px;
    }
  }
}

.notAvailable {
  .v-list-item {
    .v-avatar {
      background-color: darkgrey !important;
    }
    .v-list-item__content {
      .v-list-item__title {
        color: darkgrey !important;
      }
    }
  }
}
.clickyCard.notAvailable {
  position: relative;
  pointer-events: none;
  &:after {
    content: "Ikke tilgjengelig";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: white;
    font-weight: bold;
  }
}

.categoriExpired {
  .v-list-item {
    .v-avatar {
      background-color: darkgrey !important;
    }
    .v-list-item__content {
      .v-list-item__title {
        color: darkgrey !important;
      }
    }
  }
}
.clickyCard.categoriExpired {
  position: relative;
  // pointer-events: none;
  &:after {
    content: "Frist utgått";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: white;
    font-weight: bold;
  }
}
</style>
