let annutiyPayment = (rent, period, amount) => {
  //annuity money vaule change
  // T = L*P*(1+p)^n/((1+p)^n -1)
  //T/12

  let rentpercent = rent / 100;
  let termin = amount * rentpercent * timeValueChange(rentpercent, period);
  let monthlyPayment = termin / 12;

  return monthlyPayment;
};

let timeValueChange = (rentpercent, period) => {
  if (period === 0) return 0;
  let determinent = Math.pow(1 + rentpercent, period);
  return determinent / (determinent - 1);
};

//Housingloan for young?
//NormalHousingLoan
let normalHousingLoanAmount = (capital, amount) => {
  if (capital === 0) capital = normalCapital(amount);

  return amount - capital;
};

function normalCapital(amount) {
  //25% of amount
  return amount * 0.25;
}

export default { annutiyPayment, normalHousingLoanAmount, normalCapital };
