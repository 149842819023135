import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{staticClass:"justify-center mb-6"},[_c(VCol,{attrs:{"xl":"9"}},[_c(VRow,[(!_vm.$store.getters['shared/isMobileDevice'])?_c(VCol,{attrs:{"cols":"12"}},[_c(VList,{attrs:{"color":"transparent"}},[_c(VListItem,{staticClass:"px-0 mb-2"},[_c(VListItemAvatar,{attrs:{"color":_vm.color}},[_c(VIcon,{staticClass:"white--text"},[_vm._v(_vm._s(this.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"headline font-weight-black",style:({ color: _vm.color })},[_vm._v(_vm._s(_vm.title))])],1),_c(VListItemContent,{staticClass:"mr-10"},[_c(VTextField,{attrs:{"label":"Søk...","clearable":"","hide-no-data":"","autocomplete":"off"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VListItemAction,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"fab":"","dark":"","small":"","color":"blue"},on:{"click":function($event){_vm.showDialog.show = true}}},on),[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-help")])],1)]}}],null,false,1571901371)},[_c('span',[_vm._v("Hjelp")])])],1)],1),_c(VDivider)],1)],1):_vm._e()],1),(_vm.$store.getters['shared/isMobileDevice'])?_c(VTextField,{attrs:{"label":"Søk...","clearable":"","hide-no-data":"","autocomplete":"off","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),(_vm.getFaq.length > 0)?_c('div',_vm._l((_vm.getData),function(faq){return _c('div',{key:faq.id},[_c(VCard,{staticClass:"mx-auto",class:{
              transparent: !faq.show,
              white: faq.show
            },attrs:{"flat":"","tile":""}},[_c('a',{on:{"click":function($event){return _vm.open(faq)}}},[_c(VCardTitle,{staticClass:"px-md-12 darkgrey--text subtitle-1 font-weight-bold",staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(faq.question)+" "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v(" "+_vm._s(faq.show ? "mdi-chevron-up" : "mdi-chevron-down")+" ")])],1)],1)],1),_c(VExpandTransition,[_c('div',{directives:[{name:"show",rawName:"v-show",value:(faq.show),expression:"faq.show"}],staticClass:"px-md-12"},[_c(VDivider,{staticClass:"py-2"}),_c(VCardText,{staticClass:"px-0 pt-3"},[_c('v-runtime-template',{attrs:{"template":faq.answer}}),(faq.attachments.length > 0)?_c('div',[_c(VDivider,{staticClass:"py-2"}),_c('h3',[_vm._v("Vedlegg:")]),_vm._l((faq.attachments),function(attachments){return _c(VChip,{key:attachments.id,staticClass:"ma-1",attrs:{"pill":"","small":"","color":"lightgrey"},on:{"click":function($event){return _vm.download(attachments)}}},[_c(VAvatar,{attrs:{"left":"","color":"lightgrey darken-1"}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-download")])],1),_vm._v(" "+_vm._s(attachments.fileName)+" ")],1)})],2):_vm._e()],1)],1)])],1),_c(VDivider)],1)}),0):_c(VAlert,{staticClass:"mt-6",attrs:{"type":"info"}},[_vm._v(" Ingen ofte stilte spørsmål er tilgjengelig ")])],1)],1),_c('HelpBadges',{on:{"help":function($event){_vm.showDialog.show = true}}}),_c('Dialog',{attrs:{"show":_vm.showDialog,"title":_vm.titleDialog,"DialogMessage":_vm.DialogMessage,"DialogAccept":_vm.dialogAccept}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }