<template>
  <div>
    <v-dialog
      v-model="show"
      max-width="600"
      :fullscreen="$store.getters['shared/isMobileDevice']"
      scrollable
    >
      <v-card>
        <v-card-title class="headline primary--text pl-4">
          Detaljer
          <v-spacer></v-spacer>
          <v-btn @click="cancel" color="red" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text id="detailsCardText" class="pa-0">
          <SummaryListItems />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn depressed text color="darkgrey" @click="cancel">
            Lukk detaljer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SummaryListItems from "@/components/store/webStore/summary/children/SummaryListItems.vue";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions({
      saveDialogScroll: "purchase/saveDialogScroll"
    }),
    cancel() {
      this.show = false;
      this.$emit("cancel", true);
    }
  },
  updated: function() {
    this.$nextTick(function() {
      if (this.show) {
        //When opening dialog scroll ialog to saved scroll
        document.getElementById(
          "detailsCardText"
        ).scrollTop = this.dialogScroll;
      } else {
        //save scroll when closing dialog
        var detailsCardText = document.getElementById("detailsCardText");
        this.saveDialogScroll(detailsCardText.scrollTop);
      }
    });
  },
  computed: {
    ...mapGetters({
      dialogScroll: "purchase/dialogScroll"
    }),
    show: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        this.$emit("input", value);
      }
    }
  },
  components: {
    SummaryListItems
  }
};
</script>
