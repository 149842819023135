<template>
  <v-dialog
    v-model="showDialog"
    max-width="980"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    class="smallScroll bottomSafeArea"
    scrollable
  >
    <CloseDialog
      @cancel="cancel"
      title="Boligkalkulator"
      btnText="Lukk Boligkalkulator"
      v-if="$vuetify.breakpoint.smAndDown"
    >
      <template v-slot:content>
        <CurrentHousing
          :houseData.sync="gethouseData"
          :houseMonthlyCost.sync="gethouseMonthlyCost"
          :houseOtherCost.sync="gethouseOtherCost"
          :houseMaintanceCost.sync="gethouseMaintanceCost"
          @houseKwt="houseKwt"
          :powerData="getpowerData"
        />
        <NewHousing
          :totalPrice="totalPrice"
          :projectName="sectionInfo.projectName"
          :newhouseData.sync="getNewHouseData"
          :newMonthlyCost.sync="getnewMonthlyCost"
          :powerData="getpowerData"
          @newArea="updateNewArea"
        />
        <Power
          :powerData.sync="getpowerData"
          @houseKwt="houseKwt"
          @powerCost="powerCost"
          @newArea="updateNewArea"
          :monthlyKwt="getmonthlyKwt"
          :sectionInfo="sectionInfo"
          :houseTotalCost.sync="gethouseTotalCost"
          :newhouseTotalCost.sync="getnewhouseTotalCost"
          :newhouseData.sync="getNewHouseData"
          :totalhouse.sync="totalhouse"
          :totalnewhouse.sync="totalnewhouse"
        />
        <Summary
          :monthlyDifference="getDifference"
          :totalhouse="totalhouse"
          :totalnewhouse="totalnewhouse"
        />
      </template>
    </CloseDialog>

    <div v-else>
      <v-card>
        <v-row no-gutters dense>
          <v-col cols="6">
            <CurrentHousing
              :houseData.sync="gethouseData"
              :houseMonthlyCost.sync="gethouseMonthlyCost"
              :houseOtherCost.sync="gethouseOtherCost"
              :houseMaintanceCost.sync="gethouseMaintanceCost"
            />
          </v-col>
          <v-col cols="6">
            <NewHousing
              :totalPrice="totalPrice"
              :projectName="getprojectName"
              :newhouseData.sync="getNewHouseData"
              :newMonthlyCost.sync="getnewMonthlyCost"
            />
          </v-col>
        </v-row>

        <v-divider hide-details></v-divider>
        <Power
          :powerData.sync="getpowerData"
          @houseKwt="houseKwt"
          @powerCost="powerCost"
          @newArea="updateNewArea"
          :houseMonthlyKwt.sync="gethouseMonthlyKwt"
          :sectionInfo="sectionInfo"
          :houseTotalCost.sync="gethouseTotalCost"
          :newhouseTotalCost.sync="getnewhouseTotalCost"
          :totalhouse.sync="totalhouse"
          :totalnewhouse.sync="totalnewhouse"
        />
        <!-- <div class="py-4">
          <Summary :monthlyDifference="getDifference" />
        </div> -->
        <Summary :monthlyDifference="getDifference" />
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import CloseDialog from "@/components/store/webStore/shared/loanCalculation/children/CloseDialogSticky.vue";
import CurrentHousing from "@/components/store/webStore/shared/loanCalculation/Housing.vue";
import NewHousing from "@/components/store/webStore/shared/loanCalculation/NewHousing.vue";
import Power from "@/components/store/webStore/shared/loanCalculation/PowerUsage.vue";
import Summary from "@/components/store/webStore/shared/loanCalculation/children/HousingSummary.vue";
import { mapGetters } from "vuex";
import Calculator from "@/helpers/loanCalculator.js";

export default {
  data() {
    return {
      difference: 0,
      houseData: {},
      houseDefaults: Object.create(null),
      newhouseData: {},
      newhouseDefaults: Object.create(null),
      power: { newArea: [], newMonthlyKwt: 0 },
      housePowerCost: 0,
      check: true,
      monthlyKwt: 0,
      houseKwtPerMonth: 0,
      newhousePowerCost: 0,
      newhouseKwtPerMonth: 0,
      newArea: 0,
      areaWithBoost: [],
      totalhouse: 0,
      totalnewhouse: 0,
      Calculator,
      dialogScroll: 0,
    };
  },
  props: {
    value: { type: Boolean, required: true, default: false },
  },
  computed: {
    ...mapGetters({
      totalPrice: "purchase/totalPrice",
      sectionInfo: "purchase/sectionInfo",
    }),
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    getprojectName() {
      return this.sectionInfo.projectName;
    },
    getNewHouseData: {
      get() {
        return this.newhouseData;
      },
      set(value) {
        this.newhouseData = value;
      },
    },
    getnewMonthlyCost: {
      get() {
        return this.newhouseData.monthlyCost;
      },
      set(value) {
        this.newhouseData.monthlyCost = value;
        this.calcnewMonthlyTotal();
      },
    },
    getnewhouseTotalCost() {
      this.calcnewMonthlyTotal();
      return this.newhouseData.totalMonthlyCost;
    },
    gethouseData: {
      get() {
        return this.houseData;
      },
      set(value) {
        this.houseData = value;
      },
    },
    gethouseMonthlyCost: {
      get() {
        return this.houseData.monthlyCost;
      },
      set(value) {
        this.houseData.monthlyCost = value;
        this.calchouseMonthlyTotal();
      },
    },
    gethouseOtherCost: {
      get() {
        return this.houseData.otherCost;
      },
      set(value) {
        this.houseData.otherCost = value;
        this.calchouseMonthlyTotal();
      },
    },
    gethouseMaintanceCost: {
      get() {
        return this.houseData.maintanceCost;
      },
      set(value) {
        this.houseData.maintanceCost = value;
        this.calchouseMonthlyTotal();
      },
    },
    gethouseTotalCost() {
      this.calchouseMonthlyTotal();
      return this.houseData.totalMonthlyCost;
    },
    getpowerData: {
      get() {
        return this.power;
      },
      set(value) {
        this.power = value;
      },
    },
    getmonthlyKwt() {
      return this.monthlyKwt;
    },
    gethouseMonthlyKwt: {
      get() {
        return this.power.houseMonthlyKwt;
      },
      set(value) {
        if (isNaN(value)) return;
        this.power.houseMonthlyKwt = value;
      },
    },
    getnewhousePowerCost: {
      get() {
        return this.newhousePowerCost;
      },
      set(value) {
        this.newhousePowerCost = value;
        this.calcnewMonthlyTotal();
      },
    },
    getPowerKwtCost: {
      get() {
        return this.powerKwtCost;
      },
      set(value) {
        this.powerKwtCost = value;
        this.calchouseMonthlyTotal();
        this.calcnewMonthlyTotal();
      },
    },
    getDifference() {
      return Number(this.totalnewhouse) - Number(this.totalhouse);
    },
  },
  mounted() {
    this.initializeData();
  },
  methods: {
    cancel() {
      this.showDialog = false;
      //this.$emit("cancel", true);
    },
    updateNewArea(value) {
      this.newhouseData.commonCost =
        25 * Number(value) - Number(this.newhouseData.maintanceCost);

      this.power.newMonthlyKwt =
        Number(value) * Number(this.power.avgNewkwtPrice);

      //this.$set();
    },
    houseKwt(value, check) {
      this.check = check;
      this.power.avgAreaOrPrice = value;
      this.power.houseMonthlyKwt = this.check
        ? Number(this.power.avgAreaOrPrice) *
          Number(this.power.avgCurrentkwtPrice)
        : this.power.avgAreaOrPrice;

      this.monthlyKwt = this.power.houseMonthlyKwt;
      this.calcHouseMonthlyPowerCost();
    },
    powerCost(value) {
      this.power.powerDefault = value;
    },
    calcHouseMonthlyPowerCost() {
      return (this.power.housePowerCost =
        Number(this.power.houseMonthlyKwt) *
        Number(this.power.powerDefault / 100));
    },
    calcnewMonthlyTotal() {
      this.newhouseData.totalMonthlyCost =
        Number(this.newhouseData.monthlyCost) +
        Number(this.newhouseData.commonCost) +
        Number(this.newhouseData.maintanceCost);
    },
    calchouseMonthlyTotal() {
      this.houseData.totalMonthlyCost =
        Number(this.houseData.monthlyCost) +
        Number(this.houseData.otherCost) +
        Number(this.houseData.maintanceCost);
    },
    initializePower() {
      if (this.sectionInfo && this.sectionInfo.projectName) {
        const infos = this.sectionInfo.projectName.split(",");
        infos.forEach((i) => {
          if (i.includes("m2")) {
            this.newArea = i.replace("m2", "").trim();
            return;
          }
        });
      }

      this.areaWithBoost = this.newArea.split("/");
      this.power.areaWithBoost = this.areaWithBoost;
      if (this.areaWithBoost && this.areaWithBoost.length > 1) {
        this.power.optionForBoost = true;
      } else {
        this.power.optionForBoost = false;
      }
      this.power.newArea = this.power.areaWithBoost[0];
      this.power.avgNewkwtPrice = 95 / 12; //per mnth?
      this.power.powerDefault = 150;
      this.power.avgAreaOrPrice = 1500; //default for current housging
      this.power.avgCurrentkwtPrice = 185 / 12; //per mnth?
      this.power.minPowerPricePerKwt = 1;
      this.power.maxPowerPricePerKwt = 1000;
      this.power.houseMonthlyKwt =
        Number(this.power.avgAreaOrPrice) *
        Number(this.power.avgCurrentkwtPrice);
      this.power.newMonthlyKwt =
        Number(this.power.newArea) * Number(this.power.avgNewkwtPrice);

      this.monthlyKwt = this.power.avgAreaOrPrice;
    },
    initializeHouse() {
      (this.houseDefaults.maintanceCost = 22644 / 12),
        (this.houseDefaults.otherCost = 3750),
        (this.houseDefaults.monthlyCost = 13000),
        (this.houseData = Object.assign({}, this.houseDefaults));
    },
    initializenewHouse() {
      this.newhouseDefaults.maintanceCost = 7800 / 12;
      (this.newhouseDefaults.commonCost =
        25 * Number(this.areaWithBoost[0]) -
        this.newhouseDefaults.maintanceCost),
        (this.newhouseDefaults.timePeriod = 25);
      this.newhouseDefaults.rent = 5.5;
      this.newhouseDefaults.capital = Calculator.normalCapital(this.totalPrice);
      this.newhouseDefaults.loanAmount = Calculator.normalHousingLoanAmount(
        this.newhouseDefaults.capital,
        this.totalPrice
      );
      this.newhouseDefaults.monthlyCost = Calculator.annutiyPayment(
        this.newhouseDefaults.rent,
        this.newhouseDefaults.timePeriod,
        this.newhouseDefaults.loanAmount
      );
      this.newhouseData = Object.assign({}, this.newhouseDefaults);
    },
    initializeData() {
      this.initializePower();
      this.initializeHouse();
      this.initializenewHouse();

      this.calchouseMonthlyTotal();

      this.totalDifference =
        Number(this.houseData.totalMonthlyCost) -
        Number(this.newhouseData.totalMonthlyCost);
    },
  },
  components: {
    CloseDialog,
    CurrentHousing,
    NewHousing,
    Power,
    Summary,
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 600px) {
  div::-webkit-scrollbar {
    width: 2px;
    height: 4px;
  }
}

// @media (max-width: 600px) {
//   .smallScroll {
//     ::-webkit-scrollbar {
//       width: 2px;
//     }
//   }
// }

// @media (max-width: 600px) {
//   .smallScroll {
//     ::-webkit-scrollbar {
//       width: 2px;
//       height: 5px;
//     }
//   }
// }

.bottomSafeArea {
  padding-bottom: calc(45px +env(safe-area-inset-bottom)) !important;
}
</style>
