<template>
  <div>
    <vue-plyr v-if="src" :class="marginTop" :options="{ controls: controls }">
      <video :data-poster="dataPoster">
        <source :src="src" type="video/mp4" size="1080" />
      </video>
    </vue-plyr>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true
    },
    dataPoster: {
      type: String
    },
    marginTop: {
      type: String,
      default: "mt-2"
    }
  },
  data() {
    return {
      controls: [
        "play",
        "progress",
        "current-time",
        "mute",
        "volume",
        "captions",
        "airplay",
        "fullscreen"
      ]
    };
  }
};
</script>

<style lang="scss">
.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded="true"] {
  background: var(--v-primary-base) !important;
}

.plyr__control--overlaid {
  background: var(--v-primary-base) !important;
}
.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px #d65997 !important;
}
.plyr--full-ui input[type="range"] {
  color: var(--v-primary-base) !important;
}
</style>
