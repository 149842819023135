<template>
  <v-card class="mt-8 theme-shadow">
    <v-row no-gutters>
      <v-col md="8">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h3 class="primary--text">{{ title }}</h3>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>

        <v-list class="py-0">
          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-account-circle-outline</v-icon>
            </v-list-item-action>

            <v-list-item-content style="max-width: 100px;">
              <v-list-item-title>Navn:</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>{{
                name ? name : "Kundesenter"
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list class="py-0">
          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-email-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content style="max-width: 100px;">
              <v-list-item-title> E-post:</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>
                <a :href="'mailto:' + mailTo" title="Epost">
                  {{ mailTo }}
                </a>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list class="py-0">
          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-phone-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content style="max-width: 100px;">
              <v-list-item-title
                >{{ phoneName ? phoneName : "Telefon" }}:</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>
                <a :href="'tel:' + phoneTo">
                  {{ phone ? phone : "322 15 510" }}
                </a>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col class="card-icon d-none d-md-block">
        <v-divider vertical></v-divider>

        <img v-if="photo && name" :src="photo" width="155" />
        <img
          v-else
          src="https://thg.blob.core.windows.net/public-images/kundeansvarlig.png"
          width="155"
        />
        <!-- <v-icon class="icon" v-else>fas fa-address-book</v-icon> -->
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: ["title", "name", "email", "phone", "phoneName", "photo"],
  methods: {},
  computed: {
    phoneTo() {
      return this.phone ? this.phone : 32215510;
    },
    mailTo() {
      return this.email ? this.email : "kundesenter@trysilhus.no";
    }
  }
};
</script>
