<template>
  <div>
    <OptionsIntroContainer />
  </div>
</template>

<script>
import OptionsIntroContainer from "@/components/store/webStore/options/container/OptionsIntroContainer.vue";
export default {
  components: {
    OptionsIntroContainer
  }
};
</script>
