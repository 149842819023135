import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"width":"500","fullscreen":_vm.$store.getters['shared/isMobileDevice']},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[(_vm.images.length > 0)?_c(VCarousel,{attrs:{"hide-delimiters":"","height":"200","hide-delimiter-background":"","show-arrows":_vm.images.length > 1},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-chevron-left ")])],1)]}},{key:"next",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-chevron-right ")])],1)]}}],null,false,3895783268)},_vm._l((_vm.images),function(item,i){return _c(VCarouselItem,{key:i,attrs:{"src":item}})}),1):_vm._e(),_c(VCardTitle,{staticClass:"title primary--text"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.description)+" ")]),_c(VDivider),_c(VCardActions,[_c(VBtn,{staticClass:"mx-auto",attrs:{"color":"primary","text":""},on:{"click":_vm.cancel}},[_vm._v(" Lukk ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }