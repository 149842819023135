import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{staticClass:"filter",attrs:{"max-width":"600","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{attrs:{"height":"400"}},[_c(VCardText,{staticClass:"pt-5"},[_c(VTextField,{attrs:{"label":"Søk...","clearable":"","hide-no-data":"","autocomplete":"off","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VSelect,{attrs:{"items":_vm.getCategory,"clearable":"","label":"Filtrer etter status","hide-no-data":""},model:{value:(_vm.filterCategory),callback:function ($$v) {_vm.filterCategory=$$v},expression:"filterCategory"}}),_c(VBtn,{attrs:{"large":"","width":"100%","color":"success"},on:{"click":_vm.filter}},[_vm._v("Søk")]),(this.search || this.filterCategory)?_c(VBtn,{staticClass:"mt-4",attrs:{"large":"","width":"100%","outlined":"","color":"error"},on:{"click":_vm.deleteFilter}},[_vm._v("Fjern filteret")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }