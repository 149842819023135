<template>
  <div>
    <OptionsAtticContainer />
  </div>
</template>

<script>
import OptionsAtticContainer from "@/components/store/webStore/options/container/OptionsAtticContainer.vue";
export default {
  components: {
    OptionsAtticContainer
  }
};
</script>
