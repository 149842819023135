<template>
  <div>
    <v-card
      v-if="currentSection.settlement && currentSection.settlement.sectionPrice"
      class="mt-8 theme-shadow"
    >
      <v-list class="py-0">
        <v-list-group no-action :value="open">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Min avtale</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list color="grey lighten-4" class="py-0">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Kjøpesum:</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="justify-end mr-1">
                {{ format.formatToNok(currentSection.settlement.sectionPrice) }}
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-subheader v-if="currentSection.settlement.options.length > 0">
            Tilvalg
          </v-subheader>
          <v-divider></v-divider>
          <v-list
            class="py-0"
            v-for="(option, index) in currentSection.settlement.options"
            :key="index"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ option.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="justify-end mr-1">
                {{ format.formatToNok(option.price) }}
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>

          <v-divider></v-divider>

          <v-subheader v-if="colorConfirmed && colorConfirmed.length > 0">
            Fargevalg
          </v-subheader>
          <v-list
            class="py-0"
            v-for="(colorOption, index) in colorConfirmed"
            :key="'color' + index"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ colorOption.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="justify-end mr-1">
                {{ format.formatToNok(colorOption.price) }}
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>

          <v-list color="grey lighten-4" class="py-0">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Totalsum:</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="justify-end mr-1">
                {{ format.formatToNok(totalePrice(currentSection.settlement)) }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-list-group>
      </v-list>
    </v-card>
  </div>
</template>
<script>
import optionsService from "@/options.services";
import format from "@/helpers/format";

export default {
  props: {
    currentSection: {
      required: true
    },
    colorInfo: {
      default: null
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      optionsService,
      format
    };
  },
  methods: {
    totalePrice(data) {
      let totale = 0;
      data.options.forEach(x => {
        totale = totale + x.price;
      });
      return totale + data.sectionPrice + this.totalColorPrice;
    }
  },
  computed: {
    colorConfirmed() {
      if (this.colorInfo && !this.colorInfo.loading) {
        return this.colorInfo.cartConfirmed;
      }
      return [];
    },
    totalColorPrice() {
      if (this.colorInfo && !this.colorInfo.loading) {
        return this.colorInfo.totalPriceConfirmed;
      }
      return 0;
    }
  }
};
</script>
