import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index.js";
import vuetify from "./plugins/vuetify";
import "./assets/sass/config.scss";
import "./registerServiceWorker";
import moment from "moment";
import vueMoment from "vue-moment";
import vueShortkey from "vue-shortkey";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";

import VueAnalytics from "vue-analytics";
Vue.use(VueAnalytics, {
  id: "UA-158112945-1",
  debug: {
    sendHitTask: process.env.NODE_ENV === "production"
  },
  router
});

Vue.use(VuePlyr);
Vue.use(vueShortkey);
// Vue.use(moment);
moment.locale("nb");
//Make directives global
import taBlobLink from "@/directives/taBlobLink";
Vue.directive("taBlobLink", taBlobLink);

//Check type of device
import device from "vue-device-detector";
Vue.use(device);

//Make directives global
import truncate from "@/directives/truncate";
Vue.directive("truncate", truncate);

//Froala editor
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins.pkgd.min";
import "froala-editor/js/languages/nb";
import VueFroala from "vue-froala-wysiwyg";
Vue.use(VueFroala);

import VueScreenSize from "vue-screen-size";
Vue.use(VueScreenSize);

// Import the plugin here
import { Auth0Plugin } from "./auth";

Vue.use(vueMoment);
// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  onRedirectCallback: appState => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : "/");
  }
});

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  Vue,
  environment: process.env.VUE_APP_ENVIRONMENT,
  dsn:
    "https://a69a1c5fa8ae4cb3b83e7bcb93c69129@o100875.ingest.sentry.io/3459926",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [location.origin, /^\//]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
