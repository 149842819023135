<template>
  <div>
    <!-- <v-bottom-navigation dark fixed background-color="primary" color="white"> -->

    <v-bottom-navigation app grow dark color="white" background-color="primary">
      <v-btn class="removeBtnBackground" to="/" :ripple="false">
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-btn
        class="removeBtnBackground"
        :to="{ name: 'profile' }"
        :ripple="false"
      >
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <v-btn
        class="removeBtnBackground"
        :class="{ 'v-btn--active': routeIsJointOwnerships }"
        :to="{ name: 'requests' }"
        :ripple="false"
      >
        <v-icon>mdi-forum</v-icon>
      </v-btn>
      <v-btn
        class="removeBtnBackground"
        :to="{ name: 'documents' }"
        :ripple="false"
      >
        <v-icon>mdi-folder-outline</v-icon>
      </v-btn>
      <v-btn class="removeBtnBackground" :to="{ name: 'faq' }" :ripple="false">
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <!-- <div class="bottomSpaceing"></div> -->
  </div>
</template>

<script>
export default {
  computed: {
    routeIsJointOwnerships() {
      return (
        this.$route.name === "JointOwnerships" ||
        this.$route.name === "JointOwnership" ||
        this.$route.name === "NewJointOwnership"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.bottomSpaceing {
  height: 60px;
}
.v-item-group.v-bottom-navigation--absolute,
.v-item-group.v-bottom-navigation--fixed {
  z-index: 6;
}
</style>

<style lang="scss">
.v-bottom-navigation {
  height: calc(56px + env(safe-area-inset-bottom)) !important;

  padding-bottom: env(safe-area-inset-bottom);

  .v-btn {
    height: 100% !important;
  }
}
</style>
