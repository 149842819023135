<template>
  <div style="min-height: 80vh;">
    <AppBar :hide-tabs="hideTabs" :disabled="disabled" />
    <slot name="navigation"></slot>
    <v-card class="mx-auto" color="transparent" max-width="960" elevation="0">
      <div v-if="!intro">
        <!-- <h1 class="mt-6 mb-4 text-center grey--text">
          {{ title }}
        </h1> -->

        <v-row
          v-if="title"
          class="mt-sm-6 mb-sm-4 d-flex justify-space-between align-center"
        >
          <v-col style="min-width: 145px;" cols="auto">
            <v-alert
              v-if="leftTitle"
              class="mb-0"
              text
              dense
              color="teal"
              icon="mdi-clock-fast"
              border="left"
            >
              {{ leftTitle }}
            </v-alert>
          </v-col>
          <v-col cols="12" sm="auto" class="text-center">
            <h1 class="grey--text">{{ title }}</h1>
          </v-col>
          <v-col style="min-width: 145px;" cols="auto"></v-col>
        </v-row>

        <slot name="text"></slot>
      </div>
      <slot></slot>
    </v-card>

    <Footer
      v-if="!hideFooter"
      :buttonTitle="footerButtonTitle"
      @next="next"
      @showDetails="showDetails"
      @openCalculator="openCalculator"
    />
    <ShowDetailsDialog v-model="showDialog" />
    <CalculatorDialog v-model="showCalculator" />
    <AcceptCancel
      v-model="showUnavailable"
      :hideOverlay="false"
      persistent
      acceptButtonColor="primary"
      acceptButtonText="Gå til Trysilhus.no"
      hide-cancel-button
      title="Seksjon ikke lenger tilgjengelig"
      DialogMessage="Denne seksjonen har blitt kjøpt eller blitt fjernet fra markedet. Vi beklager dette! Du kan gå tilbake til trysilhus.no å velge en annen seksjon."
      @confirme="goToTrysilhus"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import hubConnection from "@/helpers/hubConnection";
import AppBar from "@/components/store/webStore/shared/AppBar.vue";
import Footer from "@/components/store/webStore/shared/Footer.vue";
import ShowDetailsDialog from "@/components/store/webStore/shared/dialogs/ShowDetailsDialog.vue";
import AcceptCancel from "@/components/dialog/AcceptCancel";
import CalculatorDialog from "@/components/store/webStore/shared/dialogs/MortgageCalculationDialog.vue";

export default {
  props: {
    intro: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    leftTitle: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideTabs: {
      type: Boolean,
      default: false
    },
    hideFooter: {
      type: Boolean,
      default: false
    },
    sendPurchaseProgress: {
      type: String,
      default: null
    },
    footerButtonTitle: {
      type: String,
      default: "Fortsett"
    }
  },
  data() {
    return {
      showDialog: false,
      showUnavailable: false,
      hubConnection: null,
      sectionUnavailable: false,
      showCalculator: false
    };
  },
  methods: {
    next() {
      this.$emit("next", true);
    },
    showDetails() {
      this.showDialog = true;
    },
    sectionIsUnavailable() {
      this.showUnavailable = true;
    },
    goToTrysilhus() {
      window.location.href = "https://www.trysilhus.no";
    },
    openCalculator() {
      this.showCalculator = true;
    }
  },
  computed: {
    ...mapGetters({
      profile: "purchase/profile",
      hasPurchasedSection: "purchase/hasPurchasedSection"
    })
  },
  watch: {
    sectionUnavailable(value) {
      if (value && !this.hasPurchasedSection) {
        this.sectionIsUnavailable();
      }
    }
  },

  created() {
    this.$store.watch(
      (state, getters) => getters["purchase/hasPurchasedSection"],
      value => {
        if (!value && this.sectionUnavailable) {
          this.sectionIsUnavailable();
        }
      }
    );
  },
  async mounted() {
    await hubConnection.startConnection(this.$route.params.sectionId);
    if (this.profile && this.profile.customer && this.profile.customer.id) {
      // console.info("purchaseProgressMethod", this.sendPurchaseProgress);
      await hubConnection.sendPurchaseProgress(
        this.sendPurchaseProgress,
        this.$route.params.sectionId,
        this.profile.customer.id
      );
    }

    hubConnection.sectionUnavailable().then(() => {
      this.sectionUnavailable = true;
      // console.info("section is unavailable"); //todo: disable the possibility to move forward, section is taken/unavailable
      // this.sectionIsUnavailable();
    });
  },
  components: {
    AppBar,
    Footer,
    ShowDetailsDialog,
    AcceptCancel,
    CalculatorDialog
  }
};
</script>
<style lang="scss">
.roomnav-padding {
  margin-top: 3.5rem;
}
</style>
