import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"theme-shadow py-2"},_vm._l((_vm.items),function(item,index){return _c(VList,{key:index,staticClass:"py-0"},[_c(VListItem,[_c(VListItemAction,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title)+":")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.content))])],1)],1),(index < _vm.items.length - 1)?_c(VDivider,{attrs:{"inset":""}}):_vm._e()],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }