<template>
  <v-card>
    <v-card-title class="headline primary--text pl-4">
      {{ title }}
      <v-spacer></v-spacer>
      <div class="text-right">
        <v-btn @click="cancel" color="red" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text class="pa-0">
      <slot name="content" hide-details></slot>
    </v-card-text>
    <v-flex align-self-end sticky bottom>
      <v-btn depressed text color="darkgrey" @click="cancel">
        {{ btnText }}
      </v-btn>
    </v-flex>
  </v-card>
</template>

<script>
export default {
  emits: ["cancel"],
  props: {
    title: { type: String, default: null, required: false },
    btnText: { type: String, default: null, required: true },
  },
  methods: {
    cancel() {
      this.$emit("cancel", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky {
  width: 100%;
  position: sticky;
  min-height: 50px;
  text-align: center;
  &.bottom {
    bottom: 0px;
  }
  button {
    position: absolute;
    bottom: 5px;
    transform: translateX(-50%);
  }
  background: white;
}
</style>
