import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"justify-center mb-12"},[_c(VCol,{attrs:{"xl":"9"}},[(_vm.profile && !_vm.profile.user.isOwnerToday)?_c(VAlert,{staticClass:"mb-0",attrs:{"dense":"","text":"","type":"warning"}},[_vm._v(" Det er ikke mulig å legge inn en henvendelse når du ikke lenger eier seksjonen. Kontakt Trysilhus kundesenter på 32 21 55 10 hvis det er noe du lurer på. ")]):_vm._e(),(!_vm.$store.getters['shared/isMobileDevice'])?_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VList,{attrs:{"color":"transparent"}},[_c(VListItem,{staticClass:"px-0 mb-2"},[_c(VListItemAvatar,[_c(VIcon,{staticClass:"primary white--text"},[_vm._v(_vm._s(_vm.$route.name === "requests" ? "mdi-forum" : "mdi-account-group"))])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"primary--text headline font-weight-black"},[_vm._v(" "+_vm._s(_vm.$route.name === "requests" ? "Mine henvendelser" : "Sameiets henvendelser")+" ")]),_c(VListItemSubtitle,[_vm._v(" Du har "+_vm._s(_vm.openRequests)+" "+_vm._s(_vm.openRequests === 1 ? "åpen henvendelse" : "åpne henvendelser")+" ")])],1),(_vm.getCategory.length > 0)?_c(VListItemContent,{staticClass:"flex-nowrap mr-10"},[_c(VSelect,{staticClass:"mr-4",attrs:{"items":_vm.getCategory,"clearable":"","label":"Filtrer etter status","hide-no-data":""},on:{"change":_vm.filter},model:{value:(_vm.categoryName),callback:function ($$v) {_vm.categoryName=$$v},expression:"categoryName"}}),_c(VTextField,{attrs:{"label":"Søk...","clearable":"","hide-no-data":"","autocomplete":"off"},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1):_vm._e(),_c(VListItemAction,{staticClass:"d-none d-sm-flex mr-1"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.setChangeView(!_vm.changeView)}}},on),[_c(VIcon,{attrs:{"dark":""}},[_vm._v(_vm._s(_vm.changeView ? "mdi-view-module" : "mdi-view-list"))])],1)]}}],null,false,2241603691)},[_c('span',[_vm._v(_vm._s(_vm.changeView ? "Rutenett" : "Liste"))])])],1),_c(VListItemAction,{staticClass:"d-none d-md-flex"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"fab":"","dark":"","small":"","color":"blue"},on:{"click":_vm.help}},on),[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-help")])],1)]}}],null,false,278060554)},[_c('span',[_vm._v("Hjelp")])])],1),(_vm.profile && _vm.profile.user.isOwnerToday)?_c(VListItemAction,{staticClass:"d-none d-md-flex ml-1"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"fab":"","dark":"","small":"","color":"green"},on:{"click":_vm.newRequest}},on),[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)]}}],null,false,2806684312)},[_c('span',[_vm._v("Ny henvendelse")])])],1):_vm._e()],1),_c(VDivider)],1)],1)],1):_vm._e(),_c('SpeedDial',{attrs:{"goTo":"newrequest","save":"true"},on:{"help":_vm.help}}),(!_vm.changeView)?_c('RequestCardList',{attrs:{"data":_vm.getData},on:{"newRequest":_vm.newRequest}}):_vm._e(),(_vm.changeView)?_c('RequestList',{attrs:{"data":_vm.getData},on:{"newRequest":_vm.newRequest}}):_vm._e()],1),_c('Dialog',{attrs:{"show":_vm.showDialog,"title":_vm.title,"DialogMessage":_vm.DialogMessage,"DialogAccept":_vm.dialogAccept},on:{"accept":_vm.accept}}),_c('FilterDialog',{attrs:{"show":_vm.showFilter,"getCategory":_vm.getCategory},on:{"filter":_vm.filterMob}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }