<template>
  <v-dialog
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
    v-model="show"
    width="700"
  >
    <v-card>
      <v-card-title
        class="safeAreaDialog safeAreaDialogTop font-weight-bold headline primary--text grey lighten-3"
        v-if="data && data.name"
        >Endre {{ data.id !== -1 ? "farge" : "" }} -
        {{ data.name.toLowerCase() }} {{ constrastWallRoomName }}
      </v-card-title>

      <v-card-title class="safeAreaDialog grey lighten-3 pb-5">
        <v-autocomplete
          v-model="selectedNCS"
          :search-input.sync="search"
          :items="colorList"
          prepend-inner-icon="mdi-magnify"
          filled
          label="NCS - Søk blant alle fargekoder"
          item-text="ncs"
          item-value="ncs"
          hide-no-data
          hide-details
          @click="setNCS"
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content style="max-width: 120px;">
                <v-list-item-title v-html="data.item"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-sheet
                  min-height="37"
                  :color="ncsColor.hex(data.item)"
                ></v-sheet>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-card-title>

      <v-card-text class="safeAreaDialog">
        <h3 class="mt-4">
          Mest brukte farger
          <v-btn @click="expand = !expand" x-small icon outlined color="grey">
            <v-icon small>mdi-help</v-icon>
          </v-btn>
        </h3>
        <v-expand-transition>
          <v-card
            v-show="expand"
            elevation="0"
            width="100%"
            class="mx-auto mt-2"
          >
            <v-alert class="body-2 blue lighten-4" border="left">
              Vi har fått en fargekonsulent til å velge ut noen farger for oss.
              NCS-koder er brukt fordi dette er den mest korrekte måten å angi
              farger på. Har du andre ønsker, anbefaler vi at du går til en
              fargehandler, ber om en fargeprøve og søker opp NCS-koden her i
              denne fargevelgeren. Husk også at skjermen din ikke gir en korrekt
              angivelse av fargen. For å være sikker på hvordan fargen ser ut,
              bør du be om en fargeprøve hos en fargehandler.<br /><br />
              Malingstypen som benyttes er Jotaproff PVA glansgrad 07 eller
              tilsvarende kvalitet fra annen produsent. Bestilling med annen
              kvalitet eller glansgrad avtales direkte med selger.
            </v-alert>
          </v-card>
        </v-expand-transition>

        <v-row>
          <v-col cols="12" class="d-inline-flex flex-column">
            <small class="font-weight-bold caption">Standard farge</small>
            <v-btn
              :class="{ isSelected: standardColor === selectedNCS }"
              height="40"
              depressed
              :color="ncsColor.hex(standardColor)"
              @click="changeSelectedColor(standardColor)"
            >
            </v-btn>
            <small>{{ standardColor }}</small>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            v-for="(colors, index) in getColors"
            :key="index"
            cols="6"
            sm=""
            class="d-inline-flex flex-column"
          >
            <small class="font-weight-bold caption">{{ colors.title }}</small>
            <template v-for="(color, index) in colors.data">
              <v-btn
                :class="{ isSelected: color.selected }"
                :key="'color' + index"
                height="60"
                depressed
                :color="ncsColor.hex(color.ncs)"
                @click="changeSelectedColor(color.ncs)"
              >
              </v-btn>
              <small :key="'code' + index">{{ color.ncs }}</small>
            </template>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions
        class="safeAreaDialogBottom safeAreaDialog d-block text-center py-4"
      >
        <v-btn
          @click="addToCart"
          :disabled="!selectedNCS || selectedNCS === data.ncs"
          small
          depressed
          color="success"
        >
          <div v-if="selectedNCS && selectedNCS !== data.ncs">
            Legg "{{ selectedNCS }}" i handlekurv
          </div>
          <div v-else>Legg farge i handlekurv</div>
        </v-btn>
        <v-btn @click="close" small depressed color="grey" outlined>
          Lukk
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ncsColor from "ncs-color";
import colorStore from "@/colorStore.json";
export default {
  props: {
    value: {
      required: true
    },
    data: {
      required: true
    },
    constrastWallRoomName: {
      type: String
    }
  },
  data() {
    return {
      colorList: [],
      selectedNCS: null,
      search: null,
      expand: false,
      standardColor: "NCS 0801-Y25R",
      ncsColor
    };
  },
  methods: {
    async addToCart() {
      this.data.ncs = this.selectedNCS;
      this.$emit("addToCart", this.data);
      this.close();
    },
    setNCS(value) {
      this.search = "NCS ";
    },
    changeSelectedColor(color) {
      this.selectedNCS = color;
    },
    close() {
      this.selectedNCS = null;
      this.show = false;
    },
    changeValueOfExand() {
      if (this.$vssWidth > 600) {
        this.expand = true;
      }
    }
  },
  watch: {
    search(value) {
      if (value && value.includes("-")) {
        var splited = value.split("-");
        if (splited.length === 2) {
          var splitFirst = splited[0].split(" ");
          if (splitFirst.length === 2) {
            if (splitFirst[1].includes("s") || splitFirst[1].includes("S")) {
              this.search =
                splitFirst[0] +
                " " +
                splitFirst[1].slice(0, 1) +
                " " +
                splitFirst[1].slice(1, 5) +
                "-" +
                splited[1];
            }
          }
        }

        var hexColor = ncsColor.hex(value);
        if (hexColor) {
          this.colorList = [value];
        }
      }
    }
  },
  computed: {
    show: {
      get: function() {
        if (this.value) {
          this.changeValueOfExand();
        }
        if (this.data) {
          this.changeSelectedColor(this.data.ncs);
        }
        return this.value;
      },
      set: function(value) {
        this.$emit("input", value);
      }
    },

    getColors() {
      if (this.selectedNCS) {
        colorStore.colors.forEach(x => {
          x.data.forEach(y => {
            if (y.ncs === this.selectedNCS) {
              y.selected = true;
            } else {
              y.selected = false;
            }
          });
        });
      }
      return colorStore.colors;
    }
  }
};
</script>

<style lang="scss" scoped>
.safeAreaDialogTop {
  padding-top: calc(16px + env(safe-area-inset-top)) !important;
}
.safeAreaDialogBottom {
  padding-bottom: calc(16px + env(safe-area-inset-bottom)) !important;
}

.safeAreaDialog {
  padding-left: calc(24px + env(safe-area-inset-left)) !important;
  padding-right: calc(24px + env(safe-area-inset-right)) !important;
}
</style>

<style lang="scss">
.mostUsed {
  font-size: 0.615rem !important;
}
.isSelected {
  border-color: rgb(50, 50, 50) !important;
  border-style: solid;
  border-width: 3px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: initial !important;
    right: 0 !important;
    width: 0;
    height: 0;
    border-radius: initial !important;
    border-top: 27px solid rgb(50, 50, 50) !important;
    border-left: 27px solid transparent !important;
    border-right: none !important;
    border-bottom: none !important;
    opacity: 1 !important;
    background-color: transparent !important;
  }
  &:after {
    content: "\2713 ";
    color: white;
    font-size: 12px;
    position: absolute;
    top: -3px;
    right: 0;
  }
}
</style>
