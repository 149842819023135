import axios from "axios";
import store from "@/store/index";
import notificationsService from "@/notification.services";
import { setupCache } from "axios-cache-adapter";

var counter = 0;

function update() {
  if (counter > 0) {
    store.dispatch("shared/isLoading", true);
  } else {
    store.dispatch("shared/isLoading", false);
  }
}

function updateLoading() {
  if (counter > 0) {
    store.dispatch("shared/loading", true);
  } else {
    store.dispatch("shared/loading", false);
  }
}

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  exclude: { query: false }
});

const baseURL = process.env.VUE_APP_APIURL;

const baseApi = axios.create({
  adapter: cache.adapter,
  baseURL: baseURL
});

// baseApi.defaults.baseURL = baseURL;

baseApi.interceptors.request.use(
  config => {
    let params = new URLSearchParams(window.location.search);
    let idToken = localStorage.getItem("token");

    if (params.has("login")) {
      let token = decodeURIComponent(params.get("login"));
      localStorage.setItem("token", "");
      localStorage.setItem("authorization", token);
      setTimeout(() => {
        window.location.href = window.location.href.substr(
          0,
          window.location.href.indexOf("?")
        );
      }, 200);
    } else if (idToken && idToken.length > 0) {
      let parsed = idToken.startsWith('"') ? JSON.parse(idToken) : idToken;
      config.headers.Authorization = `Bearer ${parsed}`;
    }

    if (config.headers.Authorization == null) {
      config.headers.Authorization =
        "Basic " + localStorage.getItem("authorization");
    }

    var hideLoader =
      (config && config.hideLoader) || (config.data && config.data.hideLoader);

    if (!hideLoader) {
      counter = counter + 1;
      //No delay
      updateLoading();

      setTimeout(update, 200);
    }

    return config;
  },
  error => {
    notificationsService.error(
      "Feil under kall til underliggende tjeneste: " + error.message
    );
    return Promise.reject(error);
  }
);

baseApi.interceptors.response.use(response => {
  var hideLoader =
    (response && response.config && response.config.hideLoader) ||
    (response &&
      response.config &&
      response.config.data &&
      JSON.parse(response.config.data).hideLoader);

  if (!hideLoader) {
    counter = counter - 1;
    updateLoading();

    setTimeout(update, 200);
  }

  return response;
});

baseApi.interceptors.response.use(null, error => {
  var hideLoader =
    (error && error.config && error.config.hideLoader) ||
    (error &&
      error.config &&
      error.config.data &&
      JSON.parse(error.config.data).hideLoader);

  if (!hideLoader) {
    counter = counter - 1;
    updateLoading();

    setTimeout(update, 200);
  }

  if (error.response && error.response.status) {
    switch (error.response.status) {
      case 400:
        var data = error.response.data;

        if (typeof data.errors === "object") {
          var text = "";
          Object.entries(data.errors).forEach(([key, value]) => {
            value.forEach(x => {
              text = text + x + "<br/>";
            });
          });
          error.message = text;
        } else {
          error.message = error.response.data || "Feil under validering";
        }

        notificationsService.error(error.message);
        break;
      case 401:
        error.message = "Du er ikke logget inn";
        notificationsService.error(error.message);
        break;
      case 403:
        error.message = "Du har ikke tilgang til denne operasjonen";
        notificationsService.error(error.message);
        break;
      case 404:
        error.message = "Ikke funnet";
        notificationsService.error(error.message);
        break;
      case 415:
        notificationsService.error("Forespørsel kunne ikke utføres");
        break;
      case 500:
        error.message =
          (error.response.data && error.response.data.error) ||
          "Feil på server";
        notificationsService.error(error.message);
        break;
    }
  } else {
    error.message = "Ingen kontakt med server";
    notificationsService.error(error.message);
  }

  return Promise.reject(error);
});

export default baseApi;
