var hubConnection = null;

function startConnection(sectionId, customerId) {
  return new Promise((resolve, reject) => {
    if (!hubConnection) {
      var signalR = window.signalR;

      var hubUrl = "#{MT.HubAddress}";
      hubUrl = hubUrl.indexOf("#") > -1 ? "https://localhost:4496" : hubUrl;

      hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(hubUrl + "/section", {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets
        })
        .withAutomaticReconnect()
        .build();

      //ToDo: Kalles etter registrering
      //     //component.hubConnection.invoke("SendOnPurchaseRegistration", component.$route.params.sectionId, customerId);

      //     //ToDo: Kalles etter navigering til tilvalg
      //     // component.hubConnection.invoke(
      //     //   "SendOnPurchaseOptions",
      //     //   component.$route.params.sectionId,
      //     //   component.profile.customer.id
      //     // );

      //     //ToDo: Kalles etter navigering til tilvalg
      //     //component.hubConnection.invoke("SendOnPurchaseColorOptions", component.$route.params.sectionId, customerId);

      //     //ToDo: Kalles etter navigering til oppsummering
      //     //component.hubConnection.invoke("SendOnPurchaseSummary", component.$route.params.sectionId, customerId);

      //     //ToDo: Kalles etter navigering til kjøpetilbud
      //     //component.hubConnection.invoke("SendOnPurchaseOffer", component.$route.params.sectionId, customerId);

      hubConnection
        .start()
        .then(function() {
          hubConnection.invoke("ConnectHub", parseInt(sectionId));
          resolve(hubConnection);
        })
        .catch(function(err) {});
    } else {
      resolve(hubConnection);
    }
  });
}

function sendPurchaseProgress(sendPurchaseProgressName, sectionId, customerId) {
  if (
    sendPurchaseProgressName &&
    hubConnection &&
    hubConnection.connectionStarted
  ) {
    hubConnection.invoke(
      sendPurchaseProgressName,
      parseInt(sectionId),
      customerId
    );
  }
}

function sectionUnavailable() {
  return new Promise((resolve, reject) => {
    if (hubConnection) {
      hubConnection.on("SectionUnavailable", function() {
        resolve(true);
      });
    }
  });
}

export default { startConnection, sendPurchaseProgress, sectionUnavailable };
