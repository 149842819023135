import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"600","persistent":"","fullscreen":_vm.$store.getters['shared/isMobileDevice']},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary--text"},[_vm._v(" Velkommen til Mitt Trysilhus ")]),_c(VCardText,[_c('p',{staticClass:"body-2"},[_vm._v(" Gratulerer med kjøp av ny bolig! Mitt Trysilhus er vår kundeportal hvor du vil finne nyttig informasjon om din bolig. ")]),_c('p',{staticClass:"body-2 mb-5"},[_vm._v(" Vi ønsker at du bekrefter at du vil motta varsler om svar og endringer på dine henvendelser i Mitt Trysilhus på e-post, og at vi kan sende deg nyttige tips og oppdateringer i nyhetsbrev. ")]),_c('VideoPlayer',{attrs:{"src":"https://thg.blob.core.windows.net/publicvideos/MittTrysilhus_v3.mp4"}}),_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,{attrs:{"multiple":""}},[_c(VListItem,[_c(VListItemAction,[_c(VCheckbox,{attrs:{"color":"success","hide-details":""},model:{value:(_vm.getProfile.acceptNewsLetter),callback:function ($$v) {_vm.$set(_vm.getProfile, "acceptNewsLetter", $$v)},expression:"getProfile.acceptNewsLetter"}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Automatisk nyhetsbrev")]),_c(VListItemSubtitle,[_vm._v(" Tips og oppdateringer om boligen sendes i automatiske nyhetsbrev ")])],1)],1)],1)],1),_c('div',{staticClass:"conditions-checkbox py-2 my-1 ml-2"},[_c(VCheckbox,{staticClass:"condition-checkbox",attrs:{"color":"success","rules":[
                function (v) { return !!v ||
                  'Personvernerklæringen må være godkjent for å gå videre'; }
              ],"hide-details":"auto"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"ml-8"},[_c('h4',[_c('a',{attrs:{"target":"_blank","href":"https://www.trysilhus.no/personvern/"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Personvernerklæring ")])])])]},proxy:true}]),model:{value:(_vm.privacyStatement),callback:function ($$v) {_vm.privacyStatement=$$v},expression:"privacyStatement"}})],1)],1),_c(VCardActions,{staticClass:"pb-6"},[_c(VBtn,{staticClass:"mx-auto",attrs:{"large":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.save(_vm.getProfile)}}},[_vm._v(" Bekreft ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }