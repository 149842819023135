import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"600","hide-overlay":_vm.hideOverlay,"fullscreen":_vm.$store.getters['shared/isMobileDevice'],"transition":"scroll-x-transition"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{staticClass:"safeAreaDialog"},[_c(VCardTitle,[_c('h2',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.title))])]),_c(VCardText,[_c('p',{staticClass:"mb-4 body-1",domProps:{"innerHTML":_vm._s(_vm.DialogMessage)}}),_c(VCardActions,{staticClass:"pa-0"},[_c('div',{staticClass:"d-none d-sm-flex flex-grow-1"}),(_vm.DialogAccept)?_c(VBtn,{attrs:{"large":"","color":"primary"},on:{"click":_vm.accept}},[_vm._v("Gå videre")]):_vm._e(),(_vm.DialogAccept)?_c(VBtn,{attrs:{"large":"","outlined":""},on:{"click":_vm.close}},[_vm._v("Lukk")]):_c(VBtn,{attrs:{"large":"","color":"primary"},on:{"click":_vm.close}},[_vm._v("Lukk")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }