<template>
  <div>
    <RoomColorContainer />
  </div>
</template>

<script>
import RoomColorContainer from "@/components/store/webStore/color/container/RoomColorContainer.vue";
export default {
  components: {
    RoomColorContainer
  }
};
</script>
