import baseApi from "./baseApi";
import notificationsService from "@/notification.services";

const api = {
  async commentIsViewed(commentId) {
    return await baseApi
      .post(`/complaint/comment/${commentId}/viewed`)
      .then(response => {
        return response.data;
      })
      .catch(() => {
        notificationsService.error("Kunne ikke sette kommentar som lest");
        return undefined;
      });
  },

  async getUnreadComments() {
    return await baseApi
      .get(`/complaints/unreadcomments`, {
        cache: {
          maxAge: 0 // 0 min instead of 15 min
        }
      })
      .then(response => {
        return response.data;
      })
      .catch(() => {
        notificationsService.hide();
        return [];
      });
  }
};

export default api;
