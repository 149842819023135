//v-truncate="10" or v-truncate
const truncate = {
  bind(el, binding, vnode) {
    var name = vnode.children[0].text.trim();
    var titleLength = name.length;
    var cut = binding.value;

    if (!cut) {
      cut = 20;
    }

    if (titleLength > cut) {
      var nameDisplay = name.substring(0, cut);
      nameDisplay = nameDisplay + "...";
      el.innerHTML = nameDisplay;
    } else {
      el.innerHTML = name;
    }
  }
};

export default truncate;
