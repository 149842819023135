<template>
  <WebShopContainer
    intro
    @next="next"
    send-purchase-progress="SendOnPurchaseColorOptions"
  >
    <template slot="navigation">
      <ColorRoomNavigation />
    </template>
    <IntroVideoComponent
      title="Fargevelger"
      background-image="https://thg.blob.core.windows.net/public-images/fargevalgbanner.jpg"
      videoUrl="https://thg.blob.core.windows.net/publicvideos/Film_farger_FINAL.m4v"
      data-poster="https://thg.blob.core.windows.net/public-images/Camilla_farger.jpg"
    >
      <p>
        Når du kjøper en bolig av Trysilhus, har du mulighet til å sette ditt
        preg på leiligheten ved bruk av farger. Du har muligheten til å endre
        hovedfarge, endre farge i noen av rommene eller ha en kontrastvegg i ett
        eller flere av rommene.
      </p>
      <p>
        På de neste sidene kan du velge farger rom for rom. Det er brukt
        NCS-koder fordi dette er den mest korrekte måten å angi farger på. Husk
        også at skjermen din ikke gir en korrekt angivelse av fargen. For å være
        sikker på hvordan fargen ser ut, bør du be om en fargeprøve hos en
        fargehandler.
      </p>
      <p>
        Når du legger til en farge, vil du se at leilighetsprisen i bunnen av
        siden endrer seg. Merk at du vil ha mulighet til å velge farger på et
        senere tidspunkt hvis du ønsker.
      </p>
      <p>
        Vil du ha inspirasjon til valg av farger i din bolig? Les mer i vår
        fargevalgsbrosjyre. (nedlastbar)
      </p>
      <a
        href="https://thg.blob.core.windows.net/public-assets/2103_Fargevalgsbrosjyre.pdf"
        target="_blank"
      >
        <v-icon color="primary">mdi-file-download-outline</v-icon>
        Last ned fargevalgsbrosjyre
      </a>
    </IntroVideoComponent>
    <IntroWebShopDialog />
  </WebShopContainer>
</template>

<script>
import WebShopContainer from "@/components/store/webStore/shared/WebShopContainer.vue";
import IntroVideoComponent from "@/components/store/webStore/shared/IntroVideoComponent.vue";
import ColorRoomNavigation from "@/components/store/webStore/color/children/ColorRoomNavigation.vue";
import IntroWebShopDialog from "@/components/store/webStore/shared/dialogs/IntroWebShopDialog.vue";

export default {
  methods: {
    next() {
      this.$router.push({
        name: "webShopRoomColor",
        params: { roomId: -1 }
      });
    }
  },
  components: {
    WebShopContainer,
    IntroVideoComponent,
    ColorRoomNavigation,
    IntroWebShopDialog
  }
};
</script>
