<template>
  <div>
    <WebShopContainer
      disabled
      intro
      @next="validateForm"
      send-purchase-progress="SendOnPurchaseRegistration"
    >
      <IntroContainer
        :title="title"
        imageUrl="https://www.trysilhus.no/wp-content/uploads/2019/01/Interior-001c_web.jpg"
      >
        <template v-slot:text>
          <div v-html="infoText" />
        </template>

        <VueRecaptcha
          ref="recaptcha"
          sitekey="6LdD_4IaAAAAAP8QHumsOtfMIqbNQ3lW4E6FgPKk"
          :loadRecaptchaScript="true"
          type="invisible"
          badge="inline"
          size="invisible"
          @verify="responseRecaptcha"
        >
        </VueRecaptcha>
        <v-form
          v-if="data && data.customer"
          class="mt-12 mb-4"
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            class="rounded-0"
            v-model="data.customer.firstName"
            :rules="[v => !!v || 'Fornavn må fylles ut!']"
            label="Fornavn*"
            placeholder="Hva er fornavnet ditt?"
            outlined
            dense
            autocomplete="given-name"
          ></v-text-field>
          <v-text-field
            class="rounded-0"
            v-model="data.customer.lastName"
            :rules="[v => !!v || 'Etternavn må fylles ut!']"
            label="Etternavn*"
            placeholder="Hva er etternavnet ditt?"
            outlined
            dense
            autocomplete="family-name"
          ></v-text-field>

          <MobileNumber v-model="data.customer.mobile" outlined dense />

          <v-text-field
            class="rounded-0"
            v-model="data.customer.email"
            type="email"
            :rules="[rules.emailRequired, emailErrors]"
            label="E-post*"
            placeholder="navn@eksempel.no"
            required
            outlined
            dense
            @change="emailChanged"
            autocomplete="email"
          ></v-text-field>

          <v-checkbox
            v-model="data.customer.checkbox"
            class="mt-0 pt-0"
            :rules="[v => !!v]"
            required
          >
            <template v-slot:label>
              <div>
                Jeg bekrefter at Trysilhusgruppen kan ta kontakt for å gi
                relevant informasjon om aktuelle boligprosjekter. Samtykket kan
                trekkes tilbake når som helst. Les vår personvernerklæring:
                <a
                  target="_blank"
                  href="https://www.trysilhus.no/personvern/"
                  @click.stop
                >
                  Les mer
                </a>
              </div>
            </template>
          </v-checkbox>
        </v-form>
      </IntroContainer>
      <UserAlreadyRegisteredDialog
        v-model="showUserRegistered"
        :profile="data"
        @accept="next"
      />
    </WebShopContainer>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { mapGetters, mapActions } from "vuex";
import purchaseApi from "@/api/purchaseApi";
import notifications from "@/notification.services";
import moment from "moment";
//components
import WebShopContainer from "@/components/store/webStore/shared/WebShopContainer.vue";
import IntroContainer from "@/components/store/webStore/shared/IntroContainer.vue";
import UserAlreadyRegisteredDialog from "@/components/store/webStore/shared/dialogs/UserAlreadyRegisteredDialog.vue";
import MobileNumber from "@/components/shared/textField/MobileNumber";

export default {
  data() {
    return {
      showUserRegistered: false,
      data: {
        customer: {
          firstName: null,
          lastName: null,
          mobile: null,
          email: null,
          checkbox: false
        }
      },
      emailFinishedChange: false,
      valid: true,

      rules: {
        emailRequired: value => !!value || "E-post må fulles ut!",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Ugyldig e-post";
        }
      },
      notifications
    };
  },
  created() {
    //Set profile if profile is saved
    this.setProfile()
      .then(profile => {
        this.data = JSON.parse(JSON.stringify(profile));
        if (
          parseInt(this.data.sectionId) !==
          parseInt(this.$route.params.sectionId)
        ) {
          this.data = {
            customer: {
              id: this.data.customer.id,
              email: this.data.customer.email,
              firstName: this.data.customer.firstName,
              lastName: this.data.customer.lastName,
              mobile: this.data.customer.mobile
            },
            options: {}
          };
          this.updateProfile(JSON.parse(JSON.stringify(this.data)));
        }

        this.userRegistered(this.data);
        this.$refs.form.resetValidation();
      })
      .catch(error => {
        //Ingen profil registrert
      });
  },
  methods: {
    ...mapActions({
      setProfile: "purchase/setProfile",
      updateProfile: "purchase/updateProfile",
      setOptions: "purchase/setOptions"
    }),
    validateForm() {
      let isValid = this.$refs.form.validate();
      if (isValid) {
        this.$refs.recaptcha.execute();
      } else {
        this.$vuetify.goTo(this.$vssHeight);
        var notificationsMessage = "Alle felter må være fylt ut!";
        if (isValid && !this.recaptchaVerified) {
          notificationsMessage = `Du må vise oss at du ikke er en robot. Vennligst huk av "Jeg er ikke en robot"`;
        }
        notifications.warn(notificationsMessage);
      }
    },
    async save() {
      //If customer is registrered
      if (this.data.customer.id) {
        var customer = this.data.customer;
        var savedProfile = this.profile.customer;
        //Check if somthing is changed
        if (
          customer.firstName !== savedProfile.firstName ||
          customer.lastName !== savedProfile.lastName ||
          customer.mobile !== savedProfile.mobile ||
          customer.email !== savedProfile.email
        ) {
          this.data = {
            customer: {
              firstName: customer.firstName,
              lastName: customer.lastName,
              mobile: customer.mobile,
              email: customer.email,
              checkbox: customer.checkbox
            }
          };
        }
      }

      //delete profile i vuex to get selected options from album
      this.updateProfile(null);

      this.data.customer.project = this.sectionInfo.projectId;
      this.data.customer.gender = 0;

      await purchaseApi
        .registerInterested(this.$route.params.sectionId, this.data.customer)
        .then(response => {
          this.data = response;
          //Convert mobile string to int
          this.data.buyers.forEach(x => (x.mobile = parseInt(x.mobile)));
          this.data.customer.mobile = parseInt(this.data.customer.mobile);
          this.data.includeAttic = false;

          this.updateProfile(this.data);
          this.$refs.form.resetValidation();
          //Open intro dialog every time new interested
          localStorage.removeItem("introDialogOpened");
          this.setOptions(this.$route.params.sectionId).then(() => {
            this.next();
          });
        });
    },
    userRegistered(userData) {
      if (userData.sectionId === parseInt(this.$route.params.sectionId)) {
        this.showUserRegistered = true;
      }
    },
    emailChanged() {
      this.emailFinishedChange = true;
    },
    next() {
      this.$router.push({ name: "webShopOptionsIntro" });
    },
    responseRecaptcha(data) {
      this.save();
    }
  },

  computed: {
    ...mapGetters({
      sectionInfo: "purchase/sectionInfo",
      profile: "purchase/profile"
    }),
    emailErrors() {
      if (this.emailFinishedChange) {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return value => pattern.test(value) || "Ugyldig e-post";
      }

      return true;
    },
    canChangeOptionsOrColor() {
      if (
        this.sectionInfo &&
        ((this.sectionInfo.isOptionsAvailable &&
          !this.sectionInfo.allOptionsExpired) ||
          (this.sectionInfo.isColorOptionsAvailable &&
            !moment(moment().format()).isAfter(
              this.sectionInfo.colorOptionsDeadline
            )))
      ) {
        return true;
      }

      return false;
    },
    title() {
      if (this.canChangeOptionsOrColor) {
        return "Tilpass bolig";
      }

      return "Send et elektronisk kjøpetilbud";
    },
    infoText() {
      if (this.canChangeOptionsOrColor) {
        return `I denne løsningen har du mulighet til å gjøre tilpasninger, velge farger og se en totalpris for boligen. 
        Du har også mulighet til å fylle ut kjøpetilbud for boligen du ønsker deg. Fyll inn kontaktinformasjon for å tilgang. 
        Ingen bindende avtale er inngått før kjøpetilbud er sendt inn og akseptert av selger.<br/><br/>
        Valgene dine blir lagret i nettleseren din, og vil være tilgjengelige på den samme seksjonen neste gang du går inn i løsningen.`;
      }

      return `I denne løsningen har du mulighet til å fylle ut et kjøpetilbud for boligen du ønsker deg. 
              Fyll inn kontaktinformasjon for å få tilgang. Ingen bindende avtale er 
              inngått før kjøpetilbud er sendt inn og akseptert av selger.<br/><br/>
              Valgene dine blir lagret i nettleseren din, og vil være tilgjengelige på den samme seksjonen neste gang du går inn i løsningen.`;
    }
  },
  components: {
    VueRecaptcha,
    WebShopContainer,
    IntroContainer,
    UserAlreadyRegisteredDialog,
    MobileNumber
  }
};
</script>

<style lang="scss">
.grecaptcha-badge {
  display: none;
}
</style>
