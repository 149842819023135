<template>
  <div>
    <v-dialog
      v-model="show"
      :max-width="maxWidth"
      :hide-overlay="hideOverlay"
      :persistent="persistent"
    >
      <v-card>
        <v-card-title>
          <span class="headline primary--text">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <span style="font-size: 16px;" v-html="DialogMessage"></span>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn depressed :color="acceptButtonColor" @click="accept">
            {{ acceptButtonText }}
          </v-btn>
          <v-btn
            v-if="!hideCancelButton"
            depressed
            outlined
            color="error"
            @click="cancel"
            >avbryt</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    DialogMessage: {
      type: String,
      required: true
    },
    maxWidth: {
      type: String,
      default: "600"
    },
    hideOverlay: {
      type: Boolean,
      default: true
    },
    responsData: {
      default: true
    },
    persistent: {
      type: Boolean,
      default: false
    },
    acceptButtonText: {
      type: String,
      default: "Ja"
    },
    acceptButtonColor: {
      type: String,
      default: "success"
    },
    hideCancelButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    accept() {
      this.show = false;
      //this.responsData is true if not prop is added
      this.$emit("confirme", this.responsData);
    },
    cancel() {
      this.show = false;
      this.$emit("cancel", this.responsData);
    }
  },
  computed: {
    show: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
