<template>
  <v-dialog v-model="show" max-width="600" persistent>
    <v-card class="pt-4 pb-8">
      <v-card-title
        style="word-break: break-word;"
        class="primary--text display-1 font-weight-bold"
      >
        Registrert som interessent
      </v-card-title>
      <v-card-text style="font-size: 1rem;" v-if="name">
        <strong>{{ name }}</strong>
        er registrert som interessent på denne seksjonene. Vil du fortsette
        eller registrere ny interessent?
      </v-card-text>
      <v-card-actions class="ml-2">
        <v-btn class="px-4 mr-1" depressed color="primary" @click="accept">
          Fortsett
        </v-btn>
        <v-btn depressed outlined color="darkgrey" @click="cancel">
          <span class="d-none d-sm-block">Registrer ny interessent</span>
          <span class="d-block d-sm-none">Ny interessent</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    profile: {
      required: true
    }
  },
  methods: {
    accept() {
      this.show = false;
      this.$emit("accept", true);
    },
    cancel() {
      this.show = false;
      this.$emit("cancel", true);
    }
  },
  computed: {
    show: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        this.$emit("input", value);
      }
    },
    name() {
      if (this.profile && this.profile.customer) {
        return (
          this.profile.customer.firstName + " " + this.profile.customer.lastName
        );
      }

      return null;
    }
  }
};
</script>
