<template>
  <v-row class="flex-column flex-sm-row">
    <v-col
      class="mt-4"
      sm="6"
      lg="4"
      v-for="(inquiryItem, index) in data"
      :key="inquiryItem.id"
    >
      <v-card
        min-height="160"
        @click="inquiry(index, inquiryItem.id)"
        hover
        class="theme-shadow request-card"
      >
        <v-overlay
          class="request-card-overlay"
          v-if="inquiryItem.regStatus === 'Lukket'"
          absolute
          opacity="0.93"
          :value="getOverlay[index]"
        >
          <v-btn
            class="clickyCard-overlay-closeBtn"
            fab
            x-small
            color="white"
            @click="showOverlay(index)"
          >
            <v-icon class="red--text">mdi-window-close</v-icon>
          </v-btn>
          <h3>Vurder min sak</h3>
          <p>
            Hjelp oss forbedre vår kundeservice ved å gi oss din tilbakemelding
          </p>
          <v-rating
            v-model="inquiryItem.customerRating"
            background-color="grey"
            color="yellow accent-4"
            hover
            size="20"
          ></v-rating>
          <!-- <v-btn rounded elevation="0" x-small color="primary"
              >Legg til kommentar</v-btn
          >-->
        </v-overlay>

        <v-row>
          <v-col
            class="card-icon text-center"
            :class="{
              'closed-case':
                inquiryItem.regStatus === 'Lukket' && $route.name === 'requests'
            }"
            cols="4"
          >
            <!-- <v-badge class="comments" overlap color="green" :value="false"> -->
            <v-badge
              class="comments"
              overlap
              color="green"
              :value="
                inquiryItem.newCommentsCount > 0
                  ? inquiryItem.newCommentsCount
                  : false
              "
            >
              <template v-if="inquiryItem.newCommentsCount > 0" v-slot:badge>
                {{ inquiryItem.newCommentsCount }}
              </template>
              <v-btn color="white" elevation="2" fab x-small>
                <v-icon
                  v-bind:class="{
                    'yellow--text': inquiryItem.commentsCount > 0,
                    'lightgrey--text': inquiryItem.commentsCount === 0
                  }"
                  >mdi-message-reply-text</v-icon
                >
              </v-btn>
            </v-badge>
            <v-icon class="pb-6 icon request-type-icon">
              {{
                inquiryItem.isInquiry
                  ? "mdi-headset"
                  : " mdi-clipboard-alert-outline"
              }}
            </v-icon>
            <small class="status">
              {{
                inquiryItem.isInquiry
                  ? "Generell henvendelse"
                  : "Reklamasjons-henvendelse"
              }}
            </small>
            <v-btn
              v-if="
                inquiryItem.regStatus === 'Lukket' &&
                  !inquiryItem.customerRating &&
                  $route.name === 'requests'
              "
              rounded
              class="rateInquiryBadge white--text rating-btn text-uppercase mt-2"
              x-small
              elevation="0"
              color="orange"
              @click="showOverlay(index)"
            >
              Vurder
              <i class="fas fa-check ml-1"></i>
            </v-btn>
            <v-rating
              class="request-card-rating"
              dense
              v-if="
                inquiryItem.regStatus === 'Lukket' &&
                  inquiryItem.customerRating &&
                  $route.name === 'requests'
              "
              v-model="inquiryItem.customerRating"
              background-color="grey"
              color="yellow accent-4"
              hover
              readonly
              size="13"
            ></v-rating>
          </v-col>
          <v-col cols="8">
            <v-list dense>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <h3 class="primary--text">
                      {{ inquiryItem.name }}
                    </h3>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <ul>
                <li
                  class="mt-1"
                  v-bind:class="{
                    'green--text': statusGreen(inquiryItem)
                  }"
                >
                  <v-icon
                    v-bind:class="{
                      'green--text': statusGreen(inquiryItem)
                    }"
                    >mdi-folder-sync-outline</v-icon
                  >
                  {{ inquiryItem.regStatus }}
                </li>
                <li>
                  <v-icon>mdi-folder-pound-outline</v-icon>
                  Saksnr: {{ inquiryItem.lineId }}
                </li>
                <li>
                  <v-icon>mdi-folder-search-outline</v-icon>
                  Kilde: {{ inquiryItem.source }}
                </li>
              </ul>
            </v-list>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-col
      class="pt-4"
      v-if="profile && profile.user.isOwnerToday"
      sm="6"
      lg="4"
    >
      <v-card @click="newRequest" class="theme-shadow clickyCard" ripple hover>
        <v-img
          :src="require('@/assets/imgs/gfx/add_inquiry.svg')"
          height="175"
        ></v-img>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import api from "@/api/api";
export default {
  props: ["data"],
  data() {
    return {
      overlay: false,
      showFilter: { show: false },
      showDialog: { show: false },
      dialogAccept: false,
      closeOverlay: false,
      filterData: []
    };
  },
  watch: {
    data: {
      handler(newValue, oldValue) {
        //if rating is changed
        if (newValue.length > 0 && oldValue.length > 0) {
          //Find rated. hven rating is not in database from before
          let filterRating = newValue.filter(x => {
            return x.customerRating && !x.hasRating;
          });
          filterRating.map(x => {
            let data = {};
            data.id = x.id;
            data.rating = x.customerRating;
            //save rating to database
            this.saveRating(data);
          });
        }
      },
      deep: true
    }
  },
  methods: {
    inquiry(index, id) {
      if (
        this.getOverlay[index] === false &&
        this.closeOverlay === false &&
        this.$route.name === "requests"
      ) {
        this.$router.push({ name: "request", params: { id: id } });
      } else if (
        this.getOverlay[index] === false &&
        this.closeOverlay === false
      ) {
        this.$router.push({ name: "JointOwnership", params: { id: id } });
      } else {
        this.closeOverlay = false;
      }
    },
    async saveRating(data) {
      await api.saveComplaintRate(data);
    },
    newRequest() {
      this.$emit("newRequest", true);
    },
    showOverlay(index) {
      if (this.getOverlay[index] === false) {
        this.$set(this.getOverlay, index, true);
      } else {
        this.$set(this.getOverlay, index, false);
        this.closeOverlay = true;
      }
    },
    statusGreen(data) {
      return (
        data.regStatus === "Åpen" ||
        data.regStatus === "Startet" ||
        data.regStatus === "Gjenåpnet" ||
        data.regStatus === "Ettårsbefaring"
      );
    },
    statusRed(data) {
      return (
        data.regStatus === "Lukket" ||
        data.regStatus === "Avslått" ||
        data.regStatus === "Ferdigstilt"
      );
    }
  },
  computed: {
    ...mapGetters({
      overlayTable: "request/sectionOverlayTable",
      jointOverlayTable: "request/jointOverlayTable",
      profile: "shared/profile"
    }),
    getOverlay() {
      if (this.overlayTable.length > 0 && this.$route.name === "requests") {
        return this.overlayTable;
      } else if (
        this.jointOverlayTable.length > 0 &&
        this.$route.name === "JointOwnerships"
      ) {
        return this.jointOverlayTable;
      }
      return [];
    }
  }
};
</script>
<style lang="scss">
.removeCircleBg {
  i {
    &:after {
      content: none !important;
    }
  }
}
@media (max-width: 600px) {
  .removeCircleBg {
    .v-alert__content {
      font-size: 0.9rem;
    }
    .v-alert__icon {
      display: none !important;
    }
  }
}
</style>
