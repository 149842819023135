<template>
  <v-card elevation="0" class="mb-md-6">
    <v-list color="grey lighten-4" elevation="2">
      <v-list-item>
        <div class="d-flex" style="width: 100%;">
          <v-list-item-content>
            <v-list-item-subtitle class="caption">
              Fargevalg lagt på alle rom
            </v-list-item-subtitle>
            <v-list-item-title class="font-weight-bold headline primary--text">
              Hovedfarge bolig
              <v-btn
                @click="showDialog.show = true"
                x-small
                icon
                outlined
                color="grey"
              >
                <v-icon small>mdi-help</v-icon>
              </v-btn>
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action style="height: 35px;">
            <v-list-item-action-text>
              <v-btn
                @click="resetColor(data)"
                :disabled="disabledReset"
                x-small
                depressed
                outlined
                color="grey"
                >Tilbakestill</v-btn
              >
            </v-list-item-action-text>
          </v-list-item-action>
        </div>
      </v-list-item>
      <v-list-item>
        <template>
          <v-btn
            :class="{ 'disable-btn': disabled }"
            @click="openColorDialog(data)"
            class="justify-space-between"
            :color="data.hexColor"
            block
            height="36"
          >
            <v-chip x-small>
              {{ data.ncs }}
            </v-chip>
            <v-chip x-small>
              {{
                optionsService.formatPrice(
                  data[typePrice] ? data[typePrice] : 0
                )
              }}
            </v-chip>
          </v-btn>
        </template>
      </v-list-item>
      <AcceptCancel
        v-model="showAccept"
        title="Tilbakestille hovedfarge"
        DialogMessage="Er du sikker på at du vil tilbakstille hovedfargen?"
        @confirme="reset"
      />
      <DialogMessage
        :show="showDialog"
        :title="title"
        :DialogMessage="DialogMessage"
        :DialogAccept="false"
        :hideOverlay="false"
      />
    </v-list>
  </v-card>
</template>

<script>
import optionsService from "@/options.services";
import AcceptCancel from "@/components/dialog/AcceptCancel";
import DialogMessage from "@/components/dialog/DialogMessage";
export default {
  props: {
    value: {
      required: true
    },
    typePrice: {
      default: "price"
    },
    disabledReset: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showAccept: false,
      deleteFromCart: null,
      showDialog: { show: false },
      title: "Hovedfarge bolig",
      DialogMessage: `Standard hovedfarge i boligen er NCS 0801-Y25R. Dette er fargen Jotun kaller «Refleksjon». Du kan endre hovedfarge ved å klikke her. Fargen du velger vil da bli brukt i alle rom så sant du ikke angir andre farger i de ulike rommene i fargevelgeren.`,
      optionsService
    };
  },
  methods: {
    openColorDialog(data) {
      this.$emit("changeColor", { data, constrastWallRoomName: undefined });
    },
    resetColor(data) {
      this.deleteFromCart = data;
      this.showAccept = true;
    },
    reset() {
      this.$emit("reset", this.deleteFromCart);
    }
  },
  computed: {
    data: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        this.$emit("input", value);
      }
    }
  },
  components: {
    AcceptCancel,
    DialogMessage
  }
};
</script>

<style lang="scss" scoped>
.disable-btn {
  pointer-events: none;
}
</style>
