<template>
  <div>
    <v-dialog
      class="filter"
      v-model="showDialog"
      max-width="600"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card height="400">
        <v-card-text class="pt-5">
          <v-text-field
            v-model="search"
            label="Søk..."
            clearable
            hide-no-data
            autocomplete="off"
            dense
          ></v-text-field>
          <v-select
            v-model="filterCategory"
            :items="getCategory"
            clearable
            label="Filtrer etter status"
            hide-no-data
          ></v-select>
          <v-btn large width="100%" color="success" @click="filter">Søk</v-btn>
          <v-btn
            v-if="this.search || this.filterCategory"
            class="mt-4"
            large
            width="100%"
            outlined
            color="error"
            @click="deleteFilter"
            >Fjern filteret</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["show", "getCategory"],
  data() {
    return {
      search: null,
      filterCategory: null
    };
  },
  methods: {
    ...mapActions({
      mobileNavBtn: "shared/mobileNavBtn"
    }),
    filter() {
      this.$emit("filter", this.search, this.filterCategory);
      this.close();
    },
    deleteFilter() {
      this.search = null;
      this.filterCategory = null;
      this.filter();
    },
    close() {
      this.mobileNavBtn(false);
      this.show.show = false;
    }
  },
  computed: {
    showDialog: {
      get: function() {
        if (this.show.show) {
          return this.show.show;
        }
        return false;
      },
      set: function() {
        this.close();
      }
    }
  }
};
</script>
