<template>
  <v-row class="justify-center">
    <v-col class="pt-0" xl="9">
      <v-card
        v-if="!$store.getters['shared/isMobileDevice']"
        class="transparent mt-6"
        flat
      >
        <v-list color="transparent">
          <v-list-item class="pl-0 mb-2">
            <v-list-item-avatar>
              <v-icon class="primary white--text">mdi-folder</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                class="primary--text headline font-weight-black"
              >
                Dokumenter
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ getUser }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content
              class="d-none d-sm-flex text-right justify-end"
            >
              <small style="max-width: 400px;">
                FDV for fellesområder kan inneholde lite informasjon i tiden
                frem til full overlevering av fellesområde til deg som kunde
              </small>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>
      </v-card>
      <v-treeview
        v-if="getData.length > 0"
        v-model="tree"
        :open.sync="open"
        :items="getData"
        item-key="uuid"
        open-on-click
        return-object
      >
        <template v-slot:prepend="{ item, open }">
          <span v-if="!item.extension">
            <v-badge
              v-if="
                item.numberOfUnreadDocuments && item.numberOfUnreadDocuments > 0
              "
              :content="item.numberOfUnreadDocuments"
              color="warning"
              overlap
            >
              <v-icon color="yellow">{{
                open ? "mdi-folder-open" : "mdi-folder"
              }}</v-icon>
            </v-badge>
            <v-icon v-else color="yellow">{{
              open ? "mdi-folder-open" : "mdi-folder"
            }}</v-icon>
          </span>
          <span v-else>
            <v-icon
              v-if="item.extension && item.extension !== 'getdata'"
              :color="item.isDownloaded === false ? 'success' : 'transparent'"
            >
              mdi-circle-medium
            </v-icon>
            <v-icon color="primary">
              {{ files[item.extension] }}
            </v-icon>
          </span>
        </template>

        <template v-slot:label="{ item }">
          <div class="ml-2" @click="openDocument(item)">
            {{ item.name }}
            <span
              v-if="
                item.extension &&
                  item.extension !== 'getdata' &&
                  item.isDownloaded === false
              "
              class="success--text"
              style="font-style: oblique"
            >
              Ny!
            </span>
          </div>
        </template>
      </v-treeview>

      <v-alert v-if="!loading && getData.length === 0" class="mt-6" type="info">
        Ingen dokumenter tilgjengelig
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import baseApi from "@/api/api";
import sort from "@/helpers/sort.js";
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    data: [],
    profile: null,
    open: [],
    saveOpen: [],
    files: {
      ".html": "mdi-language-html5",
      ".js": "mdi-nodejs",
      ".json": "mdi-json",
      ".md": "mdi-markdown",
      ".pdf": "mdi-file-pdf-box",
      ".png": "mdi-file-image",
      ".jpg": "mdi-file-image",
      ".txt": "mdi-file-document-outline",
      ".xls": "mdi-file-excel",
      ".xlsx": "mdi-file-excel-outline",
      ".doc": "mdi-file-word",
      ".docx": "mdi-file-word-outline"
    },
    tree: []
  }),
  watch: {
    open(item) {
      if (item.length !== 0 && !item.extension) {
        if (this.open.length >= this.saveOpen.length) {
          if (!item[item.length - 1].children[0].uuid) {
            this.getDokuments(item[item.length - 1]);
          }
        }
        this.saveOpen = [...this.open];
      }
    }
  },
  methods: {
    ...mapActions({
      setUnreadDocuments: "documents/setUnreadDocuments"
    }),
    async getDokuments(item) {
      let getModelDocs = item.name.toLowerCase().includes("boligperm");
      await baseApi.getDocument(item.uuid, getModelDocs).then(Response => {
        Response.documents.forEach(y => {
          if (!y.extension) {
            y.children = [{ name: "Henter data...", extension: "getdata" }];
          }
        });
        if (Response.documents.length === 0) {
          item.children = [
            { name: "Ingen filer i mappen", extension: "noFiles" }
          ];
        } else {
          var res = this.multiSortArrayObject(Response.documents);
          item.children = res;
        }
      });
    },
    openDocument(doc) {
      if (doc.extension && doc.extension !== "noFiles") {
        baseApi.downloadDocument(doc).then(() => {
          if (doc.isDownloaded === false) {
            doc.isDownloaded = true;
            this.setUnreadDocuments();
            this.updateNumberOfUnreadDocuments(doc);
          }
        });
      }
    },
    updateNumberOfUnreadDocuments(docDownloaded) {
      this.getData.forEach(x => {
        if (x.numberOfUnreadDocuments > 0) {
          x.children.forEach(y => {
            if (y.uuid === docDownloaded.uuid) {
              x.numberOfUnreadDocuments = x.numberOfUnreadDocuments - 1;
            }
          });
        }
      });
    },
    multiSortArrayObject(data) {
      return data.sort(function(a, b) {
        // Sort by key1 Boolean
        if (a.extension && !b.extension) {
          return 1;
        }
        if (!a.extension && b.extension) {
          return -1;
        }
        // //Sort by key2
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        return 0;
      });
    }
  },
  computed: {
    ...mapGetters({
      getDocuments: "saveData/documents",
      getProfile: "shared/profile",
      loading: "shared/loading"
    }),
    getData() {
      if (this.getDocuments.length) {
        var documents = this.multiSortArrayObject(this.getDocuments);
        // return this.getDocuments
        return documents;
      }
      return [];
    },
    getUser() {
      if (this.getProfile) {
        return this.getProfile.user.currentSection.name;
      }
      return "";
    }
  }
};
</script>
