<template>
  <div v-if="$vuetify.breakpoint.smAndDown">
    <div class="d-flex justify-space-between py-3 px-3">
      <v-label>Egen bolig kost pr. mnd.: </v-label>
      <v-label small hide-details>{{ gethouseMonthlyCost }}</v-label>
    </div>
    <v-divider hide-details></v-divider>
    <div
      class="d-flex justify-space-between py-3 px-3"
      style="background-color: rgb(242, 242, 242);"
    >
      <v-label>Trysilhusbolig bolig mnd. kost: </v-label>
      <v-label small hide-details>{{ getnewhouseMonthlyCost }}</v-label>
    </div>
    <v-divider hide-details></v-divider>
    <div id="m2" class="d-flex justify-space-between py-3 px-3">
      <v-row no-gutters>
        <v-col cols="8">
          <span hide-details style="font-weight:bold;line-height: 0.2em;">
            Differanse fra dagens bosituasjon &nbsp;<v-btn
              color="transparent"
              depressed
              fab
              small
              dark
              hideDetails
              :ripple="false"
              @click="showInfoDialog = true"
            >
              <v-icon hide-details color="info">mdi-help-circle-outline</v-icon>
            </v-btn></span
          >
        </v-col>
        <v-col cols="4">
          <div class="text-right">
            <span hide-details style="font-weight:bold;">
              {{ getDifference }}</span
            >
          </div>
        </v-col>
      </v-row>
    </div>

    <!-- <div v-html="infoText" class="text-center py-6"></div> -->
    <div class="text-center pt-5 pb-3 px-3">
      <span v-html="infoText" style="line-height: 1.4;"></span>
    </div>
    <InfoDialog
      v-model="showInfoDialog"
      title="Differanse mnd. kostnader"
      :infoText="costDifferenceInfo"
    />
  </div>

  <div v-else id="m1" class="text-large">
    <v-divider hide-details></v-divider>
    <v-row no-gutters align="center" justify="center" class="pl-5 py-3 pr-1">
      <v-col cols="8">
        <span hide-details style="font-weight:bold;">
          Differanse fra dagens bosituasjon</span
        >
        <v-tooltip hide-details top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="info" large class="pl-2"
              >mdi-help-circle-outline</v-icon
            >
          </template>
          Dette viser forskjellen på kostnaden til eksisterende bolig mot en ny
          Trysilhusbolig. Beregningen er kun veiledende for å vise forskjell
          mellom brukt og ny bolig. Tall er hentet fra statistisk underlag for
          gjennomsnittlig forbrukstall innenfor gitte betingelser. Avvik kan
          forekomme som følge av eget forbruksmønster, boligtype, bosted osv.
        </v-tooltip>
      </v-col>
      <v-col cols="4">
        <div class="text-right pr-6 py-3">
          <span hide-details style="font-weight:bold;">
            {{ getDifference }}</span
          >
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import InfoDialog from "@/components/dialog/InfoDialog.vue";
export default {
  props: {
    monthlyDifference: { type: Number, required: true },
    totalhouse: { type: Number, required: false },
    totalnewhouse: { type: Number, required: false },
  },
  data() {
    return {
      showInfoDialog: false,
      costDifferenceInfo:
        "Dette viser forskjellen på kostnaden til eksisterende bolig mot en ny Trysilhusbolig. Beregningen er kun veiledende for å vise forskjell mellom brukt og ny bolig. Tall er hentet fra statistisk underlag for gjennomsnittlig forbrukstall innenfor gitte betingelser. Avvik kan forekomme som følge av eget forbruksmønster, boligtype, bosted osv. ",
      infoText:
        "I vår boligkalkulator kan du sammenlikne hva det vil koste å bo i en ny Trysilhusbolig mot en brukt bolig. Trysilhus benytter talldata hentet fra SSB og rapport fra Samfunnsøkonomisk analyse som beregningsgrunnlag for vedlikehold og energiforbruk i brukte boliger. Kalkulatoren er kun ment som veiledende for å sammenlikne ny bolig mot brukt bolig.",
    };
  },
  methods: {
    getFormattedValue(data) {
      if (isNaN(data)) return data;
      return new Intl.NumberFormat("no-No", {
        minimumFractionDigits: 0,
      }).format(Math.round(data));
    },
  },
  computed: {
    getDifference() {
      return this.getFormattedValue(this.monthlyDifference);
    },
    gethouseMonthlyCost() {
      return this.getFormattedValue(this.totalhouse);
    },
    getnewhouseMonthlyCost() {
      return this.getFormattedValue(this.totalnewhouse);
    },
  },
  components: {
    InfoDialog,
  },
};
</script>

<style lang="scss" scoped>
div#m1 {
  color: white;
  font-size: x-large;
  background-color: var(--v-primary-base) !important; //rgb(242, 242, 242);
}

div#m2 {
  color: white;
  font-size: large;
  background-color: var(--v-primary-base) !important; //rgb(242, 242, 242);
}

div#info {
  background-color: rgb(242, 242, 242);
  border: dotted 1px;
  border-radius: 8px;
}
</style>
