<template>
  <v-row class="justify-center">
    <v-col xl="9">
      <v-row>
        <v-col v-if="!$store.getters['shared/isMobileDevice']" cols="12">
          <v-list color="transparent">
            <v-list-item class="px-0 mb-2">
              <v-list-item-avatar>
                <v-icon class="primary white--text">mdi-help</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  class="primary--text headline font-weight-black"
                >
                  Ofte stilte spørsmål
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="showDialog.show = true"
                      v-on="on"
                      fab
                      dark
                      small
                      color="blue"
                    >
                      <v-icon dark>mdi-help</v-icon>
                    </v-btn>
                  </template>
                  <span>Hjelp</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
        </v-col>
      </v-row>
      <v-row v-if="getFaq.length > 0">
        <v-col v-for="n in getFaq" :key="n.id" cols="12" sm="4">
          <a @click="goTo(n.id)">
            <v-list color="transparent">
              <v-list-item class="px-0 mb-2">
                <v-list-item-avatar :color="n.color">
                  <v-icon class=" white--text">{{ n.icon }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="--text headline"
                    >{{ n.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider> </v-list
          ></a>
        </v-col>
      </v-row>

      <v-alert v-if="!loading && getFaq.length === 0" class="mt-6" type="info">
        Ingen ofte stilte spørsmål er tilgjengelig
      </v-alert>
    </v-col>
    <HelpBadges @help="showDialog.show = true" />
    <Dialog
      :show="showDialog"
      :title="title"
      :DialogMessage="DialogMessage"
      :DialogAccept="dialogAccept"
    />
  </v-row>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import HelpBadges from "@/components/shared/HelpBadges";
import Dialog from "@/components/dialog/DialogMessage";
import _ from "lodash";
export default {
  data() {
    return {
      showDialog: { show: false },
      dialogAccept: false,
      title: "Ofte stilte spørsmål",
      DialogMessage: `Her finner du svar på spørsmål vi ofte får om våre boliger. 
      Bruk søkefunksjonen hvis du ikke finner informasjonen du leter etter. 
      Finner du ikke svar på spørsmålet ditt her, ta kontakt med vårt kundesenter på kundesenter@trysilhus.no eller 32 21 55 10.`
    };
  },
  methods: {
    ...mapActions({
      setFaq: "shared/faq"
    }),
    goTo(id) {
      this.$router.push({ name: "faqs", params: { id: id } });
    }
  },
  computed: {
    ...mapGetters({
      faq: "shared/faq",
      profile: "shared/profile",
      loading: "shared/loading"
    }),
    getFaq() {
      if (this.faq && this.profile) {
        var faq = this.faq.filter(x => {
          return (
            x.projTypeId ===
              this.profile.user.currentSection.project.projTypeId ||
            x.projTypeId === undefined
          );
        });

        let data = _.map(_.uniqBy(faq, "category"), function(f) {
          return { id: f.categoryId, name: f.category };
        });
        if (data.length !== 0) {
          data = data.filter(x => {
            return x.id !== undefined;
          });
          const all = [{ id: -1, name: "Alle kategorier" }];
          const category = [...all, ...data];
          category.forEach(x => {
            switch (x.id) {
              case -1:
                x.color = "#A82C6B";
                x.icon = " mdi-menu";
                break;
              case 1:
                x.color = "#FDB913";
                x.icon = " mdi-radiator";
                break;
              case 2:
                x.color = "#F58025";
                x.icon = " mdi-home-city-outline";
                break;
              case 3:
                x.color = "#679146";
                x.icon = " mdi-account-group";
                break;
              case 4:
                x.color = "#F15A5A";
                x.icon = " mdi-clipboard-alert";
                break;
              default:
                x.color = "#A82C6B";
                x.icon = " mdi-menu";
            }
          });
          return category;
        } else {
          return data;
        }
      }
      return {};
    }
  },
  created() {
    if (!this.faq) {
      this.setFaq();
    }
  },
  components: {
    HelpBadges,
    Dialog
  }
};
</script>
