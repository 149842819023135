<template>
  <div v-if="$vuetify.breakpoint.smAndDown" class="pb-7">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-left" style="font-weight:bold;">
          Strømpris
          <v-btn
            color="transparent"
            depressed
            fab
            dark
            :ripple="false"
            @click="showInfoDialog = true"
          >
            <v-icon color="blue">mdi-help-circle-outline</v-icon>
          </v-btn>
        </v-list-item-title>
        <Slider
          v-model="getpower"
          :minValue="minPowerPricePerKwt"
          :maxValue="maxPowerPricePerKwt"
          :stepValue="1"
          label="Øre/kWt"
          class="slBtn pb-4"
        />
        <PowerCalculation
          :powerData.sync="powerData"
          :monthlyKwt="monthlyKwt"
          :powerCost="getpower"
          :houseTotalCost.sync="houseTotalCost"
          :newhouseTotalCost.sync="newhouseTotalCost"
          :totalhouse.sync="gettotalhouse"
          :totalnewhouse.sync="gettotalnewhouse"
        />
      </v-list-item-content>
    </v-list-item>
    <InfoDialog
      v-model="showInfoDialog"
      title="Strømpris per måned"
      :infoText="getpowerInfo"
    />
  </div>

  <div v-else>
    <div class="d-flex justify-space-between pt-4 pl-6 pr-6 py-2">
      <div id="f1" class="mt-2 pt-5 px-2 pb-1 flex-grow-0 flex-shrink-0">
        <v-text-field
          v-model="getAreaOrPrice"
          dense
          outlined
          thumb-label
          hide-details
          style="width:200px !important"
          class="d-flex align-center pr-6"
          @keyup.up="onPressUpKey"
          @keyup.down="onPressDownKey"
          @keydown="handelKeydown"
          :label="checkbox ? 'Antall kvadratmeter' : 'Strømforbruk kWt pr mnd'"
          :rules="[rules.maxlen, rules.isnumer]"
          :maxlength="maxlen"
          append-icon="mdi-unfold-more-horizontal"
        ></v-text-field>
        <div
          :class="checkbox ? 'text-right pt-3 pb-1' : 'text-right pt-3 pr-6'"
        >
          <span style="font-weight:bold;"
            >{{ gethouseMonthlyKwt }}&nbsp;&nbsp;kWt per måned
            <v-tooltip bottom v-if="checkbox">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="blue">mdi-help-circle-outline</v-icon>
              </template>
              Tall hentet fra SSB i 2012. Gjennomsnittlig årlig energiforbruk
              for eneboliger i størrelse 100-149 kvm.
            </v-tooltip></span
          >
        </div>
      </div>

      <div class="flex-grow-1 flex-shrink-0 px-2">
        <div class="text-left pl-5 ">
          <!-- <span style="font-weight:bold;"> Strømpris</span> -->
          <v-list-item-title class="text-left" style="font-weight:bold;">
            Strømpris
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="blue" class="pl-1"
                  >mdi-help-circle-outline</v-icon
                >
              </template>
              Nettleie og strømstøtte er ikke inkludert i strømkalkulatoren
            </v-tooltip></v-list-item-title
          >
        </div>
        <v-slider
          v-model="getpower"
          :min="data.minPowerPricePerKwt"
          :max="data.maxPowerPricePerKwt"
          hide-details
          dense
          class="slbt px-6 pb-4 pt-2 my-1"
        ></v-slider>
        <div class="d-flex justify-center px-6 pt-2">
          <v-btn
            class="mr-1"
            color="primary"
            depressed
            small
            fab
            :ripple="false"
            @click="dcrPower"
          >
            <v-icon> mdi-minus </v-icon>
          </v-btn>
          <div class="px-2 flex-grow-0 flex-shrink-1">
            <v-text-field
              v-model="getFormattedSlider"
              outlined
              flat
              dense
              hide-details
              label="Øre/kWt"
            ></v-text-field>
          </div>
          <v-btn
            class="ml-1"
            color="primary"
            depressed
            small
            fab
            :ripple="false"
            :retain-focus-on-click="true"
            @click="incrPower"
          >
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </div>
      </div>
      <div
        id="f1"
        style="background-color: rgb(242, 242, 242);"
        class="mt-2 mr-1 pt-5 px-2  pb-1 flex-grow-0 flex-shrink-0"
      >
        <v-select
          v-model="data.newArea"
          :items="data.areaWithBoost"
          dense
          outlined
          hide-details
          required
          style="width:200px !important"
          class="d-flex align-center pr-6"
          :disabled="!data.optionForBoost"
          label="Antall kvadratmeter"
          @change="updateNewArea"
        ></v-select>
        <div class="text-right pt-3 pb-1">
          <span style="font-weight:bold;"
            >{{ getnewMonthlyKwt }}&nbsp;kWt per måned
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="blue">mdi-help-circle-outline</v-icon>
              </template>
              {{ getKwtInfo }}
            </v-tooltip></span
          >
        </div>
      </div>
    </div>
    <v-row no-gutters class="pb-2 pl-6">
      <v-col cols="4">
        <v-checkbox
          dense
          hide-details
          v-model="getCheck"
          @change="resetAreaOrPrice"
          label="Jeg vet ikke mitt strømforbruk"
        ></v-checkbox>
      </v-col>
      <v-col cols="4"></v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <PowerCalculation
      :powerData.sync="powerData"
      :houseMonthlyKwt="gethouseMonthlyKwt"
      :powerCost="getpower"
      :houseTotalCost.sync="houseTotalCost"
      :newhouseTotalCost.sync="newhouseTotalCost"
      :totalhouse.sync="gettotalhouse"
      :totalnewhouse.sync="gettotalnewhouse"
    />
  </div>
</template>

<script>
import InfoDialog from "@/components/dialog/InfoDialog.vue";
import Slider from "@/components/store/webStore/shared/loanCalculation/children/SliderWithNumberBase.vue";
import PowerCalculation from "@/components/store/webStore/shared/loanCalculation/children/PowerCalculation.vue";

export default {
  emits: ["houseKwt", "powerCost", "newArea"],
  data() {
    return {
      // houseMonthlyKwtInfo:
      //   "Tall hentet fra SSB i 2012. Gjennomsnittlig årlig energiforbruk for eneboliger i størrelse 100-149 kvm.",
      newhouseMonthlyKwtInfo:
        "Basert på beregning av netto energibehov i normalklima for Trysilhus leilighetsbygg i Årnes. Trysilhus benytter Simien 6 til våre energiberegninger.",
      powerCost: 0,
      areaOrPrice: 0,
      showInfoDialog: false,
      getpowerInfo:
        "Nettleie og strømstøtte er ikke inkludert i strømkalkulatoren",
      checkbox: false,
      powerDefault: 150,
      maxPowerPricePerKwt: 1000,
      minPowerPricePerKwt: 1,
      activeUsage: "Lav",
      tags: ["Lav", "Middels", "Høy"],
      rules: {
        maxlen: (value) =>
          value.length <= 9 || "Verdi bør ikke overstige 9 sifre",
        isnumer: (value) => {
          const pattern = /[^0-9\,\s]+/g;
          return !pattern.test(value) || "Må ha en gyldig verdi";
        },
      },
      maxlen: 10,
    };
  },
  props: {
    powerData: { type: Object, required: true },
    houseMonthlyKwt: { type: Number, default: 0 },
    sectionInfo: { type: Object, required: true },
    houseTotalCost: { type: Number },
    newhouseTotalCost: { type: Number },
    totalhouse: { type: Number },
    totalnewhouse: { type: Number },
    monthlyKwt: { type: Number, required: false },
  },
  created() {
    this.areaOrPrice = Number(this.data.avgAreaOrPrice);
    this.powerCost = Number(this.data.powerDefault);
  },
  methods: {
    getFormattedValue(data) {
      if (isNaN(data)) return data;
      return new Intl.NumberFormat("no-No", {
        minimumFractionDigits: 0,
      }).format(Math.round(data));
    },
    dcrPower() {
      this.getpower(this.powerCost--);
    },
    incrPower() {
      this.getpower(this.powerCost++);
    },
    resetAreaOrPrice() {
      this.areaOrPrice = this.checkbox ? this.data.newArea : 1500;
      let pricekwt = this.checkbox
        ? this.areaOrPrice * Number(this.data.avgCurrentkwtPrice)
        : 1500;
      this.getAreaOrPrice = this.checkbox ? this.areaOrPrice : pricekwt;
      this.$emit("houseKwt", this.getAreaOrPrice, this.checkbox);
    },
    onPressUpKey(e) {
      if (e.key != "ArrowUp") return;
      this.getAreaOrPrice = this.areaOrPrice + 1;
    },
    onPressDownKey(e) {
      if (e.key != "ArrowDown") return;
      this.getAreaOrPrice = this.areaOrPrice - 1;
    },
    handelKeydown(e) {
      if (e.which == 38 || e.which == 40) {
        e.preventDefault();
      }
    },
    updateNewArea() {
      this.$emit("newArea", this.data.newArea);
    },
  },
  computed: {
    data() {
      return this.powerData;
    },
    getKwtInfo() {
      let info = "";
      if (this.data.optionForBoost) {
        info =
          "Boligen kan innredes med loftsetasje, sett inn korrekt areal for boligen du vil beregne.\n" +
          this.newhouseMonthlyKwtInfo;
      } else {
        info = this.newhouseMonthlyKwtInfo;
      }
      return info;
    },
    gethouseMonthlyKwt() {
      let kwt = 0;
      if (this.checkbox) {
        kwt = Number(this.areaOrPrice) * Number(this.data.avgCurrentkwtPrice); //*12
      } else {
        kwt = this.areaOrPrice;
      }

      this.$emit("update:houseMonthlyKwt", kwt);
      return this.getFormattedValue(kwt);
    },
    gethousePowerCost() {
      const power = Math.round(
        Number(this.houseKwtPerMonth) * (this.data.powerDefault / 100)
      );
      return this.getFormattedValue(power);
    },
    getNewHousePowerCost() {
      const newPower = this.data.newMonthlyKwt * (this.data.powerDefault / 100);
      return this.getFormattedValue(newPower);
    },
    getnewMonthlyKwt() {
      return Math.round(this.data.newMonthlyKwt);
    },
    getpower: {
      get() {
        return this.powerCost;
      },
      set(value) {
        let val = isNaN(value) ? Number(value.replace(/\D/g, "")) : value;
        this.powerCost = Number(val);
        this.$emit("powerCost", this.powerCost);
      },
    },
    getFormattedSlider: {
      get() {
        if (isNaN(this.powerCost)) return this.powerCost;
        return new Intl.NumberFormat("no-No", {
          minimumFractionDigits: 0,
        }).format(Math.round(this.powerCost));
      },
      set(value) {
        let val = isNaN(value) ? Number(value.replace(/\D/g, "")) : value;
        this.powerCost = Number(val);
      },
    },
    getAreaOrPrice: {
      get() {
        return this.getFormattedValue(this.areaOrPrice);
      },
      set(value) {
        this.areaOrPrice = value;
        this.$emit("houseKwt", value, this.checkbox);
      },
    },
    getCheck: {
      get() {
        return this.checkbox;
      },
      set(value) {
        //reset
        this.checkbox = value;
        this.$emit("houseKwt", this.getAreaOrPrice, this.checkbox);
      },
    },
    gettotalhouse: {
      get() {
        return this.totalhouse;
      },
      set(value) {
        this.$emit("update:totalhouse", value);
      },
    },
    gettotalnewhouse: {
      get() {
        return this.totalnewhouse;
      },
      set(value) {
        this.$emit("update:totalnewhouse", value);
      },
    },
  },
  components: { Slider, PowerCalculation, InfoDialog },
};
</script>

<style lang="scss">
div#f1 {
  border: dotted 1px;
  //border: solid 1px;
  border-radius: 8px;
}
div#f2 {
  border: solid 1px;
  border-radius: 8px;
}

div#m1 {
  background-color: rgb(242, 242, 242);
}

.slbt {
  .v-slider__thumb {
    &:before {
      opacity: 0;
    }
    width: 30px !important;
    height: 30px !important;
    left: -15px !important;
    transform: translateY(-33%) !important;
  }

  .v-slider--horizontal .v-slider__track-container {
    height: 4px;
    top: 68%;
  }
}
</style>
