import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"sidemenuWrapper",class:{ sidemenuToggle: _vm.openCart }},[_c('div',{staticClass:"sidemenu-btns"},[(!_vm.openCart)?_c(VBadge,{attrs:{"overlap":"","color":"yellow"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_vm._v(" "+_vm._s(_vm.sectionConfirme.options.length > 0 ? _vm.countChangeOfConfime : _vm.cartInfo.cartCount)+" ")]},proxy:true}],null,false,771954054)},[_c(VBtn,{staticClass:"mb-0 mb-sm-4",attrs:{"fab":"","color":'green'},on:{"click":_vm.toggleSidemenu}},[_c(VIcon,{staticClass:"white--text"},[_vm._v("mdi-cart")])],1)],1):_c(VBtn,{attrs:{"fab":"","color":"red"},on:{"click":_vm.toggleSidemenu}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1),(!_vm.$store.getters['shared/isMobileDevice'])?_c(VBtn,{staticClass:"infoBtn",class:{ activeInfoBtn: _vm.showDialog.show === true },attrs:{"fab":"","color":"blue"},on:{"click":_vm.help}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-help")])],1):_vm._e()],1),_c(VCard,{staticClass:"cart-container",attrs:{"tile":"","flat":""}},[_c(VList,{staticClass:"py-0"},[_c(VListItem,[_c(VListItemContent,{staticStyle:{"max-width":"45%"}},[_c(VListItemTitle,{staticClass:"subtitle font-weight-bold text-uppercase"},[_vm._v("Handlekurv")])],1),_c(VListItemContent,[_c(VBtn,{attrs:{"disabled":_vm.CartIsEmpty(),"color":"yellow","small":"","depressed":""},on:{"click":_vm.confirmeCart}},[_vm._v("Bekreft bestilling")])],1)],1),_c('div',{staticClass:"pl-4"},[_vm._v(" Totalpris tilvalg: "+_vm._s(_vm.format.formatToNok(_vm.cartInfo.cartTotPrice))+" ")])],1),(
          _vm.optionsService.summaryIsEmty(_vm.getCartGroups) ||
            _vm.sectionConfirme.options.length > 0
        )?_c(VList,{staticClass:"cart-list-container pt-0"},[(!_vm.optionsService.confirmedOptions(_vm.sectionConfirme.options))?_c('div',_vm._l((_vm.getCartGroups.groups),function(itemCart){return _c('div',{key:itemCart.id},[(itemCart.show)?_c('div',[_c(VCardSubtitle,{staticClass:"pb-0"},[_vm._v(" "+_vm._s(itemCart.name)+" "),_c('span',[_vm._v(" - "),_c('span',{class:{
                      deadlineHasBeen:
                        itemCart.deadline &&
                        _vm.deadlineHasBeen(itemCart.deadline)
                    }},[_vm._v(" "+_vm._s(_vm._f("moment")(itemCart.deadline,"DD.MM.YYYY"))+" ")]),(
                      itemCart.deadline && _vm.deadlineHasBeen(itemCart.deadline)
                    )?_c(VIcon,{attrs:{"small":"","color":"warning"}},[_vm._v(" mdi-calendar-alert ")]):_vm._e()],1)]),_vm._l((itemCart.categories),function(categories){return _c('div',{key:categories.id},_vm._l((categories.options),function(options){return _c('div',{key:options.id},[(options.cartItemId && options.type !== 1)?_c(VListItem,{staticClass:"cart-list",attrs:{"two-line":""}},[_c(VListItemAvatar,{staticClass:"pointer",on:{"click":function($event){return _vm.change(categories)}}},[_c(VImg,{attrs:{"src":_vm.optionsService.getOptionImage(options)}})],1),_c(VListItemContent,{staticClass:"pointer",on:{"click":function($event){return _vm.change(categories)}}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(options.name)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.format.formatToNok(options.price))+",-inkludert mva ")])],1),_c(VBtn,{attrs:{"text":"","fab":"","dark":"","small":"","color":"red"},on:{"click":function($event){return _vm.deleteFromCart(options, categories.id)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1)],1):_vm._e()],1)}),0)})],2):_vm._e()])}),0):_vm._e(),(_vm.optionsService.confirmedOptions(_vm.sectionConfirme.options))?_c('div',[_c(VCardSubtitle,[_vm._v("Bekreftet tilvalg")]),_vm._l((_vm.sectionConfirme.options),function(itemCart){return _c('div',{key:itemCart.id},[(itemCart.type !== 1)?_c(VListItem,{staticClass:"cart-list",attrs:{"two-line":""}},[_c(VListItemAvatar,{staticClass:"pointer"},[_c(VImg,{attrs:{"src":_vm.optionsService.getOptionImage(itemCart)}})],1),_c(VListItemContent,{staticClass:"pointer"},[_c(VListItemTitle,[_vm._v(_vm._s(itemCart.name))]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.format.formatToNok(itemCart.price))+",- inkludert mva ")])],1)],1):_vm._e()],1)})],2):_vm._e(),(
            _vm.optionsService.confirmedOptions(_vm.sectionConfirme.options) &&
              _vm.changedCartOfConfirme.length > 0
          )?_c('div',[_c(VCardSubtitle,[_vm._v("Endringer av tilvalg")]),_vm._l((_vm.changedCartOfConfirme),function(cart){return _c('div',{key:cart.id},[_c(VListItem,{class:{
                'cart-list': true
              },attrs:{"two-line":""}},[_c(VIcon,{attrs:{"color":cart.selected ? 'success' : 'error'}},[_vm._v(" "+_vm._s(cart.selected ? "mdi-plus" : "mdi-minus")+" ")]),_c(VListItemAvatar,{staticClass:"pointer"},[_c(VImg,{attrs:{"src":_vm.optionsService.getOptionImage(cart)}})],1),_c(VListItemContent,{staticClass:"pointer"},[_c(VListItemTitle,{class:{
                    colorDelete: !cart.selected,
                    colorAdd: cart.selected
                  }},[_vm._v(" "+_vm._s(cart.name)+" "),(cart.deadline && _vm.deadlineHasBeen(cart.deadline))?_c(VIcon,{attrs:{"small":"","color":"warning"}},[_vm._v(" mdi-calendar-alert ")]):_vm._e()],1),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.format.formatToNok(cart.price))+",- inkludert mva ")])],1)],1)],1)})],2):_vm._e()]):_vm._e(),_c('div',{staticClass:"text-center mt-2 mt-md-6"},[_c(VBtn,{attrs:{"disabled":_vm.CartIsEmpty(),"color":"yellow","depressed":""},on:{"click":_vm.confirmeCart}},[_vm._v("Bekreft bestilling")])],1)],1)],1),_c('DialogMessage',{attrs:{"show":_vm.showDialog,"title":_vm.title,"DialogMessage":_vm.DialogMessage,"DialogAccept":_vm.dialogAccept,"hideOverlay":false}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }