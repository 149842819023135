import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{class:{
    optionExpired: _vm.expired && _vm.notOnSelected(_vm.getData),
    notAvailable: _vm.notAvailable,
    clickyCard: true,
    'theme-shadow': true,
    imgActive: _vm.getData.selected
  },attrs:{"height":"100%","hover":""},on:{"mouseover":function($event){return _vm.overlayMouseover(_vm.getData)},"mouseleave":function($event){return _vm.overlayMouseleave(_vm.getData)}}},[_c(VCarousel,{attrs:{"hide-delimiters":"","height":"200","hide-delimiter-background":"","show-arrows":_vm.getData.thumbnailImage.length > 1},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-chevron-left ")])],1)]}},{key:"next",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-chevron-right ")])],1)]}}]),model:{value:(_vm.getData.imageShowing),callback:function ($$v) {_vm.$set(_vm.getData, "imageShowing", $$v)},expression:"getData.imageShowing"}},_vm._l((_vm.getData.thumbnailImage),function(item,i){return _c(VCarouselItem,{key:i,attrs:{"src":item},on:{"click":function($event){return _vm.showImg(_vm.getData, _vm.getData.imageShowing)}}})}),1),_c(VList,{staticClass:"pt-0"},[_c(VListItem,{staticClass:"pt-2",nativeOn:{"click":function($event){return _vm.addDeleteFromCart(_vm.getData)}}},[_c(VListItemContent,{staticClass:"pt-0 pb-8"},[_c(VListItemTitle,{staticClass:"d-flex my-1"},[(!_vm.addon)?_c(VChip,{staticClass:"mr-1",attrs:{"x-small":"","dark":"","color":_vm.getData.type === 1
                ? 'primary'
                : _vm.getData.type === 2
                ? 'yellow'
                : 'info'}},[_vm._v(" "+_vm._s(_vm.optionType(_vm.getData.type))+" ")]):_vm._e(),(_vm.addon)?_c(VChip,{attrs:{"x-small":"","dark":"","color":"primary"}},[_vm._v("Tillegg")]):_vm._e(),_c(VChip,{attrs:{"x-small":"","color":_vm.getData.selected ? 'success' : ''}},[_vm._v(" "+_vm._s(_vm.format.formatToNok(_vm.getData.price))+" ")]),_c(VChip,{staticClass:"ml-auto",attrs:{"x-small":"","color":"grey","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.showImg(_vm.getData, _vm.getData.imageShowing)}}},[_vm._v(" Se bilde "),_c(VIcon,{staticClass:"ml-1",attrs:{"x-small":""}},[_vm._v("mdi-magnify")])],1)],1),_c(VListItemTitle,{staticClass:"my-2 darkgrey--text"},[_c('h3',[_vm._v(_vm._s(_vm.getData.name))])]),_c('p',{directives:[{name:"truncate",rawName:"v-truncate",value:(_vm.truncateValue),expression:"truncateValue"}],staticStyle:{"font-size":"0.9rem"}},[_vm._v(" "+_vm._s(_vm.getData.description)+" ")]),(_vm.descriptionsCount(_vm.getData.description) > _vm.truncateValue)?_c(VBtn,{staticClass:"mt-2 mb-2",staticStyle:{"flex":"0 30%"},attrs:{"x-small":"","depressed":""},on:{"click":function($event){$event.stopPropagation();_vm.showDialogInfo = true}}},[_vm._v(" Les mer ")]):_vm._e()],1)],1)],1),_c(VBtn,{staticStyle:{"position":"absolute","bottom":"0"},attrs:{"color":_vm.getData.selected ? 'success' : 'grey',"dark":"","block":"","depressed":"","tile":""},on:{"click":function($event){return _vm.addDeleteFromCart(_vm.getData)}}},[_vm._v(" "+_vm._s(_vm.textOnBtn(_vm.getData))+" ")]),_c('DialogOptionsInfo',{attrs:{"title":_vm.getData.name,"description":_vm.getData.description,"images":_vm.getData.image},model:{value:(_vm.showDialogInfo),callback:function ($$v) {_vm.showDialogInfo=$$v},expression:"showDialogInfo"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }