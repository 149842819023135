<template>
  <div>
    <v-btn
      color="blue"
      @click="help"
      fixed
      fab
      large
      dark
      bottom
      right
      class="d-block d-sm-none mobileDial"
    >
      <v-icon>mdi-help</v-icon>
    </v-btn>
  </div>
</template>
<script>
export default {
  methods: {
    help() {
      this.$emit("help");
    }
  }
};
</script>
