import store from "@/store/index";
// import notification from "@/notification.services";
import router from "@/router";
import moment from "moment";

function profileRegistered(to) {
  //Get profile from localStorage and save to vuex.
  if (store.getters["purchase/profile"]) {
    return new Promise(resolve => {
      var profile = store.getters["purchase/profile"];
      registeredOnSection(to, profile).then(() => {
        resolve(profile);
      });
    });
  } else {
    return new Promise((resolve, reject) => {
      store
        .dispatch("purchase/setProfile")
        .then(profile => {
          registeredOnSection(to, profile).then(() => {
            resolve(profile);
          });
        })
        .catch(() => {
          notRegistred(to);
        });
    });
  }
}

function registeredOnSection(to, profile) {
  return new Promise((resolve, reject) => {
    router;
    if (parseInt(to.params.sectionId) === profile.sectionId) {
      resolve(true);
    } else {
      notRegistred(to);
    }
  });
}

function notRegistred(to) {
  router.push({
    name: "webShopIntro",
    params: { sectionId: to.params.sectionId }
  });
}

function haveSectionInfo(to) {
  if (store.getters["purchase/sectionInfo"]) {
    return new Promise(resolve =>
      resolve(store.getters["purchase/sectionInfo"])
    );
  } else {
    return new Promise((resolve, reject) => {
      store
        .dispatch("purchase/setSectionInfo", to.params.sectionId)
        .then(async r => {
          resolve(r);
        })
        .catch(e => {
          router.replace({
            name: "webShopNoSectionFound",
            params: { sectionId: to.params.sectionId }
          });
          reject(false);
        });
    });
  }
}

function haveOptions(to) {
  if (store.getters["purchase/options"]) {
    return new Promise(resolve => resolve(store.getters["purchase/options"]));
  } else {
    return new Promise((resolve, reject) => {
      store
        .dispatch("purchase/setOptions", to.params.sectionId)
        .then(async r => {
          resolve(r);
        })
        .catch(e => {
          router.replace({
            name: "webShopColorIntro",
            params: { sectionId: to.params.sectionId }
          });
          reject(false);
        });
    });
  }
}

function optionAvailable(data) {
  // if options is available and not expired or selected from album
  if (
    data.isOptionsAvailable &&
    (!data.allOptionsExpired || data.selectedOptions.length > 0)
  ) {
    return true;
  }

  return false;
}

function haveColorOptions(to, from) {
  if (
    (from.name === "webShopColorIntro" || from.name === "webShopRoomColor") &&
    store.getters["purchase/colorOptions"]
  ) {
    return new Promise(resolve =>
      resolve(store.getters["purchase/colorOptions"])
    );
  } else {
    return new Promise((resolve, reject) => {
      store
        .dispatch("purchase/setColorOptions", to.params.sectionId)
        .then(async r => {
          resolve(r);
        })
        .catch(e => {
          router.replace({
            name: "webShopPurchaseOffer",
            params: { sectionId: to.params.sectionId }
          });
          reject(false);
        });
    });
  }
}

//intro
function intro(to, from, next) {
  store
    .dispatch("purchase/setSectionInfo", to.params.sectionId)
    .then(async r => {
      next();
    })
    .catch(e => {
      router.replace({
        name: "webShopNoSectionFound",
        params: { sectionId: to.params.sectionId }
      });
    });
}

//options
function options(to, from, next) {
  if (profileRegistered(to)) {
    haveSectionInfo(to).then(response => {
      //Otions is open or go to color
      if (optionAvailable(response)) {
        if (haveOptions(to)) {
          next();
        }
      } else {
        router.replace({
          name: "webShopColorIntro",
          params: { sectionId: to.params.sectionId }
        });
      }
    });
  }
}

function optionsCategory(to, from, next) {
  if (profileRegistered(to)) {
    haveSectionInfo(to).then(response => {
      //Otions is open or go to color
      if (optionAvailable(response)) {
        if (haveOptions(to)) {
          next();
        }
      } else {
        router.replace({
          name: "webShopColorIntro",
          params: { sectionId: to.params.sectionId }
        });
      }
    });
  }
}

function optionsItems(to, from, next) {
  if (profileRegistered(to)) {
    haveSectionInfo(to).then(response => {
      //Otions is open or go to color
      if (optionAvailable(response)) {
        if (haveOptions(to)) {
          next();
        }
      } else {
        router.replace({
          name: "webShopColorIntro",
          params: { sectionId: to.params.sectionId }
        });
      }
    });
  }
}

//Color
function color(to, from, next) {
  if (profileRegistered(to)) {
    haveSectionInfo(to).then(response => {
      //Otions is open or go to color
      if (
        response.isColorOptionsAvailable &&
        (response.selectedColorOptions.length > 0 ||
          !moment(moment().format()).isAfter(response.colorOptionsDeadline))
      ) {
        if (haveColorOptions(to, from)) {
          next();
        }
      } else {
        router.replace({
          name: "webShopSummary",
          params: { sectionId: to.params.sectionId }
        });
      }
    });
  }
}

function colorRoom(to, from, next) {
  if (profileRegistered(to)) {
    haveSectionInfo(to).then(response => {
      //Otions is open or go to color
      if (
        response.isColorOptionsAvailable &&
        (response.selectedColorOptions.length > 0 ||
          !moment(moment().format()).isAfter(response.colorOptionsDeadline))
      ) {
        if (haveColorOptions(to, from)) {
          next();
        }
      } else {
        router.replace({
          name: "webShopSummary",
          params: { sectionId: to.params.sectionId }
        });
      }
    });
  }
}

//Summary
function summary(to, from, next) {
  if (profileRegistered(to)) {
    haveSectionInfo(to).then(() => next());
  }
}

//purchase offer
function purchaseOffer(to, from, next) {
  if (profileRegistered(to)) {
    haveSectionInfo(to).then(() => next());
  }
}

function purchaseReceipt(to, from, next) {
  if (profileRegistered(to)) {
    next();
  }
}

export default {
  intro,
  options,
  optionsCategory,
  optionsItems,
  color,
  colorRoom,
  summary,
  purchaseOffer,
  purchaseReceipt
};
