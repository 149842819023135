<template>
  <div>
    <NoSectionFoundContainer />
  </div>
</template>

<script>
import NoSectionFoundContainer from "@/components/store/webStore/noSectionFound/container/NoSectionFoundContainer.vue";
export default {
  components: {
    NoSectionFoundContainer
  }
};
</script>
