<template>
  <div>
    <MessageDialog
      v-model="showDialogMessage"
      title="Velkommen til vår veiviser"
      :DialogMessage="DialogMessage"
      :hideOverlay="false"
      acceptButtonText="Gå til kjøpetilbud"
      :cancelButtonText="cancelButtonText"
      no-fullscreen
      @confirm="goToPurchase"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MessageDialog from "@/components/dialog/MessageDialog";
export default {
  data() {
    return {
      showDialogMessage: false,
      DialogMessage: "",
      cancelButtonText: ""
    };
  },
  created() {
    const introDialogIsOpened = JSON.parse(
      localStorage.getItem("introDialogOpened")
    );

    if (!introDialogIsOpened) {
      if (
        this.sectionInfo &&
        this.sectionInfo.isColorOptionsAvailable &&
        this.sectionInfo.isOptionsAvailable
      ) {
        this.DialogMessage =
          "På de neste sidene kan du tilpasse den seksjonen du har valgt med tilvalg og farger. Du vil da få oversikt over hvor mye boligen totalt vil koste. Du kan også velge å gå rett til utfylling av kjøpetilbud, og gjøre tilvalg og fargevalg på et senere tidspunkt.";
        this.cancelButtonText = "Fortsett i veiviseren";
      } else if (this.sectionInfo && this.sectionInfo.isOptionsAvailable) {
        this.DialogMessage =
          "På de neste sidene kan du tilpasse den seksjonen du har valgt med tilvalg. Du vil da få oversikt over hvor mye boligen totalt vil koste. Du kan også velge å gå rett til utfylling av kjøpetilbud, og gjøre tilvalg på et senere tidspunkt.";
        this.cancelButtonText = "Fortsett i veiviseren";
      } else if (this.sectionInfo && this.sectionInfo.isColorOptionsAvailable) {
        this.DialogMessage =
          "På de neste sidene kan du tilpasse den seksjonen du har valgt med farger. Du vil da få oversikt over hvor mye boligen totalt vil koste. Du kan også velge å gå rett til utfylling av kjøpetilbud, og gjøre fargevalg på et senere tidspunkt.";
        this.cancelButtonText = "Fortsett i veiviseren";
      }
      this.showDialogMessage = true;
      localStorage.setItem("introDialogOpened", JSON.stringify(true));
    }
  },
  methods: {
    goToPurchase() {
      this.$router.push({ name: "webShopPurchaseOffer" });
    }
  },
  components: {
    MessageDialog
  },
  computed: {
    ...mapGetters({
      profile: "purchase/profile",
      sectionInfo: "purchase/sectionInfo"
    })
  }
};
</script>
