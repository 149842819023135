<template>
  <div>
    <v-dialog
      v-model="showDialog"
      max-width="600"
      persistent
      :fullscreen="$store.getters['shared/isMobileDevice']"
    >
      <v-card class="safeAreaDialog">
        <v-card-title>
          <span class="headline primary--text">
            Introduksjonsfilm
          </span>
        </v-card-title>
        <v-card-text>
          <VideoPlayer
            src="https://thg.blob.core.windows.net/publicvideos/MittTrysilhus_v3.mp4"
          />
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-btn class="mx-auto" large depressed color="primary" @click="close">
            Lukk
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VideoPlayer from "@/components/shared/VideoPlayer";
export default {
  props: ["show"],
  methods: {
    close() {
      this.show.show = false;
    }
  },
  computed: {
    showDialog: {
      get: function() {
        if (this.show.show) {
          return this.show.show;
        }
        return false;
      },
      set: function() {
        this.close();
      }
    }
  },
  components: {
    VideoPlayer
  }
};
</script>
