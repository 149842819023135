<template>
  <div>
    <div v-for="room in rooms" :key="room.id">
      <ColorRoom
        :data="room"
        :disabled-reset="disabledReset(room)"
        @resetColor="resetColor"
        @openDialog="openDialog"
        @deleteContrastwall="deleteContrastwall"
      />
    </div>
    <ColorPicker
      v-model="showColorDialog"
      :data="roomData"
      :constrastWallRoomName="constrastWallRoomName"
      @addToCart="addToCart"
    />
    <AcceptCancel
      v-model="showAccept"
      title="Tilbakestille fargen"
      DialogMessage="Er du sikker på at du vil tilbakstille fargen på rommet?"
      @confirme="reset"
    />
  </div>
</template>

<script>
import optionsService from "@/options.services";
import ColorRoom from "@/components/store/color/shared/ColorRoom.vue";
import ColorPicker from "@/components/store/color/ColorPicker";
import AcceptCancel from "@/components/dialog/AcceptCancel";
export default {
  props: {
    value: {
      required: true
    }
  },
  data() {
    return {
      showColorDialog: false,
      roomData: null,
      constrastWallRoomName: null,
      showAccept: false,
      confirmeTitle: "",
      confirmeMessage: "",
      deleteFromCart: null,
      contrastWallDelete: null,
      showContrastWallAccept: false,
      optionsService
    };
  },
  methods: {
    openDialog({ data, constrastWallRoomName }) {
      this.roomData = data;
      this.constrastWallRoomName = constrastWallRoomName
        ? constrastWallRoomName
        : null;
      this.showColorDialog = true;
    },
    addToCart() {
      this.$emit("addToCart", this.data);
    },
    resetColor(data) {
      this.deleteFromCart = data;
      this.showAccept = true;
    },
    reset() {
      this.deleteFromCart.deleteContrastWalls = true;
      this.$emit("reset", this.deleteFromCart);
    },
    disabledReset(room) {
      if (room && room.isConfirmed && room.constrastWalls.length > 0) {
        if (room.constrastWalls.some(x => !x.isConfirmed)) {
          return false;
        }
        return true;
      } else if (room && room.isConfirmed && room.constrastWalls.length === 0) {
        return true;
      } else if (room && room.total > 0) {
        return false;
      }

      return true;
    },
    deleteContrastwall(contrastWallDelete) {
      this.$emit("reset", contrastWallDelete);
    }
  },
  computed: {
    rooms: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        this.$emit("input", value);
      }
    }
  },
  components: {
    ColorRoom,
    ColorPicker,
    AcceptCancel
  }
};
</script>
