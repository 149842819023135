<template>
  <v-row class="justify-center">
    <v-col class="pt-0" xl="9">
      <v-row>
        <v-col v-if="!$store.getters['shared/isMobileDevice']" cols="12">
          <v-list color="transparent">
            <v-list-item class="px-0 mb-2">
              <v-list-item-avatar>
                <v-icon class="primary white--text">mdi-account-group</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  class="primary--text headline font-weight-black"
                >
                  Mine seksjoner
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="d-none d-sm-flex">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="help" v-on="on" fab dark small color="blue">
                      <v-icon dark>mdi-help</v-icon>
                    </v-btn>
                  </template>
                  <span>Hjelp</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
          </v-list> </v-col
      ></v-row>
      <v-row v-if="!loading">
        <v-col sm="6" lg="4" v-for="section in getSection" :key="section.id">
          <v-card
            @click="changeSection(section)"
            hover
            class="clickyCard theme-shadow"
          >
            <v-img :src="section.imageUrl" height="100px"> </v-img>

            <v-btn
              rounded
              class="rateInquiryBadge white--text"
              small
              height="24"
              elevation="0"
              :color="section.active ? 'green' : 'yellow'"
            >
              {{ section.active ? "Aktiv seksjon" : "Sett seksjon aktiv" }}
            </v-btn>

            <v-list class="text-center" two-line>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <h3>{{ section.name }}</h3>
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="section.address">
                    <span>{{ section.address }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <HelpBadges @help="showDialog.show = true" />
    <Dialog
      :show="showDialog"
      :title="title"
      :DialogMessage="DialogMessage"
      :DialogAccept="dialogAccept"
    />
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import api from "@/api/api";
import Dialog from "@/components/dialog/DialogMessage";
import HelpBadges from "@/components/shared/HelpBadges";
export default {
  data() {
    return {
      imageData: [],
      showDialog: { show: false },
      dialogAccept: false,
      title: "Mine seksjoner",
      DialogMessage: `Her vises alle dine seksjoner. Du kan gjøre en seksjon aktiv ved å trykke på den.`
    };
  },
  methods: {
    //Question dialog
    help() {
      this.title = "Mine seksjoner";
      this.DialogMessage = `På "Mine seksjoner" kan du endre hvem seksjon som skal være aktiv.`;
      this.dialogAccept = false;
      this.showDialog.show = true;
    },
    async getImage(data) {
      data.sections.map(async x => {
        var image = await api.getProjectImage(x.project.id);
        if (image.image) {
          x.imageUrl = `data:image/png;base64,${image.image}`;
          this.imageData.push(x);
        } else {
          this.imageData.push(null);
        }
        //Sorting sections after name
        this.imageData.sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      });
    },
    async changeSection(section) {
      await api.changeSection(section, this.getProfile.id).then(response => {
        if (response.status === 200) {
          window.location.reload();
        }
      });
    }
  },

  computed: {
    ...mapGetters({
      profile: "shared/profile",
      loading: "shared/loading"
    }),
    getProfile() {
      if (this.profile) {
        this.profile.user.sections.map(x => {
          if (x.id === this.profile.user.currentSection.id) {
            x.active = true;
          } else {
            x.active = false;
          }
        });
        this.getImage(this.profile.user);
        return this.profile.user;
      }
      return {};
    },
    getSection() {
      if (this.imageData.length > 0) {
        return this.imageData;
      } else if (this.getProfile) {
        return this.getProfile;
      }
      return {};
    }
  },
  components: {
    Dialog,
    HelpBadges
  }
};
</script>
