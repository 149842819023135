import baseApi from "./baseApi";
import notificationsService from "@/notification.services";

const api = {
  //   async getProjectsSignup() {
  //     const response = await baseApi.get(`/signup`);
  //     return response.data;
  //   },
  //   async signup(data) {
  //     const response = await baseApi.post(`/signup`, data);
  //     return response.data;
  //   },
  //   async resetpassword(r) {
  //     const response = await baseApi.post(`/resetpassword`, { email: r });
  //     return response;
  //   },
  async getConsent(consentGuid) {
    return await baseApi
      .get(`/consent/` + consentGuid)
      .then(response => {
        return response.data;
      })
      .catch(() => {
        notificationsService.warn("Vi fant ingen informasjon om deg");
        return undefined;
      });
  },
  async saveConsent(consentInfo) {
    const response = await baseApi.post(`/consent/update`, consentInfo);
    return response.data;
  }
};

export default api;
