<template>
  <v-footer class="py-3 grey lighten-3" style="z-index: 9;" app>
    <v-expansion-panels active-class="contactSales-mobile-open" flat accordion>
      <v-expansion-panel id="contactSales">
        <v-expansion-panel-header class="salesHeader">
          <v-sheet color="primary"
            ><v-icon dark>mdi-chat-processing</v-icon></v-sheet
          >
          <span>Kontakt selger</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content style="height: 85px;">
          <v-list class="py-0 pt-2">
            <v-list-item class="px-0">
              <v-list-item-avatar
                v-if="this.sectionInfo && this.sectionInfo.salesRepPhoto"
                width="40"
                height="40"
                class="salesAvatar mt-0"
              >
                <v-img
                  :lazy-src="this.sectionInfo.salesRepPhoto"
                  :src="this.sectionInfo.salesRepPhoto"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content class="subtitle-2 font-weight-regular">
                <p>
                  {{ salesRepName }}
                </p>
                <a :href="'tel:' + salesRepPhone" :title="salesRepPhone">
                  {{ salesRepPhone }}
                </a>
                <a :href="'mailto:' + salesRepEmail" :title="salesRepEmail">
                  {{ salesRepEmail }}
                </a>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card
      max-width="960"
      width="100%"
      class="d-flex justify-between align-center justify-space-between mx-auto"
      color="transparent"
      flat
      tile
    >
      <div>
        <v-btn x-small depressed color="lightgrey" @click="showDetails">
          Vis detaljer
          <v-icon x-small>
            mdi-arrow-up
          </v-icon>
        </v-btn>
        <p class="mb-0">
          {{ format.formatToNok(totalPrice) }}
        </p>
      </div>

      <div v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn depressed small class="primary" @click="openCalculator"
          >Boligkalkulator
          <v-icon x-small>mdi-arrow-up</v-icon>
        </v-btn>
      </div>

      <div class="d-none d-sm-block">
        <p class="mb-0">{{ projectName }}</p>
        <p
          v-if="
            !deliveryDateHasBeen &&
              (!sectionInfo || (sectionInfo && !sectionInfo.useAquisitionDate))
          "
          class="mb-0 caption"
        >
          Est. leveringsdato: {{ deliveryDate }}
        </p>
        <p
          v-if="
            !(
              !deliveryDateHasBeen &&
              (!sectionInfo || (sectionInfo && !sectionInfo.useAquisitionDate))
            ) && sectionInfo.aquisitionDate
          "
          class="mb-0 caption"
        >
          Overtakelse: {{ sectionInfo.aquisitionDate | moment("DD.MM.YYYY") }}
        </p>
      </div>

      <div
        v-if="$vuetify.breakpoint.smAndDown"
        class="d-flex justify-space-between"
      >
        <v-menu v-model="menu" :close-on-content-click="true" top offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              dark
              depressed
              hide-details
              :ripple="false"
              color="transparent"
              class="pr-10 pb-4 px-4"
              ><v-icon x-large color="primary">{{
                menu
                  ? "mdi-chevron-down-circle-outline"
                  : "mdi-chevron-up-circle-outline"
              }}</v-icon></v-btn
            >
          </template>
          <v-card class="grey lighten-3">
            <div class="d-flex align-start flex-column bg-surface-variant pa-4">
              <div class="text-center pb-2">
                <v-btn large color="primary" icon @click="openCalculator">
                  <v-icon medium center color="primary">mdi-calculator</v-icon>
                </v-btn>
              </div>

              <div class="text-center py-2">
                <v-btn
                  large
                  color="green"
                  v-if="sectionInfo && sectionInfo.planImageUrl"
                  icon
                  @click="openFloorPlan"
                >
                  <v-icon>mdi-floor-plan</v-icon>
                </v-btn>
              </div>

              <div class="text-center pt-2">
                <v-btn
                  large
                  v-if="sectionInfo && sectionInfo.visual360Url"
                  icon
                  :href="sectionInfo.visual360Url"
                  target="_blank"
                >
                  <v-img
                    contain
                    max-width="42"
                    src="https://thg.blob.core.windows.net/public-images/360_blue.svg"
                  ></v-img>
                </v-btn>
              </div>
            </div>
          </v-card>
          <!-- <v-list>
            <v-list-item>
              <v-list-item-content>
                <div class="text-center">
                  <v-chip label outlined @click="openCalculator">
                    <v-avatar>
                      <v-icon medium center color="primary"
                        >mdi-calculator</v-icon
                      >
                    </v-avatar>
                  </v-chip>
                </div>

                <div class="text-center">
                  <v-btn
                    large
                    color="green"
                    v-if="sectionInfo && sectionInfo.planImageUrl"
                    icon
                    @click="openFloorPlan"
                  >
                    <v-icon>mdi-floor-plan</v-icon>
                  </v-btn>
                </div>

                <div class="text-center">
                  <v-btn
                    large
                    v-if="sectionInfo && sectionInfo.visual360Url"
                    class="ml-3"
                    icon
                    :href="sectionInfo.visual360Url"
                    target="_blank"
                  >
                    <v-img
                      contain
                      max-width="42"
                      src="https://thg.blob.core.windows.net/public-images/360_blue.svg"
                    ></v-img>
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list> -->
        </v-menu>

        <v-btn class="ml-4" color="primary" depressed rounded @click="next">
          {{ buttonTitle }}
        </v-btn>
      </div>
      <div v-else class="d-flex justify-space-between">
        <!-- <v-btn depressed small class="primary" @click="openCalculator"
          >Boligkalkulator
          <v-icon x-small>mdi-arrow-up</v-icon>
        </v-btn> -->

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              large
              color="green"
              v-if="sectionInfo && sectionInfo.planImageUrl"
              icon
              @click="openFloorPlan"
            >
              <v-icon>mdi-floor-plan</v-icon>
            </v-btn>
          </template>
          <span>Plantegning</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              large
              v-if="sectionInfo && sectionInfo.visual360Url"
              class="ml-3"
              icon
              :href="sectionInfo.visual360Url"
              target="_blank"
            >
              <v-img
                contain
                max-width="42"
                src="https://thg.blob.core.windows.net/public-images/360_blue.svg"
              ></v-img>
            </v-btn>
          </template>
          <span>360 visning</span>
        </v-tooltip>
        <v-btn class="ml-4" color="primary" depressed rounded @click="next">
          {{ buttonTitle }}
        </v-btn>
      </div>
    </v-card>
    <DialogShowImage
      v-if="sectionInfo && sectionInfo.planImageUrl"
      v-model="showImage"
      :title="imgTitle"
      :url="sectionInfo.planImageUrl"
    />
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";
import format from "@/helpers/format.js";
import moment from "moment";
import DialogShowImage from "@/components/dialog/DialogShowImage";

export default {
  props: {
    buttonTitle: {
      default: "Fortsett",
    },
  },
  data() {
    return {
      showImage: false,
      imgTitle: "",
      format,
      menu: false,
    };
  },
  methods: {
    next() {
      this.$emit("next", true);
    },
    showDetails() {
      this.$emit("showDetails", true);
    },
    openFloorPlan() {
      this.imgTitle = this.sectionInfo.projectName;
      this.showImage = true;
    },
    openCalculator() {
      this.$emit("openCalculator", true);
    },
  },
  computed: {
    ...mapGetters({
      sectionInfo: "purchase/sectionInfo",
      selectedOptionsList: "purchase/selectedOptionsList",
      profile: "purchase/profile",
      selectedColorOptionsTotal: "purchase/selectedColorOptionsTotal",
      totalPrice: "purchase/totalPrice",
    }),
    deliveryDate() {
      if (this.sectionInfo) {
        return moment(this.sectionInfo.deliveryDate).format("DD.MM.YYYY");
      }
      return null;
    },
    deliveryDateHasBeen() {
      if (this.sectionInfo) {
        return moment(this.sectionInfo.deliveryDate).isSameOrBefore(
          moment().format()
        );
      }

      return false;
    },
    projectName() {
      if (this.sectionInfo) {
        return this.sectionInfo.projectName;
      }
      return null;
    },
    salesRepName() {
      if (this.sectionInfo && this.sectionInfo.salesRepName) {
        return this.sectionInfo.salesRepName;
      }
      return "Trysilhus";
    },
    salesRepPhone() {
      if (this.sectionInfo && this.sectionInfo.salesRepPhone) {
        return this.sectionInfo.salesRepPhone;
      }
      return "32215510";
    },
    salesRepEmail() {
      if (this.sectionInfo && this.sectionInfo.salesRepEmail) {
        return this.sectionInfo.salesRepEmail;
      }
      return "kundesenter@trysilhus.no";
    },
  },
  components: {
    DialogShowImage,
  },
};
</script>
<style lang="scss">
#contactSales {
  position: fixed;
  right: 2rem;
  bottom: 72px;
  max-width: 280px;
  box-shadow: 2px -3px 12px rgba(0, 0, 0, 0.15) !important;
  .salesHeader {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 3.4rem;
    padding-right: 0.5rem;
    min-height: 32px;
    .v-sheet {
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-content: center;
    }
  }
  .salesAvatar {
    border: 2px solid #f6a300;
  }
}
@media (max-width: 960px) {
  #contactSales {
    max-width: 50px;
    right: 0;
    .salesHeader {
      padding-left: 2.3rem;
      padding-right: 0rem;
      span {
        display: none;
      }
      .v-expansion-panel-header__icon {
        display: none;
      }
    }
  }
  .contactSales-mobile-open {
    max-width: 100% !important;
    .salesHeader {
      padding-left: 5rem !important;
      span {
        display: block !important;
      }
      .v-expansion-panel-header__icon {
        display: block !important;
      }
    }
  }
}
</style>
