<template>
  <div>
    <v-app-bar color="white" style="z-index:10;" flat fixed app>
      <div class="nav">
        <v-app-bar-nav-icon
          @click="drawer = true"
          class="d-flex d-md-none"
        ></v-app-bar-nav-icon>

        <a href="https://www.trysilhus.no/" class="d-block ml-3 ml-sm-0">
          <img
            width="140"
            src="https://thg.blob.core.windows.net/public-images/trysilhus_logo.png"
          />
        </a>
        <v-spacer></v-spacer>

        <v-tabs
          v-if="!hideTabs"
          class="d-none d-md-flex justify-end"
          align-with-title
          height="56"
          right
        >
          <template v-for="(step, index) in steps">
            <v-tab
              :key="index"
              :exact="step.exact"
              :to="{ name: step.to }"
              :disabled="step.disabled"
            >
              <v-badge
                v-if="step.badgeCount > 0"
                :content="step.badgeCount"
                color="warning"
              >
                <span> {{ index + 1 + ". " }}{{ step.title }} </span>
              </v-badge>
              <span v-else>{{ index + 1 + ". " }}{{ step.title }}</span>
            </v-tab>
          </template>
        </v-tabs>
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="primary--text">
          <template v-for="(step, index) in steps">
            <v-list-item :key="index" exact :to="{ name: step.to }">
              <v-list-item-icon>
                <v-badge
                  v-if="step.badgeCount > 0"
                  :content="step.badgeCount"
                  color="warning"
                  overlap
                >
                  <v-icon>{{ step.icon }}</v-icon>
                </v-badge>
                <v-icon v-else>{{ step.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-title>
                {{ index + 1 + ". " }}{{ step.title }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    hideTabs: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    drawer: false,
    group: null
  }),

  computed: {
    ...mapGetters({
      sectionInfo: "purchase/sectionInfo",
      selectedOptionsList: "purchase/selectedOptionsList",
      selectedColorOptions: "purchase/selectedColorOptions"
    }),
    steps() {
      var data = [];

      data.push({
        title: "Start",
        to: "webShopIntro",
        exact: true,
        icon: "mdi-account-outline"
      });

      if (this.sectionInfo && this.sectionInfo.isOptionsAvailable) {
        var badgeCount = 0;
        if (this.selectedOptionsList && this.selectedOptionsList.list) {
          badgeCount = this.selectedOptionsList.list.length;
        }
        if (!this.sectionInfo.allOptionsExpired || badgeCount > 0) {
          data.push({
            title: "Tilvalg",
            to: "webShopOptionsIntro",
            exact: false,
            disabled: this.disabled,
            icon: "mdi-package-variant-closed",
            badgeCount: badgeCount
          });
        }
      }

      if (
        this.sectionInfo &&
        this.sectionInfo.isColorOptionsAvailable &&
        (this.sectionInfo.selectedColorOptions.length > 0 ||
          !moment(moment().format()).isAfter(
            this.sectionInfo.colorOptionsDeadline
          ))
      ) {
        data.push({
          title: "Farger",
          to: "webShopColorIntro",
          exact: false,
          disabled: this.disabled,
          icon: "mdi-format-paint",
          badgeCount:
            this.selectedColorOptions && this.selectedColorOptions.length
              ? this.selectedColorOptions.length
              : 0
        });
      }
      data.push({
        title: "Mine valg",
        to: "webShopSummary",
        exact: false,
        disabled: this.disabled,
        icon: "mdi-playlist-edit"
      });
      data.push({
        title: "Send kjøpetilbud",
        to: "webShopPurchaseOffer",
        exact: false,
        disabled: this.disabled,
        icon: "mdi-check-circle-outline"
      });

      return data;
    }
  }
};
</script>
<style lang="scss">
.nav {
  display: flex;
  max-width: 960px;
  width: 100%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
}
</style>
