function formatSizeUnits(bytes) {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2) + " GB";
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2) + " MB";
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2) + " KB";
  } else if (bytes > 1) {
    bytes = bytes + " bytes";
  } else if (bytes == 1) {
    bytes = bytes + " byte";
  } else {
    bytes = "0 bytes";
  }
  return bytes;
}

function formatToNok(number) {
  if (number) {
    return new Intl.NumberFormat("no-NO", {
      style: "currency",
      currency: "NOK",
      minimumFractionDigits: 0
    }).format(number);
  } else {
    return "Kr 0";
  }
}

function numberFormat(number) {
  var n = "" + Math.round(number);
  const sep = " ";

  if (n.length > 3) {
    n = n.replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }

  return n;
}

function phoneNumber(number) {
  if (number) {
    var n = "" + Math.round(number);
    const sep = " ";

    if (n.length > 2) {
      n = n.replace(/\B(?=(?:\d{2})+(?!\d))/g, sep);
    }

    return n;
  }
  return null;
}

function personNumber(number) {
  if (number) {
    number = number.toString().replace(/(\d{6})(\d{1})/, "$1-$2");
  }
  return number;
}

export default {
  formatSizeUnits,
  formatToNok,
  numberFormat,
  phoneNumber,
  personNumber
};
