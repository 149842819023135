<template>
  <div>
    <WebShopContainer :title="title" :leftTitle="deadline" @next="next">
      <template slot="navigation">
        <ColorRoomNavigation />
      </template>

      <v-row class="pb-12 pb-md-0" v-if="getColorRoom">
        <v-col
          cols="12"
          md="6"
          lg="6"
          xl="6"
          class="order-1 order-md-2 text-center"
        >
          <p class="mb-3 caption">
            Merk at tegningen ikke er interaktiv, trykk "fortsett" for å velge
            farge i neste rom.
          </p>
          <v-img
            max-height="750"
            contain
            :lazy-src="
              optionsService.getSectionPlanImage(
                $route.params.sectionId,
                null,
                getColorRoom.isOnAttic
              )
            "
            :src="
              optionsService.getSectionPlanImage(
                $route.params.sectionId,
                null,
                getColorRoom.isOnAttic
              )
            "
          ></v-img>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6" class="order-3">
          <p class="mb-3 caption text-center text-sm-right">
            Pris per nye farge du velger i boligen er kr. 2.000.<br />
            Pris per kontrastvegg er kr. 2.500 uansett farge.
          </p>
          <div v-if="!getColorRoom.isOnAttic">
            <MainColorRoom
              v-if="parseInt($route.params.roomId) === -1"
              v-model="getColorRoom"
              :disabledReset="
                disabledChangeColor ? disabledChangeColor : !getColorRoom.amount
              "
              typePrice="amount"
              :disabled="disabledChangeColor"
              @changeColor="openDialog"
              @reset="resetColor"
            />
            <ColorRoom
              v-else
              :data="getColorRoom"
              :open="true"
              typePrice="amount"
              :disabled="disabledChangeColor"
              @openDialog="openDialog"
              @resetColor="resetColorOpenDialog"
              @deleteContrastwall="deleteColorOption"
            />
          </div>
          <div v-if="getColorRoom.isOnAttic">
            <span v-for="(room, index) in getColorRoomOnAttic" :key="index">
              <ColorRoom
                :data="room"
                :open="true"
                typePrice="amount"
                :disabled="disabledChangeColor"
                @openDialog="openDialog"
                @resetColor="resetColorOpenDialog"
                @deleteContrastwall="deleteColorOption"
              />
            </span>
          </div>
        </v-col>
      </v-row>
      <ColorPicker
        v-model="showColorDialog"
        :data="roomData"
        :constrastWallRoomName="constrastWallRoomName"
        @addToCart="addToCart"
      />
      <AcceptCancel
        v-model="showAccept"
        title="Bekrefte tilbakestilling av farge"
        DialogMessage="Er du sikker på at du vil tilbakestille farge?"
        @confirme="resetColor(resetData)"
      />
    </WebShopContainer>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import notification from "@/notification.services";
import optionsService from "@/options.services";
import WebShopContainer from "@/components/store/webStore/shared/WebShopContainer.vue";
import ColorRoomNavigation from "@/components/store/webStore/color/children/ColorRoomNavigation.vue";
import ColorRoom from "@/components/store/color/shared/ColorRoom.vue";
import MainColorRoom from "@/components/store/color/MainColorRoom";
import ColorPicker from "@/components/store/color/ColorPicker";
import AcceptCancel from "@/components/dialog/AcceptCancel";

export default {
  methods: {},
  data() {
    return {
      showColorDialog: false,
      roomData: null,
      constrastWallRoomName: undefined,
      parentRoomColor: null,
      showAccept: false,
      resetData: null,
      optionsService
    };
  },
  methods: {
    ...mapActions({
      changeSelectedColorOptions: "purchase/changeSelectedColorOptions",
      updateProfile: "purchase/updateProfile",
      setColorOptions: "purchase/setColorOptions"
    }),
    next() {
      var index = this.colorOptions.findIndex(
        x => x.id === parseInt(this.$route.params.roomId)
      );
      if (
        this.getColorRoom &&
        !this.getColorRoom.isOnAttic &&
        index + 1 < this.colorOptions.length
      ) {
        this.$router.push({
          name: "webShopRoomColor",
          params: { roomId: this.colorOptions[index + 1].id }
        });
      } else {
        this.$router.push({
          name: "webShopSummary"
        });
      }
    },
    addToCart(data) {
      //If user select same color on parent and contrastwall. Then delet contrastwall color
      if (data.wallLetter && data.ncs === this.parentRoomColor.ncs) {
        this.deleteColorOption(data);
        notification.warn(
          "Fargen er alerede valgt på rommet og ble derfor slettet"
        );
      } else {
        this.changeSelectedColorOptions({
          sectionId: this.$route.params.sectionId,
          data: data
        });
      }
    },
    openDialog({ data, constrastWallRoomName, parentRoomColor }) {
      this.roomData = data;
      this.constrastWallRoomName = constrastWallRoomName;
      this.parentRoomColor = parentRoomColor;
      this.showColorDialog = true;
    },
    resetColorOpenDialog(data) {
      this.resetData = data;
      this.showAccept = true;
    },
    resetColor(data) {
      if (this.profile && this.profile.selectedColorOptions) {
        this.profile.selectedColorOptions = this.profile.selectedColorOptions.filter(
          x => x.id !== data.id
        );
        this.updateProfile(this.profile);
        this.setColorOptions(this.$route.params.sectionId);
      }
    },
    deleteColorOption(data) {
      this.changeSelectedColorOptions({
        sectionId: this.$route.params.sectionId,
        data,
        deleteColor: true
      });
    }
  },
  computed: {
    ...mapGetters({
      colorOptions: "purchase/colorOptions",
      profile: "purchase/profile",
      sectionInfo: "purchase/sectionInfo",
      colorsIsChangedFromAlbum: "purchase/colorsIsChangedFromAlbum"
    }),
    getColorRoom() {
      if (this.colorOptions) {
        var room = this.colorOptions.find(
          x => x.id === parseInt(this.$route.params.roomId)
        );
        return room;
      }
      return null;
    },
    title() {
      if (this.getColorRoom) {
        return this.getColorRoom.isOnAttic ? "Loft" : this.getColorRoom.name;
      }
      return "";
    },
    deadline() {
      if (this.sectionInfo) {
        return (
          "Frist " +
          moment(this.sectionInfo.colorOptionsDeadline).format("DD.MM.YYYY")
        );
      }
      return "";
    },
    getColorRoomOnAttic() {
      if (this.colorOptions) {
        var room = this.colorOptions.filter(x => x.isOnAttic);

        return room;
      }
      return [];
    },
    disabledChangeColor() {
      if (
        this.colorsIsChangedFromAlbum ||
        !this.sectionInfo.isColorOptionsAvailable ||
        (this.sectionInfo.isColorOptionsAvailable &&
          moment(moment().format()).isAfter(
            this.sectionInfo.colorOptionsDeadline
          ))
      ) {
        return true;
      }

      return false;
    }
  },

  components: {
    WebShopContainer,
    ColorRoomNavigation,
    ColorRoom,
    MainColorRoom,
    ColorPicker,
    AcceptCancel
  }
};
</script>
