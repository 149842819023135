// import baseApi from "@/api/baseApi";
import baseApi from "@/api/api";
import optionsApi from "@/api/optionsApi";
import moment from "moment";

// function saveChangInConfirme(data, confirmData) {
//   let changedCart = [];
//   if (confirmData.length > 0) {
//     data.map(y => {
//       y.categories.map(categorie => {
//         let optionsConfirmed = null;
//         let addonConfirmed = [];
//         confirmData.forEach(x => {
//           if (x.categoryId === categorie.id && x.id !== 0) {
//             if (x.type !== 3) {
//               optionsConfirmed = x;
//             } else if (x.type === 3) {
//               x.deadline = y.deadline;
//               addonConfirmed.push(x);
//             }
//           }
//         });

//         if (optionsConfirmed || addonConfirmed) {
//           categorie.options.map(option => {
//             if (optionsConfirmed) {
//               if (
//                 option.type !== 3 &&
//                 option.projOptionPoolId !== optionsConfirmed.optionId &&
//                 option.projOptionPoolId
//               ) {
//                 if (option.type === 2) {
//                   option.deadline = y.deadline;
//                   option.categoryId = categorie.id;
//                   changedCart.push(option);
//                 }
//                 if (optionsConfirmed.type === 2) {
//                   optionsConfirmed.deadline = y.deadline;
//                   optionsConfirmed.selected = false;
//                   optionsConfirmed.categoryId = categorie.id;
//                   changedCart.push(optionsConfirmed);
//                 }
//               }
//             }
//             if (addonConfirmed) {
//               if (option.type === 3 && option.projOptionPoolId) {
//                 option.deadline = y.deadline;
//                 var addonIndex = addonConfirmed.findIndex(x => {
//                   return option.projOptionPoolId === x.optionId;
//                 });
//                 if (addonIndex >= 0) {
//                   addonConfirmed.splice(addonIndex, 1);
//                 } else {
//                   changedCart.push(option);
//                 }
//               }
//             }
//           });

//           changedCart = [...changedCart, ...addonConfirmed];
//         }
//       });
//     });
//   }

//   return changedCart;
// }

function saveChangInConfirme(data, confirmData) {
  let changedCart = [];
  if (confirmData.length > 0) {
    data.map(y => {
      y.categories.map(categorie => {
        let optionsConfirmed = null;
        let addonConfirmed = [];
        confirmData.forEach(x => {
          if (x.categoryId === categorie.id && x.id !== 0) {
            if (x.type !== 3) {
              optionsConfirmed = x;
            } else if (x.type === 3) {
              x.deadline = y.deadline;
              addonConfirmed.push(x);
            }
          }
        });

        //options
        //Find selected options type 1 or 2
        var isSelectedOption = categorie.options.find(
          option => option.type !== 3
        );
        //if no one is confirmed or confirmed is different form selected
        if (
          isSelectedOption &&
          (!optionsConfirmed ||
            isSelectedOption.projOptionPoolId !== optionsConfirmed.optionId)
        ) {
          // then if selected is type 2. Then it is added after somthing is confirmed. show as added to cart
          if (isSelectedOption.type !== 1) {
            isSelectedOption.deadline = y.deadline;
            isSelectedOption.categoryId = categorie.id;
            changedCart.push(isSelectedOption);
          }
          //then if somthing is confirmed and confirmed is not like selected. Show this as removed from cart
          if (
            optionsConfirmed &&
            isSelectedOption.projOptionPoolId !== optionsConfirmed.optionId
          ) {
            optionsConfirmed.deadline = y.deadline;
            optionsConfirmed.selected = false;
            optionsConfirmed.categoryId = categorie.id;
            changedCart.push(optionsConfirmed);
          }
        }

        //Addon
        var addonSelected = categorie.options.filter(
          option => option.type === 3
        );
        // all selected
        addonSelected.forEach(addonSelected => {
          //is selected confirmed
          var isConfirmed = addonConfirmed.some(
            x => x.optionId === addonSelected.projOptionPoolId
          );
          //if not show as selected in cart
          if (!isConfirmed) {
            addonSelected.selected = true;
            changedCart.push(addonSelected);
          }
        });
        // Check if confirmed is selected
        addonConfirmed.forEach(addon => {
          var isSelected = addonSelected.some(
            x => x.projOptionPoolId === addon.optionId
          );
          //If not selected. Show addon as removed in cart
          if (!isSelected) {
            addon.selected = false;
            changedCart.push(addon);
          }
        });
      });
    });
  }

  return changedCart;
}

// function addShowInCart(cart) {
//   cart.map(x => {
//     x.categories.map(categorie => {
//       categorie.options.map(option => {
//         if (option.type !== 1) {
//           x.show = true;
//         }
//       });
//     });
//   });
//   return cart;
// }

// Count items in cart and totale price
//x.show is to show item in cart
function cartInfo(cart) {
  cart.cartCount = 0;
  cart.cartTotPrice = 0;
  cart.groups.forEach(x => {
    x.categories.forEach(c => {
      c.options.forEach(o => {
        o.deadline = x.deadline;
        if (o.type !== 1) {
          x.show = true;
          if (o.selected) {
            cart.cartCount = cart.cartCount + 1;
            cart.cartTotPrice = cart.cartTotPrice + o.price;
          }
        }
      });
    });
  });
  return cart;
}

export default {
  namespaced: true,

  state: {
    cart: [],
    categories: [],
    //Length of sectionConfirme.options gets error of not { options: [] }
    sectionConfirme: { options: [] },
    changedCartOfConfirme: [],
    alldeadline: null,
    storeOptions: [],
    scrollTop: 65,
    readOnly: false
  },
  getters: {
    cart: state => {
      return state.cart;
    },
    categories: state => {
      return state.categories;
    },
    sectionConfirme: state => {
      return state.sectionConfirme;
    },
    changedCartOfConfirme: state => {
      return state.changedCartOfConfirme;
    },
    alldeadline: state => {
      return state.alldeadline;
    },
    storeOptions: state => {
      return state.storeOptions;
    },
    scrollTop: state => {
      return state.scrollTop;
    },
    readOnly: state => {
      return state.readOnly;
    }
  },
  mutations: {
    OptionsData(state, data) {
      state.cart = data.data.cart;
      state.categories = data.data.categories;
      state.sectionConfirme = data.confirmData;
      state.changedCartOfConfirme = data.changedCartOfConfirme;
      state.alldeadline = data.alldeadline;
    },
    addToCart(state, data) {
      state.cart = data.cart;
      state.changedCartOfConfirme = data.changedCartOfConfirme;
    },
    changeConfirme(state, data) {
      state.cart = data.cart;
      state.sectionConfirme = data.confirmData;
      state.changedCartOfConfirme = data.changedCartOfConfirme;
    },
    storeOptions(state, data) {
      state.storeOptions = data;
    },
    scrollTop(state, data) {
      state.scrollTop = data;
    },
    readOnly(state, value) {
      state.readOnly = value;
    }
  },
  actions: {
    async OptionsData({ commit }) {
      const data = await baseApi.getOptionsData();
      const confirmData = await optionsApi.getSectionoptions();
      const changedCartOfConfirme = saveChangInConfirme(
        data.data.cart.groups,
        confirmData.options
      );
      // const cart = addShowInCart(data.data.cart.groups);
      cartInfo(data.data.cart);

      //Get true if all categories has expires.
      //Get true false on all categories by experies data and today
      //Filter only categories with date expires date
      let alldeadline = false;
      data.data.categories = data.data.categories.filter(x => {
        if (x.deadline !== undefined) {
          x.disabled = moment(
            moment()
              .subtract(1, "days")
              .format()
          ).isAfter(x.deadline);
          if (!x.disabled) {
            alldeadline = true;
          }
        }
        return x.disabled !== undefined;
      });

      // data.data.cart.groups = cart;
      commit("OptionsData", {
        data: data.data,
        confirmData: confirmData,
        changedCartOfConfirme: changedCartOfConfirme,
        alldeadline: alldeadline
      });
    },
    async deleteCart({ commit, state, dispatch }, data) {
      var item = data;
      var categoryId = data.categoryId;

      const response = await optionsApi.removeOptionFromCart(item);

      if (categoryId) {
        //Get store options from api after saving
        dispatch("storeOptions", categoryId);
      }

      const changedCartOfConfirme = saveChangInConfirme(
        response.cart.groups,
        state.sectionConfirme.options
      );
      // response.cart.groups = addShowInCart(response.cart.groups);
      // cartInfo
      cartInfo(response.cart);
      commit("addToCart", { cart: response.cart, changedCartOfConfirme });
    },
    async saveToCart({ commit, state, dispatch }, item) {
      const response = await optionsApi.saveOptionCart(item);
      //Get store options from api after saving
      dispatch("storeOptions", item.id);

      const changedCartOfConfirme = saveChangInConfirme(
        response.cart.groups,
        state.sectionConfirme.options
      );
      // response.cart.groups = addShowInCart(response.cart.groups);
      cartInfo(response.cart);
      // cartInfo
      commit("addToCart", {
        cart: response.cart,
        changedCartOfConfirme
      });
    },
    async changeCart({ commit }, item) {
      const confirmData = await optionsApi.getSectionoptions();
      const changedCartOfConfirme = saveChangInConfirme(
        item.groups,
        confirmData.options
      );
      // item.groups = addShowInCart(item.groups);
      cartInfo(item);
      // cartInfo
      commit("changeConfirme", {
        cart: item,
        confirmData: confirmData,
        changedCartOfConfirme
      });
    },
    async storeOptions({ commit }, param) {
      const data = await optionsApi.getCategoryOptionsData(param);
      let dataArray = {};

      dataArray.dataAddon = data.filter(x => {
        x.overlay = false;
        if (x.categoryName) dataArray.title = x.categoryName;
        return x.type === 3;
      });
      //Sorting tillegg
      dataArray.dataAddon.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });

      //Sorting standard first and tilvalg after
      let standard = [];
      let addon = [];
      data.map(x => {
        if (x.type !== 3 && x.selected) {
          dataArray.optionalId = x.id;
        }
        switch (x.type) {
          case 1:
            x.typeName = "Standard";
            standard.push(x);
            break;
          case 2:
            x.typeName = "Tilvalg";
            addon.push(x);
            break;
        }
      });
      //Sort addon
      addon.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      // standard first and then addon sorted
      dataArray.dataOptions = [...standard, ...addon];

      commit("storeOptions", dataArray);
    },
    async removeStoreOptions({ commit }) {
      commit("storeOptions", []);
    },
    scrollTop({ commit }, value) {
      //vuetify scroll 65px shorter
      commit("scrollTop", value + 65);
    },
    readOnly({ commit }, value) {
      commit("readOnly", value);
    }
  }
};
