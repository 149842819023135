<template>
  <div>
    <v-simple-table v-if="data.length > 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Saksnr</th>
            <th class="text-left">Navn</th>
            <th class="text-left">Type</th>
            <th class="text-left">Kilde</th>
            <th class="text-left">Status</th>
            <th class="text-left">Kommentarer</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data" :key="item.id">
            <td @click="inquiry(index, item.id)">
              <a>{{ item.lineId }}</a>
            </td>
            <td @click="inquiry(index, item.id)">
              <a>{{ item.name }}</a>
            </td>
            <td @click="inquiry(index, item.id)">
              {{ item.isInquiry ? "Henvendelse" : "Reklamasjonshenvendelse" }}
            </td>
            <td @click="inquiry(index, item.id)">{{ item.source }}</td>
            <td
              @click="inquiry(index, item.id)"
              :class="{
                'red--text': statusRed(item),
                'green--text': statusGreen(item)
              }"
            >
              {{ item.regStatus }}
            </td>
            <td @click="inquiry(index, item.id)">
              {{ item.commentsCount }}
              <span v-if="item.newCommentsCount > 0">
                ({{ item.newCommentsCount }} nye)
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-btn
      v-if="profile && profile.user.isOwnerToday"
      @click="newRequest"
      class="mt-4"
      color="primary"
    >
      Lag ny henvendelse
    </v-btn>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["data"],

  methods: {
    inquiry(index, id) {
      if (this.$route.name === "requests") {
        this.$router.push({ name: "request", params: { id: id } });
      } else {
        this.$router.push({ name: "JointOwnership", params: { id: id } });
      }
    },
    newRequest() {
      this.$emit("newRequest", true);
    },
    statusGreen(data) {
      return (
        data.regStatus === "Åpen" ||
        data.regStatus === "Startet" ||
        data.regStatus === "Gjenåpnet" ||
        data.regStatus === "Ettårsbefaring"
      );
    },
    statusRed(data) {
      return (
        data.regStatus === "Lukket" ||
        data.regStatus === "Avslått" ||
        data.regStatus === "Ferdigstilt"
      );
    }
  },
  computed: {
    ...mapGetters({
      profile: "shared/profile"
    })
  }
};
</script>
