<template>
  <div>
    <v-slider
      v-if="includeSlider"
      v-model="data"
      :min="minValue"
      :max="maxValue"
      :step="stepValue"
      hide-details
      dense
      class="px-5 py-4"
    ></v-slider>
    <div
      :class="
        $vuetify.breakpoint.smAndDown
          ? 'd-flex justify-center px-5'
          : 'd-flex justify-start px-2 pb-2 pt-5'
      "
    >
      <v-btn
        class="px-2 mt-4"
        color="primary"
        depressed
        small
        fab
        :ripple="false"
        @click="decrementValue"
      >
        <v-icon> mdi-minus </v-icon>
      </v-btn>
      <div
        :class="
          $vuetify.breakpoint.smAndDown
            ? 'px-2 flex-grow-0 flex-shrink-1 pt-4 px-4'
            : 'px-2 flex-grow-1 flex-shrink-0 pt-4 pb-2'
        "
      >
        <v-text-field
          v-model="getFormattedValue"
          dense
          :hide-details="hideDetails"
          outlined
          :rules="[rules.required, rules.maxlen, rules.isnumer]"
          :maxlength="maxlen"
          :label="label"
          :suffix="suffix"
          @keyup.up="onPressUpKey"
          @keyup.down="onPressDownKey"
          @keydown="handelKeydown"
        ></v-text-field>
      </div>
      <v-btn
        class="px-2 mt-4"
        color="primary"
        depressed
        small
        fab
        :ripple="false"
        :retain-focus-on-click="true"
        @click="incrementValue"
      >
        <v-icon> mdi-plus </v-icon>
      </v-btn>
      <div
        v-if="showInfo && !$vuetify.breakpoint.smAndDown"
        class="align-center pr-2 pl-4 pt-6"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" hide-details color="blue"
              >mdi-help-circle-outline</v-icon
            >
          </template>
          {{ infoText }}
        </v-tooltip>
      </div>
      <div
        v-if="showInfo && $vuetify.breakpoint.smAndDown"
        class="align-center pt-3"
      >
        <v-btn
          class="pl-5 pr-0 pb-2"
          color="transparent"
          depressed
          fab
          dark
          hide-details
          :ripple="false"
          @click="getshowInfo"
        >
          <v-icon hide-details color="blue">mdi-help-circle-outline</v-icon>
        </v-btn>
      </div>
    </div>
    <slot name="bottomField" hide-details></slot>
  </div>
</template>

<script>
export default {
  emits: ["input", "showInfo"],
  props: {
    value: { required: true },
    label: { type: String, default: "", required: false },
    suffix: { type: String, default: null, required: false },
    includeSlider: { type: Boolean, default: false, required: false },
    minValue: { type: Number, required: false },
    maxValue: { type: Number, required: false },
    stepValue: { type: Number, required: true },
    showInfo: { type: Boolean, default: false, required: false },
    infoText: { type: String, default: null, required: false },
    hideDetails: { type: Boolean, default: false, required: false },
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Må ha en verdi",
        maxlen: (value) =>
          value.length <= 9 || "Verdi bør ikke overstige 9 sifre",
        isnumer: (value) => {
          const pattern = /[^0-9\,\s]+/g;
          return !pattern.test(value) || "Må ha en gyldig verdi";
        },
      },
      maxlen: 10,
    };
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(value) {
        let val = isNaN(value) ? Number(value.replace(/\D/g, "")) : value;
        this.$emit("input", val);
      },
    },
    getFormattedValue: {
      get() {
        if (this.stepValue && this.stepValue < 1) {
          return new Intl.NumberFormat("no-No", {
            minimumFractionDigits: 2,
          }).format(this.data);
        } else {
          return new Intl.NumberFormat("no-No", {
            minimumFractionDigits: 0,
          }).format(Math.round(this.data));
        }
      },
      set(data) {
        let val = isNaN(data) ? Number(data.replace(/\D/g, "")) : data;
        this.data = Number(val);
      },
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    getshowInfo() {
      this.$emit("showInfo", true);
    },
    decrementValue() {
      return (this.data = this.data - this.stepValue);
    },
    incrementValue() {
      return (this.data = this.data + this.stepValue);
    },
    onPressUpKey(e) {
      if (e.key != "ArrowUp" || this.isMobile) return;
      this.incrementValue();
    },
    onPressDownKey(e) {
      if (e.key != "ArrowDown" || this.isMobile) return;
      e.preventDefault();
      this.decrementValue();
    },
    handelKeydown(e) {
      if (e.which == 38 || e.which == 40) {
        e.preventDefault();
      }
    },
  },
};
</script>
