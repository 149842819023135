<template>
  <v-snackbar v-if="!!color" v-model="show" :top="true" :color="color">
    <!-- {{ text }} -->
    <span v-html="text"></span>
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="show = false">
        Lukk
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    show: false,
    text: "",
    color: ""
  }),
  computed: {
    ...mapGetters({
      snackbar: "shared/snackbar"
    })
  },
  created: function() {
    this.$store.watch(
      (state, getters) => getters["shared/showSnackbar"],
      newValue => {
        this.show = newValue.show;
        this.text = newValue.message;
        this.color = newValue.color;
      }
    );
  }
};
</script>
