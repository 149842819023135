<template>
  <div>
    <RoomNavigation :rooms="rooms" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RoomNavigation from "@/components/store/webStore/shared/RoomNavigation.vue";
export default {
  computed: {
    ...mapGetters({
      options: "purchase/options",
      profile: "purchase/profile",
      sectionInfo: "purchase/sectionInfo"
    }),
    rooms() {
      if (this.options && this.sectionInfo) {
        var data = [
          { name: "Intro", to: { name: "webShopOptionsIntro" }, exact: true }
        ];

        if (
          this.sectionInfo.isAtticAvailable &&
          !this.sectionInfo.isAtticIncluded
        ) {
          data.push({
            name: "Loftbestilling",
            to: {
              name: "webShopOptionsAttic"
            }
          });
        }

        var atticIncluded =
          this.sectionInfo.isAtticAvailable && this.sectionInfo.isAtticIncluded;
        var atticAdded =
          this.sectionInfo.isAtticAvailable &&
          !this.sectionInfo.isAtticIncluded &&
          this.profile &&
          this.profile.includeAttic;

        this.options.forEach(x => {
          //Dont show expired categries
          if (x.isAtticCategory ? atticIncluded || atticAdded : true) {
            data.push({
              name: x.name,
              to: {
                name: "webShopOptionsCategory",
                params: { categoryId: x.id }
              }
            });
          }
        });

        return data;
      }
      return [];
    }
  },
  components: {
    RoomNavigation
  }
};
</script>
