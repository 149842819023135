<template>
  <div>
    <v-dialog v-model="show" max-width="600">
      <v-card>
        <v-card-title>
          <span class="headline primary--text">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <span style="font-size: 16px;"> {{ info }}</span>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn depressed color="success" @click="accept">Ja</v-btn>
          <v-btn depressed color="error" @click="show = false">Nei</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["value", "title", "info"],
  methods: {
    accept() {
      this.$emit("accept", true);
      this.show = false;
    }
  },
  computed: {
    show: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
