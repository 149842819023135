import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"600","persistent":"","fullscreen":_vm.$store.getters['shared/isMobileDevice']},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{staticClass:"safeAreaDialog"},[_c(VCardTitle,[_c('span',{staticClass:"headline primary--text"},[_vm._v(" Introduksjonsfilm ")])]),_c(VCardText,[_c('VideoPlayer',{attrs:{"src":"https://thg.blob.core.windows.net/publicvideos/MittTrysilhus_v3.mp4"}})],1),_c(VCardActions,{staticClass:"pb-6"},[_c(VBtn,{staticClass:"mx-auto",attrs:{"large":"","depressed":"","color":"primary"},on:{"click":_vm.close}},[_vm._v(" Lukk ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }