<template>
  <div class="login-bg">
    <v-img
      class="login-trysilhusLogo"
      width="180"
      :src="require('@/assets/imgs/gfx/trysilhus_logo_hvit2.png')"
    ></v-img>
    <v-card
      v-if="!$auth.loading"
      class="mx-auto"
      elevation="6"
      max-width="320"
      min-width="320"
      min-height="400"
    >
      <div class="login-content pa-12 text-center">
        <v-img
          class="mx-auto"
          width="80"
          height="80"
          :src="'https://thg.blob.core.windows.net/public-images/mt_logo.svg'"
        ></v-img>
        <h3 class="mt-1 mb-5 primary--text">Mitt Trysilhus</h3>
        <div v-if="$route.name === 'login'" class="text-center">
          <v-btn
            depressed
            small
            style="width: 100%;"
            v-if="!$auth.isAuthenticated"
            color="primary"
            @click="login"
            >Logg inn</v-btn
          >
          <v-btn
            depressed
            outlined
            small
            color="darkgrey"
            @click="$router.push({ name: 'signup' })"
            class="w-100 my-3"
            >Registrer konto</v-btn
          >
          <v-btn
            class="w-100 mb-3"
            depressed
            small
            color="darkgrey"
            @click="$router.push({ name: 'forgottenpassword' })"
            outlined
            >Glemt passord</v-btn
          >

          <div class="mb-2">
            <a class="caption info--text" @click="openPDF"
              ><i class="fas fa-info-circle"></i> Slik logger du deg på</a
            >
          </div>

          <a class="caption dark--text" href="https://www.trysilhus.no/"
            ><i class="fas fa-long-arrow-alt-left"></i> Tilbake til
            Trysilhus.no</a
          >
        </div>
        <div v-if="$route.name === 'signup'" class="text-center">
          <v-alert
            v-if="message"
            text
            :color="messageType ? 'success' : 'error'"
          >
            {{ message }}
          </v-alert>

          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="signup.name"
              label="Navn"
              :rules="[() => !!signup.name || 'Navn er påkrevd']"
              required
              autocomplete="given-name"
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              v-model="signup.email"
              label="E-post"
              :rules="emailRules"
              hide-details="auto"
              required
              autocomplete="email"
            ></v-text-field>

            <v-autocomplete
              v-model="signup.project"
              :items="projectsSignup"
              item-text="name"
              return-object
              label="Prosjekt"
              no-data-text="Finner ingen prosjekter"
              hide-details="auto"
              autocomplete="off"
              :rules="[() => !!signup.project || 'Prosjekt er påkrevd']"
              required
            ></v-autocomplete>
            <v-btn
              @click="saveSignup"
              class="w-100 my-3 mt-5"
              depressed
              small
              dark
              color="green"
              >Registrer</v-btn
            >
            <v-btn
              class="w-100"
              depressed
              outlined
              small
              color="darkgrey"
              :to="{ name: 'login' }"
              ><i class="fas fa-long-arrow-alt-left"></i> Tilbake</v-btn
            >
          </v-form>
          <p class="caption mt-4 mb-1">Kundesenter tlf: 32 21 55 10</p>
          <p class="caption mb-0">Mandag-fredag kl. 10-14</p>
        </div>
        <div v-if="$route.name === 'forgottenpassword'" class="text-center">
          <v-form ref="email" v-model="validEmail" lazy-validation>
            <v-card
              v-if="mailSendt"
              class="mx-auto mb-4"
              max-width="344"
              outlined
              color="#daede2"
            >
              <v-card-text class="text-left" style="color: #24563a">
                Hvis du har en bruker på mitt.trysilhus.no vil du få en epost
                med instruksjoner på hvordan du setter ett nytt passord.
              </v-card-text>
            </v-card>
            <v-text-field
              v-model="email"
              dense
              label="E-post"
              :rules="emailRules"
              required
              autocomplete="off"
            ></v-text-field>

            <v-btn
              @click="resetpassword(email)"
              class="w-100 my-3"
              depressed
              small
              dark
              color="green"
              >Be om nytt passord</v-btn
            >
          </v-form>

          <v-btn
            class="w-100"
            depressed
            outlined
            small
            color="darkgrey"
            @click="$router.go(-1)"
            ><i class="fas fa-long-arrow-alt-left"></i> Tilbake</v-btn
          >

          <p class="caption mt-4 mb-1">Kundesenter tlf: 32 21 55 10</p>
          <p class="caption">Mandag-fredag kl. 10-14</p>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import api from "@/api/startpageApi";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "login",
  data: () => ({
    projectsSignup: [],
    signup: {},
    loginShow: true,
    registerShow: false,
    forgotPassShow: false,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    email: null,
    mailSendt: false,
    message: null,
    messageType: false,
    valid: true,
    validEmail: true,
    emailRules: [
      v => !!v || "E-post er påkrevd",
      v => /.+@.+\..+/.test(v) || "E-post må være gyldig"
    ]
  }),
  computed: {
    ...mapGetters({
      routerTo: "shared/routerTo"
    })
  },
  methods: {
    ...mapActions({
      cookiesOff: "shared/cookiesOff"
    }),
    // Log the user in
    login() {
      try {
        localStorage;

        this.$auth.loginWithRedirect({
          appState: { targetUrl: this.routerTo }
        });
        // this.$auth.loginWithRedirect();
      } catch (e) {
        this.cookiesOff(true);
      }
    },
    async saveSignup() {
      let isValid = this.$refs.form.validate();
      if (isValid) {
        let data = this.signup;
        data.projectId = this.signup.project.id;
        delete data.project;

        await api.signup(data).then(respons => {
          if (respons.message) {
            this.message = respons.message;
            this.messageType = false;
            this.$refs.form.resetValidation();
          } else {
            this.message =
              "Det er blitt sendt e-post til kvalitetskontrollør som vil gi deg tilgang til Mitt Trysilhus.";
            this.messageType = true;
          }
        });
      }
    },
    async resetpassword(mail) {
      let isValid = this.$refs.email.validate();
      if (isValid) {
        await api.resetpassword(mail).then(respons => {
          if (respons.status === 200) {
            this.mailSendt = true;
          }
        });
      }
    },
    async getProjects() {
      this.projectsSignup = await api.getProjectsSignup();
    },
    openPDF() {
      window.open(
        "https://thg.blob.core.windows.net/public-assets/2009_Mitt_Trysilhus_brukerveiledning_login.pdf"
      );
    }
  },
  watch: {
    $route(to) {
      if (to.name === "signup") {
        this.getProjects();
      }
    }
  },
  created() {
    if (this.$route.name === "signup") {
      this.getProjects();
    }
  }
};
</script>
<style lang="scss">
.login-bg {
  background: url("https://thg.blob.core.windows.net/public-images/login_bg.jpg")
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  .login-trysilhusLogo {
    position: absolute;
    top: 3%;
    left: 2%;
  }
  @media (max-width: 768px) {
    .login-trysilhusLogo {
      left: 50%;
      top: 91%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
