import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VList,{staticClass:"py-0",attrs:{"color":"grey lighten-4"}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.title)+":")])],1)],1)],1),_vm._l((_vm.data),function(reservation,index){return _c(VList,{key:index,staticClass:"py-0"},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Frist forbehold: "+_vm._s(_vm._f("moment")(reservation.deadline,"DD.MM.YYYY")))]),_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(reservation.text)}})],1)],1),_c(VDivider)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }