import baseApi from "./baseApi";

const api = {
  async purchase(sectionId) {
    const response = await baseApi.get(`/purchase/${sectionId}`, {
      adapter: null,
      cache: {
        maxAge: 0
      }
    });
    return response.data;
  },
  async registerInterested(sectionId, data) {
    const response = await baseApi.post(
      `purchase/register/${sectionId}`,
      data,
      {
        adapter: null
      }
    );
    return response.data;
  },
  async getOptions(sectionId, includeAttic) {
    var query = includeAttic ? `?includeAttic=${includeAttic}` : "";
    const response = await baseApi.get(
      `/purchase/options/${sectionId}` + query,
      {
        adapter: null
      }
    );
    return response.data;
  },
  async getOptionsCategory(sectionId, categoryId, includeAttic) {
    var query = includeAttic ? `?includeAttic=${includeAttic}` : "";
    const response = await baseApi.get(
      `/purchase/options/${sectionId}/${categoryId}` + query,
      {
        adapter: null
      }
    );
    return response.data;
  },

  async getColorOptions(sectionId, includeAttic) {
    var query = includeAttic ? `?includeAttic=${includeAttic}` : "";
    const response = await baseApi.get(
      `purchase/coloroptions/${sectionId}` + query,
      {
        adapter: null
      }
    );
    return response.data;
  },
  async colorOptionsCosts(sectionId, data) {
    const response = await baseApi.post(
      `purchase/coloroptions/costs/${sectionId}`,
      data,
      {
        adapter: null
      }
    );
    return response.data;
  },
  async purchaseSendConfirmcode(customerId) {
    const response = await baseApi.post(`purchase/confirmcode/${customerId}`, {
      adapter: null
    });
    return response.data;
  },
  async purchaseConfirm(confirmationCode, data) {
    const response = await baseApi.post(`purchase/${confirmationCode}`, data, {
      adapter: null
    });
    return response.data;
  },
  async purchaseDownloadDocument(document) {
    const response = await baseApi.post(
      `purchase/document/download`,
      document,
      {
        adapter: null
      }
    );
    return response.data;
  },

  async purchaseDownloadReceipt(sectionId, customerId) {
    const response = await baseApi.get(
      `purchase/${sectionId}/${customerId}/download`,
      {
        responseType: "blob",
        adapter: null
      }
    );

    return response;
  }
};

export default api;
