import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mt-8 theme-shadow"},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"md":"8"}},[_c(VList,[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_c('h3',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.title))])])],1)],1),_c(VDivider)],1),_c(VList,{staticClass:"py-0"},[_c(VListItem,[_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-account-circle-outline")])],1),_c(VListItemContent,{staticStyle:{"max-width":"100px"}},[_c(VListItemTitle,[_vm._v("Navn:")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.name ? _vm.name : "Kundesenter"))])],1)],1)],1),_c(VList,{staticClass:"py-0"},[_c(VListItem,[_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-email-outline")])],1),_c(VListItemContent,{staticStyle:{"max-width":"100px"}},[_c(VListItemTitle,[_vm._v(" E-post:")])],1),_c(VListItemContent,[_c(VListItemTitle,[_c('a',{attrs:{"href":'mailto:' + _vm.mailTo,"title":"Epost"}},[_vm._v(" "+_vm._s(_vm.mailTo)+" ")])])],1)],1)],1),_c(VList,{staticClass:"py-0"},[_c(VListItem,[_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-phone-outline")])],1),_c(VListItemContent,{staticStyle:{"max-width":"100px"}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.phoneName ? _vm.phoneName : "Telefon")+":")])],1),_c(VListItemContent,[_c(VListItemTitle,[_c('a',{attrs:{"href":'tel:' + _vm.phoneTo}},[_vm._v(" "+_vm._s(_vm.phone ? _vm.phone : "322 15 510")+" ")])])],1)],1)],1)],1),_c(VCol,{staticClass:"card-icon d-none d-md-block"},[_c(VDivider,{attrs:{"vertical":""}}),(_vm.photo && _vm.name)?_c('img',{attrs:{"src":_vm.photo,"width":"155"}}):_c('img',{attrs:{"src":"https://thg.blob.core.windows.net/public-images/kundeansvarlig.png","width":"155"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }