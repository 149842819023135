<template>
  <div id="p1" v-if="$vuetify.breakpoint.smAndDown">
    <div class="d-flex justify-space-between px-2 py-3">
      <v-label>Kalkulert strømkost egen bolig pr. mnd.: </v-label>
      <v-label small hide-details>{{ gethousePowerCost }}</v-label>
    </div>
    <v-divider hide-details></v-divider>
    <div class="d-flex justify-space-between px-2 py-3 m1">
      <v-label>Kalkulert strømkost Trysilhusbolig pr. mnd.: </v-label>
      <v-label small hide-details>{{ getNewHousePowerCost }}</v-label>
    </div>
  </div>

  <div v-else class="pt-3">
    <v-divider hide-details class="mx-3"></v-divider>
    <v-row no-gutters dense>
      <v-col cols="6">
        <v-row no-gutters>
          <v-col cols="8" class="pl-6 pr-1 py-3">
            <v-label>Kalkulert strømkost egen bolig pr. mnd.: </v-label>
          </v-col>
          <v-col cols="3">
            <div class="text-right pl-1 py-3">
              <v-label small hide-details>{{ gethousePowerCost }}</v-label>
            </div>
          </v-col>
          <v-col cols="1" dense no-gutters
            ><v-divider
              hide-details
              vertical
              class=" ml-4 mr-1 pl-6 pb-0"
            ></v-divider
          ></v-col>
        </v-row>
        <v-divider class="px-4 mx-4"></v-divider>
        <v-row no-gutters>
          <v-col cols="6" class="pl-6 pr-1 py-3">
            <v-label>Egen bolig kost pr. mnd.: </v-label>
          </v-col>
          <v-col cols="5">
            <div class="text-right pr-1 py-3">
              <v-label small hide-details>{{ gethouseMonthlyCost }}</v-label>
            </div>
          </v-col>
          <v-col cols="1" dense no-gutters
            ><v-divider
              hide-details
              vertical
              class=" ml-4 mr-1 pl-6 pt-0"
            ></v-divider
          ></v-col>
        </v-row>
      </v-col>
      <v-col cols="6" class="m1">
        <v-row no-gutters>
          <v-col cols="9" class="pl-6 pr-1 py-3">
            <v-label>Kalkulert strømkost Trysilhusbolig pr. mnd.: </v-label>
          </v-col>
          <v-col cols="3">
            <div class="text-right pr-7 pl-1 py-3">
              <v-label small hide-details>{{ getNewHousePowerCost }}</v-label>
            </div>
          </v-col>
        </v-row>
        <v-divider class="px-4 mx-4"></v-divider>
        <v-row no-gutters>
          <v-col cols="7" class="pl-6 pr-1 py-3">
            <v-label>Trysilhusbolig bolig kost pr. mnd.: </v-label>
          </v-col>
          <v-col cols="5">
            <div class="text-right pr-7 pr-1 py-3">
              <v-label small hide-details>{{ getnewhouseMonthlyCost }}</v-label>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  emits: ["update:totalhouse", "update:totalnewhouse"],
  props: {
    powerData: { type: Object, required: true },
    houseMonthlyKwt: { type: String, required: false },
    powerCost: { type: Number, default: 0 },
    houseTotalCost: { type: Number },
    newhouseTotalCost: { type: Number },
    totalhouse: { type: Number },
    monthlyKwt: { type: Number, default: 0, required: false },
  },
  data() {
    return {
      showInfoDialog: false,
      costDifferenceInfo:
        "<p>Noe info om differense!</p><a href=”#”>Mer informasjon</a>",
      housePowerCost: 0,
      houseCost: 0,
      newPowerCost: 0,
      newCost: 0,
      calculatedDifferenceTest: -4550,
    };
  },
  methods: {
    getFormattedValue(data) {
      if (isNaN(data)) return data;
      return new Intl.NumberFormat("no-No", {
        minimumFractionDigits: 0,
      }).format(Math.round(data));
    },
    calcHousePowerCost() {
      this.housePowerCost = this.houseMonthlyKwt
        ? Number(this.houseMonthlyKwt.replace(/\s/g, "")) *
          Number(this.powerCost / 100)
        : Number(this.monthlyKwt) * Number(this.powerCost / 100);
    },
    calcnewPowerCost() {
      this.newPowerCost =
        Number(this.powerData.newMonthlyKwt) * Number(this.powerCost / 100);
    },
    // calcHouseMonthlyCost() {
    //   debugger;
    //   let values = Object.keys(this.houseData).filter(
    //     (key) =>
    //       key === "monthlyCost" ||
    //       key === "otherCost" ||
    //       key === "maintanceCost"
    //   );
    //   let totalMonthlyCost = Object.values(values).reduce((sum, i) => sum + i, 0);

    //   let total = Object.values(this.houseData).reduce((sum, i) => sum + i, 0);
    // },
    calcHouseTotal() {
      let total = this.housePowerCost + this.houseTotalCost;
      this.$emit("update:totalhouse", total);
    },
    calcNewTotal() {
      let total = this.newPowerCost + this.newhouseTotalCost;
      this.$emit("update:totalnewhouse", total);
    },
  },
  computed: {
    gethousePowerCost() {
      this.calcHousePowerCost();
      this.calcHouseTotal();
      return this.getFormattedValue(this.housePowerCost);
    },
    gethouseMonthlyCost() {
      let total = this.housePowerCost + this.houseTotalCost;
      this.$emit("update:totalhouse", total);
      return this.getFormattedValue(total);
    },
    getNewHousePowerCost() {
      this.calcnewPowerCost();
      this.calcNewTotal();
      return this.getFormattedValue(this.newPowerCost);
    },
    getnewhouseMonthlyCost() {
      let total = this.newPowerCost + this.newhouseTotalCost;
      this.$emit("update:totalnewhouse", total);
      return this.getFormattedValue(total);
    },
  },
  components: {
    //InfoDialog,
  },
};
</script>

<style lang="scss" scoped>
.sep {
  .col {
    max-width: 0.3% !important;
  }
}

div#p1 {
  border: dotted 1px;
  //padding: auto;
  border-radius: 8px;
}

.m1 {
  background-color: rgb(242, 242, 242);
}
</style>
