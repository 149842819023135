<template>
  <div class="notfound-bg">
    <v-img
      class="login-trysilhusLogo"
      width="180"
      :src="require('@/assets/imgs/gfx/trysilhus_logo_hvit2.png')"
    ></v-img>
    <v-card
      class="mx-auto"
      elevation="6"
      max-width="400"
      min-width="320"
      min-height="400"
    >
      <div class="login-content pa-12 text-center">
        <v-img
          class="mx-auto"
          width="80"
          height="80"
          :src="'https://thg.blob.core.windows.net/public-images/mt_logo.svg'"
        ></v-img>
        <h3 class="mt-1 mb-5 primary--text">Mitt Trysilhus</h3>
        <p>
          Kunne ikke finne seksjonen. Seksjonen er enten solgt eller ikke lenger
          tilgjengelig. Ta kontakt med selger ved spørsmål.
        </p>

        <v-avatar v-if="sales" class="mt-8" width="80" height="80">
          <v-img
            :lazy-src="sales.salesRepPhoto"
            :src="sales.salesRepPhoto"
          ></v-img>
        </v-avatar>
        <p v-if="sales" class="mt-8 mb-0">{{ sales.salesRepName }}</p>
        <p v-if="sales" class="mb-0">
          <a
            :href="'mailto:' + sales.salesRepEmail"
            :title="sales.salesRepEmail"
          >
            {{ sales.salesRepEmail }}
          </a>
        </p>
        <p v-if="sales" class="mb-6">
          <a :href="'tel:' + sales.salesRepPhone" :title="sales.salesRepPhone">
            {{ sales.salesRepPhone }}
          </a>
        </p>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  async mounted() {
    if (!this.salesInfo) {
      await this.setSectionInfo(this.$route.params.sectionId).then(() => {
        this.$router.push({
          name: "webShopIntro",
          params: { sectionId: this.$route.params.sectionId }
        });
      });
    }
  },
  methods: {
    ...mapActions({
      setSectionInfo: "purchase/setSectionInfo"
    })
  },
  computed: {
    ...mapGetters({
      salesInfo: "purchase/salesInfo"
    }),
    sales() {
      if (this.salesInfo) {
        return this.salesInfo;
      }
      return null;
    }
  }
};
</script>

<style lang="scss">
.notfound-bg {
  background: url("https://thg.blob.core.windows.net/public-images/error_bg.jpg")
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  .login-trysilhusLogo {
    position: absolute;
    top: 3%;
    left: 2%;
  }
}
</style>
