import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{staticClass:"clickyCard theme-shadow",class:{
      expired: _vm.categorie.disabled || _vm.categorie.disabled === undefined
    },attrs:{"hover":""},on:{"click":function($event){return _vm.change(_vm.item)}}},[_c(VImg,{attrs:{"src":_vm.item.isActive
          ? _vm.optionsService.getImage(_vm.item)
          : _vm.optionsService.getOptionImage(_vm.item.isActiveAddon),"height":"100px"}},[_c(VRow,{staticClass:"fill-height"},[_c(VCardTitle,{staticClass:"align-start"},[(_vm.item.isActive && _vm.item.isActive.type === 2)?_c(VChip,{staticClass:"ml-2",attrs:{"small":"","color":"primary"}},[_vm._v("Tilvalg")]):_vm._e(),(_vm.item.isActive && _vm.item.isActive.type === 1)?_c(VChip,{staticClass:"ml-2",attrs:{"small":"","dark":"","color":"yellow"}},[_vm._v("Standard")]):_vm._e(),(_vm.item.isActiveAddon)?_c(VChip,{staticClass:"ml-2",attrs:{"small":"","dark":"","color":"primary"}},[_vm._v("Tillegg")]):_vm._e(),_c(VChip,{staticClass:"ml-2 white--text",attrs:{"color":"green","small":""}},[_vm._v(" "+_vm._s(_vm.format.formatToNok(_vm.item.price))+" ")])],1),_c(VSpacer)],1)],1),_c(VBtn,{staticClass:"rateInquiryBadge white--text",attrs:{"rounded":"","small":"","height":"24","elevation":"0","color":"yellow"}},[_vm._v(" Endre ")]),(_vm.item.isActiveAddon && _vm.item.isActive)?_c('span',{staticClass:"addonImg"},[_c(VImg,{staticClass:"imgAddon",attrs:{"src":_vm.optionsService.getOptionImage(_vm.item.isActiveAddon),"height":"50px"}})],1):_vm._e(),_c(VList,{staticClass:"text-center",attrs:{"two-line":""}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_c('h3',[_vm._v(_vm._s(_vm.item.name))])]),(_vm.item.isActive)?_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.item.isActive.name)+" ")]):_vm._e(),(_vm.item.isActiveAddon && !_vm.item.isActive)?_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.item.isActiveAddon.name)+" ")]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }