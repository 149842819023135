<template>
  <v-row class="justify-center">
    <v-col class="pt-0" xl="9">
      <v-list color="transparent">
        <v-list-item class="pl-0 mb-2">
          <v-list-item-avatar>
            <v-icon class="primary white--text">mdi-cart</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="primary--text headline font-weight-black">
              Handlekurv
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>

      <slot name="alert"></slot>

      <v-row>
        <v-col cols="12">
          <div v-if="!haveConfirmed">
            <ListItems
              v-model="value"
              margin="mt-4"
              :itemSubtitleKey="itemSubtitleKey"
              :itemTitleKey="itemTitleKey"
              :showChangeIcon="showChangeIcon"
              :showDeleteIcon="showDeleteIcon"
              :hideDeleteIconOn="hideDeleteIconOn"
              @change="change"
              @delete="del"
            >
              <template v-slot:subtitle="data">
                <slot name="subtitle" v-bind="data">
                  {{ data[itemSubtitleKey] }}
                </slot>
              </template>
            </ListItems>
          </div>

          <v-list v-if="haveConfirmed">
            <ListItems
              v-model="confirmedCart"
              title="Bekreftet tilvalg"
              margin="mt-4"
              :itemSubtitleKey="itemSubtitleKey"
              :itemTitleKey="itemTitleKey"
              :showChangeIcon="showChangeIconConfirmed"
              :showDeleteIcon="showDeleteIconConfirmed"
              :hideDeleteIconOn="hideDeleteIconOn"
              @delete="del"
            />
          </v-list>

          <!-- Changed in confirmed -->
          <div v-if="haveConfirmed && haveChangedConfirmed">
            <ListItems
              v-model="changedFromConfirmed"
              is-changes
              title="Endringer"
              margin="mt-4"
              :itemSubtitleKey="itemSubtitleKey"
              :itemTitleKey="itemTitleKey"
              :showChangeIcon="showChangeIconChanged"
              :showDeleteIcon="showDeleteIconChanged"
              :hideDeleteIconOn="hideDeleteIconOn"
              @change="change"
              @delete="deleteConfirmed"
            >
              <template v-slot:subtitle="data">
                <slot name="subtitleChanges" v-bind="data">
                  {{ data[itemSubtitleKey] }}
                </slot>
              </template>
            </ListItems>
          </div>

          <ButtonBar
            v-if="showbuttons"
            :totalPrice="totalPrice"
            :showConfirmBtnDisable="showConfirmBtnDisable"
            :showConfirmBtn="showConfirmBtn"
            :showChangeBtn="showChangeBtn"
            :showSummaryBtn="showSummaryBtn"
            :confirmBtnName="confirmBtnName"
            :changeBtnName="changeBtnName"
            :summaryBtnName="summaryBtnName"
            @confirm="$emit('confirm', true)"
            @change="$emit('change', true)"
            @summary="$emit('summary', true)"
          />

          <div v-if="showEmptyCheckout">
            <h2 class="mb-4">Det er ikke lagt til noe i Handlekurven</h2>
            <v-btn :to="{ name: 'store' }" color="primary">
              {{ showEmptyBtnName }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import optionsService from "@/options.services";

import ListItems from "@/components/store/child/ListItems";
import ButtonBar from "@/components/store/shared/checkout/children/ButtonBar";
export default {
  props: {
    value: {
      required: true
    },
    confirmedCart: {
      default: null
    },
    changedFromConfirmed: {
      default: null
    },
    haveConfirmed: {
      type: Boolean,
      default: false
    },
    haveChangedConfirmed: {
      type: Boolean,
      default: false
    },
    itemSubtitleKey: {
      type: String,
      default: "name"
    },
    itemTitleKey: {
      type: String,
      default: "name"
    },
    showChangeIcon: {
      type: Boolean,
      default: false
    },
    showChangeIconChanged: {
      type: Boolean,
      default: false
    },
    showChangeIconConfirmed: {
      type: Boolean,
      default: false
    },

    showDeleteIcon: {
      type: Boolean,
      default: false
    },
    showDeleteIconChanged: {
      type: Boolean,
      default: false
    },
    showDeleteIconConfirmed: {
      type: Boolean,
      default: false
    },
    hideDeleteIconOn: {
      type: String,
      default: "hideDelete"
    },
    showbuttons: {
      type: Boolean,
      default: false
    },
    totalPrice: {
      default: null
    },
    showConfirmBtn: {
      type: Boolean,
      default: false
    },
    showConfirmBtnDisable: {
      type: Boolean,
      default: false
    },
    showChangeBtn: {
      type: Boolean,
      default: false
    },
    showSummaryBtn: {
      type: Boolean,
      default: false
    },
    confirmBtnName: {
      type: String,
      default: "Bekreft"
    },
    changeBtnName: {
      type: String,
      default: "Endre"
    },
    summaryBtnName: {
      type: String,
      default: "Kvittering"
    },
    showEmptyCheckout: {
      type: Boolean,
      default: false
    },
    showEmptyBtnName: {
      type: String,
      default: "Gå til"
    },
    showEmptyGoTo: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      optionsService
    };
  },

  methods: {
    change(data) {
      this.$emit("change", data);
    },
    del(data) {
      this.$emit("delete", data);
    },
    deleteConfirmed(data) {
      this.$emit("delete", data, true);
    }
  },
  components: {
    ListItems,
    ButtonBar
  }
};
</script>
<style lang="scss">
.checkoutFooter {
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}
@media (max-width: 600px) {
  .checkoutFooter {
    button,
    a {
      width: 100%;
    }
  }
}
.deadlineHaveBeen {
  color: var(--v-warning-base);
}
</style>
