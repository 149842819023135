import notification from "@/notification.services";
import purchaseApi from "@/api/purchaseApi";
import Vue from "vue";
import ncsColor from "ncs-color";
import moment from "moment";

//Todo
/* 
* Lagring av valgt tilvalg og fargevalg trenger ikke lagres på profilen

*/

function selectedOptionsFromAlbum(selectedAlbum, optionsSelected) {
  if (optionsSelected) {
    var optionsSelected = removeConfirmedSelectedOptions(optionsSelected);
  } else {
    optionsSelected = {};
  }

  selectedAlbum.forEach(x => {
    if (!optionsSelected[x.optionCategoryParentId]) {
      optionsSelected[x.optionCategoryParentId] = {};
    }

    if (!optionsSelected[x.optionCategoryParentId][x.optionCategoryId]) {
      optionsSelected[x.optionCategoryParentId][x.optionCategoryId] = [];
    }

    optionsSelected[x.optionCategoryParentId][
      x.optionCategoryId
    ] = optionsSelected[x.optionCategoryParentId][x.optionCategoryId].filter(
      y => {
        return y.type !== x.optionTypeId;
      }
    );

    optionsSelected[x.optionCategoryParentId][x.optionCategoryId].push({
      categoryId: x.optionCategoryParentId,
      categoryName: x.categoryName,
      id: x.id,
      name: x.name,
      parentId: x.optionCategoryId,
      price: x.price,
      type: x.optionTypeId,
      isConfirmed: true,
      disabledDelete: true
    });
  });

  return optionsSelected;
}

function removeConfirmedSelectedOptions(selectedOptions) {
  if (selectedOptions) {
    Object.entries(selectedOptions).forEach(([key, value]) => {
      Object.entries(value).forEach(([key1, value]) => {
        selectedOptions[key][key1] = value.filter(x => !x.isConfirmed);

        if (selectedOptions[key][key1].length === 0) {
          delete selectedOptions[key][key1];
        }
      });

      if (Object.keys(selectedOptions[key]).length === 0) {
        delete selectedOptions[key];
      }
    });
  }

  return selectedOptions;
}

function optionsSelectedToList(data) {
  var list = [];
  var totalPrice = 0;
  Object.keys(data).forEach(categoryId => {
    Object.keys(data[categoryId]).forEach(id => {
      data[categoryId][id].forEach(x => {
        list.push(x);
        if (x.price) {
          totalPrice = totalPrice + x.price;
        }
      });
    });
  });

  return { list: list, totalPrice: totalPrice };
}

function setOptionsAndColor(commit, profile) {
  if (profile.options) {
    //set options
    var list = optionsSelectedToList(profile.options);
    var dataOptions = { object: profile.options, list: list };
    commit("selectedOptions", dataOptions);
  }

  var dataColor = null;
  if (profile.selectedColorOptions) {
    //set options
    var total = colorOptionsSelectedTotal(profile.selectedColorOptions);
    dataColor = { object: profile.selectedColorOptions, total: total };
  }
  commit("selectedColorOptions", dataColor);
}

function removeExpiredSelectedOptions(options, selectedOptions) {
  var isDeleted = false;

  if (selectedOptions) {
    options.forEach(x => {
      if (x.deadlineExpired) {
        if (selectedOptions[x.id]) {
          Object.entries(selectedOptions[x.id]).forEach(([key, value]) => {
            //Delete all not confirmed
            selectedOptions[x.id][key] = value.filter(y => y.isConfirmed);

            //delete if noen is confirmed
            if (selectedOptions[x.id][key].length === 0) {
              delete selectedOptions[x.id][key];
              isDeleted = true;
            }

            //If somthing is changed isDeletet is true
            if (
              !isDeleted &&
              selectedOptions[x.id][key].length !== value.length
            ) {
              isDeleted = true;
            }
          });
          //If none in category is confirmed delete object
          if (Object.keys(selectedOptions[x.id]).length === 0) {
            delete selectedOptions[x.id];
          }
        }
      }
    });
  }

  return isDeleted ? selectedOptions : false;
}

// Find saved options type 2. Save as optionsSelectedId. This vil be used to show selected images on category
function optionsHaveSelected(options, selectedOptions) {
  if (selectedOptions) {
    options.forEach(x => {
      x.children.forEach(y => {
        //option is selected
        if (y.optionsSelectdId) {
          delete y.optionsSelectdId;
        }
        if (selectedOptions[x.id] && selectedOptions[x.id][y.id]) {
          var optionSelected = selectedOptions[x.id][y.id].find(
            i => i.type === 2
          );
          if (optionSelected) {
            y.optionsSelectdId = optionSelected.id;
          }
        }
        //Addon is selected
        if (y.addonSelectdId) {
          delete y.addonSelectdId;
        }
        if (selectedOptions[x.id] && selectedOptions[x.id][y.id]) {
          var addonSelectd = selectedOptions[x.id][y.id].find(
            i => i.type === 3
          );
          if (addonSelectd) {
            y.addonSelectdId = addonSelectd.id;
          }
        }
      });
    });
  }
  return options;
}

function selectedColorsFromAlbum(selectedColorOptions) {
  selectedColorOptions.forEach(x => {
    x.hexColor = ncsColor.hex(x.ncs);
    x.isConfirmed = true;
    x.disabledDelete = true;
  });

  return selectedColorOptions;
}

function colorOptionsfixData(colorOptions) {
  //change price to amunt
  //If contrast wall is added sett active

  colorOptions.forEach(x => {
    Vue.set(x, "amount", x.price ? x.price : 0);
    Vue.set(x, "hexColor", ncsColor.hex(x.ncs));
    x.constrastWalls.forEach(constrastWalls => {
      Vue.set(
        constrastWalls,
        "amount",
        constrastWalls.price ? constrastWalls.price : 0
      );
      Vue.set(constrastWalls, "hexColor", ncsColor.hex(constrastWalls.ncs));
      if (x.ncs !== constrastWalls.ncs) {
        Vue.set(constrastWalls, "active", true);
      }
    });
  });
}

function colorOptionsHaveSelected(colorOptions, selectedColorOptions) {
  selectedColorOptions.forEach(x => {
    if (x.id === -1) {
      colorOptions.forEach(y => {
        //Only add amount to main color. Rest should be 0
        Vue.set(y, "amount", y.id === -1 ? x.amount : 0);
        Vue.set(y, "ncs", x.ncs);
        Vue.set(y, "hexColor", ncsColor.hex(y.ncs));
        y.constrastWalls.forEach(constrastWalls => {
          Vue.set(constrastWalls, "amount", 0);
          Vue.set(constrastWalls, "ncs", x.ncs);
          Vue.set(constrastWalls, "hexColor", ncsColor.hex(y.ncs));
        });
      });
    } else {
      var isSelected = colorOptions.find(y => y.id === x.id);
      if (isSelected) {
        if (x.wallLetter) {
          isSelected = isSelected.constrastWalls.find(
            constrastWalls => constrastWalls.wallLetter === x.wallLetter
          );
          isSelected.active = true;
        } else {
          isSelected.constrastWalls.forEach(constrastWalls => {
            Vue.set(constrastWalls, "amount", 0);
            Vue.set(constrastWalls, "ncs", x.ncs);
            Vue.set(constrastWalls, "hexColor", ncsColor.hex(x.ncs));
          });
        }
        Vue.set(isSelected, "amount", x.amount);
        Vue.set(isSelected, "ncs", x.ncs);
      }

      Vue.set(isSelected, "hexColor", ncsColor.hex(isSelected.ncs));
    }
  });

  return colorOptions;
}

function colorOptionsSelectedTotal(data) {
  var totalPrice = data.reduce(function(accumulator, color) {
    return accumulator + color.amount;
  }, 0);

  return totalPrice;
}

function sortSelectedColors(selected) {
  selected.sort(function(item1, item2) {
    // Sort by id
    // If the first item has a higher number, move it down
    // If the first item has a lower number, move it up
    if (item1.id > item2.id) return 1;
    if (item1.id < item2.id) return -1;

    // If the id number is the same between both items, sort alphabetically
    // If the first item comes first in the alphabet, move it up
    // Otherwise move it down
    //If no wallletter then move it up
    if (!item2.wallLetter || item1.wallLetter > item2.wallLetter) return 1;

    if (!item1.wallLetter || item1.wallLetter < item2.wallLetter) return -1;
  });
}

function addHexToRooms(data, haveConstrastWalls) {
  //add Hex to alle rooms
  return data.forEach(x => {
    x.hexColor = ncsColor.hex(x.ncs);
    if (haveConstrastWalls) {
      x.constrastWalls.forEach(y => {
        y.hexColor = ncsColor.hex(y.ncs);
      });
    }
  });
}

export default {
  namespaced: true,

  state: {
    sectionInfo: null,
    options: null,
    salesInfo: null,
    colorOptions: null,
    profile: null,
    selectedOptions: null,
    selectedOptionsList: null,
    selectedColorOptions: [],
    colorOptionsNotChanged: [],
    selectedColorOptionsTotal: 0,
    totalPrice: 0,
    hasPurchasedSection: false,
    colorsIsChangedFromAlbum: false,
    dialogScroll: 0
  },
  getters: {
    sectionInfo: state => {
      return state.sectionInfo;
    },
    options: state => {
      return state.options;
    },
    salesInfo: state => {
      return state.salesInfo;
    },
    colorOptions: state => {
      return state.colorOptions;
    },
    profile: state => {
      return state.profile;
    },
    selectedOptions: state => {
      return state.selectedOptions;
    },
    selectedOptionsList: state => {
      return state.selectedOptionsList;
    },
    selectedColorOptions: state => {
      return state.selectedColorOptions;
    },
    colorOptionsNotChanged: state => {
      return state.colorOptionsNotChanged;
    },
    selectedColorOptionsTotal: state => {
      return state.selectedColorOptionsTotal;
    },
    totalPrice: state => {
      return state.totalPrice;
    },
    hasPurchasedSection: state => {
      return state.hasPurchasedSection;
    },
    colorsIsChangedFromAlbum: state => {
      return state.colorsIsChangedFromAlbum;
    },
    dialogScroll: state => {
      return state.dialogScroll;
    }
  },
  mutations: {
    sectionInfo(state, data) {
      state.sectionInfo = data;
    },
    options(state, data) {
      state.options = data;
    },
    salesInfo(state, data) {
      state.salesInfo = data;
    },
    colorOptions(state, data) {
      state.colorOptions = data;
    },
    profile(state, data) {
      state.profile = data;
    },
    selectedOptions(state, data) {
      if (!data) {
        state.selectedOptions = null;
        state.selectedOptionsList = null;
      } else {
        state.selectedOptions = data.object;
        state.selectedOptionsList = data.list;
      }
    },
    colorOptionsNotChanged(state, data) {
      state.colorOptionsNotChanged = data;
    },
    selectedColorOptions(state, data) {
      if (!data) {
        state.selectedColorOptions = [];
        state.selectedColorOptionsTotal = 0;
      } else {
        state.selectedColorOptions = data.object;
        state.selectedColorOptionsTotal = data.total;
      }
    },
    totalPrice(state, data) {
      state.totalPrice = data;
    },
    hasPurchasedSection(state, value) {
      state.hasPurchasedSection = value;
    },
    colorsIsChangedFromAlbum(state, value) {
      state.colorsIsChangedFromAlbum = value;
    },
    dialogScroll(state, value) {
      state.dialogScroll = value;
    }
  },
  actions: {
    setSectionInfo({ commit, dispatch, state }, sectionId) {
      return new Promise((resolve, reject) => {
        purchaseApi
          .purchase(sectionId)
          .then(response => {
            commit("sectionInfo", response);
            dispatch("totalPrice");

            if (state.profile) {
              state.profile.options = selectedOptionsFromAlbum(
                response.selectedOptions,
                state.profile.options
              );

              //delete color added from album
              state.profile.selectedColorOptions = state.profile.selectedColorOptions.filter(
                x => !x.isConfirmed
              );
              //If color is added from album
              if (
                response.selectedColorOptions &&
                response.selectedColorOptions.length > 0
              ) {
                state.profile.selectedColorOptions = selectedColorsFromAlbum(
                  response.selectedColorOptions,
                  state.profile.selectedColorOptions
                );
                commit("colorsIsChangedFromAlbum", true);
              }

              dispatch("updateProfile", state.profile);
              dispatch("setProfile");
            }

            resolve(response);
          })
          .catch(error => {
            notification.hide();
            var salesInfo = error.response.data;
            var data = {
              salesRepEmail: salesInfo.salesRepEmail,
              salesRepName: salesInfo.salesRepName,
              salesRepPhone: salesInfo.salesRepPhone,
              salesRepPhoto: salesInfo.salesRepPhoto
            };

            commit("salesInfo", data);
            reject(false);
          });
      });
    },
    deleteSectionInfo({ commit }) {
      commit("sectionInfo", null);
    },
    setOptions({ dispatch, commit, state }, sectionId) {
      return new Promise((resolve, reject) => {
        var includeAttic = false;
        if (state.sectionInfo.isAtticAvailable) {
          includeAttic = true;
        }

        purchaseApi
          .getOptions(sectionId, includeAttic)
          .then(response => {
            if (
              !state.sectionInfo.isAtticAvailable &&
              !state.sectionInfo.isAtticIncluded
            ) {
              response = response.filter(x => !x.isAtticCategory);
            }

            //Delete options if expired
            var updateSelectedOptions = removeExpiredSelectedOptions(
              response,
              state.selectedOptions
            );
            //Save new selected options if somthing is changed
            if (updateSelectedOptions) {
              dispatch("changeSelectedOptions", updateSelectedOptions);
            }

            // response = response.filter(x => !x.deadlineExpired);
            var options = optionsHaveSelected(response, state.selectedOptions);

            commit("options", options);
            resolve(options);
          })
          .catch(error => {
            reject(false);
          });
      });
    },
    setColorOptions({ commit, state }, sectionId) {
      return new Promise((resolve, reject) => {
        purchaseApi
          .getColorOptions(sectionId, state.profile.includeAttic)
          .then(response => {
            commit("colorOptionsNotChanged", response);
            //copy data before change it
            var data = JSON.parse(JSON.stringify(response));

            if (state.colorsIsChangedFromAlbum) {
              colorOptionsfixData(data);
            } else {
              colorOptionsHaveSelected(data, state.selectedColorOptions);
            }

            //add Hex to alle rooms
            addHexToRooms(data, true);

            //Make alle room on attic to one object
            commit("colorOptions", data);
            resolve(data);
          })
          .catch(error => {
            reject(false);
          });
      });
    },

    setProfile({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        const profile = JSON.parse(localStorage.getItem("webShopProfile"));
        if (profile) {
          if (state.sectionInfo && state.sectionInfo.selectedOptions) {
            profile.options = selectedOptionsFromAlbum(
              state.sectionInfo.selectedOptions,
              profile.options
            );
          }

          if (
            state.sectionInfo &&
            state.sectionInfo.selectedColorOptions &&
            state.sectionInfo.selectedColorOptions.length > 0
          ) {
            profile.selectedColorOptions = selectedColorsFromAlbum(
              state.sectionInfo.selectedColorOptions,
              profile.selectedColorOptions
            );
            commit("colorsIsChangedFromAlbum", true);
          }

          dispatch("updateProfile", profile);
          setOptionsAndColor(commit, profile, state.sectionInfo);
          commit("profile", profile);

          resolve(profile);
          dispatch("totalPrice");
        } else {
          reject(false);
        }
      });
    },
    updateProfile({ commit, dispatch, state }, profile) {
      if (profile) {
        //If profile not stored. Then add selected options from Album
        if (
          !state.profile &&
          state.sectionInfo &&
          state.sectionInfo.selectedOptions
        ) {
          profile.options = selectedOptionsFromAlbum(
            state.sectionInfo.selectedOptions,
            profile.options
          );
        }
        //If profile not stored. Then add selected colors from Album
        if (
          !state.profile &&
          state.sectionInfo &&
          state.sectionInfo.selectedColorOptions &&
          state.sectionInfo.selectedColorOptions.length > 0
        ) {
          profile.selectedColorOptions = selectedColorsFromAlbum(
            state.sectionInfo.selectedColorOptions,
            profile.selectedColorOptions
          );
          commit("colorsIsChangedFromAlbum", true);
        }

        localStorage.setItem("webShopProfile", JSON.stringify(profile));

        setOptionsAndColor(commit, profile, state.sectionInfo);

        dispatch("totalPrice");
      }

      commit("profile", profile);
    },
    changeSelectedOptions({ commit, state, dispatch }, selectedOptions) {
      var list = optionsSelectedToList(selectedOptions);

      var data = { object: selectedOptions, list: list };
      commit("selectedOptions", data);

      if (state.options) {
        var options = optionsHaveSelected(state.options, selectedOptions);
        commit("options", options);
      }

      //Save changes to profile
      if (state.profile) {
        state.profile.options = selectedOptions;
        dispatch("updateProfile", state.profile);
      }
    },

    async changeSelectedColorOptions(
      { commit, state, dispatch },
      { sectionId, data, deleteColor }
    ) {
      var colorOptions = JSON.parse(
        JSON.stringify(state.colorOptionsNotChanged)
      );
      var list = state.selectedColorOptions;
      //If user change mainColor. Remove alle other rooms with same color.
      if (data.id === -1) {
        list = list.filter(x => {
          //If mainColor and contrastwall is same color
          //Remove contrastwall if parent dont have selected color.
          var parentIsSelected = null;
          if (x.wallLetter && x.ncs === data.ncs) {
            parentIsSelected = list.find(y => y.id === x.id && !y.wallLetter);
          }
          if (parentIsSelected) {
            return parentIsSelected.ncs !== data.ncs;
          } else {
            return x.ncs !== data.ncs;
          }
        });
      }
      //Remove color selected in same room
      list = list.filter(x => {
        //If wallLetterColor is same as parent - Delete wallLetter
        if (
          !(
            x.id === data.id &&
            x.wallLetter &&
            x.wallLetter === data.wallLetter &&
            x.ncs === data.ncs
          )
        ) {
          return x.id !== data.id || x.wallLetter !== data.wallLetter;
        }
      });

      //Delete if deleteColor is true else change to selected
      if (!deleteColor) {
        list.push(data);
      }
      //get price on selected colors
      var selected = await purchaseApi.colorOptionsCosts(sectionId, list);
      var notInSelected = [];
      //add Selected with price 0 to selected
      //api dont return selected with price 0
      list.forEach(x => {
        var isInSelectedList = selected.some(
          y => y.id === x.id && x.wallLetter === y.wallLetter
        );

        if (!isInSelectedList) {
          notInSelected.push({
            amount: 0,
            categoryName: "Fargevalg",
            hexColor: x.hexColor,
            id: x.id,
            name: x.name,
            ncs: x.ncs
          });
        }
      });

      selected = [...selected, ...notInSelected];
      sortSelectedColors(selected);
      //Add color selected to colorOptions
      colorOptionsHaveSelected(colorOptions, selected);

      //add Hex to alle rooms
      addHexToRooms(colorOptions, true);

      //add hex color to selected
      addHexToRooms(selected, false);

      //Make alle room on attic to one object
      commit("colorOptions", colorOptions);

      //Save changes to porfile
      if (state.profile) {
        state.profile.selectedColorOptions = selected;
        dispatch("updateProfile", state.profile);
      }
    },

    totalPrice({ commit, state }) {
      var totalPrice = 0;
      if (state.sectionInfo && state.sectionInfo.estimatesTotalAmount) {
        totalPrice = totalPrice + state.sectionInfo.estimatesTotalAmount;
      }
      if (state.selectedOptionsList && state.selectedOptionsList.totalPrice) {
        totalPrice = totalPrice + state.selectedOptionsList.totalPrice;
      }
      if (state.selectedColorOptionsTotal) {
        totalPrice = totalPrice + state.selectedColorOptionsTotal;
      }
      if (state.sectionInfo && state.profile && state.profile.includeAttic) {
        totalPrice = totalPrice + state.sectionInfo.atticPrice;
      }

      commit("totalPrice", totalPrice);
    },
    setHasPurchasedSection({ commit }, value) {
      commit("hasPurchasedSection", value);
    },
    saveDialogScroll({ commit }, value) {
      commit("dialogScroll", value);
    }
  }
};
