import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VList,{class:{ expired: _vm.expired },attrs:{"color":"transparent"}},[_c(VListItem,{staticClass:"px-0 mb-2"},[_c(VListItemAvatar,{attrs:{"color":"primary"}},[_c('span',{staticClass:"headline font-weight-bold white--text"},[_vm._v(" "+_vm._s(_vm.number)+" ")])]),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"primary--text headline font-weight-black"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.subTitle))])],1)],1),_c(VDivider)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }