<template>
  <div>
    <Checkout
      v-model="cartOptions"
      :confirmed-cart="sectionConfirme.options"
      :changed-from-confirmed="changedCartOfConfirme"
      :haveConfirmed="optionsService.confirmedOptions(sectionConfirme.options)"
      :haveChangedConfirmed="changedCartOfConfirme.length > 0"
      showChangeIcon
      showChangeIconChanged
      showDeleteIcon
      :showbuttons="
        (optionsService.confirmedOptions(sectionConfirme.options) &&
          changedCartOfConfirme.length > 0) ||
          optionsService.summaryIsEmty(getCartGroups)
      "
      :showEmptyCheckout="
        !optionsService.confirmedOptions(sectionConfirme.options) &&
          !optionsService.summaryIsEmty(getCartGroups)
      "
      showEmptyGoTo="store"
      showEmptyBtnName="Gå til tilvalgsliste"
      :totalPrice="cartInfo.cartTotPrice"
      :showConfirmBtn="showConfirmBtn"
      :showChangeBtn="optionsService.confirmedOptions(sectionConfirme.options)"
      :showSummaryBtn="optionsService.confirmedOptions(sectionConfirme.options)"
      :confirmBtnName="
        optionsService.confirmedOptions(sectionConfirme.options)
          ? 'Bekreft endringer'
          : 'Bekreft tilvalg'
      "
      changeBtnName="Endre tilvalg"
      @confirm="confirmcart"
      @change="change"
      @summary="goTo('summary')"
      @delete="deleteFromCart"
    >
      <template v-slot:alert>
        <v-alert
          v-if="deadlineInCart"
          class="mb-0"
          outlined
          text
          type="warning"
        >
          En eller flere tilvalg er ikke bekreftet før fristen gikk ut. Ta
          kontakt med selger for å kunne bekrefte tilvalgene. Du har fortsatt
          mulighet til å bekrefte tilvalg der fristen ikke har gått ut.
        </v-alert>
      </template>

      <template v-slot:subtitle="data">
        {{ data.categoryName }} -
        <span v-if="!deadlineHasBeen(data.deadline)">
          Fristen for å bekrefte tilvalg er
          {{ data.deadline | moment("DD.MM.YYYY") }}
        </span>
        <span v-if="deadlineHasBeen(data.deadline)" class="deadlineHaveBeen">
          Fristen for å bekrefte tilvalg var
          {{ data.deadline | moment("DD.MM.YYYY") }}
          <v-icon small color="warning">
            mdi-calendar-alert
          </v-icon>
        </span>
      </template>

      <template v-slot:subtitleChanges="data">
        <span v-if="!deadlineHasBeen(data.deadline)">
          Fristen for å bekrefte tilvalg er
          {{ data.deadline | moment("DD.MM.YYYY") }}
        </span>
        <span v-if="deadlineHasBeen(data.deadline)" class="deadlineHaveBeen">
          Fristen for å bekrefte tilvalg var
          {{ data.deadline | moment("DD.MM.YYYY") }}
          <v-icon small color="warning">
            mdi-calendar-alert
          </v-icon>
        </span>
      </template>
    </Checkout>
    <ChangeOptions
      :show="showStoreChoice"
      :cartInfo="cartInfo"
      :getCartGroups="getCartGroups"
      @change="change"
    />
    <Confirmcode
      :show="showConfirmcode"
      title="BEKREFT TILVALG"
      info="Skriv inn bekreftelseskoden vi sendte til deg på sms."
    />
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import optionsService from "@/options.services";
import optionsApi from "@/api/optionsApi";

import Checkout from "@/components/store/shared/checkout/contianer/Checkout";

import ChangeOptions from "@/components/store/ChangeOptions";
import Confirmcode from "@/components/store/NewConfirmcodeDialog";

// import ListItems from "@/components/store/child/ListItems";
export default {
  data() {
    return {
      cart: null,
      showStoreChoice: { show: false },
      showConfirmcode: { show: false },
      id: null,
      title: null,
      optionsService
    };
  },
  async created() {
    this.cart = this.storeCart;
    this.$store.watch(
      (state, getters) => getters["options/cart"],
      newValue => {
        this.cart = newValue;
      }
    );
  },
  mounted() {
    // if user refresh page it opens dialog
    if (this.$route.name === "checkoutChange") {
      this.showStoreChoice.show = true;
    }
  },
  watch: {
    $route(to) {
      //open dialog when user navigate to router "storeOptions"
      if (to.name === "checkoutChange") {
        this.showStoreChoice.show = true;
      } else {
        this.showStoreChoice.show = false;
      }
    }
  },
  methods: {
    ...mapActions({
      deleteCart: "options/deleteCart"
    }),
    change(data) {
      if (data.categoryId || data.id) {
        this.$router.push({
          name: "storeOptions",
          params: { id: data.categoryId || data.id }
        });
      } else {
        this.goTo("store");
      }
    },
    async deleteFromCart(item) {
      this.deleteCart(item);
    },
    async confirmcart() {
      await optionsApi.getSendConfirmcode().then(status => {
        if (status === 200) {
          this.showConfirmcode.show = true;
        }
      });
    },
    deadlineHasBeen(deadline) {
      return moment(deadline)
        .add(1, "day")
        .isBefore(moment().format());
    },
    goTo(name) {
      this.$router.push({ name: name });
    }
  },
  computed: {
    ...mapGetters({
      categories: "options/categories",
      storeCart: "options/cart",
      sectionConfirme: "options/sectionConfirme",
      changedCartOfConfirme: "options/changedCartOfConfirme",
      alldeadline: "options/alldeadline",
      optionsAvailable: "shared/optionsAvailable"
    }),
    cartInfo() {
      if (this.cart.length !== 0) {
        var cartCount = 0;
        var cartTotPrice = 0;
        this.cart.groups.forEach(x => {
          x.categories.forEach(c => {
            c.options.forEach(o => {
              if (o.selected && o.type !== 1) {
                cartCount = cartCount + 1;
                cartTotPrice = cartTotPrice + o.price;
              }
            });
          });
        });
        return { cartCount: cartCount, cartTotPrice: cartTotPrice };
      }
      return 0;
    },
    changedCartOfConfirmePrice() {
      if (this.changedCartOfConfirme.length > 0) {
        let price = 0;
        this.changedCartOfConfirme.forEach(x => {
          price = price + x.price;
        });
        return price;
      }
      return null;
    },
    cartOptions() {
      if (this.getCartGroups && this.getCartGroups.groups) {
        var data = [];
        this.getCartGroups.groups.forEach(x => {
          x.categories.forEach(categories => {
            categories.options.forEach(options => {
              if (options.type !== 1) {
                options.categoryId = categories.id;
                data.push(options);
              }
            });
          });
        });

        return data;
      }
      return null;
    },
    getCartGroups() {
      if (this.cart.length !== 0) {
        return this.cart;
      }
      return {};
    },
    deadlineInCart() {
      //if somthing is confirmed
      if (this.changedCartOfConfirme && this.changedCartOfConfirme.length > 0) {
        return this.changedCartOfConfirme.some(x => {
          return moment(x.deadline)
            .add(1, "day")
            .isBefore(moment().format());
        });
      } else if (this.cart.cartCount === 0 && this.getCartGroups.groups) {
        //If nothing is confirmed
        return this.getCartGroups.groups.some(x => {
          return x.categories.some(y => {
            return y.options.some(o => {
              if (o.type !== 1 && o.selected) {
                return moment(o.deadline)
                  .add(1, "day")
                  .isBefore(moment().format());
              }
            });
          });
        });
      }

      return false;
    },
    showConfirmBtn() {
      return (
        (this.changedCartOfConfirme.length > 0 ||
          this.sectionConfirme.options.length === 0) &&
        this.alldeadline
      );
    }
  },
  components: {
    ChangeOptions,
    Confirmcode,
    // ListItems,
    Checkout
  }
};
</script>
<style lang="scss">
.checkoutFooter {
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}
@media (max-width: 600px) {
  .checkoutFooter {
    button,
    a {
      width: 100%;
    }
  }
}
.deadlineHaveBeen {
  color: var(--v-warning-base);
}
</style>
