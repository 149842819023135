<template>
  <div>
    <RoomNavigation :rooms="data" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RoomNavigation from "@/components/store/webStore/shared/RoomNavigation.vue";
export default {
  computed: {
    ...mapGetters({ colorOptions: "purchase/colorOptions" }),
    data() {
      if (this.colorOptions) {
        var data = [
          {
            name: "Intro",
            to: { name: "webShopColorIntro" },
            exact: true
          }
        ];

        var atticIsAdded = false;
        this.colorOptions.forEach(x => {
          //Dont show expired categries
          if (!x.isOnAttic) {
            data.push({
              name: x.name,
              to: {
                name: "webShopRoomColor",
                params: { roomId: x.id }
              }
            });
          } else if (!atticIsAdded) {
            data.push({
              name: "Loft",
              to: {
                name: "webShopRoomColor",
                params: { roomId: x.id }
              }
            });
            atticIsAdded = true;
          }
        });

        return data;
      }
      return [];
    }
  },
  components: {
    RoomNavigation
  }
};
</script>
