<template>
  <v-dialog v-model="showDialog" max-width="1000">
    <v-card tile>
      <v-card-title style="word-break: break-word;">
        {{ getData.name }}
        <div class="flex-grow-1"></div>
        <v-card-actions>
          <v-btn color="red" outlined @click="close">Lukk</v-btn>
        </v-card-actions>
      </v-card-title>
      <v-img
        contain
        max-height="75vh"
        :src="optionsService.getOptionImage(getData, false, info)"
        class="white--text text-right"
      >
      </v-img>
    </v-card>
  </v-dialog>
</template>

<script>
import optionsService from "@/options.services";
export default {
  props: ["show", "data", "info"],
  data() {
    return {
      optionsService,
    };
  },
  methods: {
    close() {
      this.show.show = false;
    },
  },
  computed: {
    showDialog: {
      get: function() {
        if (this.show.show) {
          return this.show.show;
        }
        return false;
      },
      set: function() {
        this.close();
      },
    },
    getData() {
      if (this.data) {
        return this.data;
      }
      return {};
    },
  },
};
</script>
