import baseApi from "./baseApi";

const api = {
  async isAlbumUser() {
    const response = await baseApi.get(`/common/security/isalbumuser`);
    return response.data;
  }
};

export default api;
