<template>
  <div>
    <ul class="mb-4">
      <li class="mb-3">
        Kjøpetilbudet skal fylles ut så nøyaktig som mulig med hensyn til
        finansiering, finansinstitusjonens kontaktperson samt opplysninger om
        egenkapital.
      </li>
      <li class="mb-3">
        Selger står fritt til å akseptere og eventuelt avvise ethvert
        kjøpetilbud.
      </li>
      <li>
        Selger bekrefter overfor kjøper at kjøpetilbudet aksepteres og innkaller
        partene til kontraktsmøte etter aksept av budet.
      </li>
    </ul>
    <p v-if="purchaseAndSalesAct === 0">
      Vær oppmerksom på at det ikke eksisterer noen form for angrefrist ved
      kjøp/salg av eiendom. Ved avbestilling/kansellering av kontrakt vil det
      kreves erstatning for økonomisk tap som selger blir påført jfr. § 52, §53
      og §54 i bustadoppføringsloven.
    </p>
    <p v-if="purchaseAndSalesAct === 1">
      Ved avbestilling/kansellering av kontrakt vil det kreves erstatning for
      økonomisk tap som selger blir påført jfr. Avhendingsloven § 5-4
    </p>

    <h3>Avtalevilkår:</h3>
    <p>Boligen selges etter bestemmelsene i {{ titleLaw }}.</p>

    <h3>Betalingsbetingelser:</h3>
    <p>
      Oppgjøret inkl. omkostninger innbetales innen kl. 12 arbeidsdagen før
      overtakelse.
    </p>
  </div>
</template>
<script>
export default {
  props: {
    titleLaw: {
      type: String,
      required: true
    },
    purchaseAndSalesAct: {
      required: true
    }
  }
};
</script>
