<template>
  <div>
    <v-dialog
      v-model="showDialog"
      max-width="600"
      :hide-overlay="hideOverlay"
      :fullscreen="$store.getters['shared/isMobileDevice']"
      transition="scroll-x-transition"
    >
      <v-card class="safeAreaDialog">
        <v-card-title>
          <h2 class="primary--text">{{ title }}</h2>
        </v-card-title>
        <v-card-text>
          <p class="mb-4 body-1" v-html="DialogMessage"></p>
          <v-card-actions class="pa-0">
            <div class="d-none d-sm-flex flex-grow-1"></div>
            <v-btn v-if="DialogAccept" large color="primary" @click="accept"
              >Gå videre</v-btn
            >
            <v-btn v-if="DialogAccept" large outlined @click="close"
              >Lukk</v-btn
            >
            <v-btn v-else large color="primary" @click="close">Lukk</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    show: {
      required: true
    },
    title: {
      default: ""
    },
    DialogMessage: {
      default: ""
    },
    DialogAccept: {
      default: true
    },
    hideOverlay: {
      default: true
    }
  },
  methods: {
    ...mapActions({
      setShowDialogMessage: "shared/showDialogMessage"
    }),
    close() {
      this.show.show = false;
      this.setShowDialogMessage({});
    },
    accept() {
      this.$emit("accept", true);
      this.show.show = false;
    }
  },
  computed: {
    ...mapGetters({
      showDialogMessage: "shared/showDialogMessage"
    }),
    showDialog: {
      get: function() {
        if (this.show.show) {
          return this.show.show;
        } else if (this.showDialogMessage.show) {
          return this.showDialogMessage.show;
        }
        return false;
      },
      set: function() {
        this.close();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.safeAreaDialog {
  padding-top: calc(0px + env(safe-area-inset-top)) !important;
  padding-bottom: calc(0px + env(safe-area-inset-bottom)) !important;
  padding-left: calc(0px + env(safe-area-inset-left)) !important;
  padding-right: calc(0px + env(safe-area-inset-right)) !important;
}
</style>
