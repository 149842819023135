<template>
  <div>
    <TimelineNotReady v-if="getData.length === 0" />
    <v-row v-else class="justify-center">
      <v-col xl="9">
        <v-list color="transparent">
          <v-list-item class="pl-0 mb-2">
            <v-list-item-avatar>
              <v-icon class="primary white--text"
                >mdi-chart-timeline-variant</v-icon
              >
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                class="primary--text headline font-weight-black"
                >Tidslinje bygg</v-list-item-title
              >
              <v-list-item-subtitle
                >Tidslinje for din bolig</v-list-item-subtitle
              >
            </v-list-item-content>
            <v-list-item-action class="d-none d-sm-flex">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    @click="showDialogMessage.show = true"
                    fab
                    dark
                    small
                    color="blue"
                  >
                    <v-icon dark>mdi-help</v-icon>
                  </v-btn>
                </template>
                <span>Hjelp</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>

        <v-timeline :dense="$store.getters['shared/isMobileDevice']">
          <v-timeline-item
            class="align-center"
            v-for="(item, i) in getData"
            :key="i"
            :color="item.color"
          >
            <template v-slot:icon>
              <v-avatar color="#ffffff">
                <v-icon
                  v-if="item.color === 'yellow'"
                  class="hammerAnimation"
                  left
                  color="yellow"
                >
                  mdi-hammer
                </v-icon>
                <v-icon
                  v-if="item.color !== 'yellow'"
                  :color="item.hasDone ? 'success' : item.color"
                >
                  {{
                    item.hasDone
                      ? "mdi-checkbox-marked-circle-outline"
                      : "mdi-checkbox-blank-circle-outline"
                  }}
                </v-icon>
              </v-avatar>
            </template>

            <span slot="opposite">
              <v-img
                v-if="item.imageName"
                :src="`https://thg.blob.core.windows.net/${item.imageName}`"
                :lazy-src="
                  `https://thg.blob.core.windows.net/${item.imageName}`
                "
              />
            </span>
            <div class="py-4">
              <h2
                :class="{
                  '`headline font-weight-light mb-4`': true,
                  'grey--text': !item.hasDone,
                  'yellow--text': item.color === 'yellow',
                  'primary--text': item.hasDone
                }"
              >
                {{ item.nameMT ? item.nameMT : item.resourceName }}
              </h2>
              <div>
                {{ item.description }}
              </div>
            </div>
          </v-timeline-item>
        </v-timeline>
      </v-col>
      <DialogMessage
        :show="showDialogMessage"
        title="Tidslinje"
        :DialogMessage="DialogMessage"
      />
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import DialogMessage from "@/components/dialog/DialogMessage";
import TimelineNotReady from "@/components/timeline/TimelineNotReady";
export default {
  data: () => ({
    showDialogMessage: { show: false },
    DialogMessage: `Tidlsinjen viser hvor langt i prosjektet vi er kommet med din bolig.`
  }),
  computed: {
    ...mapGetters({
      getTimeline: "saveData/timeline"
    }),
    getData() {
      if (this.getTimeline.length === undefined) {
        return this.getTimeline.events;
      }
      return {};
    }
  },
  components: {
    DialogMessage,
    TimelineNotReady
  }
};
</script>
