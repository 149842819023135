<template>
  <div class="mt-4">
    <h3 class="darkgrey--text">
      Finansieringsplan for kjøp av leiligheten
    </h3>
    <p class="mb-6">
      Fullt oppgjør ved ferdigstillelse av bolig
    </p>

    <v-radio-group
      v-if="data"
      v-model="data.type"
      class="pt-0 mb-4"
      row
      :rules="[v => !!v || 'Finansiering må være valgt']"
    >
      <v-radio label="Lånefinansiering" :value="3"></v-radio>
      <v-radio label="Uten lånefinansiering" :value="4"></v-radio>
    </v-radio-group>
    <v-row v-if="data && data.type && data.type !== 4">
      <v-col class="py-0" cols="12" md="6">
        <v-text-field
          v-model="data.loanBankName"
          :rules="[loanBankNameRule]"
          label="Lån/mellomfinansiering i bank"
          prepend-inner-icon="mdi-bank"
          placeholder="Navn til banken"
          required
          filled
          autocomplete="off"
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" md="6">
        <v-text-field
          v-model="data.loanBankContactName"
          :rules="[loanBankContactNameRule]"
          label="Saksbehandler bank"
          placeholder="Navn til saksbehandler"
          prepend-inner-icon="mdi-account"
          required
          filled
          autocomplete="off"
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" md="6">
        <MobileNumber
          v-model="data.loanBankContactPhone"
          label="Saksbehandler mobil"
          filled
          prepend-inner-icon="mdi-phone"
          autocomplete="off"
        />
      </v-col>

      <v-col class="py-0" cols="12" md="6">
        <v-text-field
          v-model="data.loanBankContactEmail"
          :rules="[loanBankContactEmailRule]"
          label="Saksbehandler e-post"
          placeholder="navn@eksempel.no"
          prepend-inner-icon="mdi-email"
          required
          filled
          autocomplete="off"
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" md="6">
        <v-text-field
          v-model.number="loanAmountFormated"
          label="Lånesum"
          prepend-inner-icon="mdi-cash-plus"
          :rules="[v => !!v || 'Lånesum må fylles ut!']"
          suffix="Kr"
          placeholder="Skriv riktig sum uten tegn og mellomrom"
          required
          filled
          autocomplete="off"
          @blur="loanAmountFocus = false"
          @focus="loanAmountFocus = true"
          @input="AddToTotal"
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" md="6">
        <v-text-field
          v-model.number="loanEquityAmountFormated"
          label="Egenkapital"
          prepend-inner-icon="mdi-account-cash"
          suffix="Kr"
          :rules="[v => !!v || 'Egenkapital må fylles ut!']"
          required
          placeholder="Skriv riktig sum uten tegn og mellomrom"
          filled
          autocomplete="off"
          @blur="loanEquityAmountFocus = false"
          @focus="loanEquityAmountFocus = true"
          @input="AddToTotal"
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="12">
        <v-text-field
          v-model="loanBankTotalFormated"
          :rules="[totalRule]"
          label="Til sammen"
          prepend-inner-icon="mdi-cash-multiple"
          suffix="Kr"
          readonly
          filled
          autocomplete="off"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import format from "@/helpers/format";
import MobileNumber from "@/components/shared/textField/MobileNumber";
export default {
  props: {
    value: {
      required: true
    },
    isValidating: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loanEquityAmountFocus: false,
      loanAmountFocus: false,
      nameRules: [
        v => !!v || "Name is required",
        v => (v && v.length <= 10) || "Name must be less than 10 characters"
      ],
      emailRules: [
        v => !!v || "E-post må fylles ut",
        v => /.+@.+\..+/.test(v) || "E-post må være gyldig"
      ],
      format
    };
  },
  methods: {
    AddToTotal() {
      var tot =
        (this.data.loanAmount ? this.data.loanAmount : 0) +
        (this.data.loanEquityAmount ? this.data.loanEquityAmount : 0);

      this.$set(this.data, "loanBankTotal", tot);
    }
  },
  computed: {
    ...mapGetters({
      totalPrice: "purchase/totalPrice"
    }),
    data: {
      get() {
        return this.value;
      },
      set(data) {
        this.$emit("input", data);
      }
    },
    loanBankNameRule() {
      return v => {
        return !!v || "Bankens navn må fylles ut!";
      };
    },
    loanBankContactNameRule() {
      return v => {
        return !!v || "Kundekontakt i banken må fylles ut!";
      };
    },
    loanBankContactEmailRule() {
      return v => {
        return !!v || "E-post til banken må fylles ut!";
      };
    },
    totalRule() {
      return () => {
        return (
          (!!this.data &&
            !!this.data.loanBankTotal &&
            this.data.loanBankTotal >= this.totalPrice) ||
          "Totalprisen på boligen må være lik lånesum + egenkapital"
        );
      };
    },

    loanEquityAmountFormated: {
      get() {
        if (this.data) {
          if (this.loanEquityAmountFocus) {
            return this.data.loanEquityAmount
              ? this.data.loanEquityAmount
              : null;
          } else {
            return this.data.loanEquityAmount
              ? format.numberFormat(this.data.loanEquityAmount)
              : null;
          }
        }
        return null;
      },
      set(value) {
        this.$set(this.data, "loanEquityAmount", value);
      }
    },
    loanAmountFormated: {
      get() {
        if (this.data) {
          if (this.loanAmountFocus) {
            return this.data.loanAmount ? this.data.loanAmount : null;
          } else {
            return this.data.loanAmount
              ? format.numberFormat(this.data.loanAmount)
              : null;
          }
        }
        return null;
      },
      set(value) {
        this.$set(this.data, "loanAmount", value);
      }
    },
    loanBankTotalFormated() {
      if (this.data) {
        return this.data.loanBankTotal
          ? format.numberFormat(this.data.loanBankTotal)
          : null;
      }
      return null;
    }
  },
  components: {
    MobileNumber
  }
};
</script>
