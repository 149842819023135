<template>
  <div>
    <v-dialog
      v-model="showDialog"
      max-width="600"
      :persistent="firstTime"
      :fullscreen="$store.getters['shared/isMobileDevice']"
    >
      <v-card>
        <v-card-title>
          <span class="headline primary--text">{{
            firstTime ? "Velkommen til Mitt Trysilhus" : "Varslinger"
          }}</span>
        </v-card-title>
        <v-card-text>
          <span v-if="firstTime" style="font-size: 16px;">
            Gratulerer med kjøp av ny bolig! Mitt Trysilhus er vår kundeportal
            hvor du vil finne nyttig informasjon om din bolig.<br /><br />
            Vi ønsker at du bekrefter at du vil motta varsler om svar og
            endringer på dine henvendelser i Mitt Trysilhus på e-post, og at vi
            kan sende deg nyttige tips og oppdateringer i automatiske
            nyhetsbrev.
          </span>

          <span v-if="!firstTime" style="font-size: 16px;">
            Du har skrudd av varslinger og vil derfor ikke motta e-post når det
            blir lagt til informasjon eller det er statusendring i forbindelse
            med din henvendelse. Sett hake i boksen for e-post-varsling hvis du
            ønsker å motta slike e-poster.
          </span>
          <v-list dense>
            <v-subheader>VARSLINGER</v-subheader>

            <v-list-item-group multiple>
              <v-list-item three-line>
                <v-list-item-action>
                  <v-checkbox
                    v-model="getProfile.acceptEmail"
                    color="success"
                    hide-details
                  ></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>E-post-varslinger</v-list-item-title>
                  <v-list-item-subtitle>
                    Varsler om svar og endringer på dine henvendelser sendes på
                    e-post
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item three-line>
                <v-list-item-action>
                  <v-checkbox
                    v-model="getProfile.acceptNewsLetter"
                    color="success"
                    hide-details
                  ></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>Automatisk nyhetsbrev</v-list-item-title>
                  <v-list-item-subtitle>
                    Tips og oppdateringer om boligen sendes i automatiske
                    nyhetsbrev
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-btn
            class="mx-auto"
            large
            depressed
            color="primary"
            @click="save(getProfile)"
            >{{ !firstTime ? "Skru på varslinger" : "Bekreft" }}</v-btn
          >
          <v-btn class="mx-auto" v-if="!firstTime" large outlined @click="close"
            >Avbryt</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import notificationsService from "@/notification.services";
import baseApi from "@/api/api";
export default {
  props: ["show", "goToRoute", "firstTime"],
  data() {
    return {
      data: {}
    };
  },
  methods: {
    close() {
      this.show.show = false;
      if (!this.firstTime) {
        this.goTo();
      }
    },
    async save(data) {
      if (this.firstTime) {
        await baseApi.firstLogin();
        this.profile.user.seenWelcomeMessage = true;
      }
      this.profile.user.acceptEmail = data.acceptEmail;
      this.profile.user.acceptNewsLetter = data.acceptNewsLetter;
      await baseApi.editUser(this.profile.user).then(Response => {
        if (Response.status === 200) {
          notificationsService.success("Innstillinger for Varslinger lagret");
          this.$store.dispatch("shared/profile");
          this.close();
        }
      });
    },
    goTo() {
      if (this.goToRoute) {
        this.$router.push({ name: this.goToRoute });
      }
    }
  },
  computed: {
    ...mapGetters({ profile: "shared/profile" }),
    getProfile() {
      if (this.profile) {
        let data = {};
        data.acceptEmail = true;
        data.acceptNewsLetter = false;
        if (this.firstTime || this.profile.user.acceptNewsLetter === true) {
          data.acceptNewsLetter = true;
        }
        return data;
      }
      return {};
    },
    showDialog: {
      get: function() {
        if (this.show.show) {
          return this.show.show;
        }
        return false;
      },
      set: function() {
        this.close();
      }
    }
  }
};
</script>
