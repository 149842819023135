<template>
  <div>
    <v-dialog
      :fullscreen="$store.getters['shared/isMobileDevice']"
      v-model="show"
      max-width="1000"
    >
      <v-card>
        <v-card-title class="headline mb-3 primary--text">
          {{ title }}
          <v-spacer></v-spacer>
          <v-btn @click="cancel" color="red" icon
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-img
            contain
            max-height="75vh"
            :src="url"
            class="white--text text-right"
          >
          </v-img>
        </v-card-text>
        <v-card-actions class="justify-center pb-sm-8">
          <v-btn depressed large color="primary" @click="cancel">
            Lukk
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      required: true
    },
    url: {
      type: String,
      default: null
    }
  },
  methods: {
    cancel() {
      this.show = false;
      this.$emit("cancel", true);
    }
  },
  computed: {
    show: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
