<template>
  <div>
    <div class="bg-banner" :style="{ background: `url(${backgroundImage})` }">
      <div class="videoWrapper">
        <!-- <iframe
          width="560"
          height="349"
          :src="videoUrl"
          frameborder="0"
          allowfullscreen
        ></iframe> -->
        <VideoPlayer
          :src="videoUrl"
          :data-poster="dataPoster"
          margin-top="mt-0"
        />
      </div>
      <v-card-title
        class="mt-6 display-1 darkgrey--text font-weight-bold justify-center"
      >
        {{ title }}
      </v-card-title>
      <v-card-text class="body-1 mb-12">
        <slot></slot>
      </v-card-text>
    </div>
  </div>
</template>

<script>
import VideoPlayer from "@/components/shared/VideoPlayer";
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    backgroundImage: {
      type: String,
      required: true
    },
    videoUrl: {
      required: true
    },
    dataPoster: {
      type: String
    }
  },
  components: {
    VideoPlayer
  }
};
</script>
