<template>
  <div>
    <h3 class="darkgrey--text mt-4">
      Samtykke kjøpetilbud
    </h3>

    <v-checkbox
      v-if="data"
      v-model="data.notInvestor"
      :label="textCheckbox"
      :rules="[v => !!v || 'Du må huke av for at du ikke er investor']"
    ></v-checkbox>

    <p>
      Dersom du ikke ønsker å signere erklæringen kan du ta kontakt med den
      ansvarlige selgeren av prosjektet.
    </p>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    }
  },
  data() {
    return {
      textCheckbox: `Jeg bekrefter at jeg/vi skal bo i boligen og at boligen ikke skal utleies. Kontraktsposisjonen kan ikke videreselges til tredjeperson før ferdigstillelse og overlevering. Jeg samtykker til at selger har rett til å avvise ethvert kjøpetilbud.`
    };
  },
  computed: {
    data: {
      get() {
        if (this.value) {
          return this.value;
        }
        return null;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
