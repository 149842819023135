import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.value && _vm.value.length > 0)?_c('div',[_vm._l((_vm.value),function(option,index){return _c(VCard,{key:index,staticClass:"px-sm-4",attrs:{"elevation":"0"}},[_c(VListItem,[_c(VListItemIcon,{staticClass:"d-none d-sm-flex"},[_c(VIcon,[_vm._v(_vm._s(option.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(option[_vm.itemTitleKey]))]),_c(VListItemSubtitle,{staticClass:"d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.format.formatToNok(option[_vm.priceKey]))+",- ")])],1),_c(VListItemContent,{staticClass:"d-none d-sm-flex text-right"},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.format.formatToNok(option[_vm.priceKey]))+",-")])],1)],1),_c(VDivider,{staticClass:"d-none d-sm-flex",attrs:{"inset":""}}),_c(VDivider,{staticClass:"d-flex d-sm-none"})],1)}),_c(VCard,{staticClass:"px-sm-4",attrs:{"elevation":"0"}},[_c(VListItem,[_c(VListItemIcon,{staticClass:"d-none d-sm-flex"}),_c(VListItemContent,{staticClass:"font-weight-bold"},[_vm._v("Totalpris:")]),_c(VListItemContent,{staticClass:"text-right"},[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.format.formatToNok(_vm.totalPrice))+",-")])],1)],1)],1),_c('p',{staticClass:"subtitle-2 font-weight-regular mt-4"},[_vm._v(" Det tas forbehold om endringer av omkostningsbeløpene som følge av politiske vedtak og/eller lovendringer og at Statens kartverk v/tinglysingen godkjenner dokumentavgiftsgrunnlaget. Økninger i satser og grunnlag må bæres av kjøper. ")]),_vm._t("footer")],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }