<template>
  <div>
    <nav class="navHeader">
      <v-app-bar height="65" style="z-index:8;" color="white" flat app>
        <v-app-bar-nav-icon
          v-if="!goBackShowing"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>
        <v-btn icon v-if="goBackShowing" @click="goBack">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-items
          :class="{
            headline: true,
            'd-md-flex d-none': $route.name !== 'home'
          }"
        >
          <v-btn
            v-if="$route.name !== 'storeOptions'"
            text
            @click="goTo('home')"
          >
            <img
              class="mr-2"
              width="38"
              src="https://thg.blob.core.windows.net/public-images/mt_logo.svg"
            />
            Mitt Trysilhus
          </v-btn>
          <v-btn
            text
            v-if="$route.name === 'storeOptions' && storeOptions.title"
            >{{ storeOptions.title }} - Tilvalg</v-btn
          >
        </v-toolbar-items>
        <v-toolbar-items
          v-if="$route.name !== 'home'"
          class="d-block d-md-none ml-2 headline"
        >
          <v-btn v-if="$route.name === 'faqs'" text>
            {{ mobileHeaderNameFaq }}
          </v-btn>
          <v-btn v-else text>{{ $route.meta.mobName }}</v-btn>
        </v-toolbar-items>

        <v-spacer></v-spacer>

        <v-btn
          v-if="
            ($route.name === 'storeOptions' || $route.name === 'store') &&
              $store.getters['shared/isMobileDevice']
          "
          @click="help"
          fab
          depressed
          color="info"
          small
        >
          <v-icon>mdi-help</v-icon>
        </v-btn>

        <v-toolbar-items
          v-if="!$store.getters['shared/isMobileDevice']"
          class="ml-2 headline d-none d-md-flex"
        >
          <v-menu
            :open-on-hover="!disabledSectionChange && section.items.length > 1"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-btn
                @mouseover="rotateIcon(false)"
                @mouseleave="rotateIcon(true)"
                text
                color="rgba(0, 0, 0, 0.87)"
                dark
                v-on="on"
              >
                <v-icon
                  v-if="!disabledSectionChange && section.items.length > 1"
                  :class="{ rotate: !iconDown }"
                  dark
                  >mdi-chevron-down</v-icon
                >
                {{ section.title }}
              </v-btn>
            </template>
            <v-list v-if="!disabledSectionChange && section.items.length > 1">
              <div v-for="subItem in section.items" :key="subItem.id" ripple>
                <v-list-item
                  @click="changeSection(subItem)"
                  @mouseover="rotateIcon(false)"
                  @mouseleave="rotateIcon(true)"
                  >{{ subItem.name }}</v-list-item
                >
              </div>
            </v-list>
          </v-menu>
        </v-toolbar-items>

        <!-- Mobile btn -->
        <v-btn
          v-if="$store.getters['shared/isMobileDevice'] && requestsShowing"
          @click="setChangeView(!changeView)"
          text
          icon
          color="primary"
        >
          <v-icon color="primary">
            {{ changeView ? "mdi-view-module" : "mdi-view-list" }}
          </v-icon>
        </v-btn>
        <v-btn
          v-if="$store.getters['shared/isMobileDevice'] && requestsShowing"
          @click="changeMobileNavBtn"
          text
          color="primary"
          >Filter</v-btn
        >

        <div v-if="!$auth.loading">
          <!-- show logout when authenticated -->
          <v-btn
            class="d-none d-sm-block"
            text
            v-if="$auth.isAuthenticated"
            @click="logout"
            color="secondary"
          >
            Logg ut
            <v-icon>mdi-logout-variant</v-icon>
          </v-btn>
        </div>

        <!-- Dropdown mob change project -->
        <template v-if="requestsShowing" v-slot:extension>
          <BtnBar v-if="requestsShowing" class="d-block d-md-none" />
        </template>
      </v-app-bar>
      <v-navigation-drawer
        class="drawerSafeArea"
        style="z-index: 8;"
        v-model="drawer"
        app
      >
        <template v-slot:prepend>
          <v-list-item
            class="safeAreaDrawerProfile"
            two-line
            @click="goTo('profile')"
          >
            <v-list-item-avatar class="my-1">
              <v-img
                v-if="profile && $auth.isAuthenticated"
                :src="optionsService.getProfileImage(profile.id, getStamp)"
                max-height="180"
              />
              <v-skeleton-loader
                ref="skeleton"
                type="avatar"
                class="mx-auto"
              ></v-skeleton-loader>
            </v-list-item-avatar>
            <v-list-item-content v-if="profile && $auth.isAuthenticated">
              <v-list-item-title>{{ profile.fullName }}</v-list-item-title>
              <v-list-item-subtitle v-if="profile.currentSection">
                {{ profile.currentSection.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-list-item-title>
                <v-skeleton-loader
                  ref="skeleton"
                  type="text"
                  class="mx-auto"
                ></v-skeleton-loader>
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-skeleton-loader
                  ref="skeleton"
                  type="text"
                  class="mx-auto"
                ></v-skeleton-loader>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-divider></v-divider>

        <v-list class="safeAreaDrawerRoutes" dense nav>
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>fas fa-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Startside</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'profile' }">
            <v-list-item-icon>
              <v-icon>fas fa-user-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Min profil</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="getOptionsReady" :to="{ name: 'StoreInfo' }">
            <v-list-item-icon>
              <!-- <v-icon>mdi-package-variant</v-icon> -->
              <v-badge
                v-if="notConfirmedOptions > 0"
                :content="notConfirmedOptions"
                color="warning"
                overlap
              >
                <v-icon>mdi-cart</v-icon>
              </v-badge>
              <v-icon v-else>mdi-cart</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Bestill tilvalg</v-list-item-title>
            </v-list-item-content>
            <!-- <v-badge
              v-if="notConfirmedOptions > 0"
              :content="notConfirmedOptions"
              color="warning"
              overlap
            >
              <v-icon :size="26">mdi-cart</v-icon>
            </v-badge> -->
          </v-list-item>
          <v-list-item v-if="getTimeline > 0" :to="{ name: 'timeline' }">
            <v-list-item-icon>
              <v-icon>mdi-chart-timeline-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Tidslinje bygg</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'requests' }">
            <v-list-item-icon>
              <v-badge
                v-if="
                  getUnreadComments && getUnreadComments.totalNewComments > 0
                "
                :content="getUnreadComments.totalNewComments"
                color="warning"
                overlap
              >
                <v-icon>mdi-forum</v-icon>
              </v-badge>
              <v-icon v-else>mdi-forum</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Mine henvendelser</v-list-item-title>
            </v-list-item-content>

            <!-- <v-badge
              v-if="getUnreadComments && getUnreadComments.totalNewComments > 0"
              :content="getUnreadComments.totalNewComments"
              color="warning"
              overlap
            >
              <v-icon :size="26">mdi-message</v-icon>
            </v-badge> -->
          </v-list-item>
          <v-list-item :to="{ name: 'JointOwnerships' }">
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sameiets henvendelser</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :to="{ name: 'documents' }">
            <v-list-item-icon>
              <v-badge
                v-if="unreadDocuments && unreadDocuments > 0"
                :content="unreadDocuments"
                color="warning"
                overlap
              >
                <v-icon>mdi-folder-outline</v-icon>
              </v-badge>
              <v-icon v-else>mdi-folder-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Dokumenter</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :to="{ name: 'faq' }">
            <v-list-item-icon>
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Ofte stilte spørsmål</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="section.items.length > 1"
            :to="{ name: 'mysections' }"
          >
            <v-list-item-icon>
              <v-icon>mdi-home-city</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Mine seksjoner</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Mobile only -->
          <v-list-item class="d-sm-none" @click="introMovie">
            <v-list-item-icon>
              <v-icon>mdi-filmstrip</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Se introduksjonsfilm</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="d-sm-none" @click="openUserGuide">
            <v-list-item-icon>
              <v-icon>mdi-information</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Brukerveiledning</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            class="d-sm-none"
            v-if="$auth.isAuthenticated"
            @click="logout"
          >
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logg ut</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <template v-slot:append>
          <v-btn
            @click="introMovie"
            color="grey darken-2"
            block
            text
            small
            class="mb-2 mx-auto d-none d-sm-block text-capitalize"
            depressed
            ><v-icon class="mr-2" small>mdi-filmstrip</v-icon>Se
            introduksjonsfilm</v-btn
          >
          <v-btn
            @click="openUserGuide"
            color="grey darken-2"
            block
            text
            small
            class="mb-4 mb-md-8 mx-auto d-none d-sm-block text-capitalize"
            depressed
            ><v-icon class="mr-2" small>mdi-information</v-icon
            >Brukerveiledning</v-btn
          >
          <v-img
            class="mx-auto mb-6"
            width="100"
            :src="
              'https://thg.blob.core.windows.net/public-images/trysilhus_logo.png'
            "
          ></v-img>
        </template>
      </v-navigation-drawer>
    </nav>
    <IntroVideo :show="showDialog" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BtnBar from "@/components/shared/btnBar";
import baseApi from "@/api/api";
import optionsService from "@/options.services";
import IntroVideo from "@/components/shared/IntroVideo";
export default {
  components: {
    BtnBar,
    IntroVideo
  },
  data() {
    return {
      drawer: true,
      iconDown: true,
      optionsService,
      showDialog: { show: false }
    };
  },
  created() {
    if (this.$store.getters["shared/isMobileDevice"]) {
      this.drawer = false;
    }

    this.$store.watch(
      (state, getters) => getters["shared/profile"],
      profile => {
        if (profile) {
          if (this.getOptionsReady === null) {
            this.optionsReady();
          }
          if (this.unreadDocuments === null) {
            this.setUnreadDocuments();
          }
          if (this.getUnreadComments === null) {
            this.unreadComments();
          }
        }
      }
    );
  },

  computed: {
    ...mapGetters({
      getProfile: "shared/profile",
      changeProfileImage: "shared/changeProfileImage",
      timeline: "saveData/timeline",
      faq: "shared/faq",
      getMobileNavBtn: "shared/mobileNavBtn",
      getOptionsReady: "shared/optionsReady",
      changeView: "request/changeViewRequests",
      storeOptions: "options/storeOptions",
      storeCart: "options/cart",
      sectionConfirme: "options/sectionConfirme",
      changedCartOfConfirme: "options/changedCartOfConfirme",
      colorCart: "color/colorCart",
      colorAvailable: "shared/colorAvailable",
      optionsAvailable: "shared/optionsAvailable",
      alldeadline: "options/alldeadline",
      getUnreadComments: "request/unreadComments",
      unreadDocuments: "documents/unreadDocuments"
    }),
    profile() {
      if (this.getProfile) {
        return this.getProfile.user;
      }
      return false;
    },
    section() {
      if (this.getProfile) {
        return {
          title: this.getProfile.user.currentSection.name,
          items: this.getProfile.user.sections
        };
      }
      return { title: "", items: [] };
    },
    goBackShowing() {
      return (
        this.$route.name === "newrequest" ||
        this.$route.name === "request" ||
        this.$route.name === "NewJointOwnership" ||
        this.$route.name === "JointOwnership" ||
        this.$route.name === "faqInfo" ||
        this.$route.name === "faqs" ||
        this.$route.name === "checkout" ||
        this.$route.name === "summary" ||
        this.$route.name === "signup" ||
        this.$route.name === "forgottenpassword" ||
        this.$route.name === "store" ||
        this.$route.name === "storeOptions" ||
        this.$route.name === "colorCheckout" ||
        this.$route.name === "ColorStore" ||
        this.$route.name === "colors"
      );
    },
    requestsShowing() {
      return (
        this.$route.name === "requests" ||
        this.$route.name === "JointOwnerships"
      );
    },
    disabledSectionChange() {
      return (
        this.$route.name === "store" ||
        this.$route.name === "checkout" ||
        this.$route.name === "IntroStore" ||
        this.$route.name === "storeOptions" ||
        this.$route.name === "checkoutChange" ||
        this.$route.name === "summary"
      );
    },
    getStamp() {
      if (this.changeProfileImage) {
        return this.changeProfileImage;
      }
      return 0;
    },
    getTimeline() {
      if (this.timeline.length === undefined) {
        return this.timeline.events.length;
      }
      return [];
    },
    mobileHeaderNameFaq() {
      if (this.$route.params.id && this.faq) {
        const id = parseInt(this.$route.params.id);
        if (id === -1) return "Alle kategorier";
        var category = this.faq.find(x => {
          return x.categoryId === id;
        });
        if (category.category) return category.category;
        else return "Faq";
      }
      return "";
    },
    notConfirmedOptions() {
      var count = 0;
      // alldeadline is true if user stil kan change options.
      if (
        this.optionsAvailable &&
        this.storeCart.length === undefined &&
        this.alldeadline
      ) {
        // return this.sectionConfirme.options.length > 0
        //   ? optionsService.countChangeOfConfime(this.changedCartOfConfirme)
        //   : this.storeCart.cartCount;
        count =
          count +
          (this.sectionConfirme.options.length > 0
            ? optionsService.countChangeOfConfime(this.changedCartOfConfirme)
            : this.storeCart.cartCount);
      }
      if (this.colorAvailable && !this.colorCart.loading) {
        count = count + this.colorCart.changes;
      }

      return count;
    }
  },
  methods: {
    ...mapActions({
      mobileNavBtn: "shared/mobileNavBtn",
      optionsReady: "shared/optionsReady",
      setChangeView: "request/changeViewRequests",
      showDialogMessage: "shared/showDialogMessage",
      unreadComments: "request/unreadComments",
      setUnreadDocuments: "documents/setUnreadDocuments"
    }),
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
    goTo(name) {
      if (name !== this.$route.name) {
        this.$router.push({ name: name });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    async changeSection(section) {
      await baseApi.changeSection(section, this.profile.id).then(response => {
        if (response.status === 200) {
          window.location.href = this.$route.path;
        }
      });
    },
    changeMobileNavBtn() {
      this.mobileNavBtn(!this.getMobileNavBtn);
    },
    rotateIcon(value) {
      this.iconDown = value;
    },
    help() {
      this.showDialogMessage({ show: true });
    },
    introMovie() {
      this.showDialog.show = true;
    },
    openUserGuide() {
      window.open(
        "https://thg.blob.core.windows.net/public-assets/2021_Mitt_Trysilhus_brukerveiledning.pdf"
      );
    }
  }
};
</script>

<style lang="scss">
.navHeader {
  header {
    padding-top: calc(0px + env(safe-area-inset-top)) !important;
    height: calc(65px + env(safe-area-inset-top)) !important;
  }
}

nav {
  .v-toolbar__content {
    border-bottom: 1px solid lightgrey !important;
  }
}

.drawerSafeArea {
  padding-top: calc(0px + env(safe-area-inset-top));
  width: calc(256px + env(safe-area-inset-left)) !important;
}

//Navbar extension height fix
@media (min-width: 960px) {
  .v-toolbar {
    height: unset !important;
  }
  .v-toolbar__extension {
    display: none !important;
  }
}

.safeAreaDrawerProfile {
  padding-left: calc(16px + env(safe-area-inset-left)) !important;
}

.safeAreaDrawerRoutes {
  padding-left: calc(8px + env(safe-area-inset-left)) !important;
}
</style>
