<template>
  <div class="login-bg">
    <v-img
      class="login-trysilhusLogo"
      width="180"
      :src="require('@/assets/imgs/gfx/trysilhus_logo_hvit2.png')"
    ></v-img>
    <v-card
      v-if="!$auth.loading"
      class="mx-auto"
      elevation="6"
      max-width="320"
      min-width="320"
      min-height="400"
    >
      <div class="login-content pa-12 text-center">
        <v-img
          class="mx-auto"
          width="80"
          height="80"
          :src="'https://thg.blob.core.windows.net/public-images/mt_logo.svg'"
        ></v-img>
        <h3 class="mt-1 mb-5 primary--text">Mitt Trysilhus</h3>
        <div class="text-center">
          <h2 class="mb-2">Finner ingen bruker</h2>
          <p>
            Vi kan ikke finne informasjon om brukeren du prøvde å logge inn med.
            Kontakt kundesenter på <a href="tel:32215510">32 21 55 10</a> for å
            løse dette problemet.
          </p>

          <a class="caption primary--text" @click="goToLogin('login')">
            <i class="fas fa-long-arrow-alt-left"></i>
            Tilbake til mitt.trysilhus.no
          </a>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  methods: {
    goToLogin(to) {
      this.$router.push({ name: to });
    }
  }
};
</script>

<style lang="scss">
.login-bg {
  background: url("https://thg.blob.core.windows.net/public-images/login_bg.jpg")
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  .login-trysilhusLogo {
    position: absolute;
    top: 3%;
    left: 2%;
  }
  @media (max-width: 768px) {
    .login-trysilhusLogo {
      left: 50%;
      top: 91%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
