const baseURL = process.env.VUE_APP_APIURL;
import _ from "lodash";

const optionsService = {
  getOptionImage(option, useThumbnail, info, type) {
    if (!type) {
      type = "id";
    }
    if (info && option && option[type]) {
      return baseURL + `/options/option/${option[type]}/infoimage`;
    } else if (option && option[type]) {
      var url = baseURL + `/options/option/${option[type]}/image`;

      if (useThumbnail) {
        url = url + "?useThumbnail=true";
      }
      return url;
    } else {
      return "https://dummyimage.com/600x400/fff/fff";
    }
  },
  getCategoryImage(context) {
    return baseURL + `/options/category/${context.id}/image`;
  },
  getImage(option) {
    if (option.isActive && option.isActive.id) {
      return baseURL + `/options/option/${option.isActive.id}/image`;
    } else {
      return "https://dummyimage.com/600x400/525252/ffffff&text=Standard";
    }
  },
  getProfileImage(customerId, change) {
    if (customerId) {
      if (change === 0)
        return baseURL + `/customer/profile/${customerId}/image`;
      else return baseURL + `/customer/profile/${customerId}/image?${change}`;
    }
  },
  getEmployeeProfileImage(personId) {
    return baseURL + `/common/employee/${personId}/image`;
  },
  formatPrice(n) {
    if (n) {
      return "Kr. " + n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1 ");
    } else {
      return "Kr. 0.00";
    }
  },
  anyConfirmed(cart) {
    if (cart) {
      var anyConfirmed = false;
      _.each(cart.groups, function(c) {
        _.each(c.categories, function(g) {
          _.each(g.options, function(o) {
            if (o.confirmedDate) {
              anyConfirmed = true;
            }
          });
        });
      });
      return anyConfirmed;
    }
  },
  summaryIsEmty(cart) {
    if (cart) {
      var anyConfirmed = false;
      _.each(cart.groups, function(c) {
        _.each(c.categories, function(g) {
          _.each(g.options, function(o) {
            if (o.selected && o.type !== 1) {
              anyConfirmed = true;
            }
          });
        });
      });
      return anyConfirmed;
    }
  },
  confirmedOptions(confrimed) {
    const isConfirmed = confrimed.some(x => x.type !== 1);
    return isConfirmed;
  },
  getSectionPlanImage(sectionId, albumUserAuth, attic) {
    var authString = "";
    if (sectionId) {
      authString = "?sectionId=" + sectionId;
    } else if (albumUserAuth) {
      authString = "?token=" + albumUserAuth;
    }
    if (attic) {
      authString = authString + "&attic=" + attic;
    }

    return baseURL + `/colors/section/image${authString}`;
  },
  //It counts changes of the confirmed options
  countChangeOfConfime(changedCartOfConfirme) {
    if (changedCartOfConfirme.length > 0) {
      //Filter oute type 3. Type 3 kan be multi
      const filterNotType3 = changedCartOfConfirme.filter(x => {
        return x.type !== 3;
      });
      //Find unique in list of type 1 and type 2
      const distinctAges = [
        ...new Set(filterNotType3.map(x => x.categoryName))
      ];
      //Finde alle changes on type 3
      const filterType3 = changedCartOfConfirme.filter(x => {
        return x.type === 3;
      });
      //The count of unique type 1 and 2 and add all cahnges of type 3
      return distinctAges.length + filterType3.length;
    }
    return 0;
  }
  // getProjectImage(ProjectId) {
  //   return baseURL + `projectimage/${ProjectId}`;
  // }
};

export default optionsService;
