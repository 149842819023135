<template>
  <div>
    <OptionsCheckoutContainer />
  </div>
</template>
<script>
import OptionsCheckoutContainer from "@/components/store/options/checkout/OptionsCheckoutContainer";
export default {
  components: {
    OptionsCheckoutContainer
  }
};
</script>
