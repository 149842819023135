function sortArrayObject(data, key) {
  return data.sort(function(a, b) {
    var textA = a[key];
    var textB = b[key];

    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
}

export default { sortArrayObject };
