import "@fortawesome/fontawesome-free/css/all.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

import no from "vuetify/es5/locale/no";

export default new Vuetify({
  lang: {
    locales: { no },
    current: "no"
  },
  icons: {
    iconfont: "mdi" || "fa"
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#a02361",
        //green: "#679146",
        orange: "#F58025",
        yellow: "#FDB913",
        //blue: "#79BDE8",
        lightblue: "#79BDE8",
        dark: "#332C2F",
        darkgrey: "#717171",
        lightgrey: "#DCDCDC",
        greybg: "#F5F5F5"
      },
      dark: {
        primary: "#a02361"
      }
    }
  }
});
