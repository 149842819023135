<template>
  <div>
    <h3 class="darkgrey--text mt-4">
      Dato for overtakelse
    </h3>
    <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="computedDateFormattedMomentjs"
          clearable
          label="Dato for overtakelse"
          readonly
          :rules="[v => !!v || 'Dato for overtakelse må fylles ut!']"
          v-bind="attrs"
          v-on="on"
          @click:clear="date = null"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        :allowed-dates="allowedDates"
        no-title
        :first-day-of-week="1"
        show-week
        @change="menu1 = false"
        :min="minDate"
        :max="maxDate"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    value: {
      required: true
    }
  },
  data() {
    return {
      menu1: false
    };
  },
  methods: {
    allowedDates(val) {
      var day = moment(val).day();
      if (day !== 0 && day !== 6) {
        return true;
      }
    }
  },
  computed: {
    date: {
      get() {
        if (this.value) {
          return this.value.aquisitionDate;
        }
        return null;
      },
      set(date) {
        var data = JSON.parse(JSON.stringify(this.value));
        this.$set(data, "aquisitionDate", date);
        this.$emit("input", data);
      }
    },
    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format("DD-MM-yyyy") : "";
    },

    minDate() {
      return moment()
        .add(14, "d")
        .format();
    },
    maxDate() {
      return moment()
        .add(3, "M")
        .format();
    }
  }
};
</script>
