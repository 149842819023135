import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cookie-law',{attrs:{"theme":"dark-lime","buttonText":"Godta"}},[_c('div',{attrs:{"slot":"message"},slot:"message"},[_vm._v(" På denne siden brukes informasjonskapsler for å få statistikk på bruken av siden vår. Ved å fortsette å bruke siden bekrefter du at du godtar dette "),_c('a',{staticClass:"green--text",attrs:{"href":"https://www.trysilhus.no/personvern/"}},[_vm._v("Les mer")])])]),_c(VDialog,{attrs:{"max-width":"600","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.cookiesOff),callback:function ($$v) {_vm.cookiesOff=$$v},expression:"cookiesOff"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline primary--text"},[_vm._v("Informasjonskapsler"),(_vm.$store.getters['shared/isMobileDevice'])?_c('br'):_vm._e(),_vm._v(" (Cookies)")])]),_c(VCardText,[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v("Mitt Trysilhus er avhengig av at informasjonskapsler er skrudd på. For å kunne logge inn på Mitt Trysilhus må du skru på informasjonskapsler.")])]),_c(VCardActions,[_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{attrs:{"large":"","color":"primary"},on:{"click":_vm.close}},[_vm._v("Lukk")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }