<template>
  <div>
    <v-dialog
      v-model="showDialog"
      max-width="600"
      persistent
      :fullscreen="$store.getters['shared/isMobileDevice']"
    >
      <v-form v-model="valid" ref="form">
        <v-card>
          <v-card-title class="primary--text">
            Velkommen til Mitt Trysilhus
          </v-card-title>
          <v-card-text>
            <p class="body-2">
              Gratulerer med kjøp av ny bolig! Mitt Trysilhus er vår kundeportal
              hvor du vil finne nyttig informasjon om din bolig.
            </p>
            <p class="body-2 mb-5">
              Vi ønsker at du bekrefter at du vil motta varsler om svar og
              endringer på dine henvendelser i Mitt Trysilhus på e-post, og at
              vi kan sende deg nyttige tips og oppdateringer i nyhetsbrev.
            </p>

            <VideoPlayer
              src="https://thg.blob.core.windows.net/publicvideos/MittTrysilhus_v3.mp4"
            />

            <v-list dense>
              <v-list-item-group multiple>
                <v-list-item>
                  <v-list-item-action>
                    <v-checkbox
                      v-model="getProfile.acceptNewsLetter"
                      color="success"
                      hide-details
                    ></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>Automatisk nyhetsbrev</v-list-item-title>
                    <v-list-item-subtitle>
                      Tips og oppdateringer om boligen sendes i automatiske
                      nyhetsbrev
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <div class="conditions-checkbox py-2 my-1 ml-2">
              <v-checkbox
                v-model="privacyStatement"
                class="condition-checkbox"
                color="success"
                :rules="[
                  v =>
                    !!v ||
                    'Personvernerklæringen må være godkjent for å gå videre'
                ]"
                hide-details="auto"
              >
                <template v-slot:label>
                  <span class="ml-8">
                    <h4>
                      <a
                        target="_blank"
                        href="https://www.trysilhus.no/personvern/"
                        @click.stop
                      >
                        Personvernerklæring
                      </a>
                    </h4>
                  </span>
                </template>
              </v-checkbox>
            </div>
          </v-card-text>
          <v-card-actions class="pb-6">
            <v-btn
              class="mx-auto"
              large
              depressed
              color="primary"
              @click="save(getProfile)"
            >
              Bekreft
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import notificationsService from "@/notification.services";
import baseApi from "@/api/api";
import VideoPlayer from "@/components/shared/VideoPlayer";
export default {
  props: ["show"],
  data() {
    return {
      valid: true,
      data: {},
      privacyStatement: false
    };
  },
  methods: {
    close() {
      this.show.show = false;
    },
    async save(data) {
      var isValid = this.$refs.form.validate();
      if (isValid) {
        await baseApi.firstLogin();
        this.profile.user.seenWelcomeMessage = true;

        this.profile.user.acceptEmail = data.acceptEmail;
        this.profile.user.acceptNewsLetter = data.acceptNewsLetter;
        await baseApi.editUser(this.profile.user).then(Response => {
          if (Response.status === 200) {
            notificationsService.success("Innstillinger for Varslinger lagret");
            this.$store.dispatch("shared/profile");
            this.close();
          }
        });
      } else {
        notificationsService.warn(
          "Personvernerklæring må være godkjent for å gå videre!"
        );
      }
    }
  },
  computed: {
    ...mapGetters({ profile: "shared/profile" }),
    getProfile() {
      if (this.profile) {
        let data = {};
        data.acceptEmail = true;
        data.acceptNewsLetter = true;

        return data;
      }
      return {};
    },
    showDialog: {
      get: function() {
        if (this.show.show) {
          return this.show.show;
        }
        return false;
      },
      set: function() {
        this.close();
      }
    }
  },
  components: {
    VideoPlayer
  }
};
</script>
