<template>
  <div>
    <v-row v-scroll="onScroll" class="justify-center">
      <v-col class="pt-0" xl="9">
        <Cart
          v-if="!showStoreChoice.show && !readOnly"
          :cartInfo="cartInfo"
          :getCartGroups="getCartGroups"
          @change="change"
        />
        <div
          v-if="!showStoreChoice.show"
          id="cart-bg"
          :class="{ cartbgToggle: openCart }"
        ></div>

        <v-row v-for="(categorie, index) in getCategories" :key="index">
          <v-col v-if="categorie.children.length > 0" cols="12">
            <OptionStoreHeader
              :expired="categorie.disabled || categorie.disabled === undefined"
              :number="index + 1"
              :title="categorie.name"
              :subTitle="
                categorie.deadline
                  ? 'Siste frist: ' + deadline(categorie.deadline)
                  : ''
              "
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            xl="4"
            v-for="item in categorie.children"
            :key="item.id"
          >
            <OptionsStoreCard
              :item="item"
              :categorie="categorie"
              @change="change"
            />
          </v-col>
        </v-row>
        <ChangeOptions
          :show="showStoreChoice"
          :cartInfo="cartInfo"
          :getCartGroups="getCartGroups"
          @openCartDialog="openCartDialog"
          @change="change"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ChangeOptions from "@/components/store/ChangeOptions";

import OptionStoreHeader from "@/components/store/options/store/children/OptionStoreHeader";

import Cart from "@/components/store/Cart";
import OptionsStoreCard from "@/components/store/options/store/children/OptionsStoreCard";
import optionsService from "@/options.services";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      data: null,
      cart: null,
      showStoreChoice: { show: false },
      id: null,
      title: null,
      optionsService,
      overlay: false,
      openCart: false,
      cartGroups: null
    };
  },

  async created() {
    if (this.$route.name === "storeOpen") {
      this.optionsReady();
    }

    this.cart = this.storeCart;
    this.$store.watch(
      (state, getters) => getters["options/cart"],
      newValue => {
        this.cart = newValue;
      }
    );
  },
  mounted() {
    // if user refresh page it opens dialog
    if (this.$route.name === "storeOptions") {
      this.showStoreChoice.show = true;
    }
    //Scroll when go back from options selector
    if (this.scrollTop) {
      var scroll = this.scrollTop;
      if (this.readOnly) {
        scroll = scroll - 64;
      }
      this.$vuetify.goTo(scroll);
    }
  },
  watch: {
    $route(to) {
      //open dialog when user navigate to router "storeOptions"
      if (to.name === "storeOptions") {
        this.showStoreChoice.show = true;
      } else {
        this.showStoreChoice.show = false;
      }
    }
  },
  methods: {
    ...mapActions({
      deleteCart: "options/deleteCart",
      setScrollTop: "options/scrollTop",
      optionsReady: "shared/optionsReady"
    }),
    change(data) {
      if (!window.location.href.endsWith("/vis")) {
        this.$router.push({ name: "storeOptions", params: { id: data.id } });
      } else {
        this.$router.push({
          name: "storeOptionsOpen",
          params: { id: data.id }
        });
      }
    },
    toggleSidemenu() {
      this.openCart = !this.openCart;
    },
    getSelected() {
      this.categories.forEach(x => {
        x.children.forEach(c => {
          this.cart.groups.forEach(g => {
            var categories = g.categories.find(y => {
              return y.id === c.id;
            });
            if (categories) {
              var isActive = categories.options.filter(y => {
                return y.selected === true;
              });
              c.price = 0;
              c.isActiveAddon = undefined;
              isActive.map(y => {
                if (y.type === 3) {
                  c.isActiveAddon = y;
                  c.price = c.price + y.price;
                } else {
                  c.isActive = y;
                  if (y.type === 2) {
                    c.price = c.price + y.price;
                  }
                }
              });
            }
          });
        });
      });
    },
    async deleteFromCart(item) {
      this.deleteCart(item);
    },
    openCartDialog(value) {
      this.openCart = value;
    },
    deadline(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    onScroll() {
      const offsetTop =
        window.pageYOffset || document.documentElement.scrollTop;
      this.setScrollTop(offsetTop);
    }
  },
  computed: {
    ...mapGetters({
      categories: "options/categories",
      storeCart: "options/cart",
      scrollTop: "options/scrollTop",
      readOnly: "options/readOnly",
      optionsAvailable: "shared/optionsAvailable"
    }),
    cartInfo() {
      if (this.cart.length !== 0) {
        this.getSelected();
        return this.cart;
      }
      return 0;
    },
    getCategories() {
      if (this.categories.length > 0) {
        this.getSelected();
        return this.categories;
      }
      return {};
    },
    getCartGroups() {
      if (this.cart.length !== 0) {
        return this.cart;
      }
      return {};
    }
  },
  components: {
    ChangeOptions,
    Cart,
    OptionsStoreCard,
    OptionStoreHeader
  }
};
</script>

<style lang="scss">
.expired {
  .v-list-item {
    .v-avatar {
      background-color: darkgrey !important;
    }
    .v-list-item__content {
      .v-list-item__title {
        color: darkgrey !important;
      }
    }
  }
}
.clickyCard.expired {
  position: relative;
  pointer-events: none;
  &:after {
    content: "Frist utgått";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: white;
    font-weight: bold;
  }
}

.sidemenuWrapper {
  position: fixed;
  // top: 64px;
  top: calc(64px + env(safe-area-inset-top));
  right: -300px;
  max-width: 300px;
  width: 100%;
  height: 100%;
  z-index: 10;
  transition: right 0.5s ease;
  -webkit-transition: right 0.5s ease;
  z-index: 10;
  .sidemenu-btns {
    position: absolute;
    // top: 5%;
    top: calc(5% + env(safe-area-inset-top));
    left: -50%;
    display: flex;
    flex-direction: column;
    button {
      margin-bottom: 0.6rem;
    }
  }
  .cart-container {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 340px;
    width: 100%;
    height: 100%;
    background-color: white;
    border-top: 1px solid lightgrey;
    border-left: 1px solid lightgrey;
    .cart-list-container {
      height: 65vh;
      overflow-y: auto;
      .cart-list {
        &:hover {
          background-color: rgb(225, 225, 225) !important;
        }
        &:nth-child(even) {
          background-color: rgb(240, 240, 240);
        }
      }
    }
    .updatedHeight {
      height: 80vh !important;
    }
  }
}
.sidemenuToggle {
  right: 0 !important;
  &:before {
    content: "";
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.4);
  }
}

//Tablet - lg
@media (min-width: 600px) and (max-width: 1920px) {
  .sidemenuWrapper {
    .sidemenu-btns {
      top: 13%;
      left: -30%;
    }
  }
}

//Mobile
@media (max-width: 600px) {
  .sidemenuWrapper {
    right: -300px;
    .sidemenu-btns {
      left: -22%;
    }
    .cart-container {
      max-width: 300px;
      .cart-list-container {
        height: calc(60vh - env(safe-area-inset-bottom));
      }
    }
  }
}

.addonImg {
  position: absolute;
  top: 55%;
  left: 85%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  z-index: 1;
  border-radius: 10px;
  background-color: white;
}
.imgAddon {
  width: 95px !important;
  border-radius: 10px;
}
</style>
