// import baseApi from "@/api/baseApi";
import baseApi from "@/api/api";
import colorApi from "@/api/colorApi";
import moment from "moment";
import store from "@/store/index";
import { getInstance } from "@/auth/index";
export default {
  namespaced: true,

  state: {
    isLoading: false,
    loading: false,
    routerTo: "/",
    snackbar: null,
    profile: null,
    faq: null,
    changeProfileImage: null,
    isMobileDevice: false,
    mobileNavBtn: false,
    optionsReady: null,
    optionsAvailable: null,
    colorAvailable: null,
    colorAvailableInfo: {},
    cookiesOff: false,
    showDialogMessage: {},
    colorReady: null
  },
  getters: {
    isLoading: state => {
      return state.isLoading;
    },
    loading: state => {
      return state.loading;
    },
    routerTo: state => {
      return state.routerTo;
    },
    showSnackbar: state => {
      return state.snackbar;
    },
    profile: state => {
      return state.profile;
    },
    faq: state => {
      return state.faq;
    },
    changeProfileImage: state => {
      return state.changeProfileImage;
    },
    isMobileDevice: state => {
      return state.isMobileDevice;
    },
    mobileNavBtn: state => {
      return state.mobileNavBtn;
    },
    optionsReady: state => {
      return state.optionsReady;
    },
    optionsAvailable: state => {
      return state.optionsAvailable;
    },
    colorAvailable: state => {
      return state.colorAvailable;
    },
    colorAvailableInfo: state => {
      return state.colorAvailableInfo;
    },
    colorReady: state => {
      return state.colorReady;
    },
    cookiesOff: state => {
      return state.cookiesOff;
    },
    showDialogMessage: state => {
      return state.showDialogMessage;
    }
  },
  mutations: {
    isLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    loading(state, loading) {
      state.loading = loading;
    },
    routerTo(state, route) {
      state.routerTo = route;
    },
    showSnackbar(state, snackbar) {
      let newSnackBar = snackbar;
      newSnackBar.show = true;
      state.snackbar = newSnackBar;
    },
    hideSnackbar(state, snackbar) {
      let newSnackBar = snackbar;
      newSnackBar.show = false;
      state.snackbar = newSnackBar;
    },
    profile(state, data) {
      state.profile = data;
    },
    faq(state, data) {
      state.faq = data;
    },
    changeProfileImage(state, data) {
      state.changeProfileImage = data;
    },
    isMobileDevice(state, data) {
      state.isMobileDevice = data;
    },
    mobileNavBtn(state, value) {
      state.mobileNavBtn = value;
    },
    optionsReady(state, value) {
      state.optionsReady = value.optionsReady;
      state.colorAvailable = value.colorAvailable;
      state.optionsAvailable = value.optionsAvailable;
      state.colorAvailableInfo = value.colorAvailableInfo;
      state.colorReady = value.colorReady;
    },
    cookiesOff(state, value) {
      state.cookiesOff = value;
    },
    showDialogMessage(state, value) {
      state.showDialogMessage = value;
    }
  },
  actions: {
    isLoading({ commit }, isLoading) {
      commit("isLoading", isLoading);
    },
    loading(context, loading) {
      context.commit("loading", loading);
    },
    routerTo(context, route) {
      context.commit("routerTo", route);
    },
    showSnackbar(context, snackbar) {
      context.commit("showSnackbar", snackbar);
    },
    hideSnackbar(context, snackbar) {
      context.commit("hideSnackbar", snackbar);
    },
    async profile({ commit }, value) {
      if (!value) {
        await baseApi
          .getUser()
          .then(respons => {
            value = respons;
          })
          .catch(() => {
            value = null;
            const authService = getInstance();
            const url = window.location.origin + "/ingen-bruker";
            authService.logout({
              returnTo: url
            });
          });
      }
      let data = {};
      // data.data = value;
      if (value) {
        data.user = value;
      } else {
        data = null;
      }
      commit("profile", data);
    },
    async faq({ commit }) {
      const respones = await baseApi.getFaqs();
      respones.forEach(x => {
        x.show = false;
      });
      //delete faq with no answer
      const data = respones.filter(x => {
        x.answer.length;
        return x.answer !== "";
      });
      commit("faq", data);
    },
    changeProfileImage({ commit }) {
      const date = moment().format();
      commit("changeProfileImage", date);
    },
    isMobileDevice({ commit }) {
      let isMobile = false;
      isMobile = this._vm.$device.mobile || this._vm.$device.ipad;
      commit("isMobileDevice", isMobile);
    },
    mobileNavBtn({ commit }, value) {
      commit("mobileNavBtn", value);
    },
    async optionsReady({ commit }) {
      const optionsAvailable = await baseApi.getOptionsReady();
      const colorAvailable = await colorApi.colorAvailable();
      var colorReady = { ready: false };
      if (colorAvailable) {
        colorReady = await colorApi.getColorReady();
      }

      if (
        optionsAvailable &&
        store.getters["options/categories"].length === 0
      ) {
        store.dispatch("options/OptionsData");
      }
      if (colorReady && colorReady.ready) {
        store.dispatch("color/color");
      }
      let optionsReady = false;
      if (optionsAvailable || colorAvailable) {
        optionsReady = true;
      }
      commit("optionsReady", {
        optionsReady: optionsReady,
        colorAvailable: colorAvailable,
        optionsAvailable: optionsAvailable,
        colorAvailableInfo: colorReady,
        colorReady: colorReady.ready
      });
    },
    async colorReady({ commit }) {
      const colorAvailable = await colorApi.colorAvailable();
      var colorReady = { ready: false };
      if (colorAvailable) {
        colorReady = await colorApi.getColorReady();
      }

      if (colorReady && colorReady.ready) {
        store.dispatch("color/color");
      }

      commit("optionsReady", {
        optionsReady: null,
        colorAvailable: colorAvailable,
        optionsAvailable: null,
        colorAvailableInfo: colorReady,
        colorReady: colorReady.ready
      });
    },

    cookiesOff({ commit }, value) {
      commit("cookiesOff", value);
    },
    showDialogMessage({ commit }, value) {
      commit("showDialogMessage", value);
    }
  }
};
