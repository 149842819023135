<template>
  <div>
    <HeaderDivider
      v-if="title && !hideHeader"
      :title="title"
      :margin="margin"
    />
    <div v-if="value && value.length > 0">
      <v-card v-for="(option, index) in value" :key="index" flat tile>
        <v-list class="pa-0" :color="isChanges ? 'greybg' : null">
          <v-list-item>
            <v-icon
              v-if="isChanges"
              class="mr-3"
              large
              :color="option.selected ? 'success' : 'error'"
            >
              {{ option.selected ? "mdi-plus" : "mdi-minus" }}
            </v-icon>
            <!-- If icon -->
            <v-list-item-icon v-if="icon">
              <v-icon large>{{ option.icon }}</v-icon>
            </v-list-item-icon>
            <!-- If image -->
            <v-list-item-avatar
              v-if="!option.hexColor && !icon"
              size="50"
              :class="{ 'mr-4': isChanges }"
            >
              <v-img
                v-if="option.id"
                :src="optionsService.getOptionImage(option)"
                class="lightgrey"
              ></v-img>
            </v-list-item-avatar>
            <!-- If color -->
            <v-list-item-avatar
              v-if="option.hexColor && !icon"
              :color="option.hexColor"
              size="50"
              :class="{ 'mr-4': isChanges }"
            >
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-subtitle>
                <slot name="subtitle" v-bind="option">
                  {{ option[itemSubtitleKey] }}
                  <span style="color:#4caf50">
                    <span v-if="option.confirmedDate"
                      >Bekreftet av {{ option.confirmedBy }}
                      {{ option.confirmedDate | moment("DD.MM.YYYY HH:mm") }}
                    </span>
                    <span v-if="!option.confirmedDate && option.createdBy">
                      Opprettet av {{ option.createdBy }}
                      {{ option.createdDate | moment("DD.MM.YYYY HH:mm") }}
                    </span>
                  </span>
                </slot>
              </v-list-item-subtitle>
              <v-list-item-title>{{ option[itemTitleKey] }}</v-list-item-title>
              <v-list-item-subtitle class="d-flex d-sm-none">
                {{ format.formatToNok(option[priceKey]) }},-
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-icon class="my-2">
              <v-list-item-content class="d-none d-sm-flex text-right mr-4">
                <v-list-item-subtitle>
                  {{ format.formatToNok(option[priceKey]) }},-
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="showChangeIcon"
                    v-on="on"
                    text
                    fab
                    dark
                    small
                    color="blue"
                    :disabled="!option.selected"
                    @click="change(option)"
                  >
                    <v-icon>
                      mdi-swap-horizontal
                    </v-icon>
                  </v-btn>
                </template>
                <span>Endre</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="showDeleteIcon"
                    v-on="on"
                    icon
                    dark
                    small
                    fab
                    color="red"
                    :disabled="option[hideDeleteIconOn]"
                    @click="del(option)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Slett</span>
              </v-tooltip>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
      </v-card>
    </div>
    <slot name="footer"></slot>
  </div>
</template>

<script>
import format from "@/helpers/format.js";
import optionsService from "@/options.services";
import HeaderDivider from "@/components/store/child/HeaderDivider";
export default {
  props: {
    value: {
      required: true
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    totalPrice: {
      default: 0
    },
    itemTitleKey: {
      default: "name"
    },
    itemSubtitleKey: {
      default: "categoryName"
    },
    priceKey: {
      default: "price"
    },
    title: {
      default: null
    },
    icon: {
      type: Boolean,
      default: false
    },
    margin: {
      default: null
    },
    showChangeIcon: {
      type: Boolean,
      default: false
    },
    showDeleteIcon: {
      type: Boolean,
      default: false
    },
    hideDeleteIconOn: {
      default: null
    },
    isChanges: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      optionsService,
      format
    };
  },
  methods: {
    downloadReceipt() {
      this.$emit("downloadReceipt");
    },
    del(data) {
      this.$emit("delete", data);
    },
    change(data) {
      this.$emit("change", data);
    }
  },
  components: {
    HeaderDivider
  }
};
</script>

<style lang="scss" scoped>
.img {
  height: 200px;
  width: 300px;
  border-radius: 15px !important;
}
</style>
