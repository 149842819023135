import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VTextField,{ref:"inputMob",staticClass:"rounded-0",attrs:{"type":"tel","pattern":"[0-9]*","rules":[
      function (v) { return !!v || 'Mobilnummer må fylles ut!'; },
      _vm.telRequired,
      _vm.telLength,
      _vm.telNotMoreThanEight
    ],"label":_vm.label,"prepend-inner-icon":_vm.prependInnerIcon,"counter":8,"counter-value":_vm.counterValue,"placeholder":"xx xx xx xx","outlined":_vm.outlined,"filled":_vm.filled,"dense":_vm.dense,"disabled":_vm.disabled,"autocomplete":_vm.autocomplete,"required":""},on:{"change":function($event){_vm.telFinishedChange = true},"blur":function($event){_vm.fucusInput = false},"focus":function($event){_vm.fucusInput = true}},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }