<template>
  <div>
    <!-- <v-row v-if="optionsAvailable" class="justify-center">
      <v-col class="pt-0" xl="9">
        <v-list color="transparent">
          <v-list-item class="pl-0 mb-2">
            <v-list-item-avatar>
              <v-icon class="primary white--text">mdi-cart</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                class="primary--text headline font-weight-black"
              >
                Kvittering
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>
        <v-row>
          <v-col cols="12">
            <div v-for="option in data.options" :key="option.id">
              <v-card v-if="option.type !== 1" flat tile>
                <v-list>
                  <v-list-item>
                    <v-list-item-avatar size="50" class="my-0">
                      <v-img
                        v-if="option.id"
                        :src="optionsService.getOptionImage(option)"
                        class="lightgrey img"
                      ></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content class="pb-0 pb-sm-2">
                      <v-list-item-subtitle>
                        {{ option.categoryName }}
                        <span style="color:#4caf50">
                          <span v-if="option.confirmedDate"
                            >Bekreftet av {{ option.createdBy }}

                            {{
                              option.confirmedDate | moment("DD.MM.YYYY HH:mm")
                            }}</span
                          >
                          <span v-else>
                            Opprettet av {{ option.createdBy }}
                            {{
                              option.createdDate | moment("DD.MM.YYYY HH:mm")
                            }}</span
                          >
                        </span>
                      </v-list-item-subtitle>

                      <v-list-item-title>
                        {{ option.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content class="d-none d-sm-flex mr-4">
                      <v-list-item-subtitle class="text-right">
                        {{ optionsService.formatPrice(option.price) }},-
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="d-sm-none">
                    <v-list-item-content>
                      <v-list-item-subtitle class="pl-5 ml-12">
                        {{ optionsService.formatPrice(option.price) }},-
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
              </v-card>
            </div>

            <div
              class="mt-8 d-sm-flex align-center justify-space-between text-center text-sm-right"
            >
              <p class="d-sm-none mb-4">
                Totalpris: {{ optionsService.formatPrice(data.totalPrice) }} ,-
              </p>
              <v-btn
                class="m-w-100"
                depressed
                @click="downloadReceipt"
                color="primary"
                >Last ned kvittering
              </v-btn>
              <p class="d-none d-sm-block mb-0">
                Totalpris: {{ optionsService.formatPrice(data.totalPrice) }} ,-
              </p>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <ColorSummary
      v-if="colorAvailable"
      v-model="storeCart"
      title="Fargevalg"
      @downloadReceipt="downloadColorReceipt"
    /> -->

    <SummaryOptions
      v-if="colorAvailable"
      v-model="summaryOptionsAndColor"
      :totalPrice="totalPrice"
      @downloadReceipt="downloadReceipt"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FileSaver from "file-saver";
import api from "@/api/api";
import colorApi from "@/api/colorApi";
import optionsApi from "@/api/optionsApi";
import optionsService from "@/options.services";
// import ColorSummary from "@/components/store/color/ColorSummary";
import SummaryOptions from "@/components/store/SummaryOptions";
export default {
  data() {
    return {
      optionsService
    };
  },
  methods: {
    downloadReceipt() {
      optionsApi.downloadOptionsReceipt().then(fileData => {
        var blob = api.b64toBlob(fileData.file, "application/pdf");
        FileSaver.saveAs(blob, fileData.fileName);
      });
    },
    downloadColorReceipt() {
      colorApi.getColorReceipt().then(fileData => {
        var blob = api.b64toBlob(fileData.file, "application/pdf");
        FileSaver.saveAs(blob, fileData.fileName);
      });
    }
  },
  computed: {
    ...mapGetters({
      data: "options/sectionConfirme",
      storeCart: "color/colorCart",
      optionsReady: "shared/optionsReady",
      colorAvailable: "shared/colorAvailable",
      optionsAvailable: "shared/optionsAvailable"
    }),
    summaryOptionsAndColor() {
      let optionsConfirmed = [];
      let colorConfirmed = [];
      if (this.data && this.data.options) {
        optionsConfirmed = this.data.options.filter(x => x.type !== 1);
      }
      if (this.storeCart && this.storeCart.cartConfirmed) {
        colorConfirmed = this.storeCart.cartConfirmed;
      }
      return { optionsConfirmed, colorConfirmed };
    },
    totalPrice() {
      //Gets totale price for options  and color options
      let totalOptions = 0;
      let totalColor = 0;
      if (this.data) {
        totalOptions = this.data.totalPrice;
      }
      if (this.storeCart) {
        totalColor = this.storeCart.totalPriceConfirmed;
      }
      return totalOptions + totalColor;
    }
  },
  components: {
    // ColorSummary,
    SummaryOptions
  }
};
</script>

<style lang="scss" scoped>
.img {
  height: 200px;
  width: 300px;
  border-radius: 15px !important;
}
</style>
