<template>
  <v-list class="collapsibleContainer mb-6 pt-0" color="greybg">
    <v-list-group v-model="openValue">
      <template v-slot:prependIcon>
        <v-icon class="ml-sm-7" large>{{ headerIcon }}</v-icon>
      </template>
      <template v-slot:activator>
        <v-list-item-title class="collapsibleContainer-title">
          {{ title }}
        </v-list-item-title>
      </template>

      <v-card class="px-4 px-sm-12" color="greybg" elevation="0">
        <slot></slot>
      </v-card>
    </v-list-group>
  </v-list>
</template>

<script>
import optionsService from "@/options.services";

export default {
  props: {
    value: {
      type: Boolean,
      require: true
    },
    disabledReset: {
      default: false
    },
    title: {
      type: String
    },
    headerIcon: {
      type: String
    }
  },
  data() {
    return {
      optionsService
    };
  },
  computed: {
    openValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
<style lang="scss">
.collapsibleContainer {
  .collapsibleContainer-title {
    font-size: 2rem;
    font-weight: bold;
  }
  .v-list-group__header__append-icon {
    i {
      font-size: 2.5rem;
    }
  }
}

@media (max-width: 600px) {
  .collapsibleContainer {
    .v-list-group__header__prepend-icon {
      display: none;
    }
    .collapsibleContainer-title {
      font-size: 1.2rem;
    }
    .v-list-group__header__append-icon {
      margin-left: 0 !important;
      min-width: 30px !important;
      i {
        font-size: 1.5rem;
      }
    }
  }
}
</style>
