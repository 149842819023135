import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{staticClass:"justify-center"},[_c(VCol,{staticClass:"pt-0",attrs:{"xl":"9"}},[_c(VList,{attrs:{"color":"transparent"}},[_c(VListItem,{staticClass:"pl-0 mb-2"},[_c(VListItemAvatar,[_c(VIcon,{staticClass:"primary white--text"},[_vm._v("mdi-cart")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"primary--text headline font-weight-black"},[_vm._v("Kvittering ")])],1)],1),_c(VDivider)],1),_c(VRow,[(_vm.haveData(_vm.value))?_c(VCol,{attrs:{"cols":"12"}},[(_vm.value.optionsConfirmed.length > 0)?_c('div',[_c('ListItems',{attrs:{"title":"Tilvalg","margin":"mt-5"},model:{value:(_vm.value.optionsConfirmed),callback:function ($$v) {_vm.$set(_vm.value, "optionsConfirmed", $$v)},expression:"value.optionsConfirmed"}})],1):_vm._e(),(_vm.value.colorConfirmed.length > 0)?_c('div',[_c('ListItems',{attrs:{"title":"Farger","margin":"mt-10","itemSubtitleKey":"name","itemTitleKey":"ncs"},model:{value:(_vm.value.colorConfirmed),callback:function ($$v) {_vm.$set(_vm.value, "colorConfirmed", $$v)},expression:"value.colorConfirmed"}})],1):_vm._e(),_c('TotalPrice',{on:{"downloadReceipt":_vm.downloadReceipt},model:{value:(_vm.totalPrice),callback:function ($$v) {_vm.totalPrice=$$v},expression:"totalPrice"}})],1):_vm._e(),(!_vm.haveData(_vm.value))?_c(VCol,{attrs:{"cols":"12"}},[_c('div',[_c('h2',{staticClass:"mb-4"},[_vm._v(" Det er ikke bekreftet noen tilvalg eller endring av farger ")]),_c(VBtn,{attrs:{"to":{ name: 'StoreInfo' },"color":"primary"}},[_vm._v(" Endre tilvalg eller farger ")])],1)]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }