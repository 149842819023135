<template>
  <v-dialog v-model="showDialog" fullscreen scrollable>
    <v-card color="greybg">
      <v-toolbar max-height="64" flat>
        <v-btn icon color="red" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ title }} - Tilvalg</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!$store.getters['shared/isMobileDevice']"
          @click="close"
          color="error"
          outlined
          depressed
          class="pa-2 mr-6"
          >Gå tilbake
        </v-btn>
      </v-toolbar>

      <Cart
        :changedCart="changedCart"
        :cartInfo="cartInfo"
        :getCartGroups="getCartGroups"
        @change="change"
      />
      <v-card-text class="mt-6">
        <v-row class="justify-center" v-if="getInfo.length > 0">
          <v-col sm="10" xl="9">
            <h2>Tilvalg</h2>

            <v-divider class="primary mb-4"></v-divider>
            <v-row>
              <v-col sm="12" md="4" v-for="n in getInfo" :key="n.id">
                <v-card
                  height="260"
                  @mouseover="overlayMouseover(n)"
                  @mouseleave="overlayMouseleave(n)"
                  hover
                  :class="{
                    expired: expired(n),
                    clickyCard: true,
                    'theme-shadow': true,
                    imgActive: n.selected
                  }"
                >
                  <div class="optionOverview pa-4">
                    <v-chip
                      small
                      dark
                      :color="n.typeName === 'Standard' ? 'primary' : 'yellow'"
                      >{{ n.typeName }}</v-chip
                    >
                    <v-chip
                      small
                      class="ml-2"
                      :color="n.selected ? 'success' : ''"
                      >{{ optionsService.formatPrice(n.price) }}</v-chip
                    >
                    <v-avatar
                      v-if="n.selected"
                      class="ml-2"
                      color="white"
                      size="28"
                    >
                      <v-icon small color="success">
                        mdi-check-bold
                      </v-icon>
                    </v-avatar>
                    <v-avatar
                      class="ml-2"
                      v-if="n.confirmedDate"
                      color="white"
                      size="28"
                    >
                      <v-icon small color="success">
                        mdi-cart
                      </v-icon>
                    </v-avatar>
                    <v-btn
                      @click="showImg(n)"
                      class="zoomPicBtn mt-0"
                      elevation="0"
                      small
                      fab
                    >
                      <v-icon>mdi-magnify-plus-outline</v-icon>
                    </v-btn>
                  </div>
                  <v-overlay
                    class="clickyCard-overlay pa-4"
                    absolute
                    opacity="0.93"
                    :value="n.overlay"
                  >
                    <h3 class="white--text">{{ n.name }}</h3>
                    <p class="body-2 lightgrey--text">
                      {{ n.description }}
                    </p>
                    <v-chip
                      v-if="!n.selected || n.type !== 1"
                      :color="n.selected ? 'error' : 'success'"
                      text-color="white"
                      pill
                      @click="changeCart(n)"
                    >
                      {{
                        n.selected
                          ? "Slett fra handlekurv"
                          : "Legg til handlekurv"
                      }}
                      <v-avatar
                        right
                        :color="
                          n.selected ? 'error darken-1' : 'success darken-1'
                        "
                      >
                        <v-icon small>mdi-cart</v-icon>
                      </v-avatar>
                    </v-chip>
                  </v-overlay>

                  <v-img :src="optionsService.getOptionImage(n)" height="180px">
                  </v-img>

                  <v-list class="text-center">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <h3>{{ n.name }}</h3>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="justify-center ma-0" v-if="getAddon.length > 0">
          <v-col sm="10" xl="9">
            <h2>Tillegg</h2>
            <v-divider class="primary mb-4"></v-divider>
            <v-row v-if="getAddon.length > 0">
              <v-col sm="12" md="4" v-for="n in getAddon" :key="n.id">
                <v-card
                  @mouseover="overlayMouseover(n)"
                  @mouseleave="overlayMouseleave(n)"
                  hover
                  :class="{
                    expired: expired(n),
                    clickyCard: true,
                    'theme-shadow': true,
                    imgActive: n.selected
                  }"
                >
                  <div class="optionOverview pa-4">
                    <v-chip small dark color="primary">Tillegg</v-chip>
                    <v-chip
                      small
                      class="ml-2"
                      :color="n.selected ? 'success' : ''"
                      >{{ optionsService.formatPrice(n.price) }}</v-chip
                    >
                    <v-avatar
                      v-if="n.selected"
                      class="ml-2"
                      color="white"
                      size="28"
                    >
                      <v-icon small color="success">
                        mdi-check-bold
                      </v-icon>
                    </v-avatar>
                    <v-avatar
                      class="ml-2"
                      v-if="n.confirmedDate"
                      color="white"
                      size="28"
                    >
                      <v-icon small color="success">
                        mdi-cart
                      </v-icon>
                    </v-avatar>
                    <v-btn
                      @click="showImg(n)"
                      class="zoomPicBtn mt-0"
                      elevation="0"
                      small
                      fab
                    >
                      <v-icon>mdi-magnify-plus-outline</v-icon>
                    </v-btn>
                  </div>
                  <v-overlay
                    class="clickyCard-overlay pa-4"
                    absolute
                    opacity="0.93"
                    :value="n.overlay"
                  >
                    <h3 class="white--text">{{ n.name }}</h3>
                    <p class="body-2 lightgrey--text">
                      {{ n.description }}
                    </p>
                    <v-chip
                      :color="n.selected ? 'error' : 'success'"
                      text-color="white"
                      pill
                      @click="n.selected ? removeFromCart(n) : addToCart(n)"
                    >
                      {{
                        n.selected
                          ? "Slett fra handlekurv"
                          : "Legg til handlekurv"
                      }}
                      <v-avatar
                        right
                        :color="
                          n.selected ? 'error darken-1' : 'success darken-1'
                        "
                      >
                        <v-icon small>mdi-cart</v-icon>
                      </v-avatar>
                    </v-chip>
                  </v-overlay>

                  <v-img :src="optionsService.getOptionImage(n)" height="180px">
                  </v-img>

                  <v-list class="text-center">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <h3>{{ n.name }}</h3>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <DialogImage :show="showImage" :data="imgData" />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import baseApi from "@/api/api";
import DialogImage from "@/components/dialog/DialogImage";
import optionsService from "@/options.services";
import Cart from "@/components/store/Cart";
import moment from "moment";
import notificationsService from "@/notification.services";
export default {
  props: ["show", "DialogMessage", "cartInfo", "getCartGroups"],
  data() {
    return {
      data: null,
      id: null,
      title: null,
      dataAddon: null,
      optionsService,
      showImage: { show: false },
      imgData: null,
      optionalId: null,
      addonCart: [],
      changedCart: [],
      countCloseingTabs: 0
    };
  },
  methods: {
    ...mapActions({
      saveToCart: "options/saveToCart",
      deleteCart: "options/deleteCart"
    }),
    close() {
      this.data = null;
      // this.show.show = false;
      this.addonCart = [];
      this.optionalId = null;
      // this.$router.push({ name: "store" });
      // this.countCloseingTabs;
      //Count to go back to /tilvalg/butikk
      const goBack = this.countCloseingTabs > 0 ? this.countCloseingTabs : 1;
      this.$router.go(-goBack);
      this.countCloseingTabs = 0;
    },
    changeCart(data) {
      //Remove selected from cart and replase with confirmed if has confirmed else select standard.

      if (this.optionalId === data.id) {
        let confirmed = null;
        if (!data.confirmedDate && data.type !== 3) {
          confirmed = this.getInfo.find(x => {
            return x.confirmedDate;
          });
        }
        if (!confirmed) {
          var standard = this.getInfo.find(x => {
            return x.type === 1;
          });
          this.optionalId = standard.id;
        } else {
          this.optionalId = confirmed.id;
        }
      } else {
        this.optionalId = data.id;
      }
      this.save();
    },
    save() {
      let addon = [];
      if (this.addonCart) {
        addon = this.addonCart.map(x => {
          return { id: x.id };
        });
      }

      const options = this.optionalId
        ? [...[{ id: this.optionalId }], ...addon]
        : [...addon];
      const saveData = { id: parseInt(this.$route.params.id), options };
      this.addonCart = [];
      this.saveToCart(saveData);
    },
    showImg(img) {
      this.imgData = img;
      this.showImage.show = true;
    },
    //Getting option data and separete addon and options
    //Putting the added addons in list. To not delet them when saving
    async getData() {
      // this.id = this.$route.params.id;
      if (this.$route.params.id) {
        var data = await baseApi.getCategoryOptionsData(this.$route.params.id);
      }
      //If user not have access to the category
      //User sendt beck to store
      if (data.length === 0) {
        this.$router.push({ name: "store" }).catch(err => {
          if (err) {
            this.$router.go(-1);
          }
        });
        notificationsService.warn("Dette tilvalget er ikke tilgjengelig");
      }
      this.dataAddon = data.filter(x => {
        x.overlay = false;
        if (x.categoryName) this.title = x.categoryName;
        // if (x.selected && x.type === 3) {
        //   this.addonCart.push(x);
        // }
        return x.type === 3;
      });
      this.data = data.filter(x => {
        if (x.type !== 3 && x.selected) {
          this.optionalId = x.id;
        }
        return x.type !== 3 && x.id !== 0;
      });
      const isChangedFromConfirmed = this.data.find(x => {
        return x.selected === true && !x.confirmedDate;
      });
      if (isChangedFromConfirmed) {
        this.changedCart = this.changedCart.filter(x => {
          return (
            (x.categoryName !== isChangedFromConfirmed.categoryName &&
              x.type !== 3) ||
            x.type === 3
          );
        });
        this.changedCart.push(isChangedFromConfirmed);
        this.data.map(x => {
          if (x.confirmedDate) {
            x.selected = false;
            this.changedCart.push(x);
          }
        });
      }
    },
    //Adding addon to cart
    addToCart(data) {
      this.addonCart.push(data);
      this.save();
    },
    //Removing addon form cart
    removeFromCart(data) {
      // var index = this.addonCart.findIndex(x => {
      //   return x.id === data.id;
      // });
      // this.addonCart.splice(index, 1);
      this.deleteCart(data);
      // this.save();
    },
    //Overlay on when hower over card
    overlayMouseleave(data) {
      this.$set(data, "overlay", false);
    },
    //Overlay off when hower over card
    overlayMouseover(data) {
      this.$set(data, "overlay", true);
    },
    openCartDialog() {
      this.$emit("openCartDialog", !this.drawer);
    },
    change(data) {
      this.data = null;
      this.addonCart = [];
      this.optionalId = null;
      this.$emit("change", data);
    },
    expired(data) {
      if (data.deadline !== undefined) {
        const disabled = moment(
          moment()
            .subtract(1, "days")
            .format()
        ).isAfter(data.deadline);
        if (!disabled) {
          return false;
        }
      }
      return true;
    }
  },
  //Get data again when somthing is added to cart
  created() {
    this.$store.watch(
      (state, getters) => getters["options/cart"],
      () => {
        if (this.showDialog) {
          this.getData();
        }
      }
    );
  },
  watch: {
    "$route.params.id": function(id) {
      //Counting how many "dialog" is opened
      if (id) {
        ++this.countCloseingTabs;
      }
      if (id === undefined) {
        this.countCloseingTabs = 0;
      }
    }
  },
  computed: {
    ...mapGetters({
      sectionConfirme: "options/sectionConfirme"
    }),
    showDialog: {
      get: function() {
        if (this.show.show) {
          this.getData();
          return this.show.show;
        }
        return false;
      },
      set: function() {
        this.close();
      }
    },
    getInfo() {
      if (this.data) {
        let standard = [];
        let addon = [];
        this.data.map(x => {
          switch (x.type) {
            case 1:
              x.typeName = "Standard";
              standard.push(x);
              break;
            case 2:
              x.typeName = "Tilvalg";
              addon.push(x);
              break;
          }
        });
        return [...standard, ...addon];
      }
      return {};
    },
    getAddon() {
      if (this.dataAddon) {
        return this.dataAddon;
      }
      return [];
    }
  },
  components: {
    DialogImage,
    Cart
  }
};
</script>
