import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading || _vm.getProfile)?_c(VRow,{staticClass:"justify-center my-12"},[_c(VImg,{staticClass:"profile-banner",attrs:{"src":_vm.image.url,"lazy-src":_vm.image.url,"height":"350"}}),(_vm.getProfile)?_c(VCol,{staticClass:"mt-md-6 mr-lg-6",attrs:{"sm":"8","lg":"4","xl":"3"}},[_c(VCard,{staticClass:"theme-shadow"},[_c(VImg,{attrs:{"lazy-src":_vm.optionsService.getProfileImage(_vm.getProfile.id, _vm.getStamp),"src":_vm.optionsService.getProfileImage(_vm.getProfile.id, _vm.getStamp),"max-height":"180","spect-ratio":"2.22222","height":"180"}},[_c('div',{staticClass:"d-flex align-center float-right mr-2 mt-2"},[(_vm.delProfileImage)?_c(VBtn,{staticClass:"mr-1",attrs:{"x-small":"","dark":"","color":"red"},on:{"click":_vm.delImage}},[_vm._v("Slett")]):_vm._e(),(!_vm.delProfileImage)?_c(VBtn,{staticClass:"mr-1",attrs:{"x-small":"","dark":"","color":"primary"},on:{"click":_vm.changeImage}},[_vm._v("Legg til profilbilde")]):_vm._e(),(_vm.delProfileImage)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"text":"","icon":"","dark":""},on:{"click":function($event){return _vm.confirme(true, 'add')}}},on),[_c(VIcon,[_vm._v("mdi-image-edit")])],1)]}}],null,false,3344798175)},[_c('span',[_vm._v("Endre profilbilde")])]):_vm._e(),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"upload",attrs:{"accept":"image/*","type":"file"},on:{"change":_vm.imageUplode}})],1)]),_c(VList,[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_c('h3',{staticClass:"primary--text headline font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getProfile.firstName)+" "+_vm._s(_vm.getProfile.lastName)+" ")])])],1),_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}),[_c(VListItemIcon,[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"color":"blue","fab":"","small":""},on:{"click":_vm.open}},on),[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-account-edit")])],1)]}}],null,false,319320258)},[_c('span',[_vm._v("Endre profil")])])],1)]],2)],1),_vm._l((_vm.profileItems),function(item,index){return _c(VList,{key:index,staticClass:"py-0"},[_c(VListItem,[_c(VListItemAction,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,{staticStyle:{"max-width":"100px"}},[_c(VListItemTitle,[_vm._v(_vm._s(item.title)+":")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.content))])],1)],1)],1)}),_c(VList,{attrs:{"dense":""}},[_c(VSubheader,{staticClass:"ml-3"},[_vm._v("VARSLINGER")]),_c(VListItemGroup,{attrs:{"multiple":""}},[_c(VListItem,[_c(VListItemAction,{staticClass:"mr-3"},[_c(VCheckbox,{attrs:{"color":"success","hide-details":""},on:{"change":function($event){return _vm.save2(_vm.getProfile, 'acceptNewsLetter')}},model:{value:(_vm.getProfile.acceptNewsLetter),callback:function ($$v) {_vm.$set(_vm.getProfile, "acceptNewsLetter", $$v)},expression:"getProfile.acceptNewsLetter"}})],1),_c(VListItemContent,[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VListItemTitle,_vm._g({},on),[_vm._v(" Automatisk nyhetsbrev ")])]}}],null,false,329117290)},[_c('span',[_vm._v("Tips og oppdateringer om boligen sendes i automatiske nyhetsbrev")])])],1)],1)],1)],1)],2)],1):_vm._e(),_c(VCol,{staticClass:"mt-6",attrs:{"sm":"8","lg":"6","xl":"5"}},[_c('ResidentialInfo',{attrs:{"currentSection":_vm.currentSection}}),(!_vm.HasMoveIn(_vm.currentSection.complaintDeadLine))?_c('PersonalInfo',{attrs:{"title":"Kontaktperson","name":_vm.currentSection.salesRepName,"email":_vm.currentSection.salesRepEmail,"phone":_vm.currentSection.salesRepPhone,"photo":_vm.currentSection.salesRepPhoto}}):_vm._e(),_c('MyAgreement',{attrs:{"currentSection":_vm.currentSection,"colorInfo":_vm.colorCart}}),(_vm.HasMoveIn(_vm.currentSection.complaintDeadLine))?_c('PersonalInfo',{attrs:{"title":"Kundeansvarlig","name":_vm.currentSection.qaRepName,"phoneName":"Kundesenter","email":"kundesenter@trysilhus.no","phone":32215510,"photo":_vm.currentSection.qaRepPhoto}}):_vm._e(),(
        _vm.currentSection &&
          _vm.currentSection.reservations &&
          _vm.currentSection.reservations.length > 0
      )?_c('ReservationsProfile',{model:{value:(_vm.currentSection.reservations),callback:function ($$v) {_vm.$set(_vm.currentSection, "reservations", $$v)},expression:"currentSection.reservations"}}):_vm._e(),_c('AccountModal',{attrs:{"dialogTable":_vm.dialogTable,"show":_vm.showDialog}}),_c('AcceptCancelDialog',{attrs:{"title":"Skru av varslinger","DialogMessage":_vm.DialogMessage,"show":_vm.showAcceptCancel},on:{"notifications":_vm.notifications}}),_c('AcceptDialog',{attrs:{"title":_vm.ImageTitle,"DialogMessage":_vm.ImageMessage,"from":_vm.acceptFrom,"show":_vm.showAccept},on:{"confirme":_vm.confirme}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }