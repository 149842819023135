import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"justify-center"},[_c(VCol,{staticClass:"pt-0",attrs:{"xl":"9"}},[_c(VRow,[(!_vm.$store.getters['shared/isMobileDevice'])?_c(VCol,{attrs:{"cols":"12"}},[_c(VList,{attrs:{"color":"transparent"}},[_c(VListItem,{staticClass:"px-0 mb-2"},[_c(VListItemAvatar,[_c(VIcon,{staticClass:"primary white--text"},[_vm._v("mdi-account-group")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"primary--text headline font-weight-black"},[_vm._v(" Mine seksjoner ")])],1),_c(VListItemAction,{staticClass:"d-none d-sm-flex"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"fab":"","dark":"","small":"","color":"blue"},on:{"click":_vm.help}},on),[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-help")])],1)]}}],null,false,278060554)},[_c('span',[_vm._v("Hjelp")])])],1)],1),_c(VDivider)],1)],1):_vm._e()],1),(!_vm.loading)?_c(VRow,_vm._l((_vm.getSection),function(section){return _c(VCol,{key:section.id,attrs:{"sm":"6","lg":"4"}},[_c(VCard,{staticClass:"clickyCard theme-shadow",attrs:{"hover":""},on:{"click":function($event){return _vm.changeSection(section)}}},[_c(VImg,{attrs:{"src":section.imageUrl,"height":"100px"}}),_c(VBtn,{staticClass:"rateInquiryBadge white--text",attrs:{"rounded":"","small":"","height":"24","elevation":"0","color":section.active ? 'green' : 'yellow'}},[_vm._v(" "+_vm._s(section.active ? "Aktiv seksjon" : "Sett seksjon aktiv")+" ")]),_c(VList,{staticClass:"text-center",attrs:{"two-line":""}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_c('h3',[_vm._v(_vm._s(section.name))])]),(section.address)?_c(VListItemSubtitle,[_c('span',[_vm._v(_vm._s(section.address))])]):_vm._e()],1)],1)],1)],1)],1)}),1):_vm._e()],1),_c('HelpBadges',{on:{"help":function($event){_vm.showDialog.show = true}}}),_c('Dialog',{attrs:{"show":_vm.showDialog,"title":_vm.title,"DialogMessage":_vm.DialogMessage,"DialogAccept":_vm.dialogAccept}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }