import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"darkgrey--text mt-4"},[_vm._v(" Dato for overtakelse ")]),_c(VMenu,{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"value":_vm.computedDateFormattedMomentjs,"clearable":"","label":"Dato for overtakelse","readonly":"","rules":[function (v) { return !!v || 'Dato for overtakelse må fylles ut!'; }]},on:{"click:clear":function($event){_vm.date = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c(VDatePicker,{attrs:{"allowed-dates":_vm.allowedDates,"no-title":"","first-day-of-week":1,"show-week":"","min":_vm.minDate,"max":_vm.maxDate},on:{"change":function($event){_vm.menu1 = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }