<template>
  <div>
    <PurchaseOfferContainer />
  </div>
</template>

<script>
import PurchaseOfferContainer from "@/components/store/webStore/purchaseOffer/container/PurchaseOfferContainer.vue";
export default {
  components: {
    PurchaseOfferContainer
  }
};
</script>
