// import baseApi from "@/api/baseApi";
import baseApi from "@/api/api";
import moment from "moment";
import timelineConfig from "@/timelineConfig.json";

export default {
  namespaced: true,

  state: {
    timeline: [],
    documents: []
  },
  getters: {
    timeline: state => {
      return state.timeline;
    },
    documents: state => {
      return state.documents;
    }
  },
  mutations: {
    timeline(state, data) {
      state.timeline = data;
    },
    documents(state, data) {
      state.documents = data;
    }
  },
  actions: {
    async timeline({ commit }, currentSection) {
      const respones = await baseApi.getTimeline(currentSection.id);
      const getTimeline = respones.timeline[0];
      var countHasDone = -1;
      const isNow = moment().format();

      let timelineList = Array.from(
        new Set(getTimeline.events.map(s => s.resourceName))
      ).map(resourceName => {
        return {
          resourceName: resourceName,
          parentId: getTimeline.events.find(
            s => s.resourceName === resourceName
          ).parentId
        };
      });
      //Remove ressurs from timeline
      timelineList = timelineList.filter(x => {
        return (
          x.resourceName.toLowerCase() !== "p-kjeller" &&
          x.resourceName.toLowerCase() !== "grunnarbeider"
        );
      });

      timelineList.forEach(x => {
        var filterList = getTimeline.events.filter(y => {
          return y.resourceName === x.resourceName;
        });
        filterList.forEach(y => {
          if (!x.start && !x.end) {
            x.start = y.start;
            x.end = y.end;
            x.icon = "mdi-hammer";
          } else {
            const isBeforeStart = moment(x.start).isAfter(y.start);
            const isAfterEnd = moment(y.end).isAfter(x.end);
            if (isBeforeStart) {
              x.start = y.start;
            }
            if (isAfterEnd) {
              x.end = y.end;
            }
          }
        });

        //Find if the ressurs has been or not
        const isAfter = moment(moment(isNow).format()).isAfter(x.start);
        const isAfterEnd = moment(moment(isNow).format()).isAfter(x.end);

        if (currentSection.project.projStatus === 4) {
          x.color = "primary";
          x.hasDone = true;
          countHasDone = countHasDone + 1;
        } else {
          if (isAfter && isAfterEnd) {
            x.color = "primary";
            x.hasDone = true;
            countHasDone = countHasDone + 1;
          } else if (isAfter && !isAfterEnd) {
            x.color = "yellow";
            x.hasDone = true;
            countHasDone = countHasDone + 1;
          } else {
            x.color = "grey";
            x.hasDone = false;
          }
        }
      });
      //Sorting timeline list by startdate.
      timelineList.sort(function(a, b) {
        return new Date(a.start) - new Date(b.start);
      });

      if (timelineList.length > 0) {
        //Hvis den første ressursen i årsfremdriftsplanen har startet blir de før satt til ferdig.
        const hasStarted = moment(moment().format()).isAfter(
          timelineList[0].start
        );
        let started = moment().format();
        //hvis status prosjekt er "Gjennomføring" blir prosjektering satt til ferdig og Grunnarbeid til pågående
        //Sluttdato for grunnarbied blir satt til startdato i første i ressurs.
        if (currentSection.project.projStatus === 3 || hasStarted) {
          started = moment()
            .subtract(1, "days")
            .format();
        }

        var data = [
          {
            parentId: 100,
            resourceName: "Prosjektering",
            start: moment()
              .subtract(2, "days")
              .format(),
            end: started
          },
          {
            parentId: 101,
            resourceName: "Grunnarbeid",
            start: started,
            end: timelineList[0].start
          }
        ];
        data.forEach(x => {
          if (currentSection.project.projStatus === 4) {
            x.color = "primary";
            x.hasDone = true;
            countHasDone = countHasDone + 1;
          } else {
            const isAfter = moment(moment(isNow).format()).isAfter(x.start);
            const isAfterEnd = moment(moment(isNow).format()).isAfter(x.end);
            if (isAfter && isAfterEnd) {
              x.color = "primary";
              x.hasDone = true;
              countHasDone = countHasDone + 1;
            } else if (isAfter && !isAfterEnd) {
              x.color = "yellow";
              x.hasDone = true;
              countHasDone = countHasDone + 1;
            } else {
              x.color = "grey";
              x.hasDone = false;
            }
          }
        });
        var AllEvents = [...data, ...timelineList];

        //Sjekk for type of project
        //get image, MTname and description from config.
        let projectTypeId = currentSection.project.projTypeId;
        if (!timelineConfig.timelineConfig[projectTypeId]) {
          projectTypeId = 1;
        }

        AllEvents.forEach(x => {
          timelineConfig.timelineConfig[projectTypeId].forEach(y => {
            if (x.resourceName === y.name) {
              x.description = y.description;
              x.imageName = y.imageName;
              x.nameMT = y.nameMT;
            }
          });
        });

        getTimeline.events = AllEvents;
        getTimeline.countHasDone = countHasDone;
      }
      commit("timeline", getTimeline);
    },
    async documents({ commit }) {
      const data = await baseApi.getDocuments();
      data.documents.forEach(x => {
        if (!x.extension) {
          x.children = [{ name: "Henter data...", extension: "getdata" }];
        }
      });
      commit("documents", data.documents);
    }
  }
};
