<template>
  <div
    class="bg-banner"
    style="background: url(https://thg.blob.core.windows.net/public-images/tilvalgbanner.jpg);"
  >
    <v-card
      class="webstore-info pa-6 pa-sm-12 white--text"
      style="z-index: 1;"
      color="primary"
      elevation="0"
      tile
    >
      <v-card-title class="info-headline justify-center">
        {{ title }}
      </v-card-title>
      <v-card-text class="info-text px-md-12 white--text">
        <slot name="text"></slot>
      </v-card-text>
    </v-card>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: true
    },
    imageUrl: {
      default:
        "https://www.firstplacehousepainting.com/wp-content/uploads/2019/02/david-pisnoy-660309-unsplash.jpg"
    }
  }
};
</script>
