import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"justify-center"},[_c(VCol,{staticClass:"pt-0",attrs:{"xl":"9"}},[(!_vm.$store.getters['shared/isMobileDevice'])?_c(VCard,{staticClass:"transparent mt-6",attrs:{"flat":""}},[_c(VList,{attrs:{"color":"transparent"}},[_c(VListItem,{staticClass:"pl-0 mb-2"},[_c(VListItemAvatar,[_c(VIcon,{staticClass:"primary white--text"},[_vm._v("mdi-folder")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"primary--text headline font-weight-black"},[_vm._v(" Dokumenter ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.getUser)+" ")])],1),_c(VListItemContent,{staticClass:"d-none d-sm-flex text-right justify-end"},[_c('small',{staticStyle:{"max-width":"400px"}},[_vm._v(" FDV for fellesområder kan inneholde lite informasjon i tiden frem til full overlevering av fellesområde til deg som kunde ")])])],1),_c(VDivider)],1)],1):_vm._e(),(_vm.getData.length > 0)?_c(VTreeview,{attrs:{"open":_vm.open,"items":_vm.getData,"item-key":"uuid","open-on-click":"","return-object":""},on:{"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(!item.extension)?_c('span',[(
              item.numberOfUnreadDocuments && item.numberOfUnreadDocuments > 0
            )?_c(VBadge,{attrs:{"content":item.numberOfUnreadDocuments,"color":"warning","overlap":""}},[_c(VIcon,{attrs:{"color":"yellow"}},[_vm._v(_vm._s(open ? "mdi-folder-open" : "mdi-folder"))])],1):_c(VIcon,{attrs:{"color":"yellow"}},[_vm._v(_vm._s(open ? "mdi-folder-open" : "mdi-folder"))])],1):_c('span',[(item.extension && item.extension !== 'getdata')?_c(VIcon,{attrs:{"color":item.isDownloaded === false ? 'success' : 'transparent'}},[_vm._v(" mdi-circle-medium ")]):_vm._e(),_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.files[item.extension])+" ")])],1)]}},{key:"label",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"ml-2",on:{"click":function($event){return _vm.openDocument(item)}}},[_vm._v(" "+_vm._s(item.name)+" "),(
              item.extension &&
                item.extension !== 'getdata' &&
                item.isDownloaded === false
            )?_c('span',{staticClass:"success--text",staticStyle:{"font-style":"oblique"}},[_vm._v(" Ny! ")]):_vm._e()])]}}],null,false,1140015988),model:{value:(_vm.tree),callback:function ($$v) {_vm.tree=$$v},expression:"tree"}}):_vm._e(),(!_vm.loading && _vm.getData.length === 0)?_c(VAlert,{staticClass:"mt-6",attrs:{"type":"info"}},[_vm._v(" Ingen dokumenter tilgjengelig ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }