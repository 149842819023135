<template>
  <div>
    <ColorIntroContainer />
  </div>
</template>

<script>
import ColorIntroContainer from "@/components/store/webStore/color/container/ColorIntroContainer.vue";
export default {
  components: {
    ColorIntroContainer
  }
};
</script>
