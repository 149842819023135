<template>
  <div>
    <v-dialog v-model="showDialog" max-width="450">
      <v-card class="text-center py-6">
        <v-card-title
          class="d-block mx-auto"
          :class="{
            'ml-1': isMobileDevice,
            'ml-2': !isMobileDevice
          }"
        >
          <span class="headline primary--text">{{ title }}</span>
        </v-card-title>
        <v-card-text class="pb-0">
          <div
            :class="{
              'ml-1': isMobileDevice,
              'ml-2': !isMobileDevice
            }"
          >
            <span>
              {{ info }}
            </span>
          </div>

          <div class="d-flex">
            <v-text-field
              v-model="code"
              ref="code"
              @input="newNumber(0)"
              @focus="focusInput('code')"
              :class="{
                'ma-1': isMobileDevice,
                'ma-2': !isMobileDevice,
                'centered-input': true,
                inputNotDisplayUnder: true
              }"
              dense
              filled
              autocomplete="off"
              type="text"
              pattern="[0-9]*"
              inputmode="numeric"
            ></v-text-field>
          </div>
          <div
            :class="{
              'ml-1': isMobileDevice,
              'ml-2': !isMobileDevice
            }"
          >
            <a @click="sendNewCode">Send ny bekreftelseskode</a>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mx-auto"
            outlined
            @click="close"
            v-shortkey.once="['esc']"
            @shortkey="close"
            >Avbryt
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import optionsApi from "@/api/optionsApi";
import api from "@/api/api";
import notificationsService from "@/notification.services";
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["show", "title", "info", "saveTo", "saveNotification", "callback"],
  data() {
    return {
      code: null,
      countError: 0,
      saved: false,
      valueFocus: 0
    };
  },
  methods: {
    ...mapActions({
      changeCart: "options/changeCart",
      profile: "shared/profile"
    }),
    close() {
      if (this.saveTo === "confirmeProfileInfo") {
        this.$emit("closeProfile", this.saved);
      }
      this.saved = false;
      this.show.show = false;
      this.countError = 0;
      this.reset();
    },
    newNumber(value) {
      if (this.code.length === 6) {
        this.confirmecode();
      }
    },
    //When changing inpute field the value in filed gets null
    focusInput(variable) {
      this[variable] = null;
    },
    async confirmecode() {
      if (this.callback) {
        this.$emit("confirmcode", this.code);
        this.close();
      } else if (this.saveTo === "confirmeProfileInfo") {
        this.confirmeProfileInfo(this.code);
      } else {
        var data = { key: "code", value: this.code };
        this.confirmeOptions(data);
      }
    },
    async confirmeOptions(data) {
      await optionsApi
        .confirmcode(data)
        .then(response => {
          if (response) {
            notificationsService.success(response.msg);
            this.changeCart(response.cart);
            //Updating profile. Min avtale gets tilvalg.
            this.profile();
            this.close();
          }
        })
        .catch(err => {
          if (err) {
            notificationsService.error(err);
            ++this.countError;
            this.reset();
            if (this.countError > 4) {
              notificationsService.error(
                "Feil code for mange ganger. Prøv igjen"
              );
              this.close();
            }
          }
        });
    },
    async confirmeProfileInfo(data) {
      await api
        .confirmCodeUser(data)
        .then(response => {
          if (response.status === 200) {
            notificationsService.success(this.saveNotification);
            this.saved = true;
            this.close();
          }
        })
        .catch(err => {
          if (err) {
            notificationsService.error(err);
            ++this.countError;
            this.reset();
            if (this.countError > 4) {
              notificationsService.error(
                "Feil code for mange ganger. Prøv igjen"
              );
              this.close();
            }
          }
        });
    },
    async sendNewCode() {
      if (this.callback) {
        this.$emit("sendNewConfirmcode");
      } else {
        await optionsApi
          .getSendConfirmcode()
          .then(status => {
            if (status === 200) {
              notificationsService.success("Ny bekreftelseskode sendt");
            }
          })
          .catch(err => {
            if (err) {
              notificationsService.error(err);
              this.close();
            }
          });
      }
    },
    reset() {
      this.valueFocus = 0;
      this.code = null;
      this.$refs.code.focus();
    },
    focusInpute(countFocus) {
      setTimeout(() => {
        this.$refs["code"].focus();
      }, 100);
    }
  },
  computed: {
    ...mapGetters({
      isMobileDevice: "shared/isMobileDevice"
    }),
    showDialog: {
      get: function() {
        if (this.show.show) {
          this.focusInpute(this.valueFocus);
          return this.show.show;
        }
        return false;
      },
      set: function() {
        this.close();
      }
    }
  }
};
</script>

<style lang="scss">
.inputNotDisplayUnder.v-text-field.v-text-field--enclosed
  .v-text-field__details {
  display: none;
}
</style>
