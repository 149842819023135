<template>
  <div class="store-intro-bg">
    <v-card
      v-if="
        ((notConfirmed > 0 || !optionsOpen) && !onlyConfirmedNoLimit) ||
          !alldeadline
      "
      class="mx-auto"
      elevation="6"
      width="280"
      min-height="300"
    >
      <div class="login-content pa-12 text-center">
        <v-img
          class="mx-auto"
          width="80"
          :src="'https://thg.blob.core.windows.net/public-images/mt_logo.svg'"
        ></v-img>
        <h3 class="mt-1 mb-3 primary--text">Mitt Trysilhus</h3>
        <div v-if="optionsOpen">
          <p
            class="mb-6"
            v-if="
              ((!confirmed && notConfirmed > 0) ||
                (confirmed && changesConfirmed > 0)) &&
                alldeadline
            "
          >
            <span v-if="confirmed">
              Du har gjort
              <span class="font-weight-bold">{{ changesConfirmed }}</span>
              endring{{ changesConfirmed > 1 ? "er" : "" }} i tilvalg som ikke
              er bekreftet.
            </span>
            <span v-if="!confirmed">
              Du har registrert
              <span class="font-weight-bold">{{ notConfirmed }}</span> tilvalg,
              men dette er ikke bekreftet.
            </span>
          </p>
          <span v-if="!alldeadline"
            >Fristen for å bestille eller gjøre endringer i tilvalg er dessverre
            utløpt.
          </span>
        </div>
        <div v-else>
          <span>
            Det er desverre ikke åpen for din seksjon å bestille tilvalg på Mitt
            Trysilhus.
            <span v-if="alldeadline">
              Kontakt din selger for å bestille tilvalg.
            </span>
          </span>
        </div>
        <div class="text-center">
          <v-btn
            v-if="alldeadline && optionsOpen"
            small
            depressed
            color="primary"
            :to="{ name: 'store' }"
          >
            {{ confirmed ? "Endre tilvalg" : "Bestill tilvalg" }}
          </v-btn>
          <v-btn
            v-if="confirmed"
            class="mt-3"
            small
            depressed
            color="info"
            :to="{ name: 'summary' }"
            >Kvittering
          </v-btn>
        </div>
      </div>
    </v-card>

    <v-card
      v-else
      class="mx-auto"
      elevation="6"
      max-width="460"
      min-width="280"
      min-height="300"
    >
      <div class="login-content pt-9 px-12 pb-12">
        <v-img
          class="mx-auto"
          width="80"
          :src="'https://thg.blob.core.windows.net/public-images/mt_logo.svg'"
        ></v-img>
        <h3 class="text-center mt-1 mb-3 primary--text">Mitt Trysilhus</h3>
        <div v-if="alldeadline || onlyConfirmedNoLimit">
          <p>
            Det er viktig for oss at du trives i din nye bolig, derfor har vi
            utarbeidet en tilvalgsliste hvis du skulle ønske å gjøre noen
            endringer.
          </p>
          <p>
            På de neste sidene finner du en oversikt over tilgjengelig tilvalg,
            samt beskrivelser og pris. En oppsummering vil klargjøres for deg
            når du er klar for bestilling.
          </p>
          <p class="mb-6">Lykke til med planleggingen av ditt nye hjem!</p>
          <div class="text-center">
            <v-btn
              class="py-5 py-md-4"
              small
              depressed
              color="primary"
              :to="{ name: 'store' }"
              >Bestill tilvalg
            </v-btn>
          </div>
          <!-- <div class="text-center">
            <v-btn
              class="mt-2 py-5 py-md-4"
              small
              depressed
              color="primary"
              :to="{ name: 'colors' }"
              >Bestill fargevalg
            </v-btn>
          </div> -->
        </div>
        <div v-else>
          <p>
            Fristen for å bestille tilvalg for din seksjon har dessverre utløpt.
          </p>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  computed: {
    ...mapGetters({
      storeCart: "options/cart",
      sectionConfirme: "options/sectionConfirme",
      changedCartOfConfirme: "options/changedCartOfConfirme",
      alldeadline: "options/alldeadline",
      categories: "options/categories"
    }),
    notConfirmed() {
      if (this.storeCart.length === undefined) {
        let countNotConfirme = 0;
        this.storeCart.groups.forEach(x => {
          x.categories.forEach(y => {
            y.options.forEach(options => {
              if (options.type !== 1 && options.selected === true) {
                countNotConfirme = countNotConfirme + 1;
              }
            });
          });
        });

        return countNotConfirme;
      }
      return 0;
    },
    confirmed() {
      if (this.sectionConfirme) {
        return this.sectionConfirme.options.length > 0 ? true : false;
      }
      return false;
    },
    onlyConfirmedNoLimit() {
      if (
        this.sectionConfirme &&
        this.storeCart.length === undefined &&
        this.categories
      ) {
        var categorie = [];
        this.categories.map(x => {
          x.children.map(y => categorie.push(y.name));
        });
        // Confirmed and categori have deadline
        var confirmed = this.sectionConfirme.options.filter(x => {
          return x.type !== 1 && categorie.includes(x.categoryName);
        });
        // selected and categori have deadline
        this.storeCart.groups.forEach(x => {
          x.categories.forEach(y => {
            y.options.forEach(options => {
              if (
                options.type !== 1 &&
                options.selected === true &&
                categorie.includes(y.name)
              ) {
                confirmed.push(options);
              }
            });
          });
        });
        return confirmed.length === 0 ? true : false;
      }
      return true;
    },
    optionsOpen() {
      if (this.sectionConfirme.options.length !== 0) {
        //If option is added before 15.05.2020 it vil not be posible to change options
        //It vil be posible to si the summary
        const show = this.sectionConfirme.options.some(x => {
          return moment(x.createdDate).isAfter(moment("2020-05-16").format());
        });
        return show;
      }

      return true;
    },
    changesConfirmed() {
      if (this.changedCartOfConfirme) {
        const filter = this.changedCartOfConfirme.filter(x => {
          return x.selected === true;
        });
        return filter.length;
      }
      return 0;
    }
  }
};
</script>

<style lang="scss">
.store-intro-bg {
  background: url("../../assets/imgs/store_intro.jpg") no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
</style>
