<template>
  <div v-if="$vuetify.breakpoint.smAndDown">
    <InfoCardDropDown
      title="Trysilhusbolig"
      icon="mdi-home-edit"
      :removeMarginPrependIcon="true"
      :removeMarginToIcon="true"
      :outlined="true"
      :value="show"
      :includeClass="false"
      @click="show = !show"
    >
      <template v-slot:headerInfo>
        <div class="mr-6">
          <v-chip v-if="getIsFilled" x-small hide-details color="success">
            Fylt ut
          </v-chip>
          <v-chip v-if="!getIsFilled" x-small hide-details color="warning">
            Ikke Fylt
          </v-chip>
        </div>
      </template>
      <!-- <v-expand-transition>
        <div v-show="show">
          <NewHousingItems
            :newhouseData.sync="getNewHouseData"
            :newMonthlyCost.sync="getnewMonthlyCost"
            :totalPrice="totalPrice"
            :powerData="powerData"
          />
        </div>
      </v-expand-transition> -->
      <div class="hsNew">
        <NewHousingItems
          :newhouseData.sync="getNewHouseData"
          :newMonthlyCost.sync="getnewMonthlyCost"
          :totalPrice="totalPrice"
          :powerData="powerData"
          :projectName="projectName"
          @newArea="newArea"
        />
      </div>
    </InfoCardDropDown>
  </div>
  <div v-else class="hsNew">
    <h3 class="text-center pt-4 pb-2">Trysilhusbolig</h3>
    <NewHousingItems
      :newhouseData.sync="getNewHouseData"
      :newMonthlyCost.sync="getnewMonthlyCost"
      :totalPrice="totalPrice"
      :projectName="projectName"
    />
  </div>
</template>

<script>
import InfoCardDropDown from "@/components/shared/InfoCardBaseDropDown.vue";
import NewHousingItems from "@/components/store/webStore/shared/loanCalculation/children/NewHousingItems.vue";

export default {
  props: {
    totalPrice: { type: Number },
    projectName: { type: String },
    newhouseData: { type: Object, required: true },
    newMonthlyCost: { type: Number, default: 0 },
    powerData: { type: Object, required: false, required: false },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    getFormattedTotalPrice() {
      return (
        "( " +
        new Intl.NumberFormat("no-No", {
          minimumFractionDigits: 0,
        }).format(Math.round(this.totalPrice)) +
        " )"
      );
    },
    getIsFilled() {
      return (
        this.capitalHasValue() &&
        this.rentHasValue() &&
        this.timePeriodHasValue()
      );
    },
    getNewHouseData: {
      get() {
        return this.newhouseData;
      },
      set(data) {
        this.$emit("update:newhouseData", data);
      },
    },
    getnewMonthlyCost: {
      get() {
        return this.newhouseData.monthlyCost;
      },
      set(value) {
        this.$emit("update:newMonthlyCost", value);
      },
    },
  },
  methods: {
    capitalHasValue() {
      return this.newhouseData.capital && this.newhouseData.capital > 0;
    },
    rentHasValue() {
      return this.newhouseData.rent && this.getNewHouseData.rent > 0;
    },
    timePeriodHasValue() {
      return (
        this.newhouseData.timePeriod && this.getNewHouseData.timePeriod > 0
      );
    },
    newArea(value) {
      this.$emit("newArea", value);
    },
  },
  components: {
    InfoCardDropDown,
    NewHousingItems,
  },
};
</script>

<style lang="scss" scoped>
.houseTitle {
  margin-top: 3px;
  padding-top: 3px;
  padding-bottom: 0px;
}

.hsNew {
  background-color: rgb(242, 242, 242);
}
</style>
