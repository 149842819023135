<template>
  <div>
    <div v-for="(buyer, index) in data" :key="index">
      <v-row v-if="index !== 0" class="ma-0">
        <h4 class="darkgrey--text mr-3">Medkjøper {{ index }}</h4>
        <v-btn
          @click="deleteCoBuyer(index)"
          x-small
          outlined
          depressed
          color="error"
        >
          Slett
        </v-btn>
      </v-row>

      <v-row>
        <v-col class="pb-0" cols="12" md="6">
          <v-text-field
            v-model="buyer.firstName"
            type="text"
            :rules="[v => !!v || 'Fornavn må være utfylt']"
            label="Fornavn"
            prepend-inner-icon="mdi-account"
            :placeholder="
              `Hva er fornavnet ${index === 0 ? 'ditt?' : 'til medkjøper?'}`
            "
            required
            filled
            autocomplete="given-name"
            :disabled="index === 0"
          ></v-text-field>
        </v-col>
        <v-col class="pb-0" cols="12" md="6">
          <v-text-field
            v-model="buyer.lastName"
            type="text"
            :rules="[v => !!v || 'Etternavn må være utfylt']"
            label="Etternavn"
            prepend-inner-icon="mdi-account"
            :placeholder="
              `Hva er etternavnet ${index === 0 ? 'ditt?' : 'til medkjøper?'}`
            "
            required
            filled
            autocomplete="family-name"
            :disabled="index === 0"
          ></v-text-field>
        </v-col>
        <v-col class="pt-0 pb-0" cols="12" md="6">
          <v-text-field
            v-model="buyer.address"
            :rules="[v => !!v || 'Adresse må være utfylt']"
            label="Adresse"
            prepend-inner-icon="mdi-home"
            placeholder="Eksempelveien 1"
            required
            filled
          ></v-text-field>
        </v-col>
        <v-col class="pt-0 pb-0" cols="12" md="6">
          <v-text-field
            v-model="buyer.postCode"
            :rules="[
              v => !!v || 'Postnummer må være utfylt',
              postCodeNotMoreThanFour
            ]"
            label="Postnummer"
            prepend-inner-icon="mdi-home"
            placeholder="xxxx"
            :counter="4"
            required
            filled
          ></v-text-field>
        </v-col>
        <v-col class="pt-0 pb-0" cols="12" md="6">
          <MobileNumber
            v-model="buyer.mobile"
            filled
            :disabled="index === 0"
            prepend-inner-icon="mdi-phone"
          />
        </v-col>
        <v-col class="pt-0 pb-0" cols="12" md="6">
          <v-text-field
            v-model="buyer.email"
            type="email"
            :rules="emailRules"
            label="E-post"
            prepend-inner-icon="mdi-email"
            placeholder="navn@eksempel.no"
            required
            filled
            :disabled="index === 0"
          ></v-text-field>
        </v-col>
        <v-col class="pt-0 pb-0" cols="12" md="6">
          <PersonNumber
            v-model="buyer.pNumber"
            filled
            prepend-inner-icon="mdi-card-account-details"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import MobileNumber from "@/components/shared/textField/MobileNumber";
import PersonNumber from "@/components/shared/textField/PersonNumber";

export default {
  props: {
    value: {
      required: true
    }
  },
  data: () => ({
    emailRules: [
      v => !!v || "E-post må fylles ut",
      v => /.+@.+\..+/.test(v) || "E-post må være gyldig"
    ]
  }),
  methods: {
    deleteCoBuyer(index) {
      this.data.splice(index, 1);
    }
  },

  computed: {
    data: {
      get() {
        if (this.value && this.value.buyers) {
          return this.value.buyers;
        }
        return [];
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    postCodeNotMoreThanFour() {
      return value =>
        (!!value && value.length <= 4) || "Postnummer må være 4 siffer!";
    }
  },
  components: {
    MobileNumber,
    PersonNumber
  }
};
</script>
